module.exports={
    "SilverDecisions": "1.1.0",
    "buildTimestamp": 1669186055643,
    "savetime": "2024-03-02T14:18:31.540Z",
    "lng": "en",
    "viewMode": "criterion1",
    "rule": "expected-value-maximization",
    "title": "",
    "description": "",
    "format": {
      "locales": "en",
      "payoff1": {
        "style": "currency",
        "currency": "USD",
        "currencyDisplay": "symbol",
        "minimumFractionDigits": 0,
        "maximumFractionDigits": 0,
        "useGrouping": true
      },
      "payoff2": {
        "style": "percent",
        "currency": "USD",
        "currencyDisplay": "symbol",
        "minimumFractionDigits": 0,
        "maximumFractionDigits": 0,
        "useGrouping": true
      },
      "probability": {
        "style": "percent",
        "minimumFractionDigits": 0,
        "maximumFractionDigits": 0,
        "useGrouping": true
      }
    },
    "treeDesigner": {
      "margin": {
        "left": 550,
        "right": 500,
        "top": 200,
        "bottom": 100
      },
      "scale": 1,
      "lng": "en",
      "layout": {
        "type": "tree",
        "nodeSize": 22,
        "limitNodePositioning": true,
        "limitTextPositioning": false,
        "gridHeight": 102,
        "gridWidth": 260,
        "edgeSlantWidthMax": 0
      },
      "fontFamily": "sans-serif",
      "fontSize": "12px",
      "fontWeight": "normal",
      "fontStyle": "normal",
      "node": {
        "strokeWidth": "2px",
        "optimal": {
          "stroke": "#006f00",
          "strokeWidth": "2px"
        },
        "label": {
          "fontSize": "1em",
          "color": "black"
        },
        "payoff": {
          "fontSize": "1em",
          "color": "black",
          "negativeColor": "#b60000"
        },
        "decision": {
          "fill": "#ff7777",
          "stroke": "#660000",
          "selected": {
            "fill": "#aa3333"
          }
        },
        "chance": {
          "fill": "#ffff44",
          "stroke": "#666600",
          "selected": {
            "fill": "#aaaa00"
          }
        },
        "terminal": {
          "fill": "#44ff44",
          "stroke": "black",
          "selected": {
            "fill": "#00aa00"
          },
          "payoff": {
            "fontSize": "1em",
            "color": "black",
            "negativeColor": "#b60000"
          }
        }
      },
      "edge": {
        "stroke": "#424242",
        "strokeWidth": "1.5",
        "optimal": {
          "stroke": "#006f00",
          "strokeWidth": "2.4"
        },
        "selected": {
          "stroke": "#045ad1",
          "strokeWidth": "3.5"
        },
        "label": {
          "fontSize": "1em",
          "color": "back"
        },
        "payoff": {
          "fontSize": "1em",
          "color": "black",
          "negativeColor": "#b60000"
        }
      },
      "probability": {
        "fontSize": "1em",
        "color": "#0000d7"
      },
      "title": {
        "fontSize": "16px",
        "fontWeight": "bold",
        "fontStyle": "normal",
        "color": "#000000",
        "margin": {
          "top": 15,
          "bottom": 10
        }
      },
      "description": {
        "show": true,
        "fontSize": "12px",
        "fontWeight": "bold",
        "fontStyle": "normal",
        "color": "#000000",
        "margin": {
          "top": 5,
          "bottom": 10
        }
      },
      "readOnly": false,
      "disableAnimations": false,
      "forceFullEdgeRedraw": false,
      "hideLabels": false,
      "hidePayoffs": false,
      "hideProbabilities": false,
      "hideCharts": false,
      "hideTextboxes": false,
      "hideMilestones": false,
      "raw": false,
      "payoffNames": [
        null,
        null
      ],
      "maxPayoffsToDisplay": 1,
      "milestoneNames": [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      ]
    },
    "data": {
      "code": "",
      "expressionScope": {},
      "trees": [
        {
          "computed": {
            "expected-value-maximization": {
              "childrenPayoff": [
                "75"
              ],
              "payoff": [
                "75"
              ],
              "optimal": true
            },
            "expected-value-minimization": {
              "childrenPayoff": [
                "5"
              ],
              "payoff": [
                "5"
              ],
              "optimal": true
            },
            "maxi-min": {
              "childrenPayoff": [
                "30"
              ],
              "payoff": [
                "30"
              ],
              "optimal": true
            },
            "maxi-max": {
              "childrenPayoff": [
                "150"
              ],
              "payoff": [
                "150"
              ],
              "optimal": true
            },
            "mini-min": {
              "childrenPayoff": [
                "-100"
              ],
              "payoff": [
                "-100"
              ],
              "optimal": true
            },
            "mini-max": {
              "childrenPayoff": [
                "50"
              ],
              "payoff": [
                "50"
              ],
              "optimal": true
            },
            "min-max": {
              "childrenPayoff": [
                "5",
                "0"
              ],
              "combinedPayoff": "-5",
              "payoff": [
                "5",
                "0"
              ],
              "optimal": true
            },
            "max-min": {
              "childrenPayoff": [
                "75",
                "0"
              ],
              "combinedPayoff": "75",
              "payoff": [
                "75",
                "0"
              ],
              "optimal": true
            },
            "min-min": {
              "childrenPayoff": [
                "5",
                "0"
              ],
              "combinedPayoff": "-5",
              "payoff": [
                "5",
                "0"
              ],
              "optimal": true
            },
            "max-max": {
              "childrenPayoff": [
                "75",
                "0"
              ],
              "combinedPayoff": "75",
              "payoff": [
                "75",
                "0"
              ],
              "optimal": true
            }
          },
          "childEdges": [
            {
              "computed": {
                "payoff": [
                  "0",
                  "0"
                ],
                "expected-value-maximization": {
                  "probability": 1,
                  "optimal": true
                },
                "expected-value-minimization": {
                  "probability": 1,
                  "optimal": true
                },
                "maxi-min": {
                  "probability": 0,
                  "optimal": false
                },
                "maxi-max": {
                  "probability": 1,
                  "optimal": true
                },
                "mini-min": {
                  "probability": 1,
                  "optimal": true
                },
                "mini-max": {
                  "probability": 1,
                  "optimal": true
                },
                "min-max": {
                  "probability": 1,
                  "optimal": true
                },
                "max-min": {
                  "probability": 1,
                  "optimal": true
                },
                "min-min": {
                  "probability": 1,
                  "optimal": true
                },
                "max-max": {
                  "probability": 1,
                  "optimal": true
                }
              },
              "name": "A1",
              "payoff": [
                0,
                0
              ],
              "childNode": {
                "computed": {
                  "expected-value-maximization": {
                    "childrenPayoff": [
                      "75"
                    ],
                    "payoff": [
                      "75"
                    ],
                    "optimal": true
                  },
                  "expected-value-minimization": {
                    "childrenPayoff": [
                      "5"
                    ],
                    "payoff": [
                      "5"
                    ],
                    "optimal": true
                  },
                  "maxi-min": {
                    "childrenPayoff": [
                      "-100"
                    ],
                    "payoff": [
                      "-100"
                    ]
                  },
                  "maxi-max": {
                    "childrenPayoff": [
                      "150"
                    ],
                    "payoff": [
                      "150"
                    ],
                    "optimal": true
                  },
                  "mini-min": {
                    "childrenPayoff": [
                      "-100"
                    ],
                    "payoff": [
                      "-100"
                    ],
                    "optimal": true
                  },
                  "mini-max": {
                    "childrenPayoff": [
                      "50"
                    ],
                    "payoff": [
                      "50"
                    ],
                    "optimal": true
                  },
                  "min-max": {
                    "childrenPayoff": [
                      "5",
                      "0"
                    ],
                    "combinedPayoff": "-5",
                    "payoff": [
                      "5",
                      "0"
                    ],
                    "optimal": true
                  },
                  "max-min": {
                    "childrenPayoff": [
                      "75",
                      "0"
                    ],
                    "combinedPayoff": "75",
                    "payoff": [
                      "75",
                      "0"
                    ],
                    "optimal": true
                  },
                  "min-min": {
                    "childrenPayoff": [
                      "5",
                      "0"
                    ],
                    "combinedPayoff": "-5",
                    "payoff": [
                      "5",
                      "0"
                    ],
                    "optimal": true
                  },
                  "max-max": {
                    "childrenPayoff": [
                      "75",
                      "0"
                    ],
                    "combinedPayoff": "75",
                    "payoff": [
                      "75",
                      "0"
                    ],
                    "optimal": true
                  }
                },
                "childEdges": [
                  {
                    "computed": {
                      "payoff": [
                        "50",
                        "0"
                      ],
                      "probability": "7/10",
                      "expected-value-maximization": {
                        "probability": "7/10",
                        "optimal": true
                      },
                      "expected-value-minimization": {
                        "probability": "7/10",
                        "optimal": true
                      },
                      "maxi-min": {
                        "probability": 0
                      },
                      "maxi-max": {
                        "probability": 1,
                        "optimal": true
                      },
                      "mini-min": {
                        "probability": 0,
                        "optimal": false
                      },
                      "mini-max": {
                        "probability": 1,
                        "optimal": true
                      },
                      "min-max": {
                        "probability": "7/10",
                        "optimal": true
                      },
                      "max-min": {
                        "probability": "7/10",
                        "optimal": true
                      },
                      "min-min": {
                        "probability": "7/10",
                        "optimal": true
                      },
                      "max-max": {
                        "probability": "7/10",
                        "optimal": true
                      }
                    },
                    "name": "B1",
                    "probability": ".7",
                    "payoff": [
                      "50",
                      0
                    ],
                    "childNode": {
                      "computed": {
                        "expected-value-maximization": {
                          "childrenPayoff": [
                            "100"
                          ],
                          "payoff": [
                            "150"
                          ],
                          "optimal": true
                        },
                        "expected-value-minimization": {
                          "childrenPayoff": [
                            "0"
                          ],
                          "payoff": [
                            "50"
                          ],
                          "optimal": true
                        },
                        "maxi-min": {
                          "childrenPayoff": [
                            "100"
                          ],
                          "payoff": [
                            "150"
                          ]
                        },
                        "maxi-max": {
                          "childrenPayoff": [
                            "100"
                          ],
                          "payoff": [
                            "150"
                          ],
                          "optimal": true
                        },
                        "mini-min": {
                          "childrenPayoff": [
                            "0"
                          ],
                          "payoff": [
                            "50"
                          ]
                        },
                        "mini-max": {
                          "childrenPayoff": [
                            "0"
                          ],
                          "payoff": [
                            "50"
                          ],
                          "optimal": true
                        },
                        "min-max": {
                          "childrenPayoff": [
                            "0",
                            "0"
                          ],
                          "combinedPayoff": "-50",
                          "payoff": [
                            "50",
                            "0"
                          ],
                          "optimal": true
                        },
                        "max-min": {
                          "childrenPayoff": [
                            "100",
                            "0"
                          ],
                          "combinedPayoff": "150",
                          "payoff": [
                            "150",
                            "0"
                          ],
                          "optimal": true
                        },
                        "min-min": {
                          "childrenPayoff": [
                            "0",
                            "0"
                          ],
                          "combinedPayoff": "-50",
                          "payoff": [
                            "50",
                            "0"
                          ],
                          "optimal": true
                        },
                        "max-max": {
                          "childrenPayoff": [
                            "100",
                            "0"
                          ],
                          "combinedPayoff": "150",
                          "payoff": [
                            "150",
                            "0"
                          ],
                          "optimal": true
                        }
                      },
                      "childEdges": [
                        {
                          "computed": {
                            "payoff": [
                              "100",
                              "0"
                            ],
                            "expected-value-maximization": {
                              "probability": 1,
                              "optimal": true
                            },
                            "expected-value-minimization": {
                              "probability": 0,
                              "optimal": false
                            },
                            "maxi-min": {
                              "probability": 1
                            },
                            "maxi-max": {
                              "probability": 1,
                              "optimal": true
                            },
                            "mini-min": {
                              "probability": 0
                            },
                            "mini-max": {
                              "probability": 0,
                              "optimal": false
                            },
                            "min-max": {
                              "probability": 0,
                              "optimal": false
                            },
                            "max-min": {
                              "probability": 1,
                              "optimal": true
                            },
                            "min-min": {
                              "probability": 0,
                              "optimal": false
                            },
                            "max-max": {
                              "probability": 1,
                              "optimal": true
                            }
                          },
                          "name": "C1",
                          "payoff": [
                            "100",
                            0
                          ],
                          "childNode": {
                            "computed": {
                              "expected-value-maximization": {
                                "aggregatedPayoff": [
                                  "150"
                                ],
                                "probabilityToEnter": "7/10",
                                "payoff": [
                                  "100"
                                ],
                                "optimal": true
                              },
                              "expected-value-minimization": {
                                "aggregatedPayoff": [
                                  "150"
                                ],
                                "probabilityToEnter": 0,
                                "payoff": [
                                  "100"
                                ]
                              },
                              "maxi-min": {
                                "aggregatedPayoff": [
                                  "150"
                                ],
                                "probabilityToEnter": 0,
                                "payoff": [
                                  "100"
                                ]
                              },
                              "maxi-max": {
                                "aggregatedPayoff": [
                                  "150"
                                ],
                                "probabilityToEnter": "1",
                                "payoff": [
                                  "100"
                                ],
                                "optimal": true
                              },
                              "mini-min": {
                                "aggregatedPayoff": [
                                  "150"
                                ],
                                "probabilityToEnter": 0,
                                "payoff": [
                                  "100"
                                ]
                              },
                              "mini-max": {
                                "aggregatedPayoff": [
                                  "150"
                                ],
                                "probabilityToEnter": 0,
                                "payoff": [
                                  "100"
                                ]
                              },
                              "min-max": {
                                "aggregatedPayoff": [
                                  "150",
                                  "0"
                                ],
                                "probabilityToEnter": 0,
                                "combinedPayoff": "-100",
                                "payoff": [
                                  "100",
                                  "0"
                                ]
                              },
                              "max-min": {
                                "aggregatedPayoff": [
                                  "150",
                                  "0"
                                ],
                                "probabilityToEnter": "7/10",
                                "combinedPayoff": "100",
                                "payoff": [
                                  "100",
                                  "0"
                                ],
                                "optimal": true
                              },
                              "min-min": {
                                "aggregatedPayoff": [
                                  "150",
                                  "0"
                                ],
                                "probabilityToEnter": 0,
                                "combinedPayoff": "-100",
                                "payoff": [
                                  "100",
                                  "0"
                                ]
                              },
                              "max-max": {
                                "aggregatedPayoff": [
                                  "150",
                                  "0"
                                ],
                                "probabilityToEnter": "7/10",
                                "combinedPayoff": "100",
                                "payoff": [
                                  "100",
                                  "0"
                                ],
                                "optimal": true
                              }
                            },
                            "childEdges": [],
                            "name": "Payoff",
                            "code": "",
                            "expressionScope": {},
                            "folded": false,
                            "location": {
                              "x": 791,
                              "y": 11
                            },
                            "type": "terminal",
                            "expectedValue": 150,
                            "p10": 150,
                            "p90": 150
                          },
                          "expectedValue": 100,
                          "p10": 100,
                          "p90": 100,
                          "optimal": true
                        },
                        {
                          "computed": {
                            "payoff": [
                              "0",
                              "0"
                            ],
                            "expected-value-maximization": {
                              "probability": 0,
                              "optimal": false
                            },
                            "expected-value-minimization": {
                              "probability": 1,
                              "optimal": true
                            },
                            "maxi-min": {
                              "probability": 0
                            },
                            "maxi-max": {
                              "probability": 0,
                              "optimal": false
                            },
                            "mini-min": {
                              "probability": 1
                            },
                            "mini-max": {
                              "probability": 1,
                              "optimal": true
                            },
                            "min-max": {
                              "probability": 1,
                              "optimal": true
                            },
                            "max-min": {
                              "probability": 0,
                              "optimal": false
                            },
                            "min-min": {
                              "probability": 1,
                              "optimal": true
                            },
                            "max-max": {
                              "probability": 0,
                              "optimal": false
                            }
                          },
                          "name": "C2",
                          "payoff": [
                            0,
                            0
                          ],
                          "childNode": {
                            "computed": {
                              "expected-value-maximization": {
                                "aggregatedPayoff": [
                                  "50"
                                ],
                                "probabilityToEnter": 0,
                                "payoff": [
                                  "0"
                                ]
                              },
                              "expected-value-minimization": {
                                "aggregatedPayoff": [
                                  "50"
                                ],
                                "probabilityToEnter": "7/10",
                                "payoff": [
                                  "0"
                                ],
                                "optimal": true
                              },
                              "maxi-min": {
                                "aggregatedPayoff": [
                                  "50"
                                ],
                                "probabilityToEnter": 0,
                                "payoff": [
                                  "0"
                                ]
                              },
                              "maxi-max": {
                                "aggregatedPayoff": [
                                  "50"
                                ],
                                "probabilityToEnter": 0,
                                "payoff": [
                                  "0"
                                ]
                              },
                              "mini-min": {
                                "aggregatedPayoff": [
                                  "50"
                                ],
                                "probabilityToEnter": 0,
                                "payoff": [
                                  "0"
                                ]
                              },
                              "mini-max": {
                                "aggregatedPayoff": [
                                  "50"
                                ],
                                "probabilityToEnter": "1",
                                "payoff": [
                                  "0"
                                ],
                                "optimal": true
                              },
                              "min-max": {
                                "aggregatedPayoff": [
                                  "50",
                                  "0"
                                ],
                                "probabilityToEnter": "7/10",
                                "combinedPayoff": "0",
                                "payoff": [
                                  "0",
                                  "0"
                                ],
                                "optimal": true
                              },
                              "max-min": {
                                "aggregatedPayoff": [
                                  "50",
                                  "0"
                                ],
                                "probabilityToEnter": 0,
                                "combinedPayoff": "0",
                                "payoff": [
                                  "0",
                                  "0"
                                ]
                              },
                              "min-min": {
                                "aggregatedPayoff": [
                                  "50",
                                  "0"
                                ],
                                "probabilityToEnter": "7/10",
                                "combinedPayoff": "0",
                                "payoff": [
                                  "0",
                                  "0"
                                ],
                                "optimal": true
                              },
                              "max-max": {
                                "aggregatedPayoff": [
                                  "50",
                                  "0"
                                ],
                                "probabilityToEnter": 0,
                                "combinedPayoff": "0",
                                "payoff": [
                                  "0",
                                  "0"
                                ]
                              }
                            },
                            "childEdges": [],
                            "name": "Payoff",
                            "code": "",
                            "expressionScope": {},
                            "folded": false,
                            "location": {
                              "x": 791,
                              "y": 113.00000000000001
                            },
                            "type": "terminal",
                            "expectedValue": 50,
                            "p10": 50,
                            "p90": 50
                          },
                          "expectedValue": 0,
                          "p10": 0,
                          "p90": 0,
                          "optimal": false
                        }
                      ],
                      "name": "C",
                      "code": "",
                      "expressionScope": {},
                      "folded": false,
                      "location": {
                        "x": 531,
                        "y": 62
                      },
                      "type": "decision",
                      "expectedValue": 100,
                      "p10": 100,
                      "p90": 100
                    },
                    "expectedValue": 50,
                    "p10": 50,
                    "p90": 50,
                    "optimal": true
                  },
                  {
                    "computed": {
                      "payoff": [
                        "-100",
                        "0"
                      ],
                      "probability": "3/10",
                      "expected-value-maximization": {
                        "probability": "3/10",
                        "optimal": true
                      },
                      "expected-value-minimization": {
                        "probability": "3/10",
                        "optimal": true
                      },
                      "maxi-min": {
                        "probability": 1
                      },
                      "maxi-max": {
                        "probability": 0,
                        "optimal": false
                      },
                      "mini-min": {
                        "probability": 1,
                        "optimal": true
                      },
                      "mini-max": {
                        "probability": 0,
                        "optimal": false
                      },
                      "min-max": {
                        "probability": "3/10",
                        "optimal": true
                      },
                      "max-min": {
                        "probability": "3/10",
                        "optimal": true
                      },
                      "min-min": {
                        "probability": "3/10",
                        "optimal": true
                      },
                      "max-max": {
                        "probability": "3/10",
                        "optimal": true
                      }
                    },
                    "name": "B2",
                    "probability": "#",
                    "payoff": [
                      "-100",
                      0
                    ],
                    "childNode": {
                      "computed": {
                        "expected-value-maximization": {
                          "aggregatedPayoff": [
                            "-100"
                          ],
                          "probabilityToEnter": "3/10",
                          "payoff": [
                            "-100"
                          ],
                          "optimal": true
                        },
                        "expected-value-minimization": {
                          "aggregatedPayoff": [
                            "-100"
                          ],
                          "probabilityToEnter": "3/10",
                          "payoff": [
                            "-100"
                          ],
                          "optimal": true
                        },
                        "maxi-min": {
                          "aggregatedPayoff": [
                            "-100"
                          ],
                          "probabilityToEnter": 0,
                          "payoff": [
                            "-100"
                          ]
                        },
                        "maxi-max": {
                          "aggregatedPayoff": [
                            "-100"
                          ],
                          "probabilityToEnter": 0,
                          "payoff": [
                            "-100"
                          ]
                        },
                        "mini-min": {
                          "aggregatedPayoff": [
                            "-100"
                          ],
                          "probabilityToEnter": "1",
                          "payoff": [
                            "-100"
                          ],
                          "optimal": true
                        },
                        "mini-max": {
                          "aggregatedPayoff": [
                            "-100"
                          ],
                          "probabilityToEnter": 0,
                          "payoff": [
                            "-100"
                          ]
                        },
                        "min-max": {
                          "aggregatedPayoff": [
                            "-100",
                            "0"
                          ],
                          "probabilityToEnter": "3/10",
                          "combinedPayoff": "100",
                          "payoff": [
                            "-100",
                            "0"
                          ],
                          "optimal": true
                        },
                        "max-min": {
                          "aggregatedPayoff": [
                            "-100",
                            "0"
                          ],
                          "probabilityToEnter": "3/10",
                          "combinedPayoff": "-100",
                          "payoff": [
                            "-100",
                            "0"
                          ],
                          "optimal": true
                        },
                        "min-min": {
                          "aggregatedPayoff": [
                            "-100",
                            "0"
                          ],
                          "probabilityToEnter": "3/10",
                          "combinedPayoff": "100",
                          "payoff": [
                            "-100",
                            "0"
                          ],
                          "optimal": true
                        },
                        "max-max": {
                          "aggregatedPayoff": [
                            "-100",
                            "0"
                          ],
                          "probabilityToEnter": "3/10",
                          "combinedPayoff": "-100",
                          "payoff": [
                            "-100",
                            "0"
                          ],
                          "optimal": true
                        }
                      },
                      "childEdges": [],
                      "name": "Payoff",
                      "code": "",
                      "expressionScope": {},
                      "folded": false,
                      "location": {
                        "x": 531,
                        "y": 164
                      },
                      "type": "terminal",
                      "expectedValue": -100,
                      "p10": -100,
                      "p90": -100
                    },
                    "expectedValue": -100,
                    "p10": -100,
                    "p90": -100,
                    "optimal": true
                  }
                ],
                "name": "B",
                "code": "",
                "expressionScope": {},
                "folded": false,
                "location": {
                  "x": 271,
                  "y": 113.00000000000001
                },
                "type": "chance",
                "expectedValue": 75,
                "p10": -100,
                "p90": 150
              },
              "expectedValue": 0,
              "p10": 0,
              "p90": 0,
              "optimal": true
            },
            {
              "computed": {
                "payoff": [
                  "0",
                  "0"
                ],
                "expected-value-maximization": {
                  "probability": 0,
                  "optimal": false
                },
                "expected-value-minimization": {
                  "probability": 0,
                  "optimal": false
                },
                "maxi-min": {
                  "probability": 1,
                  "optimal": true
                },
                "maxi-max": {
                  "probability": 0,
                  "optimal": false
                },
                "mini-min": {
                  "probability": 0,
                  "optimal": false
                },
                "mini-max": {
                  "probability": 1,
                  "optimal": true
                },
                "min-max": {
                  "probability": 0,
                  "optimal": false
                },
                "max-min": {
                  "probability": 0,
                  "optimal": false
                },
                "min-min": {
                  "probability": 0,
                  "optimal": false
                },
                "max-max": {
                  "probability": 0,
                  "optimal": false
                }
              },
              "name": "A2",
              "payoff": [
                0,
                0
              ],
              "childNode": {
                "computed": {
                  "expected-value-maximization": {
                    "childrenPayoff": [
                      "40"
                    ],
                    "payoff": [
                      "40"
                    ]
                  },
                  "expected-value-minimization": {
                    "childrenPayoff": [
                      "40"
                    ],
                    "payoff": [
                      "40"
                    ]
                  },
                  "maxi-min": {
                    "childrenPayoff": [
                      "30"
                    ],
                    "payoff": [
                      "30"
                    ],
                    "optimal": true
                  },
                  "maxi-max": {
                    "childrenPayoff": [
                      "50"
                    ],
                    "payoff": [
                      "50"
                    ]
                  },
                  "mini-min": {
                    "childrenPayoff": [
                      "30"
                    ],
                    "payoff": [
                      "30"
                    ]
                  },
                  "mini-max": {
                    "childrenPayoff": [
                      "50"
                    ],
                    "payoff": [
                      "50"
                    ],
                    "optimal": true
                  },
                  "min-max": {
                    "childrenPayoff": [
                      "40",
                      "0"
                    ],
                    "combinedPayoff": "-40",
                    "payoff": [
                      "40",
                      "0"
                    ]
                  },
                  "max-min": {
                    "childrenPayoff": [
                      "40",
                      "0"
                    ],
                    "combinedPayoff": "40",
                    "payoff": [
                      "40",
                      "0"
                    ]
                  },
                  "min-min": {
                    "childrenPayoff": [
                      "40",
                      "0"
                    ],
                    "combinedPayoff": "-40",
                    "payoff": [
                      "40",
                      "0"
                    ]
                  },
                  "max-max": {
                    "childrenPayoff": [
                      "40",
                      "0"
                    ],
                    "combinedPayoff": "40",
                    "payoff": [
                      "40",
                      "0"
                    ]
                  }
                },
                "childEdges": [
                  {
                    "computed": {
                      "payoff": [
                        "50",
                        "0"
                      ],
                      "probability": "1/2",
                      "expected-value-maximization": {
                        "probability": "1/2"
                      },
                      "expected-value-minimization": {
                        "probability": "1/2"
                      },
                      "maxi-min": {
                        "probability": 0,
                        "optimal": false
                      },
                      "maxi-max": {
                        "probability": 1
                      },
                      "mini-min": {
                        "probability": 0
                      },
                      "mini-max": {
                        "probability": 1,
                        "optimal": true
                      },
                      "min-max": {
                        "probability": "1/2"
                      },
                      "max-min": {
                        "probability": "1/2"
                      },
                      "min-min": {
                        "probability": "1/2"
                      },
                      "max-max": {
                        "probability": "1/2"
                      }
                    },
                    "name": "",
                    "probability": "#",
                    "payoff": [
                      "50",
                      0
                    ],
                    "childNode": {
                      "computed": {
                        "expected-value-maximization": {
                          "aggregatedPayoff": [
                            "50"
                          ],
                          "probabilityToEnter": 0,
                          "payoff": [
                            "50"
                          ]
                        },
                        "expected-value-minimization": {
                          "aggregatedPayoff": [
                            "50"
                          ],
                          "probabilityToEnter": 0,
                          "payoff": [
                            "50"
                          ]
                        },
                        "maxi-min": {
                          "aggregatedPayoff": [
                            "50"
                          ],
                          "probabilityToEnter": 0,
                          "payoff": [
                            "50"
                          ]
                        },
                        "maxi-max": {
                          "aggregatedPayoff": [
                            "50"
                          ],
                          "probabilityToEnter": 0,
                          "payoff": [
                            "50"
                          ]
                        },
                        "mini-min": {
                          "aggregatedPayoff": [
                            "50"
                          ],
                          "probabilityToEnter": 0,
                          "payoff": [
                            "50"
                          ]
                        },
                        "mini-max": {
                          "aggregatedPayoff": [
                            "50"
                          ],
                          "probabilityToEnter": "1",
                          "payoff": [
                            "50"
                          ],
                          "optimal": true
                        },
                        "min-max": {
                          "aggregatedPayoff": [
                            "50",
                            "0"
                          ],
                          "probabilityToEnter": 0,
                          "combinedPayoff": "-50",
                          "payoff": [
                            "50",
                            "0"
                          ]
                        },
                        "max-min": {
                          "aggregatedPayoff": [
                            "50",
                            "0"
                          ],
                          "probabilityToEnter": 0,
                          "combinedPayoff": "50",
                          "payoff": [
                            "50",
                            "0"
                          ]
                        },
                        "min-min": {
                          "aggregatedPayoff": [
                            "50",
                            "0"
                          ],
                          "probabilityToEnter": 0,
                          "combinedPayoff": "-50",
                          "payoff": [
                            "50",
                            "0"
                          ]
                        },
                        "max-max": {
                          "aggregatedPayoff": [
                            "50",
                            "0"
                          ],
                          "probabilityToEnter": 0,
                          "combinedPayoff": "50",
                          "payoff": [
                            "50",
                            "0"
                          ]
                        }
                      },
                      "childEdges": [],
                      "name": "Payoff",
                      "code": "",
                      "expressionScope": {},
                      "folded": false,
                      "location": {
                        "x": 531,
                        "y": 286.40000000000003
                      },
                      "type": "terminal",
                      "expectedValue": 50,
                      "p10": 50,
                      "p90": 50
                    },
                    "expectedValue": 50,
                    "p10": 50,
                    "p90": 50,
                    "optimal": false
                  },
                  {
                    "computed": {
                      "payoff": [
                        "30",
                        "0"
                      ],
                      "probability": "1/2",
                      "expected-value-maximization": {
                        "probability": "1/2"
                      },
                      "expected-value-minimization": {
                        "probability": "1/2"
                      },
                      "maxi-min": {
                        "probability": 1,
                        "optimal": true
                      },
                      "maxi-max": {
                        "probability": 0
                      },
                      "mini-min": {
                        "probability": 1
                      },
                      "mini-max": {
                        "probability": 0,
                        "optimal": false
                      },
                      "min-max": {
                        "probability": "1/2"
                      },
                      "max-min": {
                        "probability": "1/2"
                      },
                      "min-min": {
                        "probability": "1/2"
                      },
                      "max-max": {
                        "probability": "1/2"
                      }
                    },
                    "name": "",
                    "probability": "#",
                    "payoff": [
                      "30",
                      0
                    ],
                    "childNode": {
                      "computed": {
                        "expected-value-maximization": {
                          "aggregatedPayoff": [
                            "30"
                          ],
                          "probabilityToEnter": 0,
                          "payoff": [
                            "30"
                          ]
                        },
                        "expected-value-minimization": {
                          "aggregatedPayoff": [
                            "30"
                          ],
                          "probabilityToEnter": 0,
                          "payoff": [
                            "30"
                          ]
                        },
                        "maxi-min": {
                          "aggregatedPayoff": [
                            "30"
                          ],
                          "probabilityToEnter": "1",
                          "payoff": [
                            "30"
                          ],
                          "optimal": true
                        },
                        "maxi-max": {
                          "aggregatedPayoff": [
                            "30"
                          ],
                          "probabilityToEnter": 0,
                          "payoff": [
                            "30"
                          ]
                        },
                        "mini-min": {
                          "aggregatedPayoff": [
                            "30"
                          ],
                          "probabilityToEnter": 0,
                          "payoff": [
                            "30"
                          ]
                        },
                        "mini-max": {
                          "aggregatedPayoff": [
                            "30"
                          ],
                          "probabilityToEnter": 0,
                          "payoff": [
                            "30"
                          ]
                        },
                        "min-max": {
                          "aggregatedPayoff": [
                            "30",
                            "0"
                          ],
                          "probabilityToEnter": 0,
                          "combinedPayoff": "-30",
                          "payoff": [
                            "30",
                            "0"
                          ]
                        },
                        "max-min": {
                          "aggregatedPayoff": [
                            "30",
                            "0"
                          ],
                          "probabilityToEnter": 0,
                          "combinedPayoff": "30",
                          "payoff": [
                            "30",
                            "0"
                          ]
                        },
                        "min-min": {
                          "aggregatedPayoff": [
                            "30",
                            "0"
                          ],
                          "probabilityToEnter": 0,
                          "combinedPayoff": "-30",
                          "payoff": [
                            "30",
                            "0"
                          ]
                        },
                        "max-max": {
                          "aggregatedPayoff": [
                            "30",
                            "0"
                          ],
                          "probabilityToEnter": 0,
                          "combinedPayoff": "30",
                          "payoff": [
                            "30",
                            "0"
                          ]
                        }
                      },
                      "childEdges": [],
                      "name": "Payoff",
                      "code": "",
                      "expressionScope": {},
                      "folded": false,
                      "location": {
                        "x": 531,
                        "y": 388.40000000000003
                      },
                      "type": "terminal",
                      "expectedValue": 30,
                      "p10": 30,
                      "p90": 30
                    },
                    "expectedValue": 30,
                    "p10": 30,
                    "p90": 30,
                    "optimal": false
                  }
                ],
                "name": "Chance",
                "code": "",
                "expressionScope": {},
                "folded": false,
                "location": {
                  "x": 271,
                  "y": 337.40000000000003
                },
                "type": "chance",
                "expectedValue": 40,
                "p10": 30,
                "p90": 50
              },
              "expectedValue": 0,
              "p10": 0,
              "p90": 0,
              "optimal": false
            }
          ],
          "name": "A",
          "code": "",
          "expressionScope": {},
          "folded": false,
          "location": {
            "x": 11,
            "y": 225.20000000000002
          },
          "type": "decision",
          "expectedValue": 75,
          "p10": -100,
          "p90": 150
        }
      ],
      "texts": [],
      "payoffNames": [],
      "defaultCriterion1Weight": 1,
      "weightLowerBound": 0,
      "weightUpperBound": "Infinity"
    },
    "sensitivityAnalysis": {},
    "definitionsDialog": {
      "valueList": [
        {
          "name": null,
          "value": "",
          "low": null,
          "high": null,
          "distribution": "constant",
          "error": ""
        },
        {
          "name": null,
          "value": "",
          "low": null,
          "high": null,
          "distribution": "constant",
          "error": ""
        },
        {
          "name": null,
          "value": "",
          "low": null,
          "high": null,
          "distribution": "constant",
          "error": ""
        },
        {
          "name": null,
          "value": "",
          "low": null,
          "high": null,
          "distribution": "constant",
          "error": ""
        },
        {
          "name": null,
          "value": "",
          "low": null,
          "high": null,
          "distribution": "constant",
          "error": ""
        }
      ]
    }
  }