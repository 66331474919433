import charts_for_insight_tutorial_1 from './tutorial-trees/charts-for-insight-tutorial-trees/charts_for_insight_1.json';
import charts_for_insight_tutorial_2 from './tutorial-trees/charts-for-insight-tutorial-trees/charts_for_insight_2.json';
import charts_for_insight_tutorial_3 from './tutorial-trees/charts-for-insight-tutorial-trees/charts_for_insight_3.json';
import charts_for_insight_tutorial_4 from './tutorial-trees/charts-for-insight-tutorial-trees/charts_for_insight_4.json';
import charts_for_insight_tutorial_5 from './tutorial-trees/charts-for-insight-tutorial-trees/charts_for_insight_5.json';
import charts_for_insight_tutorial_6 from './tutorial-trees/charts-for-insight-tutorial-trees/charts_for_insight_6.json';
import charts_for_insight_tutorial_7 from './tutorial-trees/charts-for-insight-tutorial-trees/charts_for_insight_7.json';
import charts_for_insight_tutorial_8 from './tutorial-trees/charts-for-insight-tutorial-trees/charts_for_insight_8.json';
import charts_for_insight_tutorial_9 from './tutorial-trees/charts-for-insight-tutorial-trees/charts_for_insight_9.json';
import charts_for_insight_tutorial_10 from './tutorial-trees/charts-for-insight-tutorial-trees/charts_for_insight_10.json';
import charts_for_insight_tutorial_11 from './tutorial-trees/charts-for-insight-tutorial-trees/charts_for_insight_11.json';
import charts_for_insight_tutorial_12 from './tutorial-trees/charts-for-insight-tutorial-trees/charts_for_insight_12.json';
import charts_for_insight_tutorial_13 from './tutorial-trees/charts-for-insight-tutorial-trees/charts_for_insight_13.json';
import charts_for_insight_tutorial_14 from './tutorial-trees/charts-for-insight-tutorial-trees/charts_for_insight_14.json';
import charts_for_insight_tutorial_15 from './tutorial-trees/charts-for-insight-tutorial-trees/charts_for_insight_15.json';
import charts_for_insight_tutorial_16 from './tutorial-trees/charts-for-insight-tutorial-trees/charts_for_insight_16.json';
import charts_for_insight_tutorial_17 from './tutorial-trees/charts-for-insight-tutorial-trees/charts_for_insight_17.json';
import charts_for_insight_tutorial_18 from './tutorial-trees/charts-for-insight-tutorial-trees/charts_for_insight_18.json';
import charts_for_insight_tutorial_19 from './tutorial-trees/charts-for-insight-tutorial-trees/charts_for_insight_19.json';
import charts_for_insight_tutorial_20 from './tutorial-trees/charts-for-insight-tutorial-trees/charts_for_insight_20.json';
import charts_for_insight_tutorial_21 from './tutorial-trees/charts-for-insight-tutorial-trees/charts_for_insight_21.json';
import charts_for_insight_tutorial_22 from './tutorial-trees/charts-for-insight-tutorial-trees/charts_for_insight_22.json';
import charts_for_insight_tutorial_23 from './tutorial-trees/charts-for-insight-tutorial-trees/charts_for_insight_23.json';

const chartsForInsightTutorialFiles = [
    charts_for_insight_tutorial_1,
    charts_for_insight_tutorial_2,
    charts_for_insight_tutorial_3,
    charts_for_insight_tutorial_4,
    charts_for_insight_tutorial_5,
    charts_for_insight_tutorial_6,
    charts_for_insight_tutorial_7,
    charts_for_insight_tutorial_8,
    charts_for_insight_tutorial_9,
    charts_for_insight_tutorial_10,
    charts_for_insight_tutorial_11,
    charts_for_insight_tutorial_12,
    charts_for_insight_tutorial_13,
    charts_for_insight_tutorial_14,
    charts_for_insight_tutorial_15,
    charts_for_insight_tutorial_16,
    charts_for_insight_tutorial_17,
    charts_for_insight_tutorial_18,
    charts_for_insight_tutorial_19,
    charts_for_insight_tutorial_20,
    charts_for_insight_tutorial_21,
    charts_for_insight_tutorial_22,
    charts_for_insight_tutorial_23,
];

export default chartsForInsightTutorialFiles;