const theBasicsTutorialHtml = [
    "<p>First time clicking around dNodes.io?</p> <p>No stress! We’re here to guide you through the basics.</p> <p>Use your <strong>ARROW KEYS</strong> ← → on the keyboard to click through the tutorial.</p> <p>Let’s get started!</p>",
    
    "<p><u>Tip</u><br>In the Help menu you’ll find more advanced tutorials for later.</p><img src=\"/build/images/tutorial-images/help-menu-screenshot.png\" alt=\"\"> <br><br> <p><u>Mouse actions</u><br>Open the mouse actions menu to see available mouse actions.</p><img src=\"/build/images/tutorial-images/mouse-actions.png\" alt=\"\">",
    
    "<p><i>Tutorial overview</i></p> <p>1. We’ll start by building the basic tree structure you see to the right.</p> <p class='tutorial-text-opacity'>2. We will add annotations and probabilities as we go along.</p> <p class='tutorial-text-opacity'>3. We'll play around with payoffs to see how the tree does its math.</p> <p class='tutorial-text-opacity'>4. And we’ll add a few charts and milestones for extra insight.</p>",
    
    "<p><i>Tutorial overview</i></p> <p  class='tutorial-text-opacity'>1. We’ll start by building the basic tree structure you see to the right.</p> <p>2. We will add annotations and probabilities as we go along.</p> <p class='tutorial-text-opacity'>3. We'll play around with payoffs to see how the tree does its math.</p> <p class='tutorial-text-opacity'>4. And we’ll add a few charts and milestones for extra insight.</p>",
    
    "<p><i>Tutorial overview</i></p> <p  class='tutorial-text-opacity'>1. We’ll start by building the basic tree structure you see to the right.</p> <p class='tutorial-text-opacity'>2. We will add annotations and probabilities as we go along.</p> <p>3. We'll play around with payoffs to see how the tree does its math.</p> <p class='tutorial-text-opacity'>4. And we’ll add a few charts and milestones for extra insight.</p>",
    
    "<p><i>Tutorial overview</i></p> <p  class='tutorial-text-opacity'>1. We’ll start by building the basic tree structure you see to the right.</p> <p class='tutorial-text-opacity'>2. We will add annotations and probabilities as we go along.</p> <p class='tutorial-text-opacity'>3. We'll play around with payoffs to see how the tree does its math.</p> <p>4. And we’ll add a few charts and milestones for extra insight.</>",
    
    "<p><u>Here's a cool tip</u><br>You can play around and tweak the tree at any step, testing your own ideas and assumptions!</p> <p>Did you notice? I just flipped the tree. Try it yourself by dragging a node to a new position.</p>",
    
    "<p>But first: A decision tree is built up from three basic elements.</p><p><img src=\"/build/images/tutorial-images/decision-node.png\" alt=\"\"><strong>Decision</strong> nodes are points where decisions are made.</p><p><img src=\"/build/images/tutorial-images/chance-node.png\" alt=\"\"><strong>Chance</strong> nodes are points where uncertainty is resolved.</p><p><img src=\"/build/images/tutorial-images/terminal-node.png\" alt=\"\"><strong>Terminal</strong> nodes are points that return the <strong>Payoff</strong>.</p><p><u>Note</u><br>The payoff can be profit, cost, time, distance, or another measure.</p>",
    
    "<p>So, what exactly is a decision tree?</p><p>Well, it's basically a visual way to show the structure of a problem.</p> <p class='tutorial-text-opacity'>Navigating a decision tree from left to right  is like a journey through time. Decision nodes are points in time where choices are made in the face of uncertainty. While chance nodes are points in time when those uncertainties are resolved.</p><p class='tutorial-text-opacity'>Often, a decision tree is linked to important project milestones as illustrated here. More on this later.</p>",
    
    "<p class='tutorial-text-opacity'>So, what exactly is a decision tree?</p><p class='tutorial-text-opacity'>Well, it's basically a visual way to show the structure of a problem.</p> <p>Navigating a decision tree from left to right  is like a journey through time. Decision nodes are points in time where choices are made in the face of uncertainty. While chance nodes are points in time when those uncertainties are resolved.</p><p class='tutorial-text-opacity'>Often, a decision tree is linked to important project milestones as illustrated here. More on this later.</p>",
    
    "<p class='tutorial-text-opacity'>So, what exactly is a decision tree?</p><p class='tutorial-text-opacity'>Well, it's basically a visual way to show the structure of a problem.</p class='tutorial-text-opacity'> <p class='tutorial-text-opacity'>Navigating a decision tree from left to right  is like a journey through time. Decision nodes are points in time where choices are made in the face of uncertainty. While chance nodes are points in time when those uncertainties are resolved.</p><p>Often, a decision tree is linked to important project milestones as illustrated here. More on this later.</p>",
    
    "<p>Let's explore three distinct scenarios utilising our basic modeling elements.</p><p class='tutorial-text-opacity'>1. Generic</p><p class='tutorial-text-opacity'>2. Tech</p><p class='tutorial-text-opacity'>3. Legal</p>",
    
    "<p class='tutorial-text-opacity'>Let's explore three distinct scenarios utilising our basic modeling elements.</p><p>1. In the first scenario, we've got a <strong>generic</strong> decision tree with two options, Alternative A or B, each with their own risks represented by Chance nodes. If you decide on Alternative A there is chance that Outcome A or B happens. If Outcome A happens, you have to decide between Alternative X and Y. Each path in the tree leads to a Payoff.</p>",
    
    "<p class='tutorial-text-opacity'>Let's explore three distinct scenarios utilising our basic modeling elements.</p><p>2. Moving on to the second scenario, it's a <strong>tech</strong> case. We're deciding whether to switch from a proven technology to an experimental one. R&D success with the experimental tech leads to investing in new facilities or adapting the old ones. Sticking with the proven tech means revising the marketing strategy to regain the otherwise declining market share.</p>",
    
    "<p class='tutorial-text-opacity'>Let's explore three distinct scenarios utilising our basic modeling elements.</p><p>3. Lastly, in the <strong>legal</strong> case scenario, picture yourself in a patent infringement situation. Do you take legal action or wait to see how your competitor fares in the market? Consider the costs, risks of litigation, and the competitor's potential success.</p>",
    
    "<p><u>Summary</u></p><p>The example trees showcase how the same problem structure can represent vastly different business problems. In this step, we’re displaying them on the same canvas so that you can easily inspect and compare their differences.</p><p>Make sure to scroll down.</p>",
    
    "<p>Now that we are familiar with the basic elements, we’re ready to start building a tree from the ground.</p><p>Add nodes by right-clicking on the canvas. Here we have added the root node for our tree.</p><img src=\"/build/images/tutorial-images/dropdown-canvas-small.png\" alt=\"\">",

    "<p><u>Tip</u><br>Most trees start with a decision node.</p> <img height=65 src=\"/build/images/tutorial-images/decision-node-2.png\" alt=\"\"> <br><br> <p>At these points, the decision maker has control and must make choices.</p>",

    "<p>To add a node to another node, right click on a node and select the node type you want to add.</p><img src=\"/build/images/tutorial-images/add-node-to-node.png\" alt=\"\">",
    
    "<p>We’ll keep adding to our tree one step at a time. We make sure to follow a logical order based on the problem at hand.</p><p><u>Just some friendly reminders</u><br>1. Each node can have as many branches as you need.<br> 2. If you hover over the symbol ‼️, it will help you identify any issues in your tree.<br>3. Try to keep your trees as simple as possible to start with. Don't worry, you can always add more details later on!</li></ol></p>",
    
    "<p>Alright, we've finished building the structure of the tree. It's time to add some annotations! Here’s how.</p> <p>1. Select a node and begin typing.</p> <p>OR</p> <p>2. Double-click on a node to open the node panel.</p><img src=\"/build/images/tutorial-images/node-panel-small.png\" alt=\"\">",

    "<p><u>Quick tip</u><br>You can also click on the branches in the tree to add annotations directly to that branch.</p> <p>Adding annotations as you build the tree can be beneficial unless you have a clear mental picture of how the final tree will appear.</p>",
    
    "<p>Adjusting the probabilities in the chance nodes is the next step to completing your decision tree.</p> <p>I'm sure you've got the hang of how to do it by now.</p><img src=\"/build/images/tutorial-images/node-panel-probability.png\" alt=\"\">",

    "<p><u>Just a heads-up</u><br>It’s only the chance node panel that contains probabilities.</p> <p><u>Remember</u><br>Ensure the probabilities add up to ONE. Any empty field will be calculated automatically.</p>",
    
    "<p>And lastly, let's add a payoff to one of the end nodes in our tree to see the magic happen!</p> <p>As you can see, by adding $100 to the upper branch of the tree (marked in blue), the expected value of our project (first node in the tree) is $70.</p> <p>Don’t worry, in the next step we will explain <strong>how it’s calculated.</strong></p><img src=\"/build/images/tutorial-images/alternative-x-payoff-100.png\" width=195 alt=\"\">",
    
    "<p>The tree is calculated from the right to the left (←) as each node is encountered.</p> <p>The value of a decision node <img src=\"/build/images/tutorial-images/decision-node.png\" alt=\"\"> is the highest value of the succeeding branches leading from that node.</p> <p>The value of each chance node <img src=\"/build/images/tutorial-images/chance-node.png\" alt=\"\"> is the sum of the values of the uncertain outcomes multiplied by their probabilities.</p>",
    
    "<p><u>Note</u><br>The value at the root node is often called the expected value. The probability-weighted value at a chance node is also called the expected value.</p><p>In statistics, the term \"mean\" is another way to refer to the expected value.</p><p>In finance, the term EMV (Expected Monetary Value) is commonly used.</p>",
    
    "<p>Here it is in symbol format, making it easy to follow along.</p> <div class=\"img-and-p-wrapper\"><img src=\"/build/images/tutorial-images/decision-node-c.png\" width=31 height=51.91 alt=\"\"><p class=\"p-without-margin\">C1 > C2, hence C = $100</p></div> <div class=\"img-and-p-wrapper\"><img src=\"/build/images/tutorial-images/chance-node-b.png\" width=31 height=44.3 alt=\"\"><p class=\"p-without-margin\">B1 x P(B1) + ... = $100 x 70% + $0 x 30% = $70</p></div> <p><i>Where, P(B1) = Probability for B1 to occur.</i></p> <div class=\"img-and-p-wrapper\"><img src=\"/build/images/tutorial-images/decision-node-a.png\" width=31 height=47.14 alt=\"\"><p class=\"p-without-margin\">A1 > A2, hence A = $70</p></div>",
    
    "<p>To make the tree more realistic, we’ve added a few more payoffs to various branches. </p> <p>By opening the Layout panel, you can turn off all other annotations to see clearly where the payoffs have been added.</p> <p>You locate the layout panel here:</p><img src=\"/build/images/tutorial-images/layout-menu.png\" alt=\"\">",
    
    "<p>Cool, let's switch those annotations back on!</p> <p><u>Just a heads up</u><br>The <strong>payoffs</strong> are in bold right under each branch of the tree. The <strong>expected values</strong> are right under each node. And the section of the tree highlighted in green represents the <strong>optimum path</strong>.</p>\n</p>",

    "<p><u>Don’t forget</u><br><i>Payoffs: what you put in.</i></p><p><i>Expected values: the math stuff.</i></p><p><i>Optimum path: the sequence of decisions and uncertain outcomes leading to the highest expected value at the root node.</i></p>",
    
    "<p>Almost done with this tutorial!</p> <p>But before we wrap up, let's add some charts for better visualization.</p> <p>Simply right-click on a node and select your preferred chart type.</p> <p><u>Tip</u><br>Click on the plus sign in the chart bar to enlarge it for better readability!</p><img height=130 src=\"/build/images/tutorial-images/bar-chart.png\" alt=\"\">",
    
    "<p>Milestones are also added to give a sense of time. They're there to help you visualize when important decisions need to be made or when uncertainties are resolved.</p><img height=240 src=\"/build/images/tutorial-images/milestone-menu.png\" alt=\"\">",
    
    "<p><u>Here's a quick summary of the tree</u></p> <p>Opting for Alternative A is the best decision to make.</p> <p>You can anticipate a payoff of $75, which is $35 more than Alternative B.</p> <p>The optimum path presents two potential outcomes:</p> <p>Payoff 1 = $150 with a 70% chance of occurring.</p> <p>Payoff 2 = -$100 with a 30% probability of occurring.</p>",
    
    "<p>That’s it - well done on finishing this tutorial!</p> <p>Feel free to keep learning by clicking on the next tutorial.</p><div class=\"tutorial-button-container\"><button id=\"next-tutorial-button\" class=\"next-tutorial-btn\">→ NEXT TUTORIAL</button></div> <br> <p>Alternatively, dive in and start exploring the tool on your own. You can revisit any of the tutorials whenever you like.</p><img src=\"/build/images/tutorial-images/help-menu-screenshot.png\" alt=\"\"><br><br><p>Have fun!</p>"
];

export default theBasicsTutorialHtml;