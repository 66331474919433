module.exports={
  "SilverDecisions": "1.1.0",
  "buildTimestamp": 1641216304572,
  "savetime": "2022-02-11T15:06:47.419Z",
  "lng": "en",
  "viewMode": "criterion1",
  "rule": "expected-value-maximization",
  "title": "Uncertainties",
  "description": "",
  "format": {
    "locales": "en",
    "payoff1": {
      "style": "currency",
      "currency": "USD",
      "currencyDisplay": "symbol",
      "minimumFractionDigits": 0,
      "maximumFractionDigits": 0,
      "useGrouping": true
    },
    "payoff2": {
      "style": "percent",
      "currency": "USD",
      "currencyDisplay": "symbol",
      "minimumFractionDigits": 0,
      "maximumFractionDigits": 0,
      "useGrouping": true
    },
    "probability": {
      "style": "percent",
      "minimumFractionDigits": 0,
      "maximumFractionDigits": 0,
      "useGrouping": true
    }
  },
  "treeDesigner": {
    "margin": {
      "left": 500,
      "right": 500,
      "top": 250,
      "bottom": 100
    },
    "scale": 1,
    "lng": "en",
    "layout": {
      "type": "cluster",
      "nodeSize": 22,
      "limitNodePositioning": true,
      "limitTextPositioning": true,
      "gridHeight": 95,
      "gridWidth": 205,
      "edgeSlantWidthMax": 20
    },
    "fontFamily": "sans-serif",
    "fontSize": "12px",
    "fontWeight": "normal",
    "fontStyle": "normal",
    "node": {
      "strokeWidth": "2px",
      "optimal": {
        "stroke": "#006f00",
        "strokeWidth": "2px"
      },
      "label": {
        "fontSize": "1em",
        "color": "black"
      },
      "payoff": {
        "fontSize": "1em",
        "color": "black",
        "negativeColor": "#b60000"
      },
      "decision": {
        "fill": "#ff7777",
        "stroke": "#660000",
        "selected": {
          "fill": "#aa3333"
        }
      },
      "chance": {
        "fill": "#ffff44",
        "stroke": "#666600",
        "selected": {
          "fill": "#aaaa00"
        }
      },
      "terminal": {
        "fill": "#44ff44",
        "stroke": "black",
        "selected": {
          "fill": "#00aa00"
        },
        "payoff": {
          "fontSize": "1em",
          "color": "black",
          "negativeColor": "#b60000"
        }
      }
    },
    "edge": {
      "stroke": "#424242",
      "strokeWidth": "1.5",
      "optimal": {
        "stroke": "#006f00",
        "strokeWidth": "2.4"
      },
      "selected": {
        "stroke": "#045ad1",
        "strokeWidth": "3.5"
      },
      "label": {
        "fontSize": "1em",
        "color": "back"
      },
      "payoff": {
        "fontSize": "1em",
        "color": "black",
        "negativeColor": "#b60000"
      }
    },
    "probability": {
      "fontSize": "1em",
      "color": "#0000d7"
    },
    "title": {
      "fontSize": "16px",
      "fontWeight": "bold",
      "fontStyle": "normal",
      "color": "#000000",
      "margin": {
        "top": 15,
        "bottom": 10
      }
    },
    "description": {
      "show": true,
      "fontSize": "12px",
      "fontWeight": "bold",
      "fontStyle": "normal",
      "color": "#000000",
      "margin": {
        "top": 5,
        "bottom": 10
      }
    },
    "readOnly": false,
    "disableAnimations": false,
    "forceFullEdgeRedraw": false,
    "hideLabels": false,
    "hidePayoffs": false,
    "hideProbabilities": false,
    "hideCharts": false,
    "hideTextboxes": false,
    "hideMilestones": false,
    "raw": false,
    "payoffNames": [
      null,
      null
    ],
    "maxPayoffsToDisplay": 1,
    "milestoneNames": [
      "",
      "",
      "",
      "",
      "Pad 4",
      "",
      "",
      "",
      "",
      ""
    ]
  },
  "data": {
    "code": "INFOCOST=-100\nDEVX=20000\nDEVXREV=25000\n",
    "expressionScope": {
      "INFOCOST": -100,
      "DEVX": 20000,
      "DEVXREV": 25000
    },
    "trees": [
      {
        "computed": {
          "expected-value-maximization": {
            "childrenPayoff": [
              "27616 250075376401/274877906944"
            ],
            "payoff": [
              "27617 2661102655/8589934592"
            ],
            "optimal": true
          },
          "expected-value-minimization": {
            "childrenPayoff": [
              "20000"
            ],
            "payoff": [
              "20000"
            ],
            "optimal": true
          },
          "maxi-min": {
            "childrenPayoff": [
              "20000"
            ],
            "payoff": [
              "20000"
            ],
            "optimal": true
          },
          "maxi-max": {
            "childrenPayoff": [
              "30924 25023368991/34359738368"
            ],
            "payoff": [
              "30924 25023368991/34359738368"
            ],
            "optimal": true
          },
          "mini-min": {
            "childrenPayoff": [
              "19900"
            ],
            "payoff": [
              "19900"
            ],
            "optimal": true
          },
          "mini-max": {
            "childrenPayoff": [
              "20000"
            ],
            "payoff": [
              "20000"
            ],
            "optimal": true
          },
          "min-max": {
            "childrenPayoff": [
              "20000",
              "0"
            ],
            "combinedPayoff": "-20000",
            "payoff": [
              "20000",
              "0"
            ],
            "optimal": true
          },
          "max-min": {
            "childrenPayoff": [
              "27617 2661102655/8589934592",
              "0"
            ],
            "combinedPayoff": "27617 2661102655/8589934592",
            "payoff": [
              "27617 2661102655/8589934592",
              "0"
            ],
            "optimal": true
          },
          "min-min": {
            "childrenPayoff": [
              "20000",
              "0"
            ],
            "combinedPayoff": "-20000",
            "payoff": [
              "20000",
              "0"
            ],
            "optimal": true
          },
          "max-max": {
            "childrenPayoff": [
              "27617 2661102655/8589934592",
              "0"
            ],
            "combinedPayoff": "27617 2661102655/8589934592",
            "payoff": [
              "27617 2661102655/8589934592",
              "0"
            ],
            "optimal": true
          }
        },
        "childEdges": [
          {
            "computed": {
              "payoff": [
                "-100",
                "0"
              ],
              "expected-value-maximization": {
                "probability": 1,
                "optimal": true
              },
              "expected-value-minimization": {
                "probability": 0,
                "optimal": false
              },
              "maxi-min": {
                "probability": 0,
                "optimal": false
              },
              "maxi-max": {
                "probability": 1,
                "optimal": true
              },
              "mini-min": {
                "probability": 1,
                "optimal": true
              },
              "mini-max": {
                "probability": 0,
                "optimal": false
              },
              "min-max": {
                "probability": 0,
                "optimal": false
              },
              "max-min": {
                "probability": 1,
                "optimal": true
              },
              "min-min": {
                "probability": 0,
                "optimal": false
              },
              "max-max": {
                "probability": 1,
                "optimal": true
              }
            },
            "name": "\n\nInvest in \nmore information",
            "payoff": [
              "INFOCOST",
              0
            ],
            "childNode": {
              "computed": {
                "expected-value-maximization": {
                  "childrenPayoff": [
                    "27716 125037688199/137438953472"
                  ],
                  "payoff": [
                    "27617 2661102655/8589934592"
                  ],
                  "optimal": true
                },
                "expected-value-minimization": {
                  "childrenPayoff": [
                    "27717 2661102655/8589934592"
                  ],
                  "payoff": [
                    "27617 2661102655/8589934592"
                  ]
                },
                "maxi-min": {
                  "childrenPayoff": [
                    "20000"
                  ],
                  "payoff": [
                    "19900"
                  ]
                },
                "maxi-max": {
                  "childrenPayoff": [
                    "31024 25023368991/34359738368"
                  ],
                  "payoff": [
                    "30924 25023368991/34359738368"
                  ],
                  "optimal": true
                },
                "mini-min": {
                  "childrenPayoff": [
                    "20000"
                  ],
                  "payoff": [
                    "19900"
                  ],
                  "optimal": true
                },
                "mini-max": {
                  "childrenPayoff": [
                    "31024 25023368991/34359738368"
                  ],
                  "payoff": [
                    "30924 25023368991/34359738368"
                  ]
                },
                "min-max": {
                  "childrenPayoff": [
                    "27717 2661102655/8589934592",
                    "0"
                  ],
                  "combinedPayoff": "-27617 2661102655/8589934592",
                  "payoff": [
                    "27617 2661102655/8589934592",
                    "0"
                  ]
                },
                "max-min": {
                  "childrenPayoff": [
                    "27717 2661102655/8589934592",
                    "0"
                  ],
                  "combinedPayoff": "27617 2661102655/8589934592",
                  "payoff": [
                    "27617 2661102655/8589934592",
                    "0"
                  ],
                  "optimal": true
                },
                "min-min": {
                  "childrenPayoff": [
                    "27717 2661102655/8589934592",
                    "0"
                  ],
                  "combinedPayoff": "-27617 2661102655/8589934592",
                  "payoff": [
                    "27617 2661102655/8589934592",
                    "0"
                  ]
                },
                "max-max": {
                  "childrenPayoff": [
                    "27717 2661102655/8589934592",
                    "0"
                  ],
                  "combinedPayoff": "27617 2661102655/8589934592",
                  "payoff": [
                    "27617 2661102655/8589934592",
                    "0"
                  ],
                  "optimal": true
                }
              },
              "childEdges": [
                {
                  "computed": {
                    "payoff": [
                      "0",
                      "0"
                    ],
                    "probability": "7/10",
                    "expected-value-maximization": {
                      "probability": "7/10",
                      "optimal": true
                    },
                    "expected-value-minimization": {
                      "probability": "7/10"
                    },
                    "maxi-min": {
                      "probability": 0
                    },
                    "maxi-max": {
                      "probability": 1,
                      "optimal": true
                    },
                    "mini-min": {
                      "probability": 0,
                      "optimal": false
                    },
                    "mini-max": {
                      "probability": 1
                    },
                    "min-max": {
                      "probability": "7/10"
                    },
                    "max-min": {
                      "probability": "7/10",
                      "optimal": true
                    },
                    "min-min": {
                      "probability": "7/10"
                    },
                    "max-max": {
                      "probability": "7/10",
                      "optimal": true
                    }
                  },
                  "name": "Yes",
                  "probability": ".70",
                  "payoff": [
                    "0",
                    0
                  ],
                  "childNode": {
                    "computed": {
                      "expected-value-maximization": {
                        "childrenPayoff": [
                          "31024 25023368991/34359738368"
                        ],
                        "payoff": [
                          "31024 25023368991/34359738368"
                        ],
                        "optimal": true
                      },
                      "expected-value-minimization": {
                        "childrenPayoff": [
                          "31024 25023368991/34359738368"
                        ],
                        "payoff": [
                          "31024 25023368991/34359738368"
                        ]
                      },
                      "maxi-min": {
                        "childrenPayoff": [
                          "31024 25023368991/34359738368"
                        ],
                        "payoff": [
                          "31024 25023368991/34359738368"
                        ]
                      },
                      "maxi-max": {
                        "childrenPayoff": [
                          "31024 25023368991/34359738368"
                        ],
                        "payoff": [
                          "31024 25023368991/34359738368"
                        ],
                        "optimal": true
                      },
                      "mini-min": {
                        "childrenPayoff": [
                          "31024 25023368991/34359738368"
                        ],
                        "payoff": [
                          "31024 25023368991/34359738368"
                        ]
                      },
                      "mini-max": {
                        "childrenPayoff": [
                          "31024 25023368991/34359738368"
                        ],
                        "payoff": [
                          "31024 25023368991/34359738368"
                        ]
                      },
                      "min-max": {
                        "childrenPayoff": [
                          "31024 25023368991/34359738368",
                          "0"
                        ],
                        "combinedPayoff": "-31024 25023368991/34359738368",
                        "payoff": [
                          "31024 25023368991/34359738368",
                          "0"
                        ]
                      },
                      "max-min": {
                        "childrenPayoff": [
                          "31024 25023368991/34359738368",
                          "0"
                        ],
                        "combinedPayoff": "31024 25023368991/34359738368",
                        "payoff": [
                          "31024 25023368991/34359738368",
                          "0"
                        ],
                        "optimal": true
                      },
                      "min-min": {
                        "childrenPayoff": [
                          "31024 25023368991/34359738368",
                          "0"
                        ],
                        "combinedPayoff": "-31024 25023368991/34359738368",
                        "payoff": [
                          "31024 25023368991/34359738368",
                          "0"
                        ]
                      },
                      "max-max": {
                        "childrenPayoff": [
                          "31024 25023368991/34359738368",
                          "0"
                        ],
                        "combinedPayoff": "31024 25023368991/34359738368",
                        "payoff": [
                          "31024 25023368991/34359738368",
                          "0"
                        ],
                        "optimal": true
                      }
                    },
                    "childEdges": [
                      {
                        "computed": {
                          "payoff": [
                            "31024 25023368991/34359738368",
                            "0"
                          ],
                          "expected-value-maximization": {
                            "probability": 1,
                            "optimal": true
                          },
                          "expected-value-minimization": {
                            "probability": 1
                          },
                          "maxi-min": {
                            "probability": 1
                          },
                          "maxi-max": {
                            "probability": 1,
                            "optimal": true
                          },
                          "mini-min": {
                            "probability": 1
                          },
                          "mini-max": {
                            "probability": 1
                          },
                          "min-max": {
                            "probability": 1
                          },
                          "max-min": {
                            "probability": 1,
                            "optimal": true
                          },
                          "min-min": {
                            "probability": 1
                          },
                          "max-max": {
                            "probability": 1,
                            "optimal": true
                          }
                        },
                        "name": "",
                        "payoff": [
                          "DEVXREV",
                          0
                        ],
                        "childNode": {
                          "computed": {
                            "expected-value-maximization": {
                              "aggregatedPayoff": [
                                "30924 200186951917/274877906944"
                              ],
                              "probabilityToEnter": "7/10",
                              "payoff": [
                                "31024 25023368991/34359738368"
                              ],
                              "optimal": true
                            },
                            "expected-value-minimization": {
                              "aggregatedPayoff": [
                                "30924 25023368991/34359738368"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "31024 25023368991/34359738368"
                              ]
                            },
                            "maxi-min": {
                              "aggregatedPayoff": [
                                "30924 25023368991/34359738368"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "31024 25023368991/34359738368"
                              ]
                            },
                            "maxi-max": {
                              "aggregatedPayoff": [
                                "30924 25023368991/34359738368"
                              ],
                              "probabilityToEnter": "1",
                              "payoff": [
                                "31024 25023368991/34359738368"
                              ],
                              "optimal": true
                            },
                            "mini-min": {
                              "aggregatedPayoff": [
                                "30924 25023368991/34359738368"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "31024 25023368991/34359738368"
                              ]
                            },
                            "mini-max": {
                              "aggregatedPayoff": [
                                "30924 25023368991/34359738368"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "31024 25023368991/34359738368"
                              ]
                            },
                            "min-max": {
                              "aggregatedPayoff": [
                                "30924 25023368991/34359738368",
                                "0"
                              ],
                              "probabilityToEnter": 0,
                              "combinedPayoff": "-31024 25023368991/34359738368",
                              "payoff": [
                                "31024 25023368991/34359738368",
                                "0"
                              ]
                            },
                            "max-min": {
                              "aggregatedPayoff": [
                                "30924 25023368991/34359738368",
                                "0"
                              ],
                              "probabilityToEnter": "7/10",
                              "combinedPayoff": "31024 25023368991/34359738368",
                              "payoff": [
                                "31024 25023368991/34359738368",
                                "0"
                              ],
                              "optimal": true
                            },
                            "min-min": {
                              "aggregatedPayoff": [
                                "30924 25023368991/34359738368",
                                "0"
                              ],
                              "probabilityToEnter": 0,
                              "combinedPayoff": "-31024 25023368991/34359738368",
                              "payoff": [
                                "31024 25023368991/34359738368",
                                "0"
                              ]
                            },
                            "max-max": {
                              "aggregatedPayoff": [
                                "30924 25023368991/34359738368",
                                "0"
                              ],
                              "probabilityToEnter": "7/10",
                              "combinedPayoff": "31024 25023368991/34359738368",
                              "payoff": [
                                "31024 25023368991/34359738368",
                                "0"
                              ],
                              "optimal": true
                            }
                          },
                          "childEdges": [],
                          "name": "",
                          "code": "",
                          "expressionScope": {
                            "INFOCOST": -100,
                            "DEVX": 20000,
                            "DEVXREV": 25000
                          },
                          "folded": false,
                          "location": {
                            "x": 626,
                            "y": 11
                          },
                          "type": "terminal",
                          "expectedValue": 30924.72827588853,
                          "p10": 21514,
                          "p90": 42219,
                          "charts": [
                            {
                              "nodeId": "53804517-e694-4ec5-4320-ba230c709ddb",
                              "x": 72.99989318847656,
                              "y": -40.00029182434082,
                              "type": "distribution",
                              "active": true
                            }
                          ]
                        },
                        "expectedValue": 31024.72827588857,
                        "p10": 21614,
                        "p90": 42319,
                        "optimal": true
                      }
                    ],
                    "name": "Develop\nX improved",
                    "code": "",
                    "expressionScope": {
                      "INFOCOST": -100,
                      "DEVX": 20000,
                      "DEVXREV": 25000
                    },
                    "folded": false,
                    "location": {
                      "x": 421,
                      "y": 11
                    },
                    "type": "decision",
                    "milestone": 2,
                    "expectedValue": 31024.72827588857,
                    "p10": 21614,
                    "p90": 42319
                  },
                  "expectedValue": 0,
                  "p10": 0,
                  "p90": 0,
                  "optimal": true
                },
                {
                  "computed": {
                    "payoff": [
                      "0",
                      "0"
                    ],
                    "probability": "3/10",
                    "expected-value-maximization": {
                      "probability": "3/10",
                      "optimal": true
                    },
                    "expected-value-minimization": {
                      "probability": "3/10"
                    },
                    "maxi-min": {
                      "probability": 1
                    },
                    "maxi-max": {
                      "probability": 0,
                      "optimal": false
                    },
                    "mini-min": {
                      "probability": 1,
                      "optimal": true
                    },
                    "mini-max": {
                      "probability": 0
                    },
                    "min-max": {
                      "probability": "3/10"
                    },
                    "max-min": {
                      "probability": "3/10",
                      "optimal": true
                    },
                    "min-min": {
                      "probability": "3/10"
                    },
                    "max-max": {
                      "probability": "3/10",
                      "optimal": true
                    }
                  },
                  "name": "No",
                  "probability": "#",
                  "payoff": [
                    0,
                    0
                  ],
                  "childNode": {
                    "computed": {
                      "expected-value-maximization": {
                        "childrenPayoff": [
                          "20000"
                        ],
                        "payoff": [
                          "20000"
                        ],
                        "optimal": true
                      },
                      "expected-value-minimization": {
                        "childrenPayoff": [
                          "20000"
                        ],
                        "payoff": [
                          "20000"
                        ]
                      },
                      "maxi-min": {
                        "childrenPayoff": [
                          "20000"
                        ],
                        "payoff": [
                          "20000"
                        ]
                      },
                      "maxi-max": {
                        "childrenPayoff": [
                          "20000"
                        ],
                        "payoff": [
                          "20000"
                        ]
                      },
                      "mini-min": {
                        "childrenPayoff": [
                          "20000"
                        ],
                        "payoff": [
                          "20000"
                        ],
                        "optimal": true
                      },
                      "mini-max": {
                        "childrenPayoff": [
                          "20000"
                        ],
                        "payoff": [
                          "20000"
                        ]
                      },
                      "min-max": {
                        "childrenPayoff": [
                          "20000",
                          "0"
                        ],
                        "combinedPayoff": "-20000",
                        "payoff": [
                          "20000",
                          "0"
                        ]
                      },
                      "max-min": {
                        "childrenPayoff": [
                          "20000",
                          "0"
                        ],
                        "combinedPayoff": "20000",
                        "payoff": [
                          "20000",
                          "0"
                        ],
                        "optimal": true
                      },
                      "min-min": {
                        "childrenPayoff": [
                          "20000",
                          "0"
                        ],
                        "combinedPayoff": "-20000",
                        "payoff": [
                          "20000",
                          "0"
                        ]
                      },
                      "max-max": {
                        "childrenPayoff": [
                          "20000",
                          "0"
                        ],
                        "combinedPayoff": "20000",
                        "payoff": [
                          "20000",
                          "0"
                        ],
                        "optimal": true
                      }
                    },
                    "childEdges": [
                      {
                        "computed": {
                          "payoff": [
                            "20000",
                            "0"
                          ],
                          "expected-value-maximization": {
                            "probability": 1,
                            "optimal": true
                          },
                          "expected-value-minimization": {
                            "probability": 1
                          },
                          "maxi-min": {
                            "probability": 1
                          },
                          "maxi-max": {
                            "probability": 1
                          },
                          "mini-min": {
                            "probability": 1,
                            "optimal": true
                          },
                          "mini-max": {
                            "probability": 1
                          },
                          "min-max": {
                            "probability": 1
                          },
                          "max-min": {
                            "probability": 1,
                            "optimal": true
                          },
                          "min-min": {
                            "probability": 1
                          },
                          "max-max": {
                            "probability": 1,
                            "optimal": true
                          }
                        },
                        "name": "",
                        "payoff": [
                          "DEVX",
                          0
                        ],
                        "childNode": {
                          "computed": {
                            "expected-value-maximization": {
                              "aggregatedPayoff": [
                                "19900"
                              ],
                              "probabilityToEnter": "3/10",
                              "payoff": [
                                "20000"
                              ],
                              "optimal": true
                            },
                            "expected-value-minimization": {
                              "aggregatedPayoff": [
                                "19900"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "20000"
                              ]
                            },
                            "maxi-min": {
                              "aggregatedPayoff": [
                                "19900"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "20000"
                              ]
                            },
                            "maxi-max": {
                              "aggregatedPayoff": [
                                "19900"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "20000"
                              ]
                            },
                            "mini-min": {
                              "aggregatedPayoff": [
                                "19900"
                              ],
                              "probabilityToEnter": "1",
                              "payoff": [
                                "20000"
                              ],
                              "optimal": true
                            },
                            "mini-max": {
                              "aggregatedPayoff": [
                                "19900"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "20000"
                              ]
                            },
                            "min-max": {
                              "aggregatedPayoff": [
                                "19900",
                                "0"
                              ],
                              "probabilityToEnter": 0,
                              "combinedPayoff": "-20000",
                              "payoff": [
                                "20000",
                                "0"
                              ]
                            },
                            "max-min": {
                              "aggregatedPayoff": [
                                "19900",
                                "0"
                              ],
                              "probabilityToEnter": "3/10",
                              "combinedPayoff": "20000",
                              "payoff": [
                                "20000",
                                "0"
                              ],
                              "optimal": true
                            },
                            "min-min": {
                              "aggregatedPayoff": [
                                "19900",
                                "0"
                              ],
                              "probabilityToEnter": 0,
                              "combinedPayoff": "-20000",
                              "payoff": [
                                "20000",
                                "0"
                              ]
                            },
                            "max-max": {
                              "aggregatedPayoff": [
                                "19900",
                                "0"
                              ],
                              "probabilityToEnter": "3/10",
                              "combinedPayoff": "20000",
                              "payoff": [
                                "20000",
                                "0"
                              ],
                              "optimal": true
                            }
                          },
                          "childEdges": [],
                          "name": "",
                          "code": "",
                          "expressionScope": {
                            "INFOCOST": -100,
                            "DEVX": 20000,
                            "DEVXREV": 25000
                          },
                          "folded": false,
                          "location": {
                            "x": 626,
                            "y": 125
                          },
                          "type": "terminal",
                          "milestone": 3,
                          "expectedValue": 19900,
                          "p10": 14848,
                          "p90": 24948,
                          "charts": [
                            {
                              "nodeId": "92acb0fc-4235-ecf6-4edf-b1b404871d1a",
                              "x": 71.99989318847656,
                              "y": -40.00029754638672,
                              "type": "distribution",
                              "active": true
                            }
                          ]
                        },
                        "expectedValue": 20000,
                        "p10": 14948,
                        "p90": 25048,
                        "optimal": true
                      }
                    ],
                    "name": "Develop\nX",
                    "code": "",
                    "expressionScope": {
                      "INFOCOST": -100,
                      "DEVX": 20000,
                      "DEVXREV": 25000
                    },
                    "folded": false,
                    "location": {
                      "x": 421,
                      "y": 125
                    },
                    "type": "decision",
                    "milestone": 2,
                    "expectedValue": 20000,
                    "p10": 14948,
                    "p90": 25048
                  },
                  "expectedValue": 0,
                  "p10": 0,
                  "p90": 0,
                  "optimal": true
                }
              ],
              "name": "Revised\ndevelopment\nstrategy",
              "code": "",
              "expressionScope": {
                "INFOCOST": -100,
                "DEVX": 20000,
                "DEVXREV": 25000
              },
              "folded": false,
              "location": {
                "x": 216,
                "y": 68
              },
              "type": "chance",
              "expectedValue": 27716.90976891951,
              "p10": 17978,
              "p90": 40299
            },
            "expectedValue": -100,
            "p10": -100,
            "p90": -100,
            "optimal": true
          },
          {
            "computed": {
              "payoff": [
                "0",
                "0"
              ],
              "expected-value-maximization": {
                "probability": 0,
                "optimal": false
              },
              "expected-value-minimization": {
                "probability": 1,
                "optimal": true
              },
              "maxi-min": {
                "probability": 1,
                "optimal": true
              },
              "maxi-max": {
                "probability": 0,
                "optimal": false
              },
              "mini-min": {
                "probability": 0,
                "optimal": false
              },
              "mini-max": {
                "probability": 1,
                "optimal": true
              },
              "min-max": {
                "probability": 1,
                "optimal": true
              },
              "max-min": {
                "probability": 0,
                "optimal": false
              },
              "min-min": {
                "probability": 1,
                "optimal": true
              },
              "max-max": {
                "probability": 0,
                "optimal": false
              }
            },
            "name": "Develop with\nexisting data",
            "payoff": [
              0,
              0
            ],
            "childNode": {
              "computed": {
                "expected-value-maximization": {
                  "childrenPayoff": [
                    "20000"
                  ],
                  "payoff": [
                    "20000"
                  ]
                },
                "expected-value-minimization": {
                  "childrenPayoff": [
                    "20000"
                  ],
                  "payoff": [
                    "20000"
                  ],
                  "optimal": true
                },
                "maxi-min": {
                  "childrenPayoff": [
                    "20000"
                  ],
                  "payoff": [
                    "20000"
                  ],
                  "optimal": true
                },
                "maxi-max": {
                  "childrenPayoff": [
                    "20000"
                  ],
                  "payoff": [
                    "20000"
                  ]
                },
                "mini-min": {
                  "childrenPayoff": [
                    "20000"
                  ],
                  "payoff": [
                    "20000"
                  ]
                },
                "mini-max": {
                  "childrenPayoff": [
                    "20000"
                  ],
                  "payoff": [
                    "20000"
                  ],
                  "optimal": true
                },
                "min-max": {
                  "childrenPayoff": [
                    "20000",
                    "0"
                  ],
                  "combinedPayoff": "-20000",
                  "payoff": [
                    "20000",
                    "0"
                  ],
                  "optimal": true
                },
                "max-min": {
                  "childrenPayoff": [
                    "20000",
                    "0"
                  ],
                  "combinedPayoff": "20000",
                  "payoff": [
                    "20000",
                    "0"
                  ]
                },
                "min-min": {
                  "childrenPayoff": [
                    "20000",
                    "0"
                  ],
                  "combinedPayoff": "-20000",
                  "payoff": [
                    "20000",
                    "0"
                  ],
                  "optimal": true
                },
                "max-max": {
                  "childrenPayoff": [
                    "20000",
                    "0"
                  ],
                  "combinedPayoff": "20000",
                  "payoff": [
                    "20000",
                    "0"
                  ]
                }
              },
              "childEdges": [
                {
                  "computed": {
                    "payoff": [
                      "20000",
                      "0"
                    ],
                    "expected-value-maximization": {
                      "probability": 1
                    },
                    "expected-value-minimization": {
                      "probability": 1,
                      "optimal": true
                    },
                    "maxi-min": {
                      "probability": 1,
                      "optimal": true
                    },
                    "maxi-max": {
                      "probability": 1
                    },
                    "mini-min": {
                      "probability": 1
                    },
                    "mini-max": {
                      "probability": 1,
                      "optimal": true
                    },
                    "min-max": {
                      "probability": 1,
                      "optimal": true
                    },
                    "max-min": {
                      "probability": 1
                    },
                    "min-min": {
                      "probability": 1,
                      "optimal": true
                    },
                    "max-max": {
                      "probability": 1
                    }
                  },
                  "name": "",
                  "payoff": [
                    "DEVX",
                    0
                  ],
                  "childNode": {
                    "computed": {
                      "expected-value-maximization": {
                        "aggregatedPayoff": [
                          "20000"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "20000"
                        ]
                      },
                      "expected-value-minimization": {
                        "aggregatedPayoff": [
                          "20000"
                        ],
                        "probabilityToEnter": "1",
                        "payoff": [
                          "20000"
                        ],
                        "optimal": true
                      },
                      "maxi-min": {
                        "aggregatedPayoff": [
                          "20000"
                        ],
                        "probabilityToEnter": "1",
                        "payoff": [
                          "20000"
                        ],
                        "optimal": true
                      },
                      "maxi-max": {
                        "aggregatedPayoff": [
                          "20000"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "20000"
                        ]
                      },
                      "mini-min": {
                        "aggregatedPayoff": [
                          "20000"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "20000"
                        ]
                      },
                      "mini-max": {
                        "aggregatedPayoff": [
                          "20000"
                        ],
                        "probabilityToEnter": "1",
                        "payoff": [
                          "20000"
                        ],
                        "optimal": true
                      },
                      "min-max": {
                        "aggregatedPayoff": [
                          "20000",
                          "0"
                        ],
                        "probabilityToEnter": "1",
                        "combinedPayoff": "-20000",
                        "payoff": [
                          "20000",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-min": {
                        "aggregatedPayoff": [
                          "20000",
                          "0"
                        ],
                        "probabilityToEnter": 0,
                        "combinedPayoff": "20000",
                        "payoff": [
                          "20000",
                          "0"
                        ]
                      },
                      "min-min": {
                        "aggregatedPayoff": [
                          "20000",
                          "0"
                        ],
                        "probabilityToEnter": "1",
                        "combinedPayoff": "-20000",
                        "payoff": [
                          "20000",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-max": {
                        "aggregatedPayoff": [
                          "20000",
                          "0"
                        ],
                        "probabilityToEnter": 0,
                        "combinedPayoff": "20000",
                        "payoff": [
                          "20000",
                          "0"
                        ]
                      }
                    },
                    "childEdges": [],
                    "name": "",
                    "code": "",
                    "expressionScope": {
                      "INFOCOST": -100,
                      "DEVX": 20000,
                      "DEVXREV": 25000
                    },
                    "folded": false,
                    "location": {
                      "x": 626,
                      "y": 239
                    },
                    "type": "terminal",
                    "milestone": 3,
                    "expectedValue": 20000,
                    "p10": 14948,
                    "p90": 25048,
                    "charts": [
                      {
                        "nodeId": "b9ffdbf1-6ad5-47d1-0b57-c398e50dea14",
                        "x": 70.99989318847656,
                        "y": -41.000288009643555,
                        "type": "distribution",
                        "active": true
                      }
                    ]
                  },
                  "expectedValue": 20000,
                  "p10": 14948,
                  "p90": 25048,
                  "optimal": false
                }
              ],
              "name": "Develop\nX",
              "code": "",
              "expressionScope": {
                "INFOCOST": -100,
                "DEVX": 20000,
                "DEVXREV": 25000
              },
              "folded": false,
              "location": {
                "x": 421,
                "y": 239
              },
              "type": "decision",
              "milestone": 2,
              "expectedValue": 20000,
              "p10": 14948,
              "p90": 25048
            },
            "expectedValue": 0,
            "p10": 0,
            "p90": 0,
            "optimal": false
          }
        ],
        "name": "Development\nproject\nX",
        "code": "",
        "expressionScope": {
          "INFOCOST": -100,
          "DEVX": 20000,
          "DEVXREV": 25000
        },
        "folded": false,
        "location": {
          "x": 11,
          "y": 153.5
        },
        "type": "decision",
        "milestone": 1,
        "expectedValue": 27616.909768919522,
        "p10": 17878,
        "p90": 40199,
        "charts": [
          {
            "nodeId": "7c4fdfb8-6b95-6bf5-1f81-e0e238a340e1",
            "x": -215.9987030029297,
            "y": -39.000301361083984,
            "type": "distribution",
            "active": true
          }
        ]
      }
    ],
    "texts": [
      {
        "value": "Optimal decision",
        "location": {
          "x": 125.00055313110352,
          "y": -25.000837802886963
        }
      },
      {
        "value": "Suboptimal decision",
        "location": {
          "x": 110.00054931640625,
          "y": 305.99743843078613
        }
      }
    ],
    "payoffNames": [],
    "defaultCriterion1Weight": 1,
    "weightLowerBound": 0,
    "weightUpperBound": "Infinity"
  },
  "sensitivityAnalysis": {},
  "definitionsDialog": {
    "valueList": [
      {
        "name": "INFOCOST",
        "value": "-100",
        "low": null,
        "high": null,
        "distribution": "constant",
        "error": ""
      },
      {
        "name": "DEVX",
        "value": "20000",
        "low": 15000,
        "high": 25000,
        "distribution": "triangular",
        "error": ""
      },
      {
        "name": "DEVXREV",
        "value": "25000",
        "low": 20000,
        "high": 40000,
        "distribution": "triangular",
        "error": ""
      },
      {
        "name": null,
        "value": "",
        "low": null,
        "high": null,
        "distribution": "constant",
        "error": ""
      },
      {
        "name": null,
        "value": "",
        "low": null,
        "high": null,
        "distribution": "constant",
        "error": ""
      }
    ]
  }
}