import the_basics_tutorial_1 from './tutorial-trees/the-basics-tutorial-trees/the_basics_1.json';
import the_basics_tutorial_2 from './tutorial-trees/the-basics-tutorial-trees/the_basics_2.json';
import the_basics_tutorial_3 from './tutorial-trees/the-basics-tutorial-trees/the_basics_3.json';
import the_basics_tutorial_4 from './tutorial-trees/the-basics-tutorial-trees/the_basics_4.json';
import the_basics_tutorial_5 from './tutorial-trees/the-basics-tutorial-trees/the_basics_5.json';
import the_basics_tutorial_6 from './tutorial-trees/the-basics-tutorial-trees/the_basics_6.json';
import the_basics_tutorial_7 from './tutorial-trees/the-basics-tutorial-trees/the_basics_7.json';
import the_basics_tutorial_8 from './tutorial-trees/the-basics-tutorial-trees/the_basics_8.json';
import the_basics_tutorial_9 from './tutorial-trees/the-basics-tutorial-trees/the_basics_9.json';
import the_basics_tutorial_10 from './tutorial-trees/the-basics-tutorial-trees/the_basics_10.json';
import the_basics_tutorial_11 from './tutorial-trees/the-basics-tutorial-trees/the_basics_11.json';
import the_basics_tutorial_12 from './tutorial-trees/the-basics-tutorial-trees/the_basics_12.json';
import the_basics_tutorial_13 from './tutorial-trees/the-basics-tutorial-trees/the_basics_13.json';
import the_basics_tutorial_14 from './tutorial-trees/the-basics-tutorial-trees/the_basics_14.json';
import the_basics_tutorial_15 from './tutorial-trees/the-basics-tutorial-trees/the_basics_15.json';
import the_basics_tutorial_16 from './tutorial-trees/the-basics-tutorial-trees/the_basics_16.json';
import the_basics_tutorial_17 from './tutorial-trees/the-basics-tutorial-trees/the_basics_17.json';
import the_basics_tutorial_18 from './tutorial-trees/the-basics-tutorial-trees/the_basics_18.json';
import the_basics_tutorial_19 from './tutorial-trees/the-basics-tutorial-trees/the_basics_19.json';
import the_basics_tutorial_20 from './tutorial-trees/the-basics-tutorial-trees/the_basics_20.json';
import the_basics_tutorial_21 from './tutorial-trees/the-basics-tutorial-trees/the_basics_21.json';
import the_basics_tutorial_22 from './tutorial-trees/the-basics-tutorial-trees/the_basics_22.json';
import the_basics_tutorial_23 from './tutorial-trees/the-basics-tutorial-trees/the_basics_23.json';
import the_basics_tutorial_24 from './tutorial-trees/the-basics-tutorial-trees/the_basics_24.json';
import the_basics_tutorial_25 from './tutorial-trees/the-basics-tutorial-trees/the_basics_25.json';
import the_basics_tutorial_26 from './tutorial-trees/the-basics-tutorial-trees/the_basics_26.json';
import the_basics_tutorial_27 from './tutorial-trees/the-basics-tutorial-trees/the_basics_27.json';
import the_basics_tutorial_28 from './tutorial-trees/the-basics-tutorial-trees/the_basics_28.json';
import the_basics_tutorial_29 from './tutorial-trees/the-basics-tutorial-trees/the_basics_29.json';
import the_basics_tutorial_30 from './tutorial-trees/the-basics-tutorial-trees/the_basics_30.json';
import the_basics_tutorial_31 from './tutorial-trees/the-basics-tutorial-trees/the_basics_31.json';
import the_basics_tutorial_32 from './tutorial-trees/the-basics-tutorial-trees/the_basics_32.json';
import the_basics_tutorial_33 from './tutorial-trees/the-basics-tutorial-trees/the_basics_33.json';
import the_basics_tutorial_34 from './tutorial-trees/the-basics-tutorial-trees/the_basics_34.json';
import the_basics_tutorial_35 from './tutorial-trees/the-basics-tutorial-trees/the_basics_35.json';

const theBasicsTutorialFiles = [
    the_basics_tutorial_1,
    the_basics_tutorial_2,
    the_basics_tutorial_3,
    the_basics_tutorial_4,
    the_basics_tutorial_5,
    the_basics_tutorial_6,
    the_basics_tutorial_7,
    the_basics_tutorial_8,
    the_basics_tutorial_9,
    the_basics_tutorial_10,
    the_basics_tutorial_11,
    the_basics_tutorial_12,
    the_basics_tutorial_13,
    the_basics_tutorial_14,
    the_basics_tutorial_15,
    the_basics_tutorial_16,
    the_basics_tutorial_17,
    the_basics_tutorial_18,
    the_basics_tutorial_19,
    the_basics_tutorial_20,
    the_basics_tutorial_21,
    the_basics_tutorial_22,
    the_basics_tutorial_23,
    the_basics_tutorial_24,
    the_basics_tutorial_25,
    the_basics_tutorial_26,
    the_basics_tutorial_27,
    the_basics_tutorial_28,
    the_basics_tutorial_29,
    the_basics_tutorial_30,
    the_basics_tutorial_31,
    the_basics_tutorial_32,
    the_basics_tutorial_33,
    the_basics_tutorial_34,
    the_basics_tutorial_35,
];

export default theBasicsTutorialFiles;