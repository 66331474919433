module.exports={
  "SilverDecisions": "1.1.0",
  "buildTimestamp": 1710935291638,
  "savetime": "2024-05-08T12:50:04.460Z",
  "lng": "en",
  "viewMode": "criterion1",
  "rule": "expected-value-maximization",
  "title": "Project 2",
  "description": "",
  "format": {
    "locales": "en",
    "payoff1": {
      "style": "currency",
      "currency": "USD",
      "currencyDisplay": "symbol",
      "minimumFractionDigits": 0,
      "maximumFractionDigits": 0,
      "useGrouping": true
    },
    "payoff2": {
      "style": "percent",
      "currency": "USD",
      "currencyDisplay": "symbol",
      "minimumFractionDigits": 0,
      "maximumFractionDigits": 0,
      "useGrouping": true
    },
    "probability": {
      "style": "percent",
      "minimumFractionDigits": 0,
      "maximumFractionDigits": 0,
      "useGrouping": true
    }
  },
  "treeDesigner": {
    "margin": {
      "left": 550,
      "right": 500,
      "top": 200,
      "bottom": 100
    },
    "scale": 1,
    "lng": "en",
    "layout": {
      "type": "cluster",
      "nodeSize": 22,
      "limitNodePositioning": true,
      "limitTextPositioning": true,
      "gridHeight": 95,
      "gridWidth": 160,
      "edgeSlantWidthMax": 0
    },
    "fontFamily": "sans-serif",
    "fontSize": "12px",
    "fontWeight": "normal",
    "fontStyle": "normal",
    "node": {
      "strokeWidth": "2px",
      "optimal": {
        "stroke": "#006f00",
        "strokeWidth": "2px"
      },
      "label": {
        "fontSize": "1em",
        "color": "black"
      },
      "payoff": {
        "fontSize": "1em",
        "color": "black",
        "negativeColor": "#b60000"
      },
      "decision": {
        "fill": "#ff7777",
        "stroke": "#660000",
        "selected": {
          "fill": "#aa3333"
        }
      },
      "chance": {
        "fill": "#ffff44",
        "stroke": "#666600",
        "selected": {
          "fill": "#aaaa00"
        }
      },
      "terminal": {
        "fill": "#44ff44",
        "stroke": "black",
        "selected": {
          "fill": "#00aa00"
        },
        "payoff": {
          "fontSize": "1em",
          "color": "black",
          "negativeColor": "#b60000"
        }
      }
    },
    "edge": {
      "stroke": "#424242",
      "strokeWidth": "1.5",
      "optimal": {
        "stroke": "#006f00",
        "strokeWidth": "2.4"
      },
      "selected": {
        "stroke": "#045ad1",
        "strokeWidth": "3.5"
      },
      "label": {
        "fontSize": "1em",
        "color": "back"
      },
      "payoff": {
        "fontSize": "1em",
        "color": "black",
        "negativeColor": "#b60000"
      }
    },
    "probability": {
      "fontSize": "1em",
      "color": "#0000d7"
    },
    "title": {
      "fontSize": "16px",
      "fontWeight": "bold",
      "fontStyle": "normal",
      "color": "#000000",
      "margin": {
        "top": 15,
        "bottom": 10
      }
    },
    "description": {
      "show": true,
      "fontSize": "12px",
      "fontWeight": "bold",
      "fontStyle": "normal",
      "color": "#000000",
      "margin": {
        "top": 5,
        "bottom": 10
      }
    },
    "readOnly": false,
    "disableAnimations": false,
    "forceFullEdgeRedraw": false,
    "hideLabels": false,
    "hidePayoffs": true,
    "hideExpectedValues": false,
    "hideProbabilities": false,
    "hideCharts": false,
    "hideTextboxes": false,
    "hideMilestones": false,
    "raw": false,
    "payoffNames": [
      null,
      null
    ],
    "maxPayoffsToDisplay": 1,
    "milestoneNames": [
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    ]
  },
  "data": {
    "code": "MainClaimA=43\nClaimB=20\nClaimC=14\n",
    "expressionScope": {
      "MainClaimA": 43,
      "ClaimB": 20,
      "ClaimC": 14
    },
    "trees": [
      {
        "computed": {
          "expected-value-maximization": {
            "childrenPayoff": [
              "66 24488322973827/70368744177664"
            ],
            "payoff": [
              "66 15305201858643/43980465111040"
            ],
            "optimal": true
          },
          "expected-value-minimization": {
            "childrenPayoff": [
              "66 15305201858643/43980465111040"
            ],
            "payoff": [
              "66 15305201858643/43980465111040"
            ],
            "optimal": true
          },
          "maxi-min": {
            "childrenPayoff": [
              "0"
            ],
            "payoff": [
              "0"
            ],
            "optimal": true
          },
          "maxi-max": {
            "childrenPayoff": [
              "87 1/35184372088832"
            ],
            "payoff": [
              "87 1/35184372088832"
            ],
            "optimal": true
          },
          "mini-min": {
            "childrenPayoff": [
              "0"
            ],
            "payoff": [
              "0"
            ],
            "optimal": true
          },
          "mini-max": {
            "childrenPayoff": [
              "87 1/35184372088832"
            ],
            "payoff": [
              "87 1/35184372088832"
            ],
            "optimal": true
          },
          "min-max": {
            "childrenPayoff": [
              "66 15305201858643/43980465111040",
              "0"
            ],
            "combinedPayoff": "-66 15305201858643/43980465111040",
            "payoff": [
              "66 15305201858643/43980465111040",
              "0"
            ],
            "optimal": true
          },
          "max-min": {
            "childrenPayoff": [
              "66 15305201858643/43980465111040",
              "0"
            ],
            "combinedPayoff": "66 15305201858643/43980465111040",
            "payoff": [
              "66 15305201858643/43980465111040",
              "0"
            ],
            "optimal": true
          },
          "min-min": {
            "childrenPayoff": [
              "66 15305201858643/43980465111040",
              "0"
            ],
            "combinedPayoff": "-66 15305201858643/43980465111040",
            "payoff": [
              "66 15305201858643/43980465111040",
              "0"
            ],
            "optimal": true
          },
          "max-max": {
            "childrenPayoff": [
              "66 15305201858643/43980465111040",
              "0"
            ],
            "combinedPayoff": "66 15305201858643/43980465111040",
            "payoff": [
              "66 15305201858643/43980465111040",
              "0"
            ],
            "optimal": true
          }
        },
        "childEdges": [
          {
            "computed": {
              "payoff": [
                "0",
                "0"
              ],
              "expected-value-maximization": {
                "probability": 1,
                "optimal": true
              },
              "expected-value-minimization": {
                "probability": 1,
                "optimal": true
              },
              "maxi-min": {
                "probability": 1,
                "optimal": true
              },
              "maxi-max": {
                "probability": 1,
                "optimal": true
              },
              "mini-min": {
                "probability": 1,
                "optimal": true
              },
              "mini-max": {
                "probability": 1,
                "optimal": true
              },
              "min-max": {
                "probability": 1,
                "optimal": true
              },
              "max-min": {
                "probability": 1,
                "optimal": true
              },
              "min-min": {
                "probability": 1,
                "optimal": true
              },
              "max-max": {
                "probability": 1,
                "optimal": true
              }
            },
            "name": "",
            "payoff": [
              0,
              0
            ],
            "childNode": {
              "computed": {
                "expected-value-maximization": {
                  "childrenPayoff": [
                    "66 12244161486913/35184372088832"
                  ],
                  "payoff": [
                    "66 15305201858643/43980465111040"
                  ],
                  "optimal": true
                },
                "expected-value-minimization": {
                  "childrenPayoff": [
                    "66 15305201858643/43980465111040"
                  ],
                  "payoff": [
                    "66 15305201858643/43980465111040"
                  ],
                  "optimal": true
                },
                "maxi-min": {
                  "childrenPayoff": [
                    "0"
                  ],
                  "payoff": [
                    "0"
                  ],
                  "optimal": true
                },
                "maxi-max": {
                  "childrenPayoff": [
                    "87 1/35184372088832"
                  ],
                  "payoff": [
                    "87 1/35184372088832"
                  ],
                  "optimal": true
                },
                "mini-min": {
                  "childrenPayoff": [
                    "0"
                  ],
                  "payoff": [
                    "0"
                  ],
                  "optimal": true
                },
                "mini-max": {
                  "childrenPayoff": [
                    "87 1/35184372088832"
                  ],
                  "payoff": [
                    "87 1/35184372088832"
                  ],
                  "optimal": true
                },
                "min-max": {
                  "childrenPayoff": [
                    "66 15305201858643/43980465111040",
                    "0"
                  ],
                  "combinedPayoff": "-66 15305201858643/43980465111040",
                  "payoff": [
                    "66 15305201858643/43980465111040",
                    "0"
                  ],
                  "optimal": true
                },
                "max-min": {
                  "childrenPayoff": [
                    "66 15305201858643/43980465111040",
                    "0"
                  ],
                  "combinedPayoff": "66 15305201858643/43980465111040",
                  "payoff": [
                    "66 15305201858643/43980465111040",
                    "0"
                  ],
                  "optimal": true
                },
                "min-min": {
                  "childrenPayoff": [
                    "66 15305201858643/43980465111040",
                    "0"
                  ],
                  "combinedPayoff": "-66 15305201858643/43980465111040",
                  "payoff": [
                    "66 15305201858643/43980465111040",
                    "0"
                  ],
                  "optimal": true
                },
                "max-max": {
                  "childrenPayoff": [
                    "66 15305201858643/43980465111040",
                    "0"
                  ],
                  "combinedPayoff": "66 15305201858643/43980465111040",
                  "payoff": [
                    "66 15305201858643/43980465111040",
                    "0"
                  ],
                  "optimal": true
                }
              },
              "childEdges": [
                {
                  "computed": {
                    "payoff": [
                      "0",
                      "0"
                    ],
                    "probability": "9/10",
                    "expected-value-maximization": {
                      "probability": "9/10",
                      "optimal": true
                    },
                    "expected-value-minimization": {
                      "probability": "9/10",
                      "optimal": true
                    },
                    "maxi-min": {
                      "probability": 0,
                      "optimal": false
                    },
                    "maxi-max": {
                      "probability": 1,
                      "optimal": true
                    },
                    "mini-min": {
                      "probability": 0,
                      "optimal": false
                    },
                    "mini-max": {
                      "probability": 1,
                      "optimal": true
                    },
                    "min-max": {
                      "probability": "9/10",
                      "optimal": true
                    },
                    "max-min": {
                      "probability": "9/10",
                      "optimal": true
                    },
                    "min-min": {
                      "probability": "9/10",
                      "optimal": true
                    },
                    "max-max": {
                      "probability": "9/10",
                      "optimal": true
                    }
                  },
                  "name": "Yes",
                  "probability": ".9",
                  "payoff": [
                    0,
                    0
                  ],
                  "childNode": {
                    "computed": {
                      "expected-value-maximization": {
                        "childrenPayoff": [
                          "73 25332747903959/35184372088832"
                        ],
                        "payoff": [
                          "73 3166593487995/4398046511104"
                        ],
                        "optimal": true
                      },
                      "expected-value-minimization": {
                        "childrenPayoff": [
                          "73 3166593487995/4398046511104"
                        ],
                        "payoff": [
                          "73 3166593487995/4398046511104"
                        ],
                        "optimal": true
                      },
                      "maxi-min": {
                        "childrenPayoff": [
                          "54 1/35184372088832"
                        ],
                        "payoff": [
                          "54 1/35184372088832"
                        ]
                      },
                      "maxi-max": {
                        "childrenPayoff": [
                          "87 1/35184372088832"
                        ],
                        "payoff": [
                          "87 1/35184372088832"
                        ],
                        "optimal": true
                      },
                      "mini-min": {
                        "childrenPayoff": [
                          "54 1/35184372088832"
                        ],
                        "payoff": [
                          "54 1/35184372088832"
                        ]
                      },
                      "mini-max": {
                        "childrenPayoff": [
                          "87 1/35184372088832"
                        ],
                        "payoff": [
                          "87 1/35184372088832"
                        ],
                        "optimal": true
                      },
                      "min-max": {
                        "childrenPayoff": [
                          "73 3166593487995/4398046511104",
                          "0"
                        ],
                        "combinedPayoff": "-73 3166593487995/4398046511104",
                        "payoff": [
                          "73 3166593487995/4398046511104",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-min": {
                        "childrenPayoff": [
                          "73 3166593487995/4398046511104",
                          "0"
                        ],
                        "combinedPayoff": "73 3166593487995/4398046511104",
                        "payoff": [
                          "73 3166593487995/4398046511104",
                          "0"
                        ],
                        "optimal": true
                      },
                      "min-min": {
                        "childrenPayoff": [
                          "73 3166593487995/4398046511104",
                          "0"
                        ],
                        "combinedPayoff": "-73 3166593487995/4398046511104",
                        "payoff": [
                          "73 3166593487995/4398046511104",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-max": {
                        "childrenPayoff": [
                          "73 3166593487995/4398046511104",
                          "0"
                        ],
                        "combinedPayoff": "73 3166593487995/4398046511104",
                        "payoff": [
                          "73 3166593487995/4398046511104",
                          "0"
                        ],
                        "optimal": true
                      }
                    },
                    "childEdges": [
                      {
                        "computed": {
                          "payoff": [
                            "54 1/35184372088832",
                            "0"
                          ],
                          "probability": "1",
                          "expected-value-maximization": {
                            "probability": "1",
                            "optimal": true
                          },
                          "expected-value-minimization": {
                            "probability": "1",
                            "optimal": true
                          },
                          "maxi-min": {
                            "probability": 1
                          },
                          "maxi-max": {
                            "probability": 1,
                            "optimal": true
                          },
                          "mini-min": {
                            "probability": 1
                          },
                          "mini-max": {
                            "probability": 1,
                            "optimal": true
                          },
                          "min-max": {
                            "probability": "1",
                            "optimal": true
                          },
                          "max-min": {
                            "probability": "1",
                            "optimal": true
                          },
                          "min-min": {
                            "probability": "1",
                            "optimal": true
                          },
                          "max-max": {
                            "probability": "1",
                            "optimal": true
                          }
                        },
                        "name": "Wins",
                        "probability": "#",
                        "payoff": [
                          "MainClaimA",
                          0
                        ],
                        "childNode": {
                          "computed": {
                            "expected-value-maximization": {
                              "childrenPayoff": [
                                "19 25332747903959/35184372088832"
                              ],
                              "payoff": [
                                "73 3166593487995/4398046511104"
                              ],
                              "optimal": true
                            },
                            "expected-value-minimization": {
                              "childrenPayoff": [
                                "19 18/25"
                              ],
                              "payoff": [
                                "73 3166593487995/4398046511104"
                              ],
                              "optimal": true
                            },
                            "maxi-min": {
                              "childrenPayoff": [
                                "0"
                              ],
                              "payoff": [
                                "54 1/35184372088832"
                              ]
                            },
                            "maxi-max": {
                              "childrenPayoff": [
                                "33"
                              ],
                              "payoff": [
                                "87 1/35184372088832"
                              ],
                              "optimal": true
                            },
                            "mini-min": {
                              "childrenPayoff": [
                                "0"
                              ],
                              "payoff": [
                                "54 1/35184372088832"
                              ]
                            },
                            "mini-max": {
                              "childrenPayoff": [
                                "33"
                              ],
                              "payoff": [
                                "87 1/35184372088832"
                              ],
                              "optimal": true
                            },
                            "min-max": {
                              "childrenPayoff": [
                                "19 18/25",
                                "0"
                              ],
                              "combinedPayoff": "-73 3166593487995/4398046511104",
                              "payoff": [
                                "73 3166593487995/4398046511104",
                                "0"
                              ],
                              "optimal": true
                            },
                            "max-min": {
                              "childrenPayoff": [
                                "19 18/25",
                                "0"
                              ],
                              "combinedPayoff": "73 3166593487995/4398046511104",
                              "payoff": [
                                "73 3166593487995/4398046511104",
                                "0"
                              ],
                              "optimal": true
                            },
                            "min-min": {
                              "childrenPayoff": [
                                "19 18/25",
                                "0"
                              ],
                              "combinedPayoff": "-73 3166593487995/4398046511104",
                              "payoff": [
                                "73 3166593487995/4398046511104",
                                "0"
                              ],
                              "optimal": true
                            },
                            "max-max": {
                              "childrenPayoff": [
                                "19 18/25",
                                "0"
                              ],
                              "combinedPayoff": "73 3166593487995/4398046511104",
                              "payoff": [
                                "73 3166593487995/4398046511104",
                                "0"
                              ],
                              "optimal": true
                            }
                          },
                          "childEdges": [
                            {
                              "computed": {
                                "payoff": [
                                  "20",
                                  "0"
                                ],
                                "probability": "7/10",
                                "expected-value-maximization": {
                                  "probability": "7/10",
                                  "optimal": true
                                },
                                "expected-value-minimization": {
                                  "probability": "7/10",
                                  "optimal": true
                                },
                                "maxi-min": {
                                  "probability": 0
                                },
                                "maxi-max": {
                                  "probability": 1,
                                  "optimal": true
                                },
                                "mini-min": {
                                  "probability": 0
                                },
                                "mini-max": {
                                  "probability": 1,
                                  "optimal": true
                                },
                                "min-max": {
                                  "probability": "7/10",
                                  "optimal": true
                                },
                                "max-min": {
                                  "probability": "7/10",
                                  "optimal": true
                                },
                                "min-min": {
                                  "probability": "7/10",
                                  "optimal": true
                                },
                                "max-max": {
                                  "probability": "7/10",
                                  "optimal": true
                                }
                              },
                              "name": "Wins",
                              "probability": ".7",
                              "payoff": [
                                "ClaimB",
                                0
                              ],
                              "childNode": {
                                "computed": {
                                  "expected-value-maximization": {
                                    "childrenPayoff": [
                                      "6 562949953421313/1125899906842624"
                                    ],
                                    "payoff": [
                                      "26 1/2"
                                    ],
                                    "optimal": true
                                  },
                                  "expected-value-minimization": {
                                    "childrenPayoff": [
                                      "6 562949953421313/1125899906842624"
                                    ],
                                    "payoff": [
                                      "26 1/2"
                                    ],
                                    "optimal": true
                                  },
                                  "maxi-min": {
                                    "childrenPayoff": [
                                      "0"
                                    ],
                                    "payoff": [
                                      "20"
                                    ]
                                  },
                                  "maxi-max": {
                                    "childrenPayoff": [
                                      "13 1/562949953421312"
                                    ],
                                    "payoff": [
                                      "33"
                                    ],
                                    "optimal": true
                                  },
                                  "mini-min": {
                                    "childrenPayoff": [
                                      "0"
                                    ],
                                    "payoff": [
                                      "20"
                                    ]
                                  },
                                  "mini-max": {
                                    "childrenPayoff": [
                                      "13 1/562949953421312"
                                    ],
                                    "payoff": [
                                      "33"
                                    ],
                                    "optimal": true
                                  },
                                  "min-max": {
                                    "childrenPayoff": [
                                      "6 562949953421313/1125899906842624",
                                      "0"
                                    ],
                                    "combinedPayoff": "-26 1/2",
                                    "payoff": [
                                      "26 1/2",
                                      "0"
                                    ],
                                    "optimal": true
                                  },
                                  "max-min": {
                                    "childrenPayoff": [
                                      "6 562949953421313/1125899906842624",
                                      "0"
                                    ],
                                    "combinedPayoff": "26 1/2",
                                    "payoff": [
                                      "26 1/2",
                                      "0"
                                    ],
                                    "optimal": true
                                  },
                                  "min-min": {
                                    "childrenPayoff": [
                                      "6 562949953421313/1125899906842624",
                                      "0"
                                    ],
                                    "combinedPayoff": "-26 1/2",
                                    "payoff": [
                                      "26 1/2",
                                      "0"
                                    ],
                                    "optimal": true
                                  },
                                  "max-max": {
                                    "childrenPayoff": [
                                      "6 562949953421313/1125899906842624",
                                      "0"
                                    ],
                                    "combinedPayoff": "26 1/2",
                                    "payoff": [
                                      "26 1/2",
                                      "0"
                                    ],
                                    "optimal": true
                                  }
                                },
                                "childEdges": [
                                  {
                                    "computed": {
                                      "payoff": [
                                        "13 1/562949953421312",
                                        "0"
                                      ],
                                      "probability": "1/2",
                                      "expected-value-maximization": {
                                        "probability": "1/2",
                                        "optimal": true
                                      },
                                      "expected-value-minimization": {
                                        "probability": "1/2",
                                        "optimal": true
                                      },
                                      "maxi-min": {
                                        "probability": 0
                                      },
                                      "maxi-max": {
                                        "probability": 1,
                                        "optimal": true
                                      },
                                      "mini-min": {
                                        "probability": 0
                                      },
                                      "mini-max": {
                                        "probability": 1,
                                        "optimal": true
                                      },
                                      "min-max": {
                                        "probability": "1/2",
                                        "optimal": true
                                      },
                                      "max-min": {
                                        "probability": "1/2",
                                        "optimal": true
                                      },
                                      "min-min": {
                                        "probability": "1/2",
                                        "optimal": true
                                      },
                                      "max-max": {
                                        "probability": "1/2",
                                        "optimal": true
                                      }
                                    },
                                    "name": "Wins",
                                    "probability": "#",
                                    "payoff": [
                                      "ClaimC",
                                      0
                                    ],
                                    "childNode": {
                                      "computed": {
                                        "expected-value-maximization": {
                                          "aggregatedPayoff": [
                                            "87 1/70368744177664"
                                          ],
                                          "probabilityToEnter": "63/200",
                                          "payoff": [
                                            "13 1/562949953421312"
                                          ],
                                          "optimal": true
                                        },
                                        "expected-value-minimization": {
                                          "aggregatedPayoff": [
                                            "87 1/35184372088832"
                                          ],
                                          "probabilityToEnter": "63/200",
                                          "payoff": [
                                            "13 1/562949953421312"
                                          ],
                                          "optimal": true
                                        },
                                        "maxi-min": {
                                          "aggregatedPayoff": [
                                            "87 1/35184372088832"
                                          ],
                                          "probabilityToEnter": 0,
                                          "payoff": [
                                            "13 1/562949953421312"
                                          ]
                                        },
                                        "maxi-max": {
                                          "aggregatedPayoff": [
                                            "87 1/35184372088832"
                                          ],
                                          "probabilityToEnter": "1",
                                          "payoff": [
                                            "13 1/562949953421312"
                                          ],
                                          "optimal": true
                                        },
                                        "mini-min": {
                                          "aggregatedPayoff": [
                                            "87 1/35184372088832"
                                          ],
                                          "probabilityToEnter": 0,
                                          "payoff": [
                                            "13 1/562949953421312"
                                          ]
                                        },
                                        "mini-max": {
                                          "aggregatedPayoff": [
                                            "87 1/35184372088832"
                                          ],
                                          "probabilityToEnter": "1",
                                          "payoff": [
                                            "13 1/562949953421312"
                                          ],
                                          "optimal": true
                                        },
                                        "min-max": {
                                          "aggregatedPayoff": [
                                            "87 1/35184372088832",
                                            "0"
                                          ],
                                          "probabilityToEnter": "63/200",
                                          "combinedPayoff": "-13 1/562949953421312",
                                          "payoff": [
                                            "13 1/562949953421312",
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "max-min": {
                                          "aggregatedPayoff": [
                                            "87 1/35184372088832",
                                            "0"
                                          ],
                                          "probabilityToEnter": "63/200",
                                          "combinedPayoff": "13 1/562949953421312",
                                          "payoff": [
                                            "13 1/562949953421312",
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "min-min": {
                                          "aggregatedPayoff": [
                                            "87 1/35184372088832",
                                            "0"
                                          ],
                                          "probabilityToEnter": "63/200",
                                          "combinedPayoff": "-13 1/562949953421312",
                                          "payoff": [
                                            "13 1/562949953421312",
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "max-max": {
                                          "aggregatedPayoff": [
                                            "87 1/35184372088832",
                                            "0"
                                          ],
                                          "probabilityToEnter": "63/200",
                                          "combinedPayoff": "13 1/562949953421312",
                                          "payoff": [
                                            "13 1/562949953421312",
                                            "0"
                                          ],
                                          "optimal": true
                                        }
                                      },
                                      "childEdges": [],
                                      "name": "Claimant wins \none or more claims",
                                      "code": "",
                                      "expressionScope": {
                                        "MainClaimA": 43,
                                        "ClaimB": 20,
                                        "ClaimC": 14
                                      },
                                      "folded": false,
                                      "location": {
                                        "x": 811,
                                        "y": 11
                                      },
                                      "type": "terminal",
                                      "expectedValue": 87.00000000000001,
                                      "p10": 70,
                                      "p90": 106
                                    },
                                    "expectedValue": 13.000000000000002,
                                    "p10": 8,
                                    "p90": 18,
                                    "optimal": true
                                  },
                                  {
                                    "computed": {
                                      "payoff": [
                                        "0",
                                        "0"
                                      ],
                                      "probability": "1/2",
                                      "expected-value-maximization": {
                                        "probability": "1/2",
                                        "optimal": true
                                      },
                                      "expected-value-minimization": {
                                        "probability": "1/2",
                                        "optimal": true
                                      },
                                      "maxi-min": {
                                        "probability": 1
                                      },
                                      "maxi-max": {
                                        "probability": 0,
                                        "optimal": false
                                      },
                                      "mini-min": {
                                        "probability": 1
                                      },
                                      "mini-max": {
                                        "probability": 0,
                                        "optimal": false
                                      },
                                      "min-max": {
                                        "probability": "1/2",
                                        "optimal": true
                                      },
                                      "max-min": {
                                        "probability": "1/2",
                                        "optimal": true
                                      },
                                      "min-min": {
                                        "probability": "1/2",
                                        "optimal": true
                                      },
                                      "max-max": {
                                        "probability": "1/2",
                                        "optimal": true
                                      }
                                    },
                                    "name": "Looses",
                                    "probability": "#",
                                    "payoff": [
                                      0,
                                      0
                                    ],
                                    "childNode": {
                                      "computed": {
                                        "expected-value-maximization": {
                                          "aggregatedPayoff": [
                                            "74 1/70368744177664"
                                          ],
                                          "probabilityToEnter": "63/200",
                                          "payoff": [
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "expected-value-minimization": {
                                          "aggregatedPayoff": [
                                            "74 1/35184372088832"
                                          ],
                                          "probabilityToEnter": "63/200",
                                          "payoff": [
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "maxi-min": {
                                          "aggregatedPayoff": [
                                            "74 1/35184372088832"
                                          ],
                                          "probabilityToEnter": 0,
                                          "payoff": [
                                            "0"
                                          ]
                                        },
                                        "maxi-max": {
                                          "aggregatedPayoff": [
                                            "74 1/35184372088832"
                                          ],
                                          "probabilityToEnter": 0,
                                          "payoff": [
                                            "0"
                                          ]
                                        },
                                        "mini-min": {
                                          "aggregatedPayoff": [
                                            "74 1/35184372088832"
                                          ],
                                          "probabilityToEnter": 0,
                                          "payoff": [
                                            "0"
                                          ]
                                        },
                                        "mini-max": {
                                          "aggregatedPayoff": [
                                            "74 1/35184372088832"
                                          ],
                                          "probabilityToEnter": 0,
                                          "payoff": [
                                            "0"
                                          ]
                                        },
                                        "min-max": {
                                          "aggregatedPayoff": [
                                            "74 1/35184372088832",
                                            "0"
                                          ],
                                          "probabilityToEnter": "63/200",
                                          "combinedPayoff": "0",
                                          "payoff": [
                                            "0",
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "max-min": {
                                          "aggregatedPayoff": [
                                            "74 1/35184372088832",
                                            "0"
                                          ],
                                          "probabilityToEnter": "63/200",
                                          "combinedPayoff": "0",
                                          "payoff": [
                                            "0",
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "min-min": {
                                          "aggregatedPayoff": [
                                            "74 1/35184372088832",
                                            "0"
                                          ],
                                          "probabilityToEnter": "63/200",
                                          "combinedPayoff": "0",
                                          "payoff": [
                                            "0",
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "max-max": {
                                          "aggregatedPayoff": [
                                            "74 1/35184372088832",
                                            "0"
                                          ],
                                          "probabilityToEnter": "63/200",
                                          "combinedPayoff": "0",
                                          "payoff": [
                                            "0",
                                            "0"
                                          ],
                                          "optimal": true
                                        }
                                      },
                                      "childEdges": [],
                                      "name": "",
                                      "code": "",
                                      "expressionScope": {
                                        "MainClaimA": 43,
                                        "ClaimB": 20,
                                        "ClaimC": 14
                                      },
                                      "folded": false,
                                      "location": {
                                        "x": 811,
                                        "y": 106
                                      },
                                      "type": "terminal",
                                      "expectedValue": 74.00000000000001,
                                      "p10": 58,
                                      "p90": 93
                                    },
                                    "expectedValue": 0,
                                    "p10": 0,
                                    "p90": 0,
                                    "optimal": true
                                  }
                                ],
                                "name": "Claim \nC",
                                "code": "",
                                "expressionScope": {
                                  "MainClaimA": 43,
                                  "ClaimB": 20,
                                  "ClaimC": 14
                                },
                                "folded": false,
                                "location": {
                                  "x": 651,
                                  "y": 58.5
                                },
                                "type": "chance",
                                "expectedValue": 6.500000000000001,
                                "p10": 0,
                                "p90": 16
                              },
                              "expectedValue": 20,
                              "p10": 16,
                              "p90": 24,
                              "optimal": true
                            },
                            {
                              "computed": {
                                "payoff": [
                                  "0",
                                  "0"
                                ],
                                "probability": "3/10",
                                "expected-value-maximization": {
                                  "probability": "3/10",
                                  "optimal": true
                                },
                                "expected-value-minimization": {
                                  "probability": "3/10",
                                  "optimal": true
                                },
                                "maxi-min": {
                                  "probability": 1
                                },
                                "maxi-max": {
                                  "probability": 0,
                                  "optimal": false
                                },
                                "mini-min": {
                                  "probability": 1
                                },
                                "mini-max": {
                                  "probability": 0,
                                  "optimal": false
                                },
                                "min-max": {
                                  "probability": "3/10",
                                  "optimal": true
                                },
                                "max-min": {
                                  "probability": "3/10",
                                  "optimal": true
                                },
                                "min-min": {
                                  "probability": "3/10",
                                  "optimal": true
                                },
                                "max-max": {
                                  "probability": "3/10",
                                  "optimal": true
                                }
                              },
                              "name": "Looses",
                              "probability": "#",
                              "payoff": [
                                0,
                                0
                              ],
                              "childNode": {
                                "computed": {
                                  "expected-value-maximization": {
                                    "childrenPayoff": [
                                      "3 506654958079181/562949953421312"
                                    ],
                                    "payoff": [
                                      "3 1266637395197953/1407374883553280"
                                    ],
                                    "optimal": true
                                  },
                                  "expected-value-minimization": {
                                    "childrenPayoff": [
                                      "3 1266637395197953/1407374883553280"
                                    ],
                                    "payoff": [
                                      "3 1266637395197953/1407374883553280"
                                    ],
                                    "optimal": true
                                  },
                                  "maxi-min": {
                                    "childrenPayoff": [
                                      "0"
                                    ],
                                    "payoff": [
                                      "0"
                                    ]
                                  },
                                  "maxi-max": {
                                    "childrenPayoff": [
                                      "13 1/562949953421312"
                                    ],
                                    "payoff": [
                                      "13 1/562949953421312"
                                    ]
                                  },
                                  "mini-min": {
                                    "childrenPayoff": [
                                      "0"
                                    ],
                                    "payoff": [
                                      "0"
                                    ]
                                  },
                                  "mini-max": {
                                    "childrenPayoff": [
                                      "13 1/562949953421312"
                                    ],
                                    "payoff": [
                                      "13 1/562949953421312"
                                    ]
                                  },
                                  "min-max": {
                                    "childrenPayoff": [
                                      "3 1266637395197953/1407374883553280",
                                      "0"
                                    ],
                                    "combinedPayoff": "-3 1266637395197953/1407374883553280",
                                    "payoff": [
                                      "3 1266637395197953/1407374883553280",
                                      "0"
                                    ],
                                    "optimal": true
                                  },
                                  "max-min": {
                                    "childrenPayoff": [
                                      "3 1266637395197953/1407374883553280",
                                      "0"
                                    ],
                                    "combinedPayoff": "3 1266637395197953/1407374883553280",
                                    "payoff": [
                                      "3 1266637395197953/1407374883553280",
                                      "0"
                                    ],
                                    "optimal": true
                                  },
                                  "min-min": {
                                    "childrenPayoff": [
                                      "3 1266637395197953/1407374883553280",
                                      "0"
                                    ],
                                    "combinedPayoff": "-3 1266637395197953/1407374883553280",
                                    "payoff": [
                                      "3 1266637395197953/1407374883553280",
                                      "0"
                                    ],
                                    "optimal": true
                                  },
                                  "max-max": {
                                    "childrenPayoff": [
                                      "3 1266637395197953/1407374883553280",
                                      "0"
                                    ],
                                    "combinedPayoff": "3 1266637395197953/1407374883553280",
                                    "payoff": [
                                      "3 1266637395197953/1407374883553280",
                                      "0"
                                    ],
                                    "optimal": true
                                  }
                                },
                                "childEdges": [
                                  {
                                    "computed": {
                                      "payoff": [
                                        "13 1/562949953421312",
                                        "0"
                                      ],
                                      "probability": "3/10",
                                      "expected-value-maximization": {
                                        "probability": "3/10",
                                        "optimal": true
                                      },
                                      "expected-value-minimization": {
                                        "probability": "3/10",
                                        "optimal": true
                                      },
                                      "maxi-min": {
                                        "probability": 0
                                      },
                                      "maxi-max": {
                                        "probability": 1
                                      },
                                      "mini-min": {
                                        "probability": 0
                                      },
                                      "mini-max": {
                                        "probability": 1
                                      },
                                      "min-max": {
                                        "probability": "3/10",
                                        "optimal": true
                                      },
                                      "max-min": {
                                        "probability": "3/10",
                                        "optimal": true
                                      },
                                      "min-min": {
                                        "probability": "3/10",
                                        "optimal": true
                                      },
                                      "max-max": {
                                        "probability": "3/10",
                                        "optimal": true
                                      }
                                    },
                                    "name": "Wins",
                                    "probability": ".3",
                                    "payoff": [
                                      "ClaimC",
                                      0
                                    ],
                                    "childNode": {
                                      "computed": {
                                        "expected-value-maximization": {
                                          "aggregatedPayoff": [
                                            "67"
                                          ],
                                          "probabilityToEnter": "81/1000",
                                          "payoff": [
                                            "13 1/562949953421312"
                                          ],
                                          "optimal": true
                                        },
                                        "expected-value-minimization": {
                                          "aggregatedPayoff": [
                                            "67 1/35184372088832"
                                          ],
                                          "probabilityToEnter": "81/1000",
                                          "payoff": [
                                            "13 1/562949953421312"
                                          ],
                                          "optimal": true
                                        },
                                        "maxi-min": {
                                          "aggregatedPayoff": [
                                            "67 1/35184372088832"
                                          ],
                                          "probabilityToEnter": 0,
                                          "payoff": [
                                            "13 1/562949953421312"
                                          ]
                                        },
                                        "maxi-max": {
                                          "aggregatedPayoff": [
                                            "67 1/35184372088832"
                                          ],
                                          "probabilityToEnter": 0,
                                          "payoff": [
                                            "13 1/562949953421312"
                                          ]
                                        },
                                        "mini-min": {
                                          "aggregatedPayoff": [
                                            "67 1/35184372088832"
                                          ],
                                          "probabilityToEnter": 0,
                                          "payoff": [
                                            "13 1/562949953421312"
                                          ]
                                        },
                                        "mini-max": {
                                          "aggregatedPayoff": [
                                            "67 1/35184372088832"
                                          ],
                                          "probabilityToEnter": 0,
                                          "payoff": [
                                            "13 1/562949953421312"
                                          ]
                                        },
                                        "min-max": {
                                          "aggregatedPayoff": [
                                            "67 1/35184372088832",
                                            "0"
                                          ],
                                          "probabilityToEnter": "81/1000",
                                          "combinedPayoff": "-13 1/562949953421312",
                                          "payoff": [
                                            "13 1/562949953421312",
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "max-min": {
                                          "aggregatedPayoff": [
                                            "67 1/35184372088832",
                                            "0"
                                          ],
                                          "probabilityToEnter": "81/1000",
                                          "combinedPayoff": "13 1/562949953421312",
                                          "payoff": [
                                            "13 1/562949953421312",
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "min-min": {
                                          "aggregatedPayoff": [
                                            "67 1/35184372088832",
                                            "0"
                                          ],
                                          "probabilityToEnter": "81/1000",
                                          "combinedPayoff": "-13 1/562949953421312",
                                          "payoff": [
                                            "13 1/562949953421312",
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "max-max": {
                                          "aggregatedPayoff": [
                                            "67 1/35184372088832",
                                            "0"
                                          ],
                                          "probabilityToEnter": "81/1000",
                                          "combinedPayoff": "13 1/562949953421312",
                                          "payoff": [
                                            "13 1/562949953421312",
                                            "0"
                                          ],
                                          "optimal": true
                                        }
                                      },
                                      "childEdges": [],
                                      "name": "",
                                      "code": "",
                                      "expressionScope": {
                                        "MainClaimA": 43,
                                        "ClaimB": 20,
                                        "ClaimC": 14
                                      },
                                      "folded": false,
                                      "location": {
                                        "x": 811,
                                        "y": 220
                                      },
                                      "type": "terminal",
                                      "expectedValue": 67,
                                      "p10": 51,
                                      "p90": 86
                                    },
                                    "expectedValue": 13.000000000000002,
                                    "p10": 8,
                                    "p90": 18,
                                    "optimal": true
                                  },
                                  {
                                    "computed": {
                                      "payoff": [
                                        "0",
                                        "0"
                                      ],
                                      "probability": "7/10",
                                      "expected-value-maximization": {
                                        "probability": "7/10",
                                        "optimal": true
                                      },
                                      "expected-value-minimization": {
                                        "probability": "7/10",
                                        "optimal": true
                                      },
                                      "maxi-min": {
                                        "probability": 1
                                      },
                                      "maxi-max": {
                                        "probability": 0
                                      },
                                      "mini-min": {
                                        "probability": 1
                                      },
                                      "mini-max": {
                                        "probability": 0
                                      },
                                      "min-max": {
                                        "probability": "7/10",
                                        "optimal": true
                                      },
                                      "max-min": {
                                        "probability": "7/10",
                                        "optimal": true
                                      },
                                      "min-min": {
                                        "probability": "7/10",
                                        "optimal": true
                                      },
                                      "max-max": {
                                        "probability": "7/10",
                                        "optimal": true
                                      }
                                    },
                                    "name": "Looses",
                                    "probability": "#",
                                    "payoff": [
                                      0,
                                      0
                                    ],
                                    "childNode": {
                                      "computed": {
                                        "expected-value-maximization": {
                                          "aggregatedPayoff": [
                                            "54 1/70368744177664"
                                          ],
                                          "probabilityToEnter": "189/1000",
                                          "payoff": [
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "expected-value-minimization": {
                                          "aggregatedPayoff": [
                                            "54 1/35184372088832"
                                          ],
                                          "probabilityToEnter": "189/1000",
                                          "payoff": [
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "maxi-min": {
                                          "aggregatedPayoff": [
                                            "54 1/35184372088832"
                                          ],
                                          "probabilityToEnter": 0,
                                          "payoff": [
                                            "0"
                                          ]
                                        },
                                        "maxi-max": {
                                          "aggregatedPayoff": [
                                            "54 1/35184372088832"
                                          ],
                                          "probabilityToEnter": 0,
                                          "payoff": [
                                            "0"
                                          ]
                                        },
                                        "mini-min": {
                                          "aggregatedPayoff": [
                                            "54 1/35184372088832"
                                          ],
                                          "probabilityToEnter": 0,
                                          "payoff": [
                                            "0"
                                          ]
                                        },
                                        "mini-max": {
                                          "aggregatedPayoff": [
                                            "54 1/35184372088832"
                                          ],
                                          "probabilityToEnter": 0,
                                          "payoff": [
                                            "0"
                                          ]
                                        },
                                        "min-max": {
                                          "aggregatedPayoff": [
                                            "54 1/35184372088832",
                                            "0"
                                          ],
                                          "probabilityToEnter": "189/1000",
                                          "combinedPayoff": "0",
                                          "payoff": [
                                            "0",
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "max-min": {
                                          "aggregatedPayoff": [
                                            "54 1/35184372088832",
                                            "0"
                                          ],
                                          "probabilityToEnter": "189/1000",
                                          "combinedPayoff": "0",
                                          "payoff": [
                                            "0",
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "min-min": {
                                          "aggregatedPayoff": [
                                            "54 1/35184372088832",
                                            "0"
                                          ],
                                          "probabilityToEnter": "189/1000",
                                          "combinedPayoff": "0",
                                          "payoff": [
                                            "0",
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "max-max": {
                                          "aggregatedPayoff": [
                                            "54 1/35184372088832",
                                            "0"
                                          ],
                                          "probabilityToEnter": "189/1000",
                                          "combinedPayoff": "0",
                                          "payoff": [
                                            "0",
                                            "0"
                                          ],
                                          "optimal": true
                                        }
                                      },
                                      "childEdges": [],
                                      "name": "",
                                      "code": "",
                                      "expressionScope": {
                                        "MainClaimA": 43,
                                        "ClaimB": 20,
                                        "ClaimC": 14
                                      },
                                      "folded": false,
                                      "location": {
                                        "x": 811,
                                        "y": 315
                                      },
                                      "type": "terminal",
                                      "expectedValue": 54.000000000000014,
                                      "p10": 39,
                                      "p90": 73
                                    },
                                    "expectedValue": 0,
                                    "p10": 0,
                                    "p90": 0,
                                    "optimal": true
                                  }
                                ],
                                "name": "Claim \nC",
                                "code": "",
                                "expressionScope": {
                                  "MainClaimA": 43,
                                  "ClaimB": 20,
                                  "ClaimC": 14
                                },
                                "folded": false,
                                "location": {
                                  "x": 651,
                                  "y": 267.5
                                },
                                "type": "chance",
                                "expectedValue": 3.9000000000000004,
                                "p10": 0,
                                "p90": 15
                              },
                              "expectedValue": 0,
                              "p10": 0,
                              "p90": 0,
                              "optimal": true
                            }
                          ],
                          "name": "Claim\nB",
                          "code": "",
                          "expressionScope": {
                            "MainClaimA": 43,
                            "ClaimB": 20,
                            "ClaimC": 14
                          },
                          "folded": false,
                          "location": {
                            "x": 491,
                            "y": 163
                          },
                          "type": "chance",
                          "expectedValue": 19.72,
                          "p10": 0,
                          "p90": 36
                        },
                        "expectedValue": 54.00000000000003,
                        "p10": 39,
                        "p90": 73,
                        "optimal": true
                      }
                    ],
                    "name": "Main\nclaim\nA",
                    "code": "",
                    "expressionScope": {
                      "MainClaimA": 43,
                      "ClaimB": 20,
                      "ClaimC": 14
                    },
                    "folded": false,
                    "location": {
                      "x": 331,
                      "y": 163
                    },
                    "type": "chance",
                    "expectedValue": 73.72,
                    "p10": 50,
                    "p90": 98,
                    "charts": [
                      {
                        "nodeId": "7b65e73c-282e-91e3-b2b0-a351abbcb358",
                        "x": -76.00000238418579,
                        "y": 44.999996185302734,
                        "type": "distribution",
                        "active": true
                      }
                    ]
                  },
                  "expectedValue": 0,
                  "p10": 0,
                  "p90": 0,
                  "optimal": true
                },
                {
                  "computed": {
                    "payoff": [
                      "0",
                      "0"
                    ],
                    "probability": "1/10",
                    "expected-value-maximization": {
                      "probability": "1/10",
                      "optimal": true
                    },
                    "expected-value-minimization": {
                      "probability": "1/10",
                      "optimal": true
                    },
                    "maxi-min": {
                      "probability": 1,
                      "optimal": true
                    },
                    "maxi-max": {
                      "probability": 0,
                      "optimal": false
                    },
                    "mini-min": {
                      "probability": 1,
                      "optimal": true
                    },
                    "mini-max": {
                      "probability": 0,
                      "optimal": false
                    },
                    "min-max": {
                      "probability": "1/10",
                      "optimal": true
                    },
                    "max-min": {
                      "probability": "1/10",
                      "optimal": true
                    },
                    "min-min": {
                      "probability": "1/10",
                      "optimal": true
                    },
                    "max-max": {
                      "probability": "1/10",
                      "optimal": true
                    }
                  },
                  "name": "No",
                  "probability": "#",
                  "payoff": [
                    0,
                    0
                  ],
                  "childNode": {
                    "computed": {
                      "expected-value-maximization": {
                        "aggregatedPayoff": [
                          "0"
                        ],
                        "probabilityToEnter": "1/10",
                        "payoff": [
                          "0"
                        ],
                        "optimal": true
                      },
                      "expected-value-minimization": {
                        "aggregatedPayoff": [
                          "0"
                        ],
                        "probabilityToEnter": "1/10",
                        "payoff": [
                          "0"
                        ],
                        "optimal": true
                      },
                      "maxi-min": {
                        "aggregatedPayoff": [
                          "0"
                        ],
                        "probabilityToEnter": "1",
                        "payoff": [
                          "0"
                        ],
                        "optimal": true
                      },
                      "maxi-max": {
                        "aggregatedPayoff": [
                          "0"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "0"
                        ]
                      },
                      "mini-min": {
                        "aggregatedPayoff": [
                          "0"
                        ],
                        "probabilityToEnter": "1",
                        "payoff": [
                          "0"
                        ],
                        "optimal": true
                      },
                      "mini-max": {
                        "aggregatedPayoff": [
                          "0"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "0"
                        ]
                      },
                      "min-max": {
                        "aggregatedPayoff": [
                          "0",
                          "0"
                        ],
                        "probabilityToEnter": "1/10",
                        "combinedPayoff": "0",
                        "payoff": [
                          "0",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-min": {
                        "aggregatedPayoff": [
                          "0",
                          "0"
                        ],
                        "probabilityToEnter": "1/10",
                        "combinedPayoff": "0",
                        "payoff": [
                          "0",
                          "0"
                        ],
                        "optimal": true
                      },
                      "min-min": {
                        "aggregatedPayoff": [
                          "0",
                          "0"
                        ],
                        "probabilityToEnter": "1/10",
                        "combinedPayoff": "0",
                        "payoff": [
                          "0",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-max": {
                        "aggregatedPayoff": [
                          "0",
                          "0"
                        ],
                        "probabilityToEnter": "1/10",
                        "combinedPayoff": "0",
                        "payoff": [
                          "0",
                          "0"
                        ],
                        "optimal": true
                      }
                    },
                    "childEdges": [],
                    "name": "Claimant\nlooses",
                    "code": "",
                    "expressionScope": {
                      "MainClaimA": 43,
                      "ClaimB": 20,
                      "ClaimC": 14
                    },
                    "folded": false,
                    "location": {
                      "x": 811,
                      "y": 429
                    },
                    "type": "terminal",
                    "expectedValue": 0,
                    "p10": 0,
                    "p90": 0
                  },
                  "expectedValue": 0,
                  "p10": 0,
                  "p90": 0,
                  "optimal": true
                }
              ],
              "name": "Basis for \nterminating \nthe contract",
              "code": "",
              "expressionScope": {
                "MainClaimA": 43,
                "ClaimB": 20,
                "ClaimC": 14
              },
              "folded": false,
              "location": {
                "x": 171,
                "y": 296
              },
              "type": "chance",
              "expectedValue": 66.34799999999998,
              "p10": 31,
              "p90": 96
            },
            "expectedValue": 0,
            "p10": 0,
            "p90": 0,
            "optimal": true
          }
        ],
        "name": "Range of \nfinancial gain",
        "code": "",
        "expressionScope": {
          "MainClaimA": 43,
          "ClaimB": 20,
          "ClaimC": 14
        },
        "folded": false,
        "location": {
          "x": 11,
          "y": 296
        },
        "type": "decision",
        "expectedValue": 66.348,
        "p10": 0,
        "p90": 96,
        "charts": [
          {
            "nodeId": "6ca41f5b-5636-679e-6b55-d5d8998dae0a",
            "x": -76.99999618530273,
            "y": 143.99998474121094,
            "type": "distribution",
            "active": true
          }
        ]
      }
    ],
    "texts": [
      {
        "value": "LEGAL DISPUTE",
        "location": {
          "x": -53.99999415874481,
          "y": 47.66838073730469
        }
      }
    ],
    "payoffNames": [],
    "defaultCriterion1Weight": 1,
    "weightLowerBound": 0,
    "weightUpperBound": "Infinity"
  },
  "sensitivityAnalysis": {},
  "definitionsDialog": {
    "valueList": [
      {
        "name": "MainClaimA",
        "value": "43",
        "low": 36,
        "high": 68,
        "distribution": "triangular",
        "error": ""
      },
      {
        "name": "ClaimB",
        "value": "20",
        "low": 16,
        "high": 24,
        "distribution": "triangular",
        "error": ""
      },
      {
        "name": "ClaimC",
        "value": "14",
        "low": 8,
        "high": 18,
        "distribution": "triangular",
        "error": ""
      }
    ]
  }
}