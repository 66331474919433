const chartsForInsightTutorialHtml = [
    "<p>This is the last of three introductory tutorials. When completed, you are ready to solve problems on your own.</p> <p><u>As in previous tutorials</u><br>Use your <strong>ARROW KEYS</strong> ← → on the keyboard to click through the tutorial.</p> <p>You can play around and tweak the tree as we go along, testing your own ideas and assumptions.</p> <p>Let's get started!</p>",

    "<p><u>Tip</u><br>This and other tutorials are readily available under the Help menu.</p> <img src=\"/build/images/tutorial-images/help-menu-screenshot.png\" alt=\"\"> <br><br> <p><u>Mouse actions</u><br>You’ll find them under the mouse icon in the top left corner of the canvas.</p><img src=\"/build/images/tutorial-images/mouse-actions-2.png\" alt=\"\" style=\"width: 219px;\">",

    "<i>Tutorial overview</i><p>1. In the previous tutorials, you learned the basics of building a decision tree.</p> <p class='tutorial-text-opacity'>2. You also learned how the tree is calculated and when to use variables and distributions.</p> <p class='tutorial-text-opacity'>3. Now, we will focus on communicating results and insights using charts.</p>",

    "<i>Tutorial overview</i><p class='tutorial-text-opacity'>1. In the previous tutorials, you learned the basics of building a decision tree.</p> <p>2. You also learned how the tree is calculated and when to use variables and distributions.</p> <p class='tutorial-text-opacity'>3. Now, we will focus on communicating results and insights using charts.</p>",

    "<i>Tutorial overview</i><p class='tutorial-text-opacity'>1. In the previous tutorials, you learned the basics of building a decision tree.</p> <p class='tutorial-text-opacity'>2. You also learned how the tree is calculated and when to use variables and distributions.</p> <p>3. Now, we will focus on communicating results and insights using charts.</p>",

    "<i>Just a friendly reminder</i> <br> <p>The example we'll be using is from a previous tutorial.</p> <p>It involves a legal case with multiple claims and assesses the potential range of financial gain. </p> <p>The tree structure is displayed on the right.</p>",

    "<p>In this example, we have three variables: MainClaimA, ClaimB, and ClaimC.</p> <p>These variables are interconnected in the tree and form the foundation for our calculations.</p> <p>Now, let's dive into the charts and explore them in more detail!</p>",
    
    "<p>We offer four types of charts in dNodes.io:</p> <p><i>1. Bar chart <br> 2. Waterfall <br> 3. Distribution <br> 4. Tornado</i></p> <p>Let's explore the uses and insights provided by each of these charts.</p>",

    "<u>1. Bar chart</u> <br> <br> <img src=\"/build/images/tutorial-images/bar-chart-2.png\" alt=\"\" style=\"width: 200px;\"> <br> <p>Bar charts are used to visualize the relationship between value and probability.</p> <p>They can be displayed at any node of a decision tree.</p>",

    "<u>1. Bar chart</u> <br> <br> <img src=\"/build/images/tutorial-images/bar-chart-2.png\" alt=\"\" style=\"width: 200px;\"> <br> <p>The grey bar represents the expected value ($84) of the selected node.</p> <p>This value is multiplied by a 32% chance to occur, resulting in the green bar.</p> <p>A wide grey bar with a narrow green bar indicates a high value with a low probability.</p>",

    "<u>1. Bar chart</u> <br> <br> <img src=\"/build/images/tutorial-images/bar-chart-2.png\" alt=\"\" style=\"width: 200px;\"> <br> <p>By stacking these charts vertically, different expected values can be easily compared.</p> <p>The x-axis (horizontal axis) is automatically set and remains consistent across all bar charts, enabling visual comparison between nodes.</p>",

    "<u>2. Waterfall</u> <br> <br> <img src=\"/build/images/tutorial-images/waterfall.png\" alt=\"\" style=\"width: 200px;\"> <br> <br> <p>Waterfall diagrams illustrate the breakdown of payoffs that contribute to the expected value of the selected node. </p> <p>These diagrams are only displayed at the terminal nodes of the tree.</p>",

    "<u>2. Waterfall</u> <br> <br> <img src=\"/build/images/tutorial-images/waterfall.png\" alt=\"\" style=\"width: 200px;\"> <br> <br> <p>In the example above, the three green bars represent the payoffs associated with winning Claim A, Claim B, and Claim C, which collectively result in an expected value of $84 for the selected node.</p>",

    "<u>2. Waterfall</u> <br> <br> <img src=\"/build/images/tutorial-images/waterfall-2.png\" alt=\"\" style=\"width: 300px;\"> <br> <br> <p>By hovering your mouse over each green bar, you can inspect the specific payoff reference. </p> <p>In this particular example, the first bar corresponds to the payoff ($51) defined by the variable \"MainClaimA\".</p>",

    "<u>3. Distribution</u> <br> <br> <img src=\"/build/images/tutorial-images/distribution-chart-2.png\" alt=\"\" style=\"width: 200px;\"> <br> <p>The probability distribution helps in understanding uncertainty and indicates which values are more or less likely to occur.</p> <p>Distribution charts can be shown for any node in the tree, provided you have defined and connected relevant variables.</p>",

    "<u>3. Distribution</u> <br> <br> <img src=\"/build/images/tutorial-images/distribution-chart-2.png\" alt=\"\" style=\"width: 200px;\"> <br> <p>In the example above, the $64 represents the expected value, which is the probability-weighted average of all possible outcomes.</p>",

    "<u>3. Distribution</u> <br> <br> <img src=\"/build/images/tutorial-images/distribution-chart-2.png\" alt=\"\" style=\"width: 200px;\"> <br> <p>The $28 represents the low value, defined such that there is a 10% probability that the outcome is lower than this value.</p> <p>The $93 represents the high value, defined such that there is a 10% probability that the outcome is higher than this value.</p>",

    "<u>3. Distribution</u> <br> <br> <img src=\"/build/images/tutorial-images/distribution-chart-2.png\" alt=\"\" style=\"width: 200px;\"> <br> <p>Similar to bar charts, you can stack distribution charts vertically to compare outcomes easily.</p> <p>The x-axis is automatically set and consistent across all distribution charts, enabling convenient visual comparison of different nodes in the tree.</p>",

    "<u>4. Tornado</u> <br> <br> <img src=\"/build/images/tutorial-images/tornado.png\" alt=\"\" style=\"width: 220px;\"> <br> <p>The tornado diagram is used to identify the relative importance each variable has on the outcome.</p> <p>Tornado diagrams can be displayed for either the root or terminal nodes in the tree, as long as you have defined and connected relevant variables.</p>",

    "<u>4. Tornado</u> <br> <br> <img src=\"/build/images/tutorial-images/tornado.png\" alt=\"\" style=\"width: 220px;\"> <br> <p>In the example above, the $64 represents the expected value of the selected node. </p> <p>Each bar in the tornado diagram has been constructed by changing the value of one variable at a time, from low to high.</p>",

    "<u>4. Tornado</u> <br> <br> <img src=\"/build/images/tutorial-images/tornado.png\" alt=\"\" style=\"width: 220px;\"> <br> <p>Hovering your mouse over each bar reveals the impact each variable has on the outcome, in descending order.</p> <p>In this example, the variable \"MainClaimA\" has the most impact. It can potentially alter the expected outcome (the $64) down to $50 on the low side or up to $82 on the high side.</p>",

    "<p>Combining charts allows us to draw important conclusions from this example:</p> <p>- The expected financial gain is $64. <br> - Winning MainClaimA leads to an expected financial gain ranging from $47 to $93, with an average of $71. <br> - Winning all claims has a 32% chance and an expected financial gain of $84. <br> - There is a 19% chance of winning MainClaimA but losing other claims, resulting in an expected financial gain of $51. <br> - The variable MainClaimA contributes the most to overall uncertainty.</p>",

    "<p>That’s it - well done on finishing this tutorial!</p> <p>Feel free to revise your learnings by clicking on the previous tutorial.</p> <div class=\"tutorial-button-container\"><button id=\"previous-tutorial-button\" class=\"prev-tutorial-btn\">← PREVIOUS TUTORIAL</button></div> <br> <p>Alternatively, dive in and start exploring the tool on your own.</p> <p>Have fun!</p>",
];

export default chartsForInsightTutorialHtml;