// https://stackblitz.com/edit/multi-touch-trackpad-gesture?file=index.js
// https://jsfiddle.net/zez538L8/13/
export function initAvailableMoveGestures(treeDesigner) {
    let treeContainer = document.getElementById('tree-designer-container');
    let layoutMenuButton =
        document.getElementsByClassName('js-toolbar-toggle')[0];

    treeContainer.addEventListener('wheel', (e) => {
        e.preventDefault();
        let prevScale = treeDesigner.scale;
        if (e.ctrlKey) {
            let scale = treeDesigner.scale - e.deltaY * 0.01;

            let svgWidth = treeDesigner.svg.attr('width');
            let containerWidth = treeContainer.offsetWidth;
            let svgHeight = treeDesigner.svg.attr('height');
            let containerHeight = treeContainer.offsetHeight;

            let minScale = containerWidth / svgWidth;
            if (containerHeight / svgHeight < minScale)
                minScale = containerHeight / svgHeight;

            if (scale < minScale) scale = minScale;
            if (scale > 3) scale = 3;
            treeDesigner.scale = scale;
        }
        
        let posX = treeDesigner.posX - e.deltaX * 2;
        let posY = treeDesigner.posY - e.deltaY * 2;
        if (treeDesigner.scale != prevScale) {
            const ratio = 1 - treeDesigner.scale / prevScale;
            const { clientX, clientY } = e;
            let leftCanvasMargin = 80;
            let topCanvasMargin = 0;
            
            posX += (clientX - leftCanvasMargin - treeDesigner.posX) * ratio;
            posY += (clientY - topCanvasMargin - treeDesigner.posY) * ratio;
        }
        treeDesigner.updatePositionAndZoom(posX, posY);
        if(treeDesigner.scale < prevScale && document.getElementById("interactive-tutorial").hidden){
            const containerWidth = treeContainer.offsetWidth;
            const treeWidth = document.querySelector("g.main-group").getBBox().width * treeDesigner.scale;
            const marginLeft = containerWidth/2 - treeWidth/2;
            
            treeDesigner.setMargin({
                left: marginLeft
            }, true)
        }
    });

    treeContainer.addEventListener('gesturestart', function (e) {
        e.preventDefault();
        treeDesigner.startX = e.pageX - treeDesigner.posX;
        treeDesigner.startY = e.pageY - treeDesigner.posY;
        treeDesigner.gestureStartScale = treeDesigner.scale;
    });

    treeContainer.addEventListener('gesturechange', function (e) {
        e.preventDefault();
        let prevScale = treeDesigner.scale;
        let scale = treeDesigner.gestureStartScale * e.scale;
        if (scale < 0.5) scale = 0.5;
        if (scale > 3) scale = 3;
        treeDesigner.scale = scale;

        let posX = treeDesigner.posX;
        let posY = treeDesigner.posY;
        if (treeDesigner.scale != prevScale) {
            const ratio = 1 - treeDesigner.scale / prevScale;
            const { clientX, clientY } = e;
            let leftCanvasMargin = 80;
            let topCanvasMargin = 80;

            posX += (clientX - leftCanvasMargin - treeDesigner.posX) * ratio;
            posY += (clientY - topCanvasMargin - treeDesigner.posY) * ratio;
        }

        treeDesigner.updatePositionAndZoom(posX, posY);
    });

    treeContainer.addEventListener('gestureend', function (e) {
        e.preventDefault();
    });

    layoutMenuButton.addEventListener('click', function () {
        setTimeout(() => {
            treeDesigner.updatePlottingRegionSize();
        }, 100);
    });
}
