module.exports={
  "SilverDecisions": "1.1.0",
  "buildTimestamp": 1710935291638,
  "savetime": "2024-05-08T10:00:02.658Z",
  "lng": "en",
  "viewMode": "criterion1",
  "rule": "expected-value-maximization",
  "title": "Slide 3",
  "description": "",
  "format": {
    "locales": "en",
    "payoff1": {
      "style": "currency",
      "currency": "USD",
      "currencyDisplay": "symbol",
      "minimumFractionDigits": 0,
      "maximumFractionDigits": 0,
      "useGrouping": true
    },
    "payoff2": {
      "style": "percent",
      "currency": "USD",
      "currencyDisplay": "symbol",
      "minimumFractionDigits": 0,
      "maximumFractionDigits": 0,
      "useGrouping": true
    },
    "probability": {
      "style": "percent",
      "minimumFractionDigits": 0,
      "maximumFractionDigits": 0,
      "useGrouping": true
    }
  },
  "treeDesigner": {
    "margin": {
      "left": 650,
      "right": 500,
      "top": 200,
      "bottom": 100
    },
    "scale": 1,
    "lng": "en",
    "layout": {
      "type": "cluster",
      "nodeSize": 22,
      "limitNodePositioning": true,
      "limitTextPositioning": true,
      "gridHeight": 55,
      "gridWidth": 200,
      "edgeSlantWidthMax": 0
    },
    "fontFamily": "sans-serif",
    "fontSize": "12px",
    "fontWeight": "normal",
    "fontStyle": "normal",
    "node": {
      "strokeWidth": "2px",
      "optimal": {
        "stroke": "#006f00",
        "strokeWidth": "2px"
      },
      "label": {
        "fontSize": "1em",
        "color": "black"
      },
      "payoff": {
        "fontSize": "1em",
        "color": "black",
        "negativeColor": "#b60000"
      },
      "decision": {
        "fill": "#ff7777",
        "stroke": "#660000",
        "selected": {
          "fill": "#aa3333"
        }
      },
      "chance": {
        "fill": "#ffff44",
        "stroke": "#666600",
        "selected": {
          "fill": "#aaaa00"
        }
      },
      "terminal": {
        "fill": "#44ff44",
        "stroke": "black",
        "selected": {
          "fill": "#00aa00"
        },
        "payoff": {
          "fontSize": "1em",
          "color": "black",
          "negativeColor": "#b60000"
        }
      }
    },
    "edge": {
      "stroke": "#424242",
      "strokeWidth": "1.5",
      "optimal": {
        "stroke": "#006f00",
        "strokeWidth": "2.4"
      },
      "selected": {
        "stroke": "#045ad1",
        "strokeWidth": "3.5"
      },
      "label": {
        "fontSize": "1em",
        "color": "back"
      },
      "payoff": {
        "fontSize": "1em",
        "color": "black",
        "negativeColor": "#b60000"
      }
    },
    "probability": {
      "fontSize": "1em",
      "color": "#0000d7"
    },
    "title": {
      "fontSize": "16px",
      "fontWeight": "bold",
      "fontStyle": "normal",
      "color": "#000000",
      "margin": {
        "top": 15,
        "bottom": 10
      }
    },
    "description": {
      "show": true,
      "fontSize": "12px",
      "fontWeight": "bold",
      "fontStyle": "normal",
      "color": "#000000",
      "margin": {
        "top": 5,
        "bottom": 10
      }
    },
    "readOnly": false,
    "disableAnimations": false,
    "forceFullEdgeRedraw": false,
    "hideLabels": false,
    "hidePayoffs": true,
    "hideExpectedValues": true,
    "hideProbabilities": true,
    "hideCharts": false,
    "hideTextboxes": false,
    "hideMilestones": false,
    "raw": false,
    "payoffNames": [
      null,
      null
    ],
    "maxPayoffsToDisplay": 1,
    "milestoneNames": [
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    ]
  },
  "data": {
    "code": "A=10\nB=15\n",
    "expressionScope": {
      "A": 10,
      "B": 15
    },
    "trees": [
      {
        "computed": {
          "expected-value-maximization": {
            "childrenPayoff": [
              "25"
            ],
            "payoff": [
              "25"
            ],
            "optimal": true
          },
          "expected-value-minimization": {
            "childrenPayoff": [
              "25"
            ],
            "payoff": [
              "25"
            ],
            "optimal": true
          },
          "maxi-min": {
            "childrenPayoff": [
              "15"
            ],
            "payoff": [
              "15"
            ],
            "optimal": true
          },
          "maxi-max": {
            "childrenPayoff": [
              "35"
            ],
            "payoff": [
              "35"
            ],
            "optimal": true
          },
          "mini-min": {
            "childrenPayoff": [
              "15"
            ],
            "payoff": [
              "15"
            ],
            "optimal": true
          },
          "mini-max": {
            "childrenPayoff": [
              "35"
            ],
            "payoff": [
              "35"
            ],
            "optimal": true
          },
          "min-max": {
            "childrenPayoff": [
              "25",
              "0"
            ],
            "combinedPayoff": "-25",
            "payoff": [
              "25",
              "0"
            ],
            "optimal": true
          },
          "max-min": {
            "childrenPayoff": [
              "25",
              "0"
            ],
            "combinedPayoff": "25",
            "payoff": [
              "25",
              "0"
            ],
            "optimal": true
          },
          "min-min": {
            "childrenPayoff": [
              "25",
              "0"
            ],
            "combinedPayoff": "-25",
            "payoff": [
              "25",
              "0"
            ],
            "optimal": true
          },
          "max-max": {
            "childrenPayoff": [
              "25",
              "0"
            ],
            "combinedPayoff": "25",
            "payoff": [
              "25",
              "0"
            ],
            "optimal": true
          }
        },
        "childEdges": [
          {
            "computed": {
              "payoff": [
                "0",
                "0"
              ],
              "expected-value-maximization": {
                "probability": 1,
                "optimal": true
              },
              "expected-value-minimization": {
                "probability": 1,
                "optimal": true
              },
              "maxi-min": {
                "probability": 1,
                "optimal": true
              },
              "maxi-max": {
                "probability": 1,
                "optimal": true
              },
              "mini-min": {
                "probability": 1,
                "optimal": true
              },
              "mini-max": {
                "probability": 1,
                "optimal": true
              },
              "min-max": {
                "probability": 1,
                "optimal": true
              },
              "max-min": {
                "probability": 1,
                "optimal": true
              },
              "min-min": {
                "probability": 1,
                "optimal": true
              },
              "max-max": {
                "probability": 1,
                "optimal": true
              }
            },
            "name": "",
            "payoff": [
              0,
              0
            ],
            "childNode": {
              "computed": {
                "expected-value-maximization": {
                  "childrenPayoff": [
                    "25"
                  ],
                  "payoff": [
                    "25"
                  ],
                  "optimal": true
                },
                "expected-value-minimization": {
                  "childrenPayoff": [
                    "25"
                  ],
                  "payoff": [
                    "25"
                  ],
                  "optimal": true
                },
                "maxi-min": {
                  "childrenPayoff": [
                    "15"
                  ],
                  "payoff": [
                    "15"
                  ],
                  "optimal": true
                },
                "maxi-max": {
                  "childrenPayoff": [
                    "35"
                  ],
                  "payoff": [
                    "35"
                  ],
                  "optimal": true
                },
                "mini-min": {
                  "childrenPayoff": [
                    "15"
                  ],
                  "payoff": [
                    "15"
                  ],
                  "optimal": true
                },
                "mini-max": {
                  "childrenPayoff": [
                    "35"
                  ],
                  "payoff": [
                    "35"
                  ],
                  "optimal": true
                },
                "min-max": {
                  "childrenPayoff": [
                    "25",
                    "0"
                  ],
                  "combinedPayoff": "-25",
                  "payoff": [
                    "25",
                    "0"
                  ],
                  "optimal": true
                },
                "max-min": {
                  "childrenPayoff": [
                    "25",
                    "0"
                  ],
                  "combinedPayoff": "25",
                  "payoff": [
                    "25",
                    "0"
                  ],
                  "optimal": true
                },
                "min-min": {
                  "childrenPayoff": [
                    "25",
                    "0"
                  ],
                  "combinedPayoff": "-25",
                  "payoff": [
                    "25",
                    "0"
                  ],
                  "optimal": true
                },
                "max-max": {
                  "childrenPayoff": [
                    "25",
                    "0"
                  ],
                  "combinedPayoff": "25",
                  "payoff": [
                    "25",
                    "0"
                  ],
                  "optimal": true
                }
              },
              "childEdges": [
                {
                  "computed": {
                    "payoff": [
                      "15",
                      "0"
                    ],
                    "probability": "3/10",
                    "expected-value-maximization": {
                      "probability": "3/10",
                      "optimal": true
                    },
                    "expected-value-minimization": {
                      "probability": "3/10",
                      "optimal": true
                    },
                    "maxi-min": {
                      "probability": 0,
                      "optimal": false
                    },
                    "maxi-max": {
                      "probability": 1,
                      "optimal": true
                    },
                    "mini-min": {
                      "probability": 0,
                      "optimal": false
                    },
                    "mini-max": {
                      "probability": 1,
                      "optimal": true
                    },
                    "min-max": {
                      "probability": "3/10",
                      "optimal": true
                    },
                    "max-min": {
                      "probability": "3/10",
                      "optimal": true
                    },
                    "min-min": {
                      "probability": "3/10",
                      "optimal": true
                    },
                    "max-max": {
                      "probability": "3/10",
                      "optimal": true
                    }
                  },
                  "name": "",
                  "probability": ".3",
                  "payoff": [
                    "15",
                    0
                  ],
                  "childNode": {
                    "computed": {
                      "expected-value-maximization": {
                        "childrenPayoff": [
                          "15"
                        ],
                        "payoff": [
                          "30"
                        ],
                        "optimal": true
                      },
                      "expected-value-minimization": {
                        "childrenPayoff": [
                          "15"
                        ],
                        "payoff": [
                          "30"
                        ],
                        "optimal": true
                      },
                      "maxi-min": {
                        "childrenPayoff": [
                          "10"
                        ],
                        "payoff": [
                          "25"
                        ]
                      },
                      "maxi-max": {
                        "childrenPayoff": [
                          "20"
                        ],
                        "payoff": [
                          "35"
                        ],
                        "optimal": true
                      },
                      "mini-min": {
                        "childrenPayoff": [
                          "10"
                        ],
                        "payoff": [
                          "25"
                        ]
                      },
                      "mini-max": {
                        "childrenPayoff": [
                          "20"
                        ],
                        "payoff": [
                          "35"
                        ],
                        "optimal": true
                      },
                      "min-max": {
                        "childrenPayoff": [
                          "15",
                          "0"
                        ],
                        "combinedPayoff": "-30",
                        "payoff": [
                          "30",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-min": {
                        "childrenPayoff": [
                          "15",
                          "0"
                        ],
                        "combinedPayoff": "30",
                        "payoff": [
                          "30",
                          "0"
                        ],
                        "optimal": true
                      },
                      "min-min": {
                        "childrenPayoff": [
                          "15",
                          "0"
                        ],
                        "combinedPayoff": "-30",
                        "payoff": [
                          "30",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-max": {
                        "childrenPayoff": [
                          "15",
                          "0"
                        ],
                        "combinedPayoff": "30",
                        "payoff": [
                          "30",
                          "0"
                        ],
                        "optimal": true
                      }
                    },
                    "childEdges": [
                      {
                        "computed": {
                          "payoff": [
                            "20",
                            "0"
                          ],
                          "probability": "3/10",
                          "expected-value-maximization": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "expected-value-minimization": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "maxi-min": {
                            "probability": 0
                          },
                          "maxi-max": {
                            "probability": 1,
                            "optimal": true
                          },
                          "mini-min": {
                            "probability": 0
                          },
                          "mini-max": {
                            "probability": 1,
                            "optimal": true
                          },
                          "min-max": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "max-min": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "min-min": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "max-max": {
                            "probability": "3/10",
                            "optimal": true
                          }
                        },
                        "name": "",
                        "probability": ".3",
                        "payoff": [
                          "20",
                          0
                        ],
                        "childNode": {
                          "computed": {
                            "expected-value-maximization": {
                              "aggregatedPayoff": [
                                "35"
                              ],
                              "probabilityToEnter": "9/100",
                              "payoff": [
                                "20"
                              ],
                              "optimal": true
                            },
                            "expected-value-minimization": {
                              "aggregatedPayoff": [
                                "35"
                              ],
                              "probabilityToEnter": "9/100",
                              "payoff": [
                                "20"
                              ],
                              "optimal": true
                            },
                            "maxi-min": {
                              "aggregatedPayoff": [
                                "35"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "20"
                              ]
                            },
                            "maxi-max": {
                              "aggregatedPayoff": [
                                "35"
                              ],
                              "probabilityToEnter": "1",
                              "payoff": [
                                "20"
                              ],
                              "optimal": true
                            },
                            "mini-min": {
                              "aggregatedPayoff": [
                                "35"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "20"
                              ]
                            },
                            "mini-max": {
                              "aggregatedPayoff": [
                                "35"
                              ],
                              "probabilityToEnter": "1",
                              "payoff": [
                                "20"
                              ],
                              "optimal": true
                            },
                            "min-max": {
                              "aggregatedPayoff": [
                                "35",
                                "0"
                              ],
                              "probabilityToEnter": "9/100",
                              "combinedPayoff": "-20",
                              "payoff": [
                                "20",
                                "0"
                              ],
                              "optimal": true
                            },
                            "max-min": {
                              "aggregatedPayoff": [
                                "35",
                                "0"
                              ],
                              "probabilityToEnter": "9/100",
                              "combinedPayoff": "20",
                              "payoff": [
                                "20",
                                "0"
                              ],
                              "optimal": true
                            },
                            "min-min": {
                              "aggregatedPayoff": [
                                "35",
                                "0"
                              ],
                              "probabilityToEnter": "9/100",
                              "combinedPayoff": "-20",
                              "payoff": [
                                "20",
                                "0"
                              ],
                              "optimal": true
                            },
                            "max-max": {
                              "aggregatedPayoff": [
                                "35",
                                "0"
                              ],
                              "probabilityToEnter": "9/100",
                              "combinedPayoff": "20",
                              "payoff": [
                                "20",
                                "0"
                              ],
                              "optimal": true
                            }
                          },
                          "childEdges": [],
                          "name": "",
                          "code": "",
                          "expressionScope": {
                            "A": 10,
                            "B": 15
                          },
                          "folded": false,
                          "location": {
                            "x": 611,
                            "y": 11
                          },
                          "type": "terminal",
                          "expectedValue": 35,
                          "p10": 35,
                          "p90": 35,
                          "charts": [
                            {
                              "nodeId": "2bf77e8a-cc0f-ff5a-5ed0-34143bd04925",
                              "x": 56,
                              "y": -39.0000057220459,
                              "type": "bar",
                              "active": false
                            },
                            {
                              "nodeId": "2bf77e8a-cc0f-ff5a-5ed0-34143bd04925",
                              "x": 50,
                              "y": -100,
                              "type": "distribution",
                              "active": false
                            },
                            {
                              "nodeId": "2bf77e8a-cc0f-ff5a-5ed0-34143bd04925",
                              "x": 60,
                              "y": -90,
                              "type": "waterfall",
                              "active": false
                            }
                          ]
                        },
                        "expectedValue": 20,
                        "p10": 20,
                        "p90": 20,
                        "optimal": true
                      },
                      {
                        "computed": {
                          "payoff": [
                            "15",
                            "0"
                          ],
                          "probability": "2/5",
                          "expected-value-maximization": {
                            "probability": "2/5",
                            "optimal": true
                          },
                          "expected-value-minimization": {
                            "probability": "2/5",
                            "optimal": true
                          },
                          "maxi-min": {
                            "probability": 0
                          },
                          "maxi-max": {
                            "probability": 0,
                            "optimal": false
                          },
                          "mini-min": {
                            "probability": 0
                          },
                          "mini-max": {
                            "probability": 0,
                            "optimal": false
                          },
                          "min-max": {
                            "probability": "2/5",
                            "optimal": true
                          },
                          "max-min": {
                            "probability": "2/5",
                            "optimal": true
                          },
                          "min-min": {
                            "probability": "2/5",
                            "optimal": true
                          },
                          "max-max": {
                            "probability": "2/5",
                            "optimal": true
                          }
                        },
                        "name": "",
                        "probability": ".4#",
                        "payoff": [
                          "15",
                          0
                        ],
                        "childNode": {
                          "computed": {
                            "expected-value-maximization": {
                              "aggregatedPayoff": [
                                "30"
                              ],
                              "probabilityToEnter": "3/25",
                              "payoff": [
                                "15"
                              ],
                              "optimal": true
                            },
                            "expected-value-minimization": {
                              "aggregatedPayoff": [
                                "30"
                              ],
                              "probabilityToEnter": "3/25",
                              "payoff": [
                                "15"
                              ],
                              "optimal": true
                            },
                            "maxi-min": {
                              "aggregatedPayoff": [
                                "30"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "15"
                              ]
                            },
                            "maxi-max": {
                              "aggregatedPayoff": [
                                "30"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "15"
                              ]
                            },
                            "mini-min": {
                              "aggregatedPayoff": [
                                "30"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "15"
                              ]
                            },
                            "mini-max": {
                              "aggregatedPayoff": [
                                "30"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "15"
                              ]
                            },
                            "min-max": {
                              "aggregatedPayoff": [
                                "30",
                                "0"
                              ],
                              "probabilityToEnter": "3/25",
                              "combinedPayoff": "-15",
                              "payoff": [
                                "15",
                                "0"
                              ],
                              "optimal": true
                            },
                            "max-min": {
                              "aggregatedPayoff": [
                                "30",
                                "0"
                              ],
                              "probabilityToEnter": "3/25",
                              "combinedPayoff": "15",
                              "payoff": [
                                "15",
                                "0"
                              ],
                              "optimal": true
                            },
                            "min-min": {
                              "aggregatedPayoff": [
                                "30",
                                "0"
                              ],
                              "probabilityToEnter": "3/25",
                              "combinedPayoff": "-15",
                              "payoff": [
                                "15",
                                "0"
                              ],
                              "optimal": true
                            },
                            "max-max": {
                              "aggregatedPayoff": [
                                "30",
                                "0"
                              ],
                              "probabilityToEnter": "3/25",
                              "combinedPayoff": "15",
                              "payoff": [
                                "15",
                                "0"
                              ],
                              "optimal": true
                            }
                          },
                          "childEdges": [],
                          "name": "",
                          "code": "",
                          "expressionScope": {
                            "A": 10,
                            "B": 15
                          },
                          "folded": false,
                          "location": {
                            "x": 611,
                            "y": 66
                          },
                          "type": "terminal",
                          "expectedValue": 30,
                          "p10": 30,
                          "p90": 30
                        },
                        "expectedValue": 15,
                        "p10": 15,
                        "p90": 15,
                        "optimal": true
                      },
                      {
                        "computed": {
                          "payoff": [
                            "10",
                            "0"
                          ],
                          "probability": "3/10",
                          "expected-value-maximization": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "expected-value-minimization": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "maxi-min": {
                            "probability": 1
                          },
                          "maxi-max": {
                            "probability": 0,
                            "optimal": false
                          },
                          "mini-min": {
                            "probability": 1
                          },
                          "mini-max": {
                            "probability": 0,
                            "optimal": false
                          },
                          "min-max": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "max-min": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "min-min": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "max-max": {
                            "probability": "3/10",
                            "optimal": true
                          }
                        },
                        "name": "",
                        "probability": "#",
                        "payoff": [
                          "10",
                          0
                        ],
                        "childNode": {
                          "computed": {
                            "expected-value-maximization": {
                              "aggregatedPayoff": [
                                "25"
                              ],
                              "probabilityToEnter": "9/100",
                              "payoff": [
                                "10"
                              ],
                              "optimal": true
                            },
                            "expected-value-minimization": {
                              "aggregatedPayoff": [
                                "25"
                              ],
                              "probabilityToEnter": "9/100",
                              "payoff": [
                                "10"
                              ],
                              "optimal": true
                            },
                            "maxi-min": {
                              "aggregatedPayoff": [
                                "25"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "10"
                              ]
                            },
                            "maxi-max": {
                              "aggregatedPayoff": [
                                "25"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "10"
                              ]
                            },
                            "mini-min": {
                              "aggregatedPayoff": [
                                "25"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "10"
                              ]
                            },
                            "mini-max": {
                              "aggregatedPayoff": [
                                "25"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "10"
                              ]
                            },
                            "min-max": {
                              "aggregatedPayoff": [
                                "25",
                                "0"
                              ],
                              "probabilityToEnter": "9/100",
                              "combinedPayoff": "-10",
                              "payoff": [
                                "10",
                                "0"
                              ],
                              "optimal": true
                            },
                            "max-min": {
                              "aggregatedPayoff": [
                                "25",
                                "0"
                              ],
                              "probabilityToEnter": "9/100",
                              "combinedPayoff": "10",
                              "payoff": [
                                "10",
                                "0"
                              ],
                              "optimal": true
                            },
                            "min-min": {
                              "aggregatedPayoff": [
                                "25",
                                "0"
                              ],
                              "probabilityToEnter": "9/100",
                              "combinedPayoff": "-10",
                              "payoff": [
                                "10",
                                "0"
                              ],
                              "optimal": true
                            },
                            "max-max": {
                              "aggregatedPayoff": [
                                "25",
                                "0"
                              ],
                              "probabilityToEnter": "9/100",
                              "combinedPayoff": "10",
                              "payoff": [
                                "10",
                                "0"
                              ],
                              "optimal": true
                            }
                          },
                          "childEdges": [],
                          "name": "",
                          "code": "",
                          "expressionScope": {
                            "A": 10,
                            "B": 15
                          },
                          "folded": false,
                          "location": {
                            "x": 611,
                            "y": 120.99999999999999
                          },
                          "type": "terminal",
                          "expectedValue": 25,
                          "p10": 25,
                          "p90": 25
                        },
                        "expectedValue": 10,
                        "p10": 10,
                        "p90": 10,
                        "optimal": true
                      }
                    ],
                    "name": "Risk B",
                    "code": "",
                    "expressionScope": {
                      "A": 10,
                      "B": 15
                    },
                    "folded": false,
                    "location": {
                      "x": 411,
                      "y": 66
                    },
                    "type": "chance",
                    "expectedValue": 15,
                    "p10": 10,
                    "p90": 20
                  },
                  "expectedValue": 15,
                  "p10": 15,
                  "p90": 15,
                  "optimal": true
                },
                {
                  "computed": {
                    "payoff": [
                      "10",
                      "0"
                    ],
                    "probability": "2/5",
                    "expected-value-maximization": {
                      "probability": "2/5",
                      "optimal": true
                    },
                    "expected-value-minimization": {
                      "probability": "2/5",
                      "optimal": true
                    },
                    "maxi-min": {
                      "probability": 0,
                      "optimal": false
                    },
                    "maxi-max": {
                      "probability": 0,
                      "optimal": false
                    },
                    "mini-min": {
                      "probability": 0,
                      "optimal": false
                    },
                    "mini-max": {
                      "probability": 0,
                      "optimal": false
                    },
                    "min-max": {
                      "probability": "2/5",
                      "optimal": true
                    },
                    "max-min": {
                      "probability": "2/5",
                      "optimal": true
                    },
                    "min-min": {
                      "probability": "2/5",
                      "optimal": true
                    },
                    "max-max": {
                      "probability": "2/5",
                      "optimal": true
                    }
                  },
                  "name": "",
                  "probability": ".4",
                  "payoff": [
                    "10",
                    0
                  ],
                  "childNode": {
                    "computed": {
                      "expected-value-maximization": {
                        "childrenPayoff": [
                          "15"
                        ],
                        "payoff": [
                          "25"
                        ],
                        "optimal": true
                      },
                      "expected-value-minimization": {
                        "childrenPayoff": [
                          "15"
                        ],
                        "payoff": [
                          "25"
                        ],
                        "optimal": true
                      },
                      "maxi-min": {
                        "childrenPayoff": [
                          "10"
                        ],
                        "payoff": [
                          "20"
                        ]
                      },
                      "maxi-max": {
                        "childrenPayoff": [
                          "20"
                        ],
                        "payoff": [
                          "30"
                        ]
                      },
                      "mini-min": {
                        "childrenPayoff": [
                          "10"
                        ],
                        "payoff": [
                          "20"
                        ]
                      },
                      "mini-max": {
                        "childrenPayoff": [
                          "20"
                        ],
                        "payoff": [
                          "30"
                        ]
                      },
                      "min-max": {
                        "childrenPayoff": [
                          "15",
                          "0"
                        ],
                        "combinedPayoff": "-25",
                        "payoff": [
                          "25",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-min": {
                        "childrenPayoff": [
                          "15",
                          "0"
                        ],
                        "combinedPayoff": "25",
                        "payoff": [
                          "25",
                          "0"
                        ],
                        "optimal": true
                      },
                      "min-min": {
                        "childrenPayoff": [
                          "15",
                          "0"
                        ],
                        "combinedPayoff": "-25",
                        "payoff": [
                          "25",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-max": {
                        "childrenPayoff": [
                          "15",
                          "0"
                        ],
                        "combinedPayoff": "25",
                        "payoff": [
                          "25",
                          "0"
                        ],
                        "optimal": true
                      }
                    },
                    "childEdges": [
                      {
                        "computed": {
                          "payoff": [
                            "20",
                            "0"
                          ],
                          "probability": "3/10",
                          "expected-value-maximization": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "expected-value-minimization": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "maxi-min": {
                            "probability": 0
                          },
                          "maxi-max": {
                            "probability": 1
                          },
                          "mini-min": {
                            "probability": 0
                          },
                          "mini-max": {
                            "probability": 1
                          },
                          "min-max": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "max-min": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "min-min": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "max-max": {
                            "probability": "3/10",
                            "optimal": true
                          }
                        },
                        "name": "",
                        "probability": ".3",
                        "payoff": [
                          "20",
                          0
                        ],
                        "childNode": {
                          "computed": {
                            "expected-value-maximization": {
                              "aggregatedPayoff": [
                                "30"
                              ],
                              "probabilityToEnter": "3/25",
                              "payoff": [
                                "20"
                              ],
                              "optimal": true
                            },
                            "expected-value-minimization": {
                              "aggregatedPayoff": [
                                "30"
                              ],
                              "probabilityToEnter": "3/25",
                              "payoff": [
                                "20"
                              ],
                              "optimal": true
                            },
                            "maxi-min": {
                              "aggregatedPayoff": [
                                "30"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "20"
                              ]
                            },
                            "maxi-max": {
                              "aggregatedPayoff": [
                                "30"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "20"
                              ]
                            },
                            "mini-min": {
                              "aggregatedPayoff": [
                                "30"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "20"
                              ]
                            },
                            "mini-max": {
                              "aggregatedPayoff": [
                                "30"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "20"
                              ]
                            },
                            "min-max": {
                              "aggregatedPayoff": [
                                "30",
                                "0"
                              ],
                              "probabilityToEnter": "3/25",
                              "combinedPayoff": "-20",
                              "payoff": [
                                "20",
                                "0"
                              ],
                              "optimal": true
                            },
                            "max-min": {
                              "aggregatedPayoff": [
                                "30",
                                "0"
                              ],
                              "probabilityToEnter": "3/25",
                              "combinedPayoff": "20",
                              "payoff": [
                                "20",
                                "0"
                              ],
                              "optimal": true
                            },
                            "min-min": {
                              "aggregatedPayoff": [
                                "30",
                                "0"
                              ],
                              "probabilityToEnter": "3/25",
                              "combinedPayoff": "-20",
                              "payoff": [
                                "20",
                                "0"
                              ],
                              "optimal": true
                            },
                            "max-max": {
                              "aggregatedPayoff": [
                                "30",
                                "0"
                              ],
                              "probabilityToEnter": "3/25",
                              "combinedPayoff": "20",
                              "payoff": [
                                "20",
                                "0"
                              ],
                              "optimal": true
                            }
                          },
                          "childEdges": [],
                          "name": "",
                          "code": "",
                          "expressionScope": {
                            "A": 10,
                            "B": 15
                          },
                          "folded": false,
                          "location": {
                            "x": 611,
                            "y": 187
                          },
                          "type": "terminal",
                          "expectedValue": 30,
                          "p10": 30,
                          "p90": 30,
                          "charts": [
                            {
                              "nodeId": "1ae34acd-d731-ad23-fb04-bc64a676fbc9",
                              "x": 56,
                              "y": -39,
                              "type": "bar",
                              "active": false
                            }
                          ]
                        },
                        "expectedValue": 20,
                        "p10": 20,
                        "p90": 20,
                        "optimal": true
                      },
                      {
                        "computed": {
                          "payoff": [
                            "15",
                            "0"
                          ],
                          "probability": "2/5",
                          "expected-value-maximization": {
                            "probability": "2/5",
                            "optimal": true
                          },
                          "expected-value-minimization": {
                            "probability": "2/5",
                            "optimal": true
                          },
                          "maxi-min": {
                            "probability": 0
                          },
                          "maxi-max": {
                            "probability": 0
                          },
                          "mini-min": {
                            "probability": 0
                          },
                          "mini-max": {
                            "probability": 0
                          },
                          "min-max": {
                            "probability": "2/5",
                            "optimal": true
                          },
                          "max-min": {
                            "probability": "2/5",
                            "optimal": true
                          },
                          "min-min": {
                            "probability": "2/5",
                            "optimal": true
                          },
                          "max-max": {
                            "probability": "2/5",
                            "optimal": true
                          }
                        },
                        "name": "",
                        "probability": ".4",
                        "payoff": [
                          "15",
                          0
                        ],
                        "childNode": {
                          "computed": {
                            "expected-value-maximization": {
                              "aggregatedPayoff": [
                                "25"
                              ],
                              "probabilityToEnter": "4/25",
                              "payoff": [
                                "15"
                              ],
                              "optimal": true
                            },
                            "expected-value-minimization": {
                              "aggregatedPayoff": [
                                "25"
                              ],
                              "probabilityToEnter": "4/25",
                              "payoff": [
                                "15"
                              ],
                              "optimal": true
                            },
                            "maxi-min": {
                              "aggregatedPayoff": [
                                "25"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "15"
                              ]
                            },
                            "maxi-max": {
                              "aggregatedPayoff": [
                                "25"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "15"
                              ]
                            },
                            "mini-min": {
                              "aggregatedPayoff": [
                                "25"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "15"
                              ]
                            },
                            "mini-max": {
                              "aggregatedPayoff": [
                                "25"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "15"
                              ]
                            },
                            "min-max": {
                              "aggregatedPayoff": [
                                "25",
                                "0"
                              ],
                              "probabilityToEnter": "4/25",
                              "combinedPayoff": "-15",
                              "payoff": [
                                "15",
                                "0"
                              ],
                              "optimal": true
                            },
                            "max-min": {
                              "aggregatedPayoff": [
                                "25",
                                "0"
                              ],
                              "probabilityToEnter": "4/25",
                              "combinedPayoff": "15",
                              "payoff": [
                                "15",
                                "0"
                              ],
                              "optimal": true
                            },
                            "min-min": {
                              "aggregatedPayoff": [
                                "25",
                                "0"
                              ],
                              "probabilityToEnter": "4/25",
                              "combinedPayoff": "-15",
                              "payoff": [
                                "15",
                                "0"
                              ],
                              "optimal": true
                            },
                            "max-max": {
                              "aggregatedPayoff": [
                                "25",
                                "0"
                              ],
                              "probabilityToEnter": "4/25",
                              "combinedPayoff": "15",
                              "payoff": [
                                "15",
                                "0"
                              ],
                              "optimal": true
                            }
                          },
                          "childEdges": [],
                          "name": "",
                          "code": "",
                          "expressionScope": {
                            "A": 10,
                            "B": 15
                          },
                          "folded": false,
                          "location": {
                            "x": 611,
                            "y": 242
                          },
                          "type": "terminal",
                          "expectedValue": 25,
                          "p10": 25,
                          "p90": 25
                        },
                        "expectedValue": 15,
                        "p10": 15,
                        "p90": 15,
                        "optimal": true
                      },
                      {
                        "computed": {
                          "payoff": [
                            "10",
                            "0"
                          ],
                          "probability": "3/10",
                          "expected-value-maximization": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "expected-value-minimization": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "maxi-min": {
                            "probability": 1
                          },
                          "maxi-max": {
                            "probability": 0
                          },
                          "mini-min": {
                            "probability": 1
                          },
                          "mini-max": {
                            "probability": 0
                          },
                          "min-max": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "max-min": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "min-min": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "max-max": {
                            "probability": "3/10",
                            "optimal": true
                          }
                        },
                        "name": "",
                        "probability": "#",
                        "payoff": [
                          "10",
                          0
                        ],
                        "childNode": {
                          "computed": {
                            "expected-value-maximization": {
                              "aggregatedPayoff": [
                                "20"
                              ],
                              "probabilityToEnter": "3/25",
                              "payoff": [
                                "10"
                              ],
                              "optimal": true
                            },
                            "expected-value-minimization": {
                              "aggregatedPayoff": [
                                "20"
                              ],
                              "probabilityToEnter": "3/25",
                              "payoff": [
                                "10"
                              ],
                              "optimal": true
                            },
                            "maxi-min": {
                              "aggregatedPayoff": [
                                "20"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "10"
                              ]
                            },
                            "maxi-max": {
                              "aggregatedPayoff": [
                                "20"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "10"
                              ]
                            },
                            "mini-min": {
                              "aggregatedPayoff": [
                                "20"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "10"
                              ]
                            },
                            "mini-max": {
                              "aggregatedPayoff": [
                                "20"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "10"
                              ]
                            },
                            "min-max": {
                              "aggregatedPayoff": [
                                "20",
                                "0"
                              ],
                              "probabilityToEnter": "3/25",
                              "combinedPayoff": "-10",
                              "payoff": [
                                "10",
                                "0"
                              ],
                              "optimal": true
                            },
                            "max-min": {
                              "aggregatedPayoff": [
                                "20",
                                "0"
                              ],
                              "probabilityToEnter": "3/25",
                              "combinedPayoff": "10",
                              "payoff": [
                                "10",
                                "0"
                              ],
                              "optimal": true
                            },
                            "min-min": {
                              "aggregatedPayoff": [
                                "20",
                                "0"
                              ],
                              "probabilityToEnter": "3/25",
                              "combinedPayoff": "-10",
                              "payoff": [
                                "10",
                                "0"
                              ],
                              "optimal": true
                            },
                            "max-max": {
                              "aggregatedPayoff": [
                                "20",
                                "0"
                              ],
                              "probabilityToEnter": "3/25",
                              "combinedPayoff": "10",
                              "payoff": [
                                "10",
                                "0"
                              ],
                              "optimal": true
                            }
                          },
                          "childEdges": [],
                          "name": "",
                          "code": "",
                          "expressionScope": {
                            "A": 10,
                            "B": 15
                          },
                          "folded": false,
                          "location": {
                            "x": 611,
                            "y": 297
                          },
                          "type": "terminal",
                          "expectedValue": 20,
                          "p10": 20,
                          "p90": 20
                        },
                        "expectedValue": 10,
                        "p10": 10,
                        "p90": 10,
                        "optimal": true
                      }
                    ],
                    "name": "Risk B",
                    "code": "",
                    "expressionScope": {
                      "A": 10,
                      "B": 15
                    },
                    "folded": false,
                    "location": {
                      "x": 411,
                      "y": 242
                    },
                    "type": "chance",
                    "expectedValue": 15,
                    "p10": 10,
                    "p90": 20
                  },
                  "expectedValue": 10,
                  "p10": 10,
                  "p90": 10,
                  "optimal": true
                },
                {
                  "computed": {
                    "payoff": [
                      "5",
                      "0"
                    ],
                    "probability": "3/10",
                    "expected-value-maximization": {
                      "probability": "3/10",
                      "optimal": true
                    },
                    "expected-value-minimization": {
                      "probability": "3/10",
                      "optimal": true
                    },
                    "maxi-min": {
                      "probability": 1,
                      "optimal": true
                    },
                    "maxi-max": {
                      "probability": 0,
                      "optimal": false
                    },
                    "mini-min": {
                      "probability": 1,
                      "optimal": true
                    },
                    "mini-max": {
                      "probability": 0,
                      "optimal": false
                    },
                    "min-max": {
                      "probability": "3/10",
                      "optimal": true
                    },
                    "max-min": {
                      "probability": "3/10",
                      "optimal": true
                    },
                    "min-min": {
                      "probability": "3/10",
                      "optimal": true
                    },
                    "max-max": {
                      "probability": "3/10",
                      "optimal": true
                    }
                  },
                  "name": "",
                  "probability": ".3",
                  "payoff": [
                    "5",
                    0
                  ],
                  "childNode": {
                    "computed": {
                      "expected-value-maximization": {
                        "childrenPayoff": [
                          "15"
                        ],
                        "payoff": [
                          "20"
                        ],
                        "optimal": true
                      },
                      "expected-value-minimization": {
                        "childrenPayoff": [
                          "15"
                        ],
                        "payoff": [
                          "20"
                        ],
                        "optimal": true
                      },
                      "maxi-min": {
                        "childrenPayoff": [
                          "10"
                        ],
                        "payoff": [
                          "15"
                        ],
                        "optimal": true
                      },
                      "maxi-max": {
                        "childrenPayoff": [
                          "20"
                        ],
                        "payoff": [
                          "25"
                        ]
                      },
                      "mini-min": {
                        "childrenPayoff": [
                          "10"
                        ],
                        "payoff": [
                          "15"
                        ],
                        "optimal": true
                      },
                      "mini-max": {
                        "childrenPayoff": [
                          "20"
                        ],
                        "payoff": [
                          "25"
                        ]
                      },
                      "min-max": {
                        "childrenPayoff": [
                          "15",
                          "0"
                        ],
                        "combinedPayoff": "-20",
                        "payoff": [
                          "20",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-min": {
                        "childrenPayoff": [
                          "15",
                          "0"
                        ],
                        "combinedPayoff": "20",
                        "payoff": [
                          "20",
                          "0"
                        ],
                        "optimal": true
                      },
                      "min-min": {
                        "childrenPayoff": [
                          "15",
                          "0"
                        ],
                        "combinedPayoff": "-20",
                        "payoff": [
                          "20",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-max": {
                        "childrenPayoff": [
                          "15",
                          "0"
                        ],
                        "combinedPayoff": "20",
                        "payoff": [
                          "20",
                          "0"
                        ],
                        "optimal": true
                      }
                    },
                    "childEdges": [
                      {
                        "computed": {
                          "payoff": [
                            "20",
                            "0"
                          ],
                          "probability": "3/10",
                          "expected-value-maximization": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "expected-value-minimization": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "maxi-min": {
                            "probability": 0,
                            "optimal": false
                          },
                          "maxi-max": {
                            "probability": 1
                          },
                          "mini-min": {
                            "probability": 0,
                            "optimal": false
                          },
                          "mini-max": {
                            "probability": 1
                          },
                          "min-max": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "max-min": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "min-min": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "max-max": {
                            "probability": "3/10",
                            "optimal": true
                          }
                        },
                        "name": "",
                        "probability": ".3",
                        "payoff": [
                          "20",
                          0
                        ],
                        "childNode": {
                          "computed": {
                            "expected-value-maximization": {
                              "aggregatedPayoff": [
                                "25"
                              ],
                              "probabilityToEnter": "9/100",
                              "payoff": [
                                "20"
                              ],
                              "optimal": true
                            },
                            "expected-value-minimization": {
                              "aggregatedPayoff": [
                                "25"
                              ],
                              "probabilityToEnter": "9/100",
                              "payoff": [
                                "20"
                              ],
                              "optimal": true
                            },
                            "maxi-min": {
                              "aggregatedPayoff": [
                                "25"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "20"
                              ]
                            },
                            "maxi-max": {
                              "aggregatedPayoff": [
                                "25"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "20"
                              ]
                            },
                            "mini-min": {
                              "aggregatedPayoff": [
                                "25"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "20"
                              ]
                            },
                            "mini-max": {
                              "aggregatedPayoff": [
                                "25"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "20"
                              ]
                            },
                            "min-max": {
                              "aggregatedPayoff": [
                                "25",
                                "0"
                              ],
                              "probabilityToEnter": "9/100",
                              "combinedPayoff": "-20",
                              "payoff": [
                                "20",
                                "0"
                              ],
                              "optimal": true
                            },
                            "max-min": {
                              "aggregatedPayoff": [
                                "25",
                                "0"
                              ],
                              "probabilityToEnter": "9/100",
                              "combinedPayoff": "20",
                              "payoff": [
                                "20",
                                "0"
                              ],
                              "optimal": true
                            },
                            "min-min": {
                              "aggregatedPayoff": [
                                "25",
                                "0"
                              ],
                              "probabilityToEnter": "9/100",
                              "combinedPayoff": "-20",
                              "payoff": [
                                "20",
                                "0"
                              ],
                              "optimal": true
                            },
                            "max-max": {
                              "aggregatedPayoff": [
                                "25",
                                "0"
                              ],
                              "probabilityToEnter": "9/100",
                              "combinedPayoff": "20",
                              "payoff": [
                                "20",
                                "0"
                              ],
                              "optimal": true
                            }
                          },
                          "childEdges": [],
                          "name": "",
                          "code": "",
                          "expressionScope": {
                            "A": 10,
                            "B": 15
                          },
                          "folded": false,
                          "location": {
                            "x": 611,
                            "y": 363
                          },
                          "type": "terminal",
                          "expectedValue": 25,
                          "p10": 25,
                          "p90": 25,
                          "charts": [
                            {
                              "nodeId": "a7883475-216f-21a2-3678-767cbfc1d809",
                              "x": 56.00000762939453,
                              "y": -39,
                              "type": "bar",
                              "active": false
                            }
                          ]
                        },
                        "expectedValue": 20,
                        "p10": 20,
                        "p90": 20,
                        "optimal": true
                      },
                      {
                        "computed": {
                          "payoff": [
                            "15",
                            "0"
                          ],
                          "probability": "2/5",
                          "expected-value-maximization": {
                            "probability": "2/5",
                            "optimal": true
                          },
                          "expected-value-minimization": {
                            "probability": "2/5",
                            "optimal": true
                          },
                          "maxi-min": {
                            "probability": 0,
                            "optimal": false
                          },
                          "maxi-max": {
                            "probability": 0
                          },
                          "mini-min": {
                            "probability": 0,
                            "optimal": false
                          },
                          "mini-max": {
                            "probability": 0
                          },
                          "min-max": {
                            "probability": "2/5",
                            "optimal": true
                          },
                          "max-min": {
                            "probability": "2/5",
                            "optimal": true
                          },
                          "min-min": {
                            "probability": "2/5",
                            "optimal": true
                          },
                          "max-max": {
                            "probability": "2/5",
                            "optimal": true
                          }
                        },
                        "name": "",
                        "probability": ".4",
                        "payoff": [
                          "15",
                          0
                        ],
                        "childNode": {
                          "computed": {
                            "expected-value-maximization": {
                              "aggregatedPayoff": [
                                "20"
                              ],
                              "probabilityToEnter": "3/25",
                              "payoff": [
                                "15"
                              ],
                              "optimal": true
                            },
                            "expected-value-minimization": {
                              "aggregatedPayoff": [
                                "20"
                              ],
                              "probabilityToEnter": "3/25",
                              "payoff": [
                                "15"
                              ],
                              "optimal": true
                            },
                            "maxi-min": {
                              "aggregatedPayoff": [
                                "20"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "15"
                              ]
                            },
                            "maxi-max": {
                              "aggregatedPayoff": [
                                "20"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "15"
                              ]
                            },
                            "mini-min": {
                              "aggregatedPayoff": [
                                "20"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "15"
                              ]
                            },
                            "mini-max": {
                              "aggregatedPayoff": [
                                "20"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "15"
                              ]
                            },
                            "min-max": {
                              "aggregatedPayoff": [
                                "20",
                                "0"
                              ],
                              "probabilityToEnter": "3/25",
                              "combinedPayoff": "-15",
                              "payoff": [
                                "15",
                                "0"
                              ],
                              "optimal": true
                            },
                            "max-min": {
                              "aggregatedPayoff": [
                                "20",
                                "0"
                              ],
                              "probabilityToEnter": "3/25",
                              "combinedPayoff": "15",
                              "payoff": [
                                "15",
                                "0"
                              ],
                              "optimal": true
                            },
                            "min-min": {
                              "aggregatedPayoff": [
                                "20",
                                "0"
                              ],
                              "probabilityToEnter": "3/25",
                              "combinedPayoff": "-15",
                              "payoff": [
                                "15",
                                "0"
                              ],
                              "optimal": true
                            },
                            "max-max": {
                              "aggregatedPayoff": [
                                "20",
                                "0"
                              ],
                              "probabilityToEnter": "3/25",
                              "combinedPayoff": "15",
                              "payoff": [
                                "15",
                                "0"
                              ],
                              "optimal": true
                            }
                          },
                          "childEdges": [],
                          "name": "",
                          "code": "",
                          "expressionScope": {
                            "A": 10,
                            "B": 15
                          },
                          "folded": false,
                          "location": {
                            "x": 611,
                            "y": 418
                          },
                          "type": "terminal",
                          "expectedValue": 20,
                          "p10": 20,
                          "p90": 20
                        },
                        "expectedValue": 15,
                        "p10": 15,
                        "p90": 15,
                        "optimal": true
                      },
                      {
                        "computed": {
                          "payoff": [
                            "10",
                            "0"
                          ],
                          "probability": "3/10",
                          "expected-value-maximization": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "expected-value-minimization": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "maxi-min": {
                            "probability": 1,
                            "optimal": true
                          },
                          "maxi-max": {
                            "probability": 0
                          },
                          "mini-min": {
                            "probability": 1,
                            "optimal": true
                          },
                          "mini-max": {
                            "probability": 0
                          },
                          "min-max": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "max-min": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "min-min": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "max-max": {
                            "probability": "3/10",
                            "optimal": true
                          }
                        },
                        "name": "",
                        "probability": "#",
                        "payoff": [
                          "10",
                          0
                        ],
                        "childNode": {
                          "computed": {
                            "expected-value-maximization": {
                              "aggregatedPayoff": [
                                "15"
                              ],
                              "probabilityToEnter": "9/100",
                              "payoff": [
                                "10"
                              ],
                              "optimal": true
                            },
                            "expected-value-minimization": {
                              "aggregatedPayoff": [
                                "15"
                              ],
                              "probabilityToEnter": "9/100",
                              "payoff": [
                                "10"
                              ],
                              "optimal": true
                            },
                            "maxi-min": {
                              "aggregatedPayoff": [
                                "15"
                              ],
                              "probabilityToEnter": "1",
                              "payoff": [
                                "10"
                              ],
                              "optimal": true
                            },
                            "maxi-max": {
                              "aggregatedPayoff": [
                                "15"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "10"
                              ]
                            },
                            "mini-min": {
                              "aggregatedPayoff": [
                                "15"
                              ],
                              "probabilityToEnter": "1",
                              "payoff": [
                                "10"
                              ],
                              "optimal": true
                            },
                            "mini-max": {
                              "aggregatedPayoff": [
                                "15"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "10"
                              ]
                            },
                            "min-max": {
                              "aggregatedPayoff": [
                                "15",
                                "0"
                              ],
                              "probabilityToEnter": "9/100",
                              "combinedPayoff": "-10",
                              "payoff": [
                                "10",
                                "0"
                              ],
                              "optimal": true
                            },
                            "max-min": {
                              "aggregatedPayoff": [
                                "15",
                                "0"
                              ],
                              "probabilityToEnter": "9/100",
                              "combinedPayoff": "10",
                              "payoff": [
                                "10",
                                "0"
                              ],
                              "optimal": true
                            },
                            "min-min": {
                              "aggregatedPayoff": [
                                "15",
                                "0"
                              ],
                              "probabilityToEnter": "9/100",
                              "combinedPayoff": "-10",
                              "payoff": [
                                "10",
                                "0"
                              ],
                              "optimal": true
                            },
                            "max-max": {
                              "aggregatedPayoff": [
                                "15",
                                "0"
                              ],
                              "probabilityToEnter": "9/100",
                              "combinedPayoff": "10",
                              "payoff": [
                                "10",
                                "0"
                              ],
                              "optimal": true
                            }
                          },
                          "childEdges": [],
                          "name": "",
                          "code": "",
                          "expressionScope": {
                            "A": 10,
                            "B": 15
                          },
                          "folded": false,
                          "location": {
                            "x": 611,
                            "y": 473
                          },
                          "type": "terminal",
                          "expectedValue": 15,
                          "p10": 15,
                          "p90": 15
                        },
                        "expectedValue": 10,
                        "p10": 10,
                        "p90": 10,
                        "optimal": true
                      }
                    ],
                    "name": "Risk B",
                    "code": "",
                    "expressionScope": {
                      "A": 10,
                      "B": 15
                    },
                    "folded": false,
                    "location": {
                      "x": 411,
                      "y": 418.00000000000006
                    },
                    "type": "chance",
                    "expectedValue": 15,
                    "p10": 10,
                    "p90": 20
                  },
                  "expectedValue": 5,
                  "p10": 5,
                  "p90": 5,
                  "optimal": true
                }
              ],
              "name": "Risk A",
              "code": "",
              "expressionScope": {
                "A": 10,
                "B": 15
              },
              "folded": false,
              "location": {
                "x": 211,
                "y": 242
              },
              "type": "chance",
              "expectedValue": 25,
              "p10": 20,
              "p90": 30
            },
            "expectedValue": 0,
            "p10": 0,
            "p90": 0,
            "optimal": true
          }
        ],
        "name": "Decision",
        "code": "",
        "expressionScope": {
          "A": 10,
          "B": 15
        },
        "folded": false,
        "location": {
          "x": 11,
          "y": 242
        },
        "type": "decision",
        "expectedValue": 25,
        "p10": 20,
        "p90": 30,
        "charts": [
          {
            "nodeId": "5ae1b85f-41d6-9689-cbf8-5a3504115f20",
            "x": -75.99999618530273,
            "y": 47.999996185302734,
            "type": "distribution",
            "active": true
          }
        ]
      },
      {
        "computed": {
          "expected-value-maximization": {
            "childrenPayoff": [
              "25"
            ],
            "payoff": [
              "25"
            ],
            "optimal": true
          },
          "expected-value-minimization": {
            "childrenPayoff": [
              "25"
            ],
            "payoff": [
              "25"
            ],
            "optimal": true
          },
          "maxi-min": {
            "childrenPayoff": [
              "25"
            ],
            "payoff": [
              "25"
            ],
            "optimal": true
          },
          "maxi-max": {
            "childrenPayoff": [
              "25"
            ],
            "payoff": [
              "25"
            ],
            "optimal": true
          },
          "mini-min": {
            "childrenPayoff": [
              "25"
            ],
            "payoff": [
              "25"
            ],
            "optimal": true
          },
          "mini-max": {
            "childrenPayoff": [
              "25"
            ],
            "payoff": [
              "25"
            ],
            "optimal": true
          },
          "min-max": {
            "childrenPayoff": [
              "25",
              "0"
            ],
            "combinedPayoff": "-25",
            "payoff": [
              "25",
              "0"
            ],
            "optimal": true
          },
          "max-min": {
            "childrenPayoff": [
              "25",
              "0"
            ],
            "combinedPayoff": "25",
            "payoff": [
              "25",
              "0"
            ],
            "optimal": true
          },
          "min-min": {
            "childrenPayoff": [
              "25",
              "0"
            ],
            "combinedPayoff": "-25",
            "payoff": [
              "25",
              "0"
            ],
            "optimal": true
          },
          "max-max": {
            "childrenPayoff": [
              "25",
              "0"
            ],
            "combinedPayoff": "25",
            "payoff": [
              "25",
              "0"
            ],
            "optimal": true
          }
        },
        "childEdges": [
          {
            "computed": {
              "payoff": [
                "0",
                "0"
              ],
              "expected-value-maximization": {
                "probability": 1,
                "optimal": true
              },
              "expected-value-minimization": {
                "probability": 1,
                "optimal": true
              },
              "maxi-min": {
                "probability": 1,
                "optimal": true
              },
              "maxi-max": {
                "probability": 1,
                "optimal": true
              },
              "mini-min": {
                "probability": 1,
                "optimal": true
              },
              "mini-max": {
                "probability": 1,
                "optimal": true
              },
              "min-max": {
                "probability": 1,
                "optimal": true
              },
              "max-min": {
                "probability": 1,
                "optimal": true
              },
              "min-min": {
                "probability": 1,
                "optimal": true
              },
              "max-max": {
                "probability": 1,
                "optimal": true
              }
            },
            "name": "",
            "payoff": [
              0,
              0
            ],
            "childNode": {
              "computed": {
                "expected-value-maximization": {
                  "childrenPayoff": [
                    "25"
                  ],
                  "payoff": [
                    "25"
                  ],
                  "optimal": true
                },
                "expected-value-minimization": {
                  "childrenPayoff": [
                    "25"
                  ],
                  "payoff": [
                    "25"
                  ],
                  "optimal": true
                },
                "maxi-min": {
                  "childrenPayoff": [
                    "25"
                  ],
                  "payoff": [
                    "25"
                  ],
                  "optimal": true
                },
                "maxi-max": {
                  "childrenPayoff": [
                    "25"
                  ],
                  "payoff": [
                    "25"
                  ],
                  "optimal": true
                },
                "mini-min": {
                  "childrenPayoff": [
                    "25"
                  ],
                  "payoff": [
                    "25"
                  ],
                  "optimal": true
                },
                "mini-max": {
                  "childrenPayoff": [
                    "25"
                  ],
                  "payoff": [
                    "25"
                  ],
                  "optimal": true
                },
                "min-max": {
                  "childrenPayoff": [
                    "25",
                    "0"
                  ],
                  "combinedPayoff": "-25",
                  "payoff": [
                    "25",
                    "0"
                  ],
                  "optimal": true
                },
                "max-min": {
                  "childrenPayoff": [
                    "25",
                    "0"
                  ],
                  "combinedPayoff": "25",
                  "payoff": [
                    "25",
                    "0"
                  ],
                  "optimal": true
                },
                "min-min": {
                  "childrenPayoff": [
                    "25",
                    "0"
                  ],
                  "combinedPayoff": "-25",
                  "payoff": [
                    "25",
                    "0"
                  ],
                  "optimal": true
                },
                "max-max": {
                  "childrenPayoff": [
                    "25",
                    "0"
                  ],
                  "combinedPayoff": "25",
                  "payoff": [
                    "25",
                    "0"
                  ],
                  "optimal": true
                }
              },
              "childEdges": [
                {
                  "computed": {
                    "payoff": [
                      "10",
                      "0"
                    ],
                    "probability": "1",
                    "expected-value-maximization": {
                      "probability": "1",
                      "optimal": true
                    },
                    "expected-value-minimization": {
                      "probability": "1",
                      "optimal": true
                    },
                    "maxi-min": {
                      "probability": 1,
                      "optimal": true
                    },
                    "maxi-max": {
                      "probability": 1,
                      "optimal": true
                    },
                    "mini-min": {
                      "probability": 1,
                      "optimal": true
                    },
                    "mini-max": {
                      "probability": 1,
                      "optimal": true
                    },
                    "min-max": {
                      "probability": "1",
                      "optimal": true
                    },
                    "max-min": {
                      "probability": "1",
                      "optimal": true
                    },
                    "min-min": {
                      "probability": "1",
                      "optimal": true
                    },
                    "max-max": {
                      "probability": "1",
                      "optimal": true
                    }
                  },
                  "name": "",
                  "probability": "#",
                  "payoff": [
                    "A",
                    0
                  ],
                  "childNode": {
                    "computed": {
                      "expected-value-maximization": {
                        "childrenPayoff": [
                          "15"
                        ],
                        "payoff": [
                          "25"
                        ],
                        "optimal": true
                      },
                      "expected-value-minimization": {
                        "childrenPayoff": [
                          "15"
                        ],
                        "payoff": [
                          "25"
                        ],
                        "optimal": true
                      },
                      "maxi-min": {
                        "childrenPayoff": [
                          "15"
                        ],
                        "payoff": [
                          "25"
                        ],
                        "optimal": true
                      },
                      "maxi-max": {
                        "childrenPayoff": [
                          "15"
                        ],
                        "payoff": [
                          "25"
                        ],
                        "optimal": true
                      },
                      "mini-min": {
                        "childrenPayoff": [
                          "15"
                        ],
                        "payoff": [
                          "25"
                        ],
                        "optimal": true
                      },
                      "mini-max": {
                        "childrenPayoff": [
                          "15"
                        ],
                        "payoff": [
                          "25"
                        ],
                        "optimal": true
                      },
                      "min-max": {
                        "childrenPayoff": [
                          "15",
                          "0"
                        ],
                        "combinedPayoff": "-25",
                        "payoff": [
                          "25",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-min": {
                        "childrenPayoff": [
                          "15",
                          "0"
                        ],
                        "combinedPayoff": "25",
                        "payoff": [
                          "25",
                          "0"
                        ],
                        "optimal": true
                      },
                      "min-min": {
                        "childrenPayoff": [
                          "15",
                          "0"
                        ],
                        "combinedPayoff": "-25",
                        "payoff": [
                          "25",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-max": {
                        "childrenPayoff": [
                          "15",
                          "0"
                        ],
                        "combinedPayoff": "25",
                        "payoff": [
                          "25",
                          "0"
                        ],
                        "optimal": true
                      }
                    },
                    "childEdges": [
                      {
                        "computed": {
                          "payoff": [
                            "15",
                            "0"
                          ],
                          "probability": "1",
                          "expected-value-maximization": {
                            "probability": "1",
                            "optimal": true
                          },
                          "expected-value-minimization": {
                            "probability": "1",
                            "optimal": true
                          },
                          "maxi-min": {
                            "probability": 1,
                            "optimal": true
                          },
                          "maxi-max": {
                            "probability": 1,
                            "optimal": true
                          },
                          "mini-min": {
                            "probability": 1,
                            "optimal": true
                          },
                          "mini-max": {
                            "probability": 1,
                            "optimal": true
                          },
                          "min-max": {
                            "probability": "1",
                            "optimal": true
                          },
                          "max-min": {
                            "probability": "1",
                            "optimal": true
                          },
                          "min-min": {
                            "probability": "1",
                            "optimal": true
                          },
                          "max-max": {
                            "probability": "1",
                            "optimal": true
                          }
                        },
                        "name": "",
                        "probability": "#",
                        "payoff": [
                          "B",
                          0
                        ],
                        "childNode": {
                          "computed": {
                            "expected-value-maximization": {
                              "aggregatedPayoff": [
                                "25"
                              ],
                              "probabilityToEnter": "1",
                              "payoff": [
                                "15"
                              ],
                              "optimal": true
                            },
                            "expected-value-minimization": {
                              "aggregatedPayoff": [
                                "25"
                              ],
                              "probabilityToEnter": "1",
                              "payoff": [
                                "15"
                              ],
                              "optimal": true
                            },
                            "maxi-min": {
                              "aggregatedPayoff": [
                                "25"
                              ],
                              "probabilityToEnter": "1",
                              "payoff": [
                                "15"
                              ],
                              "optimal": true
                            },
                            "maxi-max": {
                              "aggregatedPayoff": [
                                "25"
                              ],
                              "probabilityToEnter": "1",
                              "payoff": [
                                "15"
                              ],
                              "optimal": true
                            },
                            "mini-min": {
                              "aggregatedPayoff": [
                                "25"
                              ],
                              "probabilityToEnter": "1",
                              "payoff": [
                                "15"
                              ],
                              "optimal": true
                            },
                            "mini-max": {
                              "aggregatedPayoff": [
                                "25"
                              ],
                              "probabilityToEnter": "1",
                              "payoff": [
                                "15"
                              ],
                              "optimal": true
                            },
                            "min-max": {
                              "aggregatedPayoff": [
                                "25",
                                "0"
                              ],
                              "probabilityToEnter": "1",
                              "combinedPayoff": "-15",
                              "payoff": [
                                "15",
                                "0"
                              ],
                              "optimal": true
                            },
                            "max-min": {
                              "aggregatedPayoff": [
                                "25",
                                "0"
                              ],
                              "probabilityToEnter": "1",
                              "combinedPayoff": "15",
                              "payoff": [
                                "15",
                                "0"
                              ],
                              "optimal": true
                            },
                            "min-min": {
                              "aggregatedPayoff": [
                                "25",
                                "0"
                              ],
                              "probabilityToEnter": "1",
                              "combinedPayoff": "-15",
                              "payoff": [
                                "15",
                                "0"
                              ],
                              "optimal": true
                            },
                            "max-max": {
                              "aggregatedPayoff": [
                                "25",
                                "0"
                              ],
                              "probabilityToEnter": "1",
                              "combinedPayoff": "15",
                              "payoff": [
                                "15",
                                "0"
                              ],
                              "optimal": true
                            }
                          },
                          "childEdges": [],
                          "name": "",
                          "code": "",
                          "expressionScope": {
                            "A": 10,
                            "B": 15
                          },
                          "folded": false,
                          "location": {
                            "x": 611,
                            "y": 545
                          },
                          "type": "terminal",
                          "expectedValue": 25,
                          "p10": 18,
                          "p90": 32,
                          "charts": [
                            {
                              "nodeId": "0a13c00a-5683-4c60-5cf1-2574fe40ade9",
                              "x": 69.99999237060547,
                              "y": -40.00000208616257,
                              "type": "distribution",
                              "active": false
                            }
                          ]
                        },
                        "expectedValue": 15,
                        "p10": 10,
                        "p90": 21,
                        "optimal": true
                      }
                    ],
                    "name": "Risk B",
                    "code": "",
                    "expressionScope": {
                      "A": 10,
                      "B": 15
                    },
                    "folded": false,
                    "location": {
                      "x": 411,
                      "y": 545
                    },
                    "type": "chance",
                    "expectedValue": 15,
                    "p10": 10,
                    "p90": 21
                  },
                  "expectedValue": 10,
                  "p10": 5,
                  "p90": 16,
                  "optimal": true
                }
              ],
              "name": "Risk A",
              "code": "",
              "expressionScope": {
                "A": 10,
                "B": 15
              },
              "folded": false,
              "location": {
                "x": 211,
                "y": 545
              },
              "type": "chance",
              "expectedValue": 25,
              "p10": 18,
              "p90": 32,
              "charts": [
                {
                  "nodeId": "9b38201a-97eb-38c8-f814-4d337040ed85",
                  "x": 50,
                  "y": -100,
                  "type": "distribution",
                  "active": false
                }
              ]
            },
            "expectedValue": 0,
            "p10": 0,
            "p90": 0,
            "optimal": true
          }
        ],
        "name": "Decision",
        "code": "",
        "expressionScope": {
          "A": 10,
          "B": 15
        },
        "folded": false,
        "location": {
          "x": 11,
          "y": 545
        },
        "type": "decision",
        "expectedValue": 25,
        "p10": 18,
        "p90": 32,
        "charts": [
          {
            "nodeId": "c94bfb16-a9ea-1f48-f46b-44fe9c76d89c",
            "x": -75.99999570846558,
            "y": -126,
            "type": "distribution",
            "active": true
          }
        ]
      }
    ],
    "texts": [
      {
        "value": "FROM THIS",
        "location": {
          "x": -167.99998378753662,
          "y": 331.0017375946045
        }
      },
      {
        "value": "TO THIS",
        "location": {
          "x": -152.9999885559082,
          "y": 462.001708984375
        }
      }
    ],
    "payoffNames": [],
    "defaultCriterion1Weight": 1,
    "weightLowerBound": 0,
    "weightUpperBound": "Infinity"
  },
  "sensitivityAnalysis": {},
  "definitionsDialog": {
    "valueList": [
      {
        "name": "A",
        "value": "10",
        "low": 5,
        "high": 15,
        "distribution": "triangular",
        "error": ""
      },
      {
        "name": "B",
        "value": "15",
        "low": 10,
        "high": 20,
        "distribution": "triangular",
        "error": ""
      },
      {
        "name": null,
        "value": "",
        "low": null,
        "high": null,
        "distribution": "constant",
        "error": ""
      },
      {
        "name": null,
        "value": "",
        "low": null,
        "high": null,
        "distribution": "constant",
        "error": ""
      },
      {
        "name": null,
        "value": "",
        "low": null,
        "high": null,
        "distribution": "constant",
        "error": ""
      }
    ]
  }
}