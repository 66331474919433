import * as d3 from '../d3';
import { Dialog } from './dialog';
import { Utils } from 'sd-utils';
import { AppUtils } from '../app-utils';
import { Templates } from '../templates';
import { domain as model } from 'sd-model';
import { i18n } from '../i18n/i18n';

// dnodes.io-colors
// More styling for the variable table is found in ../styles/modal.scss
const ChartPositive = '#B4D290';
const ChartNegative = '#D36060';

export class DefinitionsDialog extends Dialog {
    changeCallback;
    valueList;
    numberOfRows;
    maxValue;

    constructor(app) {
        super(app.container.select('#sd-definitions-dialog'), app);
        var self = this;
        this.numberOfRows = 0;
        this.validationError = { error: false, text: '' };
        this.definitionsScopeLabel = this.container.select(
            '.sd-definitions-dialog-scope-label',
        );
        this.maxValue = 0;
        this.valueList = [];

        let numberOfInitialRows = 5;
        this.createInitialRows(numberOfInitialRows);

        var addRowButton = document.getElementById('add-row-button');
        addRowButton.addEventListener('click', function () {
            self.addRow();
        });

        document.addEventListener(
            'SilverDecisionsRecomputedEvent',
            function (data) {
                if (data.detail === app && self.isVisible()) {
                    self.update();
                }
            },
        );

        var openTreeButton = document.getElementsByClassName('js-open-tree')[0];
        openTreeButton.addEventListener('click', function () {
            setTimeout(() => {
                self.app.treeDesigner.updatePlottingRegionSize();
            }, 10);
            setTimeout(() => {
                self.app.treeDesigner.updatePlottingRegionSize();
            }, 100);
        });
    }

    createInitialRows(numberOfInitialRows) {
        for (let i = 0; i < numberOfInitialRows; i++) {
            this.addRow();
        }
    }

    open(definitionsSourceObject, changeCallback) {
        super.open();
        this.changeCallback = changeCallback;
        this.definitionsSourceObject = definitionsSourceObject;
        this.update();
        this.updateDistributions();
    }

    addRow() {
        this.valueList.push({
            name: null,
            value: '',
            low: null,
            high: null,
            distribution: 'constant',
            error: '',
        });
        var i = this.numberOfRows;
        document.getElementById('data-table-body').insertAdjacentHTML(
            'beforeend',
            `<tr class='variable-table-row' id='table-row-${i}'> 
                <td class='variable-table-data name'> 
                    <input id="variable-name-${i}" placeholder='VariableName' /> 
                </td> 
                <td class='variable-table-data value'> 
                    <input type='text' id='variable-value-${i}' placeholder='Value' /> 
                </td> 
                <td class='variable-table-data low'> 
                    <input type='text' id='variable-extreme-low-${i}' /> 
                </td> 
                <td class='variable-table-data high'> 
                    <input type='text' id='variable-extreme-high-${i}' />
                </td> 
                <td class='variable-table-data chart' id='table-chart-${i}'></td> 
                <td class="variable-table-data edit" id="edit-${i}">
                        <div class="kebab-menu" id="kebab-menu-${i}">
                            <span class="material-icons-outlined">
                                drive_file_rename_outline
                            </span>
                        </div>
                        <div class="kebab-dropdown" id="kebab-dropdown-${i}">
                            <p id="dropdown-kebab-moveup-${i}">
                                <span class="material-icons-outlined">
                                    north
                                </span>
                            </p>
                            <p id="dropdown-kebab-movedown-${i}">
                                <span class="material-icons-outlined">
                                    south
                                </span>
                            </p>
                            <p id="dropdown-kebab-remove-${i}">
                                <span class="material-icons-outlined">
                                    delete
                                </span>
                            </p>
                        </div>
                    </td>
            </tr>`,
        );
        var valueField = document.querySelector(`#variable-value-${i}`);
        var nameField = document.querySelector(`#variable-name-${i}`);
        var lowField = document.querySelector(`#variable-extreme-low-${i}`);
        var highField = document.querySelector(`#variable-extreme-high-${i}`);

        this.addEventListenerForValue(valueField);
        this.addEventListenerForName(nameField);
        this.addEventListenerForHighLow(lowField);
        this.addEventListenerForHighLow(highField);

        let self = this;
        const kebabMenus = document.getElementById(
            `kebab-dropdown-${i}`,
        ).childNodes;
        for (var i = 0; i < kebabMenus.length; i++) {
            self.addEventListenerForKebabMenus(kebabMenus[i]);
        }
        this.numberOfRows += 1;
    }

    getString(list) {
        let stringResult = '';
        for (var i = 0; i < list.length; i++) {
            if (list[i].name && list[i].value) {
                stringResult += list[i].name + '=' + list[i].value + '\n';
            } else if (
                list[i].name &&
                (list[i].high || list[i].high == 0) &&
                (list[i].low || list[i].low == 0)
            ) {
                const val = (list[i].high + list[i].low) * 0.5;
                stringResult += list[i].name + '=' + val + '\n';
            }
        }
        return stringResult;
    }

    getVariableNames() {
        let names = this.valueList.map((row) => row.name);
        return names.filter((name) => name != null && name != '');
    }

    updateAndWriteValues(definitionsDialog) {
        definitionsDialog.valueList.forEach((newRow, i) => {
            this.valueList[i] = newRow;
        });
        this.numberOfRows = 5;
        for (var i = 0; i < definitionsDialog.valueList.length - 5; i++) {
            this.addRow();
            this.valueList.pop();
        }
        this.printValueList();
    }

    clearValues() {
        while (this.valueList.length > 0) {
            this.removeRow(this.valueList.length - 1);
        }
        for (let i = 0; i < 5; i++) {
            this.addRow();
        }
    }

    clearDistribution(i) {
        var wrapper = d3.select('#table-chart-' + i);
        wrapper.select('svg').remove();
    }

    printValueList() {
        var self = this;
        self.valueList.map((row, i) => {
            document.getElementById(`variable-name-${i}`).value = row.name;
            document.getElementById(`variable-value-${i}`).value = row.value
                ? self.formatNumber(row.value)
                : '';
            document.getElementById(`variable-extreme-low-${i}`).value = row.low
                ? self.formatNumber(row.low)
                : '';
            document.getElementById(`variable-extreme-high-${i}`).value =
                row.high ? self.formatNumber(row.high) : '';
        });
        for (var i = 0; i < self.valueList.length; i++) {
            self.updatePercentage(i);
        }
        self.updateDistributions();
    }

    update(force = false) {
        if (!force && !this.isVisible()) {
            return;
        }

        let scopeType = 'global';
        let labelSuffix = '';
        if (this.definitionsSourceObject instanceof model.Node) {
            scopeType = 'node';
            let name = this.definitionsSourceObject.name;
            if (name) {
                labelSuffix += ' (' + name + ')';
            }
        }
    }

    printError(text, i) {
        const row = document.getElementById('table-row-' + i);
        row.classList.add('error');
        const errorText = document.getElementById('table-error-text');
        errorText.style.display = 'block';
        errorText.innerHTML = text;
    }

    removeError() {
        var markedRow = document.querySelector('tr.error');
        if (markedRow) markedRow.classList.remove('error');
        const errorText = document.getElementById('table-error-text');
        errorText.style.display = 'none';
    }

    isValidName(name, i) {
        if (name == '') {
            if (!this.isEmptyRow(this.valueList[i], false)) {
                this.printError(
                    'A variable with values must have a name. Changes not made.',
                    i,
                );
                return false;
            }
        } else {
            if (!this.isLetter(name.charAt(0))) {
                this.printError(
                    'Variable name must start with a letter. Changes not made.',
                    i,
                );
                return false;
            }
        }
        if (this.hasWhiteSpace(name)){
            this.printError(
                'A variable name cannot contain spaces. Changes not made.',
                i,
            );
            return false;
        }

        if (this.isDuplicateName(name, i)) {
            this.printError(
                'Two variables cannot have the same name. Changes not made.',
                i,
            );
            return false;
        }
        return true;
    }

    hasWhiteSpace(s) {
        return /\s/g.test(s);
      }

    isValidValue(value, i) {
        if (value == '') return true;
        var row = this.valueList[i];
        if (isNaN(value) || !Number.isInteger(parseFloat(value))) {
            this.printError(
                "'Value' must be an integer if set. Changes not made.",
                i,
            );
            return false;
        }
        if (row.high != null && row.high < parseFloat(value)) {
            this.printError(
                "'Base value' must be lower than or equal to 'high'. Changes not made.",
                i,
            );
            return false;
        }
        if (row.low != null && row.low > parseFloat(value)) {
            this.printError(
                "'Base value' must be higher than or equal to 'low'. Changes not made.",
                i,
            );
            return false;
        }
        return true;
    }

    isValidHigh(high, i) {
        if (high == '') return true;
        var row = this.valueList[i];
        if (isNaN(high) || !Number.isInteger(parseFloat(high))) {
            this.printError(
                "'High' must be an integer if set. Changes not made.",
                i,
            );
            return false;
        }
        if (row.value != '' && parseFloat(high) < parseFloat(row.value)) {
            this.printError(
                "'High' must be higher than or equal to 'Base case'. Changes not made.",
                i,
            );
            return false;
        }
        if (row.low != null && parseFloat(high) < row.low) {
            this.printError(
                "'High' must be higher than or equal to 'Low'. Changes not made.",
                i,
            );
            return false;
        }
        return true;
    }

    isValidLow(low, i) {
        if (low == '') return true;
        var row = this.valueList[i];
        if (isNaN(low) || !Number.isInteger(parseFloat(low))) {
            this.printError(
                "'Low' must be an integer if set. Changes not made.",
                i,
            );
            return false;
        }
        if (row.value != '' && parseFloat(low) > parseFloat(row.value)) {
            this.printError(
                "'Low' must be lower than or equal to 'Base case'. Changes not made.",
                i,
            );
            return false;
        }
        if (row.high != null && row.high < parseFloat(low)) {
            this.printError(
                "'Low' must be lower than or equal to 'High'. Changes not made.",
                i,
            );
            return false;
        }
        return true;
    }

    isDuplicateName(name, i) {
        return this.valueList.some((r, j) => name == r.name && i != j);
    }

    isEmptyRow(row, checkName = true) {
        if (checkName)
            return (
                !row.name && !row.value && row.high == null && row.low == null
            );
        return !row.value && row.high == null && row.low == null;
    }

    isLetter(c) {
        return c.toLowerCase() != c.toUpperCase();
    }

    removeRow(i) {
        let treeEdges = this.app.dataModel.edges;
        treeEdges.map((edge) => {
            if (edge.payoff[0] == this.valueList[i].name) {
                edge.setPayoff('');
            }
        });
        this.updateDistributions();
        if (this.changeCallback)
            this.changeCallback(this.getString(this.valueList));
        this.valueList.splice(i, 1);
        const lastRow = document.getElementById(
            `table-row-${this.numberOfRows - 1}`,
        );
        lastRow.remove();
        this.numberOfRows -= 1;
        this.printValueList();
    }

    printVariables(scope) {
        var html = Templates.get('evaluatedVariables', {
            scopeVariables: Utils.getVariablesAsList(scope),
        });
        console.log(html);

        this.variableValuesContainer.html(html);
    }

    getValue(value, name) {
        if (isNaN(value)) {
            return this.definitionsSourceObject.expressionScope[name];
        }
        return parseFloat(value);
    }

    printLineChart(valueItem, i) {
        //Remove old svg's
        var wrapper = d3.select('#table-chart-' + i);
        wrapper.select('svg').remove();

        const chartWidth = 600;
        const chartHeight = 48;

        const svg = wrapper
            .append('svg')
            .attr('height', chartHeight)
            .attr('width', chartWidth);
        this.drawDistribution(valueItem, chartWidth, chartHeight, svg);
    }

    updateDistributions() {
        if (
            this.definitionsSourceObject &&
            !this.definitionsSourceObject.$codeError
        ) {
            for (var i = 0; i < this.valueList.length; i++) {
                if (this.canPrintDistribution(this.valueList[i])) {
                    this.printLineChart(this.valueList[i], i);
                } else {
                    var wrapper = d3.select('#table-chart-' + i);
                    wrapper.select('svg').remove();
                }
            }
        }
    }

    getMaxAndMinValue() {
        var max = 1;
        var min = -1;
        for (var i = 0; i < this.valueList.length; i++) {
            const isnum = /^-?\d+$/.test(this.valueList[i].value);
            // console.log("is num?", this.valueList[i].value, isnum)

            if (!isnum) {
                max =
                    this.definitionsSourceObject.expressionScope[
                        this.valueList[i].name
                    ] > max
                        ? this.definitionsSourceObject.expressionScope[
                              this.valueList[i].name
                          ]
                        : max;
                min =
                    this.definitionsSourceObject.expressionScope[
                        this.valueList[i].name
                    ] < min
                        ? this.definitionsSourceObject.expressionScope[
                              this.valueList[i].name
                          ]
                        : min;
            } else {
                let value = parseFloat(this.valueList[i].value);
                max = value > max ? value : max;
                min = value < min ? value : min;
            }
            if (this.valueList[i].high) {
                max = Math.max(this.valueList[i].high, max);
                min = Math.min(this.valueList[i].high, min);
            }
            if (this.valueList[i].low) {
                max = Math.max(this.valueList[i].low, max);
                min = Math.min(this.valueList[i].low, min);
            }
        }
        return { max: max, min: min };
    }

    drawDistribution(valueItem, chartWidth, chartHeight, svg) {
        const maxmin = this.getMaxAndMinValue();
        const value = this.getValue(valueItem.value, valueItem.name);
        var points;
        var isPositive = true;
        var fillOpacity = 1;
        var lineFunc = d3.line();

        var scaleX = d3
            .scaleLinear()
            .domain([maxmin.min * 1.1, maxmin.max * 1.1])
            .range([0, chartWidth]);

        // add zero on x axis
        if (maxmin.min != -1) {
            var zeroX = scaleX(0);
            svg.append('path')
                .attr(
                    'd',
                    lineFunc([
                        [zeroX, 0],
                        [zeroX, chartHeight + 2],
                    ]),
                )
                .attr('stroke', '#c0c0c0');
        }

        if (valueItem.distribution === 'constant') {
            isPositive = value > 0;
            const x = scaleX(value);
            points = [
                [x, chartHeight * 0.2],
                [x, chartHeight],
            ];
        } else {
            const graphWidth = (valueItem.high - valueItem.low) / maxmin.max;
            // set height on graph depending on width
            const height =
                graphWidth > 0.5
                    ? chartHeight * 0.6
                    : graphWidth > 0.2
                    ? chartHeight * 0.4
                    : chartHeight * 0.2;

            const left = scaleX(valueItem.low);
            const right = scaleX(valueItem.high);

            if (valueItem.distribution === 'uniform') {
                isPositive = (valueItem.high + valueItem.low) / 2 > 0;
                fillOpacity = isPositive
                    ? (valueItem.high + valueItem.low) / 2 / maxmin.max
                    : (valueItem.high + valueItem.low) / 2 / maxmin.min;
                points = [
                    [left, chartHeight],
                    [left, height],
                    [right, height],
                    [right, chartHeight],
                ];
            } else {
                isPositive = value > 0;
                fillOpacity = isPositive
                    ? value / maxmin.max
                    : value / maxmin.min;
                points = [
                    [left, chartHeight],
                    [scaleX(value), height],
                    [right, chartHeight],
                ];
            }
        }
        svg.append('path')
            .attr('d', lineFunc(points))
            .attr('stroke', isPositive ? ChartPositive : ChartNegative)
            .attr('fill', isPositive ? ChartPositive : ChartNegative);
        // .attr('fill-opacity', Math.max(0.3, fillOpacity));
    }

    setDistributionType(i) {
        let row = this.valueList[i];
        if (this.rowHasAllValues(row)) {
            this.valueList[i].distribution = 'triangular';
        } else if (
            row.name &&
            (row.high || row.high == 0) &&
            (row.low || row.low == 0)
        ) {
            this.valueList[i].distribution = 'uniform';
        } else if (row.name && row.value) {
            this.valueList[i].distribution = 'constant';
        }
    }

    addEventListenerForValue(valueField) {
        var self = this;
        valueField.addEventListener('change', function () {
            var i = this.id.split('-').pop();
            let newValue = self.unFormatNumber(this.value);
            self.removeError();
            if (!self.isValidValue(newValue, i)) {
                this.value = self.formatNumber(self.valueList[i].value);
                return;
            }
            self.valueList[i].value = newValue;
            this.value = self.formatNumber(newValue);
            self.updatePercentage(i);
            if (self.changeCallback) {
                self.app.recompute();
                self.setDistributionType(i);
                self.updateDistributions();
                self.changeCallback(self.getString(self.valueList));
            }
        });
        valueField.addEventListener('focus', function () {
            if (!this.value) return;
            this.value = self.unFormatNumber(this.value);
        });
        valueField.addEventListener('blur', function () {
            this.value = self.formatNumber(this.value);
        });
    }

    addEventListenerForName(nameField) {
        var self = this;    
        nameField.addEventListener('input', function () {
            var i = this.id.split('-').pop();
            self.hasWhiteSpace(this.value) ? 
                self.printError(
                    'A variable name cannot contain spaces.',
                    i,
                )
                : self.removeError()
        })
        nameField.addEventListener('change', function () {
            var i = this.id.split('-').pop();
            self.removeError();
            if (!self.isValidName(this.value, i)) {
                this.value = self.valueList[i].name;
                return;
            }
            if (this.value != '') {
                let treeEdges = self.app.dataModel.edges;
                treeEdges.map((edge) => {
                    if (edge.payoff[0] == self.valueList[i].name) {
                        edge.setPayoff(this.value);
                    }
                });
            }
            self.valueList[i].name = this.value;
            if (self.changeCallback) {
                self.changeCallback(self.getString(self.valueList));
                self.setDistributionType(i);
                self.updateDistributions();
            }
        });
    }

    addEventListenerForHighLow(highLowField) {
        var self = this;
        highLowField.addEventListener('change', function () {
            var i = this.id.split('-').pop();
            let newValue = self.unFormatNumber(this.value.split(' ')[0]);
            self.removeError();
            if (this.id.includes('high')) {
                if (!self.isValidHigh(newValue, i)) {
                    this.value = self.valueList[i].high
                        ? self.formatNumber(self.valueList[i].high)
                        : null;
                    return;
                }
                self.valueList[i].high =
                    newValue == '' ? null : parseFloat(newValue);
            } else {
                if (!self.isValidLow(newValue, i)) {
                    this.value = self.valueList[i].low
                        ? self.formatNumber(self.valueList[i].low)
                        : null;
                    return;
                }
                self.valueList[i].low =
                    newValue == '' ? null : parseFloat(newValue);
            }
            this.value = self.formatNumber(newValue);
            self.updatePercentage(i);
            self.setDistributionType(i);
            self.updateDistributions();
            self.changeCallback(self.getString(self.valueList));
        });
        highLowField.addEventListener('focus', function () {
            if (!this.value) return;
            this.value = self.unFormatNumber(this.value.split(' ')[0]);
        });
        highLowField.addEventListener('keyup', function () {
            //if (!this.value) return;
            //let newValue = self.unFormatNumber(this.value.split(' ')[0]);
            //this.value = self.formatNumber(newValue);
        });
        highLowField.addEventListener('blur', function () {
            this.value = self.formatNumber(this.value);
            var i = this.id.split('-').pop();
            self.updatePercentage(i);
        });
    }

    addEventListenerForKebabMenus(kebabMenus) {
        var self = this;
        kebabMenus.addEventListener('click', function () {
            self.removeError();
            var parent = this.parentElement;
            parent.style.display = 'none';
            setTimeout(function () {
                parent.removeAttribute('style');
            }, 500);
            var list = this.id.split('-');
            var i = parseInt(list.pop());
            var action = list.pop();

            Array.prototype.move = function (from, to) {
                this.splice(to, 0, this.splice(from, 1)[0]);
            };

            if (action == 'remove') {
                self.removeRow(i);
            } else if (action == 'moveup') {
                if (i != 0) {
                    self.valueList.move(i, i - 1);
                    self.printValueList();
                }
            } else if (action == 'movedown') {
                if (i < self.numberOfRows - 1) {
                    self.valueList.move(i, i + 1);
                    self.printValueList();
                }
            } else if (action == 'insert') {
                self.addRow();
                self.valueList.move(self.numberOfRows - 1, i);
                self.printValueList();
            }
        });
    }

    unFormatNumber(inp) {
        return inp.replace(/,/g, '');
    }

    formatNumber(inp) {
        return inp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    updatePercentage(i) {
        var row = this.valueList[i];
        var baseValue = row.value;
        var lowDisplayed = document.getElementById(`variable-extreme-low-${i}`);
        var highDisplayed = document.getElementById(
            `variable-extreme-high-${i}`,
        );
        lowDisplayed.value = lowDisplayed.value.split(' ')[0];
        highDisplayed.value = highDisplayed.value.split(' ')[0];

        if (baseValue && baseValue != 0) {
            if (row.low) {
                var lowPercent = (100 * row.low) / baseValue - 100;
                lowDisplayed.value += ` (${lowPercent.toFixed(1)}%)`;
            }
            if (row.high) {
                var highPercent = (100 * row.high) / baseValue - 100;
                highDisplayed.value += ` (${highPercent.toFixed(1)}%)`;
            }
        }
    }

    canPrintDistribution(valueItem) {
        if (
            valueItem.distribution == 'constant' &&
            valueItem.value &&
            valueItem.name
        )
            return true;
        if (
            valueItem.distribution == 'uniform' &&
            valueItem.high != null &&
            valueItem.low != null &&
            valueItem.name &&
            valueItem.high > valueItem.low
        )
            return true;
        if (
            valueItem.distribution == 'triangular' &&
            this.rowHasAllValues(valueItem) &&
            this.valuesAreInCorrectOrder(valueItem)
        )
            return true;
        return false;
    }

    rowHasAllValues(row) {
        return row.value && row.name && row.low != null && row.high != null;
    }

    valuesAreInCorrectOrder(valueItem) {
        let val = valueItem.value;
        if (isNaN(valueItem.value)) {
            val = this.definitionsSourceObject.expressionScope[val];
        }
        return (
            valueItem.low <= valueItem.value &&
            valueItem.value <= valueItem.high
        );
    }
}
