module.exports={
  "SilverDecisions": "1.1.0",
  "buildTimestamp": 1717680700296,
  "savetime": "2024-06-06T13:56:54.551Z",
  "lng": "en",
  "viewMode": "criterion1",
  "rule": "expected-value-maximization",
  "title": "Charts for insight",
  "description": "",
  "format": {
    "locales": "en",
    "payoff1": {
      "style": "currency",
      "currency": "USD",
      "currencyDisplay": "symbol",
      "minimumFractionDigits": 0,
      "maximumFractionDigits": 0,
      "useGrouping": true
    },
    "payoff2": {
      "style": "percent",
      "currency": "USD",
      "currencyDisplay": "symbol",
      "minimumFractionDigits": 0,
      "maximumFractionDigits": 0,
      "useGrouping": true
    },
    "probability": {
      "style": "percent",
      "minimumFractionDigits": 0,
      "maximumFractionDigits": 0,
      "useGrouping": true
    }
  },
  "treeDesigner": {
    "margin": {
      "left": 550,
      "right": 500,
      "top": 200,
      "bottom": 100
    },
    "scale": 1,
    "lng": "en",
    "layout": {
      "type": "cluster",
      "nodeSize": 22,
      "limitNodePositioning": true,
      "limitTextPositioning": true,
      "gridHeight": 105,
      "gridWidth": 160,
      "edgeSlantWidthMax": 0
    },
    "fontFamily": "sans-serif",
    "fontSize": "12px",
    "fontWeight": "normal",
    "fontStyle": "normal",
    "node": {
      "strokeWidth": "2px",
      "optimal": {
        "stroke": "#006f00",
        "strokeWidth": "2px"
      },
      "label": {
        "fontSize": "1em",
        "color": "black"
      },
      "payoff": {
        "fontSize": "1em",
        "color": "black",
        "negativeColor": "#b60000"
      },
      "decision": {
        "fill": "#ff7777",
        "stroke": "#660000",
        "selected": {
          "fill": "#aa3333"
        }
      },
      "chance": {
        "fill": "#ffff44",
        "stroke": "#666600",
        "selected": {
          "fill": "#aaaa00"
        }
      },
      "terminal": {
        "fill": "#44ff44",
        "stroke": "black",
        "selected": {
          "fill": "#00aa00"
        },
        "payoff": {
          "fontSize": "1em",
          "color": "black",
          "negativeColor": "#b60000"
        }
      }
    },
    "edge": {
      "stroke": "#424242",
      "strokeWidth": "1.5",
      "optimal": {
        "stroke": "#006f00",
        "strokeWidth": "2.4"
      },
      "selected": {
        "stroke": "#045ad1",
        "strokeWidth": "3.5"
      },
      "label": {
        "fontSize": "1em",
        "color": "back"
      },
      "payoff": {
        "fontSize": "1em",
        "color": "black",
        "negativeColor": "#b60000"
      }
    },
    "probability": {
      "fontSize": "1em",
      "color": "#0000d7"
    },
    "title": {
      "fontSize": "16px",
      "fontWeight": "bold",
      "fontStyle": "normal",
      "color": "#000000",
      "margin": {
        "top": 15,
        "bottom": 10
      }
    },
    "description": {
      "show": true,
      "fontSize": "12px",
      "fontWeight": "bold",
      "fontStyle": "normal",
      "color": "#000000",
      "margin": {
        "top": 5,
        "bottom": 10
      }
    },
    "readOnly": false,
    "disableAnimations": false,
    "forceFullEdgeRedraw": false,
    "hideLabels": false,
    "hidePayoffs": true,
    "hideExpectedValues": false,
    "hideProbabilities": false,
    "hideCharts": false,
    "hideTextboxes": false,
    "hideMilestones": false,
    "raw": false,
    "payoffNames": [
      null,
      null
    ],
    "maxPayoffsToDisplay": 1,
    "milestoneNames": [
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    ]
  },
  "data": {
    "code": "MainClaimA=43\nClaimB=20\nClaimC=14\n",
    "expressionScope": {
      "MainClaimA": 43,
      "ClaimB": 20,
      "ClaimC": 14
    },
    "trees": [
      {
        "computed": {
          "expected-value-maximization": {
            "childrenPayoff": [
              "64 22211443580767/70368744177664"
            ],
            "payoff": [
              "63 2184012748173/4294967296000"
            ],
            "optimal": true
          },
          "expected-value-minimization": {
            "childrenPayoff": [
              "63 2184012748173/4294967296000"
            ],
            "payoff": [
              "63 2184012748173/4294967296000"
            ],
            "optimal": true
          },
          "maxi-min": {
            "childrenPayoff": [
              "0"
            ],
            "payoff": [
              "0"
            ],
            "optimal": true
          },
          "maxi-max": {
            "childrenPayoff": [
              "83 16256694536103/17592186044416"
            ],
            "payoff": [
              "83 16256694536103/17592186044416"
            ],
            "optimal": true
          },
          "mini-min": {
            "childrenPayoff": [
              "0"
            ],
            "payoff": [
              "0"
            ],
            "optimal": true
          },
          "mini-max": {
            "childrenPayoff": [
              "83 16256694536103/17592186044416"
            ],
            "payoff": [
              "83 16256694536103/17592186044416"
            ],
            "optimal": true
          },
          "min-max": {
            "childrenPayoff": [
              "63 2184012748173/4294967296000",
              "0"
            ],
            "combinedPayoff": "-63 2184012748173/4294967296000",
            "payoff": [
              "63 2184012748173/4294967296000",
              "0"
            ],
            "optimal": true
          },
          "max-min": {
            "childrenPayoff": [
              "63 2184012748173/4294967296000",
              "0"
            ],
            "combinedPayoff": "63 2184012748173/4294967296000",
            "payoff": [
              "63 2184012748173/4294967296000",
              "0"
            ],
            "optimal": true
          },
          "min-min": {
            "childrenPayoff": [
              "63 2184012748173/4294967296000",
              "0"
            ],
            "combinedPayoff": "-63 2184012748173/4294967296000",
            "payoff": [
              "63 2184012748173/4294967296000",
              "0"
            ],
            "optimal": true
          },
          "max-max": {
            "childrenPayoff": [
              "63 2184012748173/4294967296000",
              "0"
            ],
            "combinedPayoff": "63 2184012748173/4294967296000",
            "payoff": [
              "63 2184012748173/4294967296000",
              "0"
            ],
            "optimal": true
          }
        },
        "childEdges": [
          {
            "computed": {
              "payoff": [
                "0",
                "0"
              ],
              "expected-value-maximization": {
                "probability": 1,
                "optimal": true
              },
              "expected-value-minimization": {
                "probability": 1,
                "optimal": true
              },
              "maxi-min": {
                "probability": 1,
                "optimal": true
              },
              "maxi-max": {
                "probability": 1,
                "optimal": true
              },
              "mini-min": {
                "probability": 1,
                "optimal": true
              },
              "mini-max": {
                "probability": 1,
                "optimal": true
              },
              "min-max": {
                "probability": 1,
                "optimal": true
              },
              "max-min": {
                "probability": 1,
                "optimal": true
              },
              "min-min": {
                "probability": 1,
                "optimal": true
              },
              "max-max": {
                "probability": 1,
                "optimal": true
              }
            },
            "name": "",
            "payoff": [
              0,
              0
            ],
            "childNode": {
              "computed": {
                "expected-value-maximization": {
                  "childrenPayoff": [
                    "64 22211443580769/70368744177664"
                  ],
                  "payoff": [
                    "63 2184012748173/4294967296000"
                  ],
                  "optimal": true
                },
                "expected-value-minimization": {
                  "childrenPayoff": [
                    "63 2184012748173/4294967296000"
                  ],
                  "payoff": [
                    "63 2184012748173/4294967296000"
                  ],
                  "optimal": true
                },
                "maxi-min": {
                  "childrenPayoff": [
                    "0"
                  ],
                  "payoff": [
                    "0"
                  ],
                  "optimal": true
                },
                "maxi-max": {
                  "childrenPayoff": [
                    "83 16256694536103/17592186044416"
                  ],
                  "payoff": [
                    "83 16256694536103/17592186044416"
                  ],
                  "optimal": true
                },
                "mini-min": {
                  "childrenPayoff": [
                    "0"
                  ],
                  "payoff": [
                    "0"
                  ],
                  "optimal": true
                },
                "mini-max": {
                  "childrenPayoff": [
                    "83 16256694536103/17592186044416"
                  ],
                  "payoff": [
                    "83 16256694536103/17592186044416"
                  ],
                  "optimal": true
                },
                "min-max": {
                  "childrenPayoff": [
                    "63 2184012748173/4294967296000",
                    "0"
                  ],
                  "combinedPayoff": "-63 2184012748173/4294967296000",
                  "payoff": [
                    "63 2184012748173/4294967296000",
                    "0"
                  ],
                  "optimal": true
                },
                "max-min": {
                  "childrenPayoff": [
                    "63 2184012748173/4294967296000",
                    "0"
                  ],
                  "combinedPayoff": "63 2184012748173/4294967296000",
                  "payoff": [
                    "63 2184012748173/4294967296000",
                    "0"
                  ],
                  "optimal": true
                },
                "min-min": {
                  "childrenPayoff": [
                    "63 2184012748173/4294967296000",
                    "0"
                  ],
                  "combinedPayoff": "-63 2184012748173/4294967296000",
                  "payoff": [
                    "63 2184012748173/4294967296000",
                    "0"
                  ],
                  "optimal": true
                },
                "max-max": {
                  "childrenPayoff": [
                    "63 2184012748173/4294967296000",
                    "0"
                  ],
                  "combinedPayoff": "63 2184012748173/4294967296000",
                  "payoff": [
                    "63 2184012748173/4294967296000",
                    "0"
                  ],
                  "optimal": true
                }
              },
              "childEdges": [
                {
                  "computed": {
                    "payoff": [
                      "0",
                      "0"
                    ],
                    "probability": "9/10",
                    "expected-value-maximization": {
                      "probability": "9/10",
                      "optimal": true
                    },
                    "expected-value-minimization": {
                      "probability": "9/10",
                      "optimal": true
                    },
                    "maxi-min": {
                      "probability": 0,
                      "optimal": false
                    },
                    "maxi-max": {
                      "probability": 1,
                      "optimal": true
                    },
                    "mini-min": {
                      "probability": 0,
                      "optimal": false
                    },
                    "mini-max": {
                      "probability": 1,
                      "optimal": true
                    },
                    "min-max": {
                      "probability": "9/10",
                      "optimal": true
                    },
                    "max-min": {
                      "probability": "9/10",
                      "optimal": true
                    },
                    "min-min": {
                      "probability": "9/10",
                      "optimal": true
                    },
                    "max-max": {
                      "probability": "9/10",
                      "optimal": true
                    }
                  },
                  "name": "Yes",
                  "probability": ".9",
                  "payoff": [
                    0,
                    0
                  ],
                  "childNode": {
                    "computed": {
                      "expected-value-maximization": {
                        "childrenPayoff": [
                          "70 383991157949/549755813888"
                        ],
                        "payoff": [
                          "70 38826893300853/68719476736000"
                        ],
                        "optimal": true
                      },
                      "expected-value-minimization": {
                        "childrenPayoff": [
                          "70 38826893300853/68719476736000"
                        ],
                        "payoff": [
                          "70 38826893300853/68719476736000"
                        ],
                        "optimal": true
                      },
                      "maxi-min": {
                        "childrenPayoff": [
                          "50 13772412824231/17592186044416"
                        ],
                        "payoff": [
                          "50 13772412824231/17592186044416"
                        ]
                      },
                      "maxi-max": {
                        "childrenPayoff": [
                          "83 16256694536103/17592186044416"
                        ],
                        "payoff": [
                          "83 16256694536103/17592186044416"
                        ],
                        "optimal": true
                      },
                      "mini-min": {
                        "childrenPayoff": [
                          "50 13772412824231/17592186044416"
                        ],
                        "payoff": [
                          "50 13772412824231/17592186044416"
                        ]
                      },
                      "mini-max": {
                        "childrenPayoff": [
                          "83 16256694536103/17592186044416"
                        ],
                        "payoff": [
                          "83 16256694536103/17592186044416"
                        ],
                        "optimal": true
                      },
                      "min-max": {
                        "childrenPayoff": [
                          "70 38826893300853/68719476736000",
                          "0"
                        ],
                        "combinedPayoff": "-70 38826893300853/68719476736000",
                        "payoff": [
                          "70 38826893300853/68719476736000",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-min": {
                        "childrenPayoff": [
                          "70 38826893300853/68719476736000",
                          "0"
                        ],
                        "combinedPayoff": "70 38826893300853/68719476736000",
                        "payoff": [
                          "70 38826893300853/68719476736000",
                          "0"
                        ],
                        "optimal": true
                      },
                      "min-min": {
                        "childrenPayoff": [
                          "70 38826893300853/68719476736000",
                          "0"
                        ],
                        "combinedPayoff": "-70 38826893300853/68719476736000",
                        "payoff": [
                          "70 38826893300853/68719476736000",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-max": {
                        "childrenPayoff": [
                          "70 38826893300853/68719476736000",
                          "0"
                        ],
                        "combinedPayoff": "70 38826893300853/68719476736000",
                        "payoff": [
                          "70 38826893300853/68719476736000",
                          "0"
                        ],
                        "optimal": true
                      }
                    },
                    "childEdges": [
                      {
                        "computed": {
                          "payoff": [
                            "50 13772412824231/17592186044416",
                            "0"
                          ],
                          "probability": "1",
                          "expected-value-maximization": {
                            "probability": "1",
                            "optimal": true
                          },
                          "expected-value-minimization": {
                            "probability": "1",
                            "optimal": true
                          },
                          "maxi-min": {
                            "probability": 1
                          },
                          "maxi-max": {
                            "probability": 1,
                            "optimal": true
                          },
                          "mini-min": {
                            "probability": 1
                          },
                          "mini-max": {
                            "probability": 1,
                            "optimal": true
                          },
                          "min-max": {
                            "probability": "1",
                            "optimal": true
                          },
                          "max-min": {
                            "probability": "1",
                            "optimal": true
                          },
                          "min-min": {
                            "probability": "1",
                            "optimal": true
                          },
                          "max-max": {
                            "probability": "1",
                            "optimal": true
                          }
                        },
                        "name": "Wins",
                        "probability": "#",
                        "payoff": [
                          "MainClaimA",
                          0
                        ],
                        "childNode": {
                          "computed": {
                            "expected-value-maximization": {
                              "childrenPayoff": [
                                "19 257719844392841/281474976710656"
                              ],
                              "payoff": [
                                "70 38826893300853/68719476736000"
                              ],
                              "optimal": true
                            },
                            "expected-value-minimization": {
                              "childrenPayoff": [
                                "19 214991529768801/274877906944000"
                              ],
                              "payoff": [
                                "70 38826893300853/68719476736000"
                              ],
                              "optimal": true
                            },
                            "maxi-min": {
                              "childrenPayoff": [
                                "0"
                              ],
                              "payoff": [
                                "50 13772412824231/17592186044416"
                              ]
                            },
                            "maxi-max": {
                              "childrenPayoff": [
                                "33 19874253694977/140737488355328"
                              ],
                              "payoff": [
                                "83 16256694536103/17592186044416"
                              ],
                              "optimal": true
                            },
                            "mini-min": {
                              "childrenPayoff": [
                                "0"
                              ],
                              "payoff": [
                                "50 13772412824231/17592186044416"
                              ]
                            },
                            "mini-max": {
                              "childrenPayoff": [
                                "33 19874253694977/140737488355328"
                              ],
                              "payoff": [
                                "83 16256694536103/17592186044416"
                              ],
                              "optimal": true
                            },
                            "min-max": {
                              "childrenPayoff": [
                                "19 214991529768801/274877906944000",
                                "0"
                              ],
                              "combinedPayoff": "-70 38826893300853/68719476736000",
                              "payoff": [
                                "70 38826893300853/68719476736000",
                                "0"
                              ],
                              "optimal": true
                            },
                            "max-min": {
                              "childrenPayoff": [
                                "19 214991529768801/274877906944000",
                                "0"
                              ],
                              "combinedPayoff": "70 38826893300853/68719476736000",
                              "payoff": [
                                "70 38826893300853/68719476736000",
                                "0"
                              ],
                              "optimal": true
                            },
                            "min-min": {
                              "childrenPayoff": [
                                "19 214991529768801/274877906944000",
                                "0"
                              ],
                              "combinedPayoff": "-70 38826893300853/68719476736000",
                              "payoff": [
                                "70 38826893300853/68719476736000",
                                "0"
                              ],
                              "optimal": true
                            },
                            "max-max": {
                              "childrenPayoff": [
                                "19 214991529768801/274877906944000",
                                "0"
                              ],
                              "combinedPayoff": "70 38826893300853/68719476736000",
                              "payoff": [
                                "70 38826893300853/68719476736000",
                                "0"
                              ],
                              "optimal": true
                            }
                          },
                          "childEdges": [
                            {
                              "computed": {
                                "payoff": [
                                  "20",
                                  "0"
                                ],
                                "probability": "7/10",
                                "expected-value-maximization": {
                                  "probability": "7/10",
                                  "optimal": true
                                },
                                "expected-value-minimization": {
                                  "probability": "7/10",
                                  "optimal": true
                                },
                                "maxi-min": {
                                  "probability": 0
                                },
                                "maxi-max": {
                                  "probability": 1,
                                  "optimal": true
                                },
                                "mini-min": {
                                  "probability": 0
                                },
                                "mini-max": {
                                  "probability": 1,
                                  "optimal": true
                                },
                                "min-max": {
                                  "probability": "7/10",
                                  "optimal": true
                                },
                                "max-min": {
                                  "probability": "7/10",
                                  "optimal": true
                                },
                                "min-min": {
                                  "probability": "7/10",
                                  "optimal": true
                                },
                                "max-max": {
                                  "probability": "7/10",
                                  "optimal": true
                                }
                              },
                              "name": "Wins",
                              "probability": ".7",
                              "payoff": [
                                "ClaimB",
                                0
                              ],
                              "childNode": {
                                "computed": {
                                  "expected-value-maximization": {
                                    "childrenPayoff": [
                                      "6 405292360077433/562949953421312"
                                    ],
                                    "payoff": [
                                      "26 160611742050305/281474976710656"
                                    ],
                                    "optimal": true
                                  },
                                  "expected-value-minimization": {
                                    "childrenPayoff": [
                                      "6 642446968201219/1125899906842624"
                                    ],
                                    "payoff": [
                                      "26 160611742050305/281474976710656"
                                    ],
                                    "optimal": true
                                  },
                                  "maxi-min": {
                                    "childrenPayoff": [
                                      "0"
                                    ],
                                    "payoff": [
                                      "20"
                                    ]
                                  },
                                  "maxi-max": {
                                    "childrenPayoff": [
                                      "13 79497014779907/562949953421312"
                                    ],
                                    "payoff": [
                                      "33 19874253694977/140737488355328"
                                    ],
                                    "optimal": true
                                  },
                                  "mini-min": {
                                    "childrenPayoff": [
                                      "0"
                                    ],
                                    "payoff": [
                                      "20"
                                    ]
                                  },
                                  "mini-max": {
                                    "childrenPayoff": [
                                      "13 79497014779907/562949953421312"
                                    ],
                                    "payoff": [
                                      "33 19874253694977/140737488355328"
                                    ],
                                    "optimal": true
                                  },
                                  "min-max": {
                                    "childrenPayoff": [
                                      "6 642446968201219/1125899906842624",
                                      "0"
                                    ],
                                    "combinedPayoff": "-26 160611742050305/281474976710656",
                                    "payoff": [
                                      "26 160611742050305/281474976710656",
                                      "0"
                                    ],
                                    "optimal": true
                                  },
                                  "max-min": {
                                    "childrenPayoff": [
                                      "6 642446968201219/1125899906842624",
                                      "0"
                                    ],
                                    "combinedPayoff": "26 160611742050305/281474976710656",
                                    "payoff": [
                                      "26 160611742050305/281474976710656",
                                      "0"
                                    ],
                                    "optimal": true
                                  },
                                  "min-min": {
                                    "childrenPayoff": [
                                      "6 642446968201219/1125899906842624",
                                      "0"
                                    ],
                                    "combinedPayoff": "-26 160611742050305/281474976710656",
                                    "payoff": [
                                      "26 160611742050305/281474976710656",
                                      "0"
                                    ],
                                    "optimal": true
                                  },
                                  "max-max": {
                                    "childrenPayoff": [
                                      "6 642446968201219/1125899906842624",
                                      "0"
                                    ],
                                    "combinedPayoff": "26 160611742050305/281474976710656",
                                    "payoff": [
                                      "26 160611742050305/281474976710656",
                                      "0"
                                    ],
                                    "optimal": true
                                  }
                                },
                                "childEdges": [
                                  {
                                    "computed": {
                                      "payoff": [
                                        "13 79497014779907/562949953421312",
                                        "0"
                                      ],
                                      "probability": "1/2",
                                      "expected-value-maximization": {
                                        "probability": "1/2",
                                        "optimal": true
                                      },
                                      "expected-value-minimization": {
                                        "probability": "1/2",
                                        "optimal": true
                                      },
                                      "maxi-min": {
                                        "probability": 0
                                      },
                                      "maxi-max": {
                                        "probability": 1,
                                        "optimal": true
                                      },
                                      "mini-min": {
                                        "probability": 0
                                      },
                                      "mini-max": {
                                        "probability": 1,
                                        "optimal": true
                                      },
                                      "min-max": {
                                        "probability": "1/2",
                                        "optimal": true
                                      },
                                      "max-min": {
                                        "probability": "1/2",
                                        "optimal": true
                                      },
                                      "min-min": {
                                        "probability": "1/2",
                                        "optimal": true
                                      },
                                      "max-max": {
                                        "probability": "1/2",
                                        "optimal": true
                                      }
                                    },
                                    "name": "Wins",
                                    "probability": "#",
                                    "payoff": [
                                      "ClaimC",
                                      0
                                    ],
                                    "childNode": {
                                      "computed": {
                                        "expected-value-maximization": {
                                          "aggregatedPayoff": [
                                            "83 32513389072209/35184372088832"
                                          ],
                                          "probabilityToEnter": "63/200",
                                          "payoff": [
                                            "13 79497014779907/562949953421312"
                                          ],
                                          "optimal": true
                                        },
                                        "expected-value-minimization": {
                                          "aggregatedPayoff": [
                                            "83 16256694536103/17592186044416"
                                          ],
                                          "probabilityToEnter": "63/200",
                                          "payoff": [
                                            "13 79497014779907/562949953421312"
                                          ],
                                          "optimal": true
                                        },
                                        "maxi-min": {
                                          "aggregatedPayoff": [
                                            "83 16256694536103/17592186044416"
                                          ],
                                          "probabilityToEnter": 0,
                                          "payoff": [
                                            "13 79497014779907/562949953421312"
                                          ]
                                        },
                                        "maxi-max": {
                                          "aggregatedPayoff": [
                                            "83 16256694536103/17592186044416"
                                          ],
                                          "probabilityToEnter": "1",
                                          "payoff": [
                                            "13 79497014779907/562949953421312"
                                          ],
                                          "optimal": true
                                        },
                                        "mini-min": {
                                          "aggregatedPayoff": [
                                            "83 16256694536103/17592186044416"
                                          ],
                                          "probabilityToEnter": 0,
                                          "payoff": [
                                            "13 79497014779907/562949953421312"
                                          ]
                                        },
                                        "mini-max": {
                                          "aggregatedPayoff": [
                                            "83 16256694536103/17592186044416"
                                          ],
                                          "probabilityToEnter": "1",
                                          "payoff": [
                                            "13 79497014779907/562949953421312"
                                          ],
                                          "optimal": true
                                        },
                                        "min-max": {
                                          "aggregatedPayoff": [
                                            "83 16256694536103/17592186044416",
                                            "0"
                                          ],
                                          "probabilityToEnter": "63/200",
                                          "combinedPayoff": "-13 79497014779907/562949953421312",
                                          "payoff": [
                                            "13 79497014779907/562949953421312",
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "max-min": {
                                          "aggregatedPayoff": [
                                            "83 16256694536103/17592186044416",
                                            "0"
                                          ],
                                          "probabilityToEnter": "63/200",
                                          "combinedPayoff": "13 79497014779907/562949953421312",
                                          "payoff": [
                                            "13 79497014779907/562949953421312",
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "min-min": {
                                          "aggregatedPayoff": [
                                            "83 16256694536103/17592186044416",
                                            "0"
                                          ],
                                          "probabilityToEnter": "63/200",
                                          "combinedPayoff": "-13 79497014779907/562949953421312",
                                          "payoff": [
                                            "13 79497014779907/562949953421312",
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "max-max": {
                                          "aggregatedPayoff": [
                                            "83 16256694536103/17592186044416",
                                            "0"
                                          ],
                                          "probabilityToEnter": "63/200",
                                          "combinedPayoff": "13 79497014779907/562949953421312",
                                          "payoff": [
                                            "13 79497014779907/562949953421312",
                                            "0"
                                          ],
                                          "optimal": true
                                        }
                                      },
                                      "childEdges": [],
                                      "name": "Wins A-B-C",
                                      "code": "",
                                      "expressionScope": {
                                        "MainClaimA": 43,
                                        "ClaimB": 20,
                                        "ClaimC": 14
                                      },
                                      "folded": false,
                                      "location": {
                                        "x": 811,
                                        "y": 11
                                      },
                                      "type": "terminal",
                                      "expectedValue": 83.924086096808,
                                      "p10": 67.92133642554637,
                                      "p90": 101.92133642554637,
                                      "charts": [
                                        {
                                          "nodeId": "e060c1f4-a939-1b01-44b1-537332dfa258",
                                          "x": 53,
                                          "y": -91.99999618530273,
                                          "type": "bar",
                                          "active": true
                                        },
                                        {
                                          "nodeId": "e060c1f4-a939-1b01-44b1-537332dfa258",
                                          "x": 211.9999828338623,
                                          "y": -40.0000057220459,
                                          "type": "distribution",
                                          "active": false
                                        },
                                        {
                                          "nodeId": "e060c1f4-a939-1b01-44b1-537332dfa258",
                                          "x": 52.99998617172241,
                                          "y": 2.0000057220458984,
                                          "type": "waterfall",
                                          "active": true
                                        },
                                        {
                                          "nodeId": "e060c1f4-a939-1b01-44b1-537332dfa258",
                                          "x": 64.99998664855957,
                                          "y": -50,
                                          "type": "tornado",
                                          "active": false
                                        }
                                      ]
                                    },
                                    "expectedValue": 13.14121506591619,
                                    "p10": 7.684686797894351,
                                    "p90": 17.68468679789435,
                                    "optimal": true
                                  },
                                  {
                                    "computed": {
                                      "payoff": [
                                        "0",
                                        "0"
                                      ],
                                      "probability": "1/2",
                                      "expected-value-maximization": {
                                        "probability": "1/2",
                                        "optimal": true
                                      },
                                      "expected-value-minimization": {
                                        "probability": "1/2",
                                        "optimal": true
                                      },
                                      "maxi-min": {
                                        "probability": 1
                                      },
                                      "maxi-max": {
                                        "probability": 0,
                                        "optimal": false
                                      },
                                      "mini-min": {
                                        "probability": 1
                                      },
                                      "mini-max": {
                                        "probability": 0,
                                        "optimal": false
                                      },
                                      "min-max": {
                                        "probability": "1/2",
                                        "optimal": true
                                      },
                                      "max-min": {
                                        "probability": "1/2",
                                        "optimal": true
                                      },
                                      "min-min": {
                                        "probability": "1/2",
                                        "optimal": true
                                      },
                                      "max-max": {
                                        "probability": "1/2",
                                        "optimal": true
                                      }
                                    },
                                    "name": "Looses",
                                    "probability": "#",
                                    "payoff": [
                                      0,
                                      0
                                    ],
                                    "childNode": {
                                      "computed": {
                                        "expected-value-maximization": {
                                          "aggregatedPayoff": [
                                            "70 27544825648461/35184372088832"
                                          ],
                                          "probabilityToEnter": "63/200",
                                          "payoff": [
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "expected-value-minimization": {
                                          "aggregatedPayoff": [
                                            "70 13772412824231/17592186044416"
                                          ],
                                          "probabilityToEnter": "63/200",
                                          "payoff": [
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "maxi-min": {
                                          "aggregatedPayoff": [
                                            "70 13772412824231/17592186044416"
                                          ],
                                          "probabilityToEnter": 0,
                                          "payoff": [
                                            "0"
                                          ]
                                        },
                                        "maxi-max": {
                                          "aggregatedPayoff": [
                                            "70 13772412824231/17592186044416"
                                          ],
                                          "probabilityToEnter": 0,
                                          "payoff": [
                                            "0"
                                          ]
                                        },
                                        "mini-min": {
                                          "aggregatedPayoff": [
                                            "70 13772412824231/17592186044416"
                                          ],
                                          "probabilityToEnter": 0,
                                          "payoff": [
                                            "0"
                                          ]
                                        },
                                        "mini-max": {
                                          "aggregatedPayoff": [
                                            "70 13772412824231/17592186044416"
                                          ],
                                          "probabilityToEnter": 0,
                                          "payoff": [
                                            "0"
                                          ]
                                        },
                                        "min-max": {
                                          "aggregatedPayoff": [
                                            "70 13772412824231/17592186044416",
                                            "0"
                                          ],
                                          "probabilityToEnter": "63/200",
                                          "combinedPayoff": "0",
                                          "payoff": [
                                            "0",
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "max-min": {
                                          "aggregatedPayoff": [
                                            "70 13772412824231/17592186044416",
                                            "0"
                                          ],
                                          "probabilityToEnter": "63/200",
                                          "combinedPayoff": "0",
                                          "payoff": [
                                            "0",
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "min-min": {
                                          "aggregatedPayoff": [
                                            "70 13772412824231/17592186044416",
                                            "0"
                                          ],
                                          "probabilityToEnter": "63/200",
                                          "combinedPayoff": "0",
                                          "payoff": [
                                            "0",
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "max-max": {
                                          "aggregatedPayoff": [
                                            "70 13772412824231/17592186044416",
                                            "0"
                                          ],
                                          "probabilityToEnter": "63/200",
                                          "combinedPayoff": "0",
                                          "payoff": [
                                            "0",
                                            "0"
                                          ],
                                          "optimal": true
                                        }
                                      },
                                      "childEdges": [],
                                      "name": "Wins A-B",
                                      "code": "",
                                      "expressionScope": {
                                        "MainClaimA": 43,
                                        "ClaimB": 20,
                                        "ClaimC": 14
                                      },
                                      "folded": false,
                                      "location": {
                                        "x": 811,
                                        "y": 116
                                      },
                                      "type": "terminal",
                                      "expectedValue": 70.7828710308917,
                                      "p10": 55.236649627652014,
                                      "p90": 88.23664962765201,
                                      "charts": [
                                        {
                                          "nodeId": "2880e625-a93b-7233-407f-ff0849bbfde6",
                                          "x": 59,
                                          "y": -40,
                                          "type": "bar",
                                          "active": false
                                        },
                                        {
                                          "nodeId": "2880e625-a93b-7233-407f-ff0849bbfde6",
                                          "x": 68,
                                          "y": -39,
                                          "type": "distribution",
                                          "active": false
                                        },
                                        {
                                          "nodeId": "2880e625-a93b-7233-407f-ff0849bbfde6",
                                          "x": 64.00000143051147,
                                          "y": -40,
                                          "type": "waterfall",
                                          "active": false
                                        },
                                        {
                                          "nodeId": "2880e625-a93b-7233-407f-ff0849bbfde6",
                                          "x": 64.99996948242188,
                                          "y": -50,
                                          "type": "tornado",
                                          "active": false
                                        }
                                      ]
                                    },
                                    "expectedValue": 0,
                                    "p10": 0,
                                    "p90": 0,
                                    "optimal": true
                                  }
                                ],
                                "name": "Claim \nC",
                                "code": "",
                                "expressionScope": {
                                  "MainClaimA": 43,
                                  "ClaimB": 20,
                                  "ClaimC": 14
                                },
                                "folded": false,
                                "location": {
                                  "x": 651,
                                  "y": 63.5
                                },
                                "type": "chance",
                                "expectedValue": 6.719943855780217,
                                "p10": 0,
                                "p90": 17,
                                "charts": [
                                  {
                                    "nodeId": "403493c5-b71a-86c1-1bc7-0469145c9ef3",
                                    "x": -73.99999332427979,
                                    "y": -155,
                                    "type": "bar",
                                    "active": false
                                  }
                                ]
                              },
                              "expectedValue": 20,
                              "p10": 16,
                              "p90": 24,
                              "optimal": true
                            },
                            {
                              "computed": {
                                "payoff": [
                                  "0",
                                  "0"
                                ],
                                "probability": "3/10",
                                "expected-value-maximization": {
                                  "probability": "3/10",
                                  "optimal": true
                                },
                                "expected-value-minimization": {
                                  "probability": "3/10",
                                  "optimal": true
                                },
                                "maxi-min": {
                                  "probability": 1
                                },
                                "maxi-max": {
                                  "probability": 0,
                                  "optimal": false
                                },
                                "mini-min": {
                                  "probability": 1
                                },
                                "mini-max": {
                                  "probability": 0,
                                  "optimal": false
                                },
                                "min-max": {
                                  "probability": "3/10",
                                  "optimal": true
                                },
                                "max-min": {
                                  "probability": "3/10",
                                  "optimal": true
                                },
                                "min-min": {
                                  "probability": "3/10",
                                  "optimal": true
                                },
                                "max-max": {
                                  "probability": "3/10",
                                  "optimal": true
                                }
                              },
                              "name": "Looses",
                              "probability": "#",
                              "payoff": [
                                0,
                                0
                              ],
                              "childNode": {
                                "computed": {
                                  "expected-value-maximization": {
                                    "childrenPayoff": [
                                      "4 17995434677935/562949953421312"
                                    ],
                                    "payoff": [
                                      "3 663130078141441/703687441776640"
                                    ],
                                    "optimal": true
                                  },
                                  "expected-value-minimization": {
                                    "childrenPayoff": [
                                      "3 663130078141441/703687441776640"
                                    ],
                                    "payoff": [
                                      "3 663130078141441/703687441776640"
                                    ],
                                    "optimal": true
                                  },
                                  "maxi-min": {
                                    "childrenPayoff": [
                                      "0"
                                    ],
                                    "payoff": [
                                      "0"
                                    ]
                                  },
                                  "maxi-max": {
                                    "childrenPayoff": [
                                      "13 79497014779907/562949953421312"
                                    ],
                                    "payoff": [
                                      "13 79497014779907/562949953421312"
                                    ]
                                  },
                                  "mini-min": {
                                    "childrenPayoff": [
                                      "0"
                                    ],
                                    "payoff": [
                                      "0"
                                    ]
                                  },
                                  "mini-max": {
                                    "childrenPayoff": [
                                      "13 79497014779907/562949953421312"
                                    ],
                                    "payoff": [
                                      "13 79497014779907/562949953421312"
                                    ]
                                  },
                                  "min-max": {
                                    "childrenPayoff": [
                                      "3 663130078141441/703687441776640",
                                      "0"
                                    ],
                                    "combinedPayoff": "-3 663130078141441/703687441776640",
                                    "payoff": [
                                      "3 663130078141441/703687441776640",
                                      "0"
                                    ],
                                    "optimal": true
                                  },
                                  "max-min": {
                                    "childrenPayoff": [
                                      "3 663130078141441/703687441776640",
                                      "0"
                                    ],
                                    "combinedPayoff": "3 663130078141441/703687441776640",
                                    "payoff": [
                                      "3 663130078141441/703687441776640",
                                      "0"
                                    ],
                                    "optimal": true
                                  },
                                  "min-min": {
                                    "childrenPayoff": [
                                      "3 663130078141441/703687441776640",
                                      "0"
                                    ],
                                    "combinedPayoff": "-3 663130078141441/703687441776640",
                                    "payoff": [
                                      "3 663130078141441/703687441776640",
                                      "0"
                                    ],
                                    "optimal": true
                                  },
                                  "max-max": {
                                    "childrenPayoff": [
                                      "3 663130078141441/703687441776640",
                                      "0"
                                    ],
                                    "combinedPayoff": "3 663130078141441/703687441776640",
                                    "payoff": [
                                      "3 663130078141441/703687441776640",
                                      "0"
                                    ],
                                    "optimal": true
                                  }
                                },
                                "childEdges": [
                                  {
                                    "computed": {
                                      "payoff": [
                                        "13 79497014779907/562949953421312",
                                        "0"
                                      ],
                                      "probability": "3/10",
                                      "expected-value-maximization": {
                                        "probability": "3/10",
                                        "optimal": true
                                      },
                                      "expected-value-minimization": {
                                        "probability": "3/10",
                                        "optimal": true
                                      },
                                      "maxi-min": {
                                        "probability": 0
                                      },
                                      "maxi-max": {
                                        "probability": 1
                                      },
                                      "mini-min": {
                                        "probability": 0
                                      },
                                      "mini-max": {
                                        "probability": 1
                                      },
                                      "min-max": {
                                        "probability": "3/10",
                                        "optimal": true
                                      },
                                      "max-min": {
                                        "probability": "3/10",
                                        "optimal": true
                                      },
                                      "min-min": {
                                        "probability": "3/10",
                                        "optimal": true
                                      },
                                      "max-max": {
                                        "probability": "3/10",
                                        "optimal": true
                                      }
                                    },
                                    "name": "Wins",
                                    "probability": ".3",
                                    "payoff": [
                                      "ClaimC",
                                      0
                                    ],
                                    "childNode": {
                                      "computed": {
                                        "expected-value-maximization": {
                                          "aggregatedPayoff": [
                                            "63 130053556288825/140737488355328"
                                          ],
                                          "probabilityToEnter": "81/1000",
                                          "payoff": [
                                            "13 79497014779907/562949953421312"
                                          ],
                                          "optimal": true
                                        },
                                        "expected-value-minimization": {
                                          "aggregatedPayoff": [
                                            "63 130053556288825/140737488355328"
                                          ],
                                          "probabilityToEnter": "81/1000",
                                          "payoff": [
                                            "13 79497014779907/562949953421312"
                                          ],
                                          "optimal": true
                                        },
                                        "maxi-min": {
                                          "aggregatedPayoff": [
                                            "63 130053556288825/140737488355328"
                                          ],
                                          "probabilityToEnter": 0,
                                          "payoff": [
                                            "13 79497014779907/562949953421312"
                                          ]
                                        },
                                        "maxi-max": {
                                          "aggregatedPayoff": [
                                            "63 130053556288825/140737488355328"
                                          ],
                                          "probabilityToEnter": 0,
                                          "payoff": [
                                            "13 79497014779907/562949953421312"
                                          ]
                                        },
                                        "mini-min": {
                                          "aggregatedPayoff": [
                                            "63 130053556288825/140737488355328"
                                          ],
                                          "probabilityToEnter": 0,
                                          "payoff": [
                                            "13 79497014779907/562949953421312"
                                          ]
                                        },
                                        "mini-max": {
                                          "aggregatedPayoff": [
                                            "63 130053556288825/140737488355328"
                                          ],
                                          "probabilityToEnter": 0,
                                          "payoff": [
                                            "13 79497014779907/562949953421312"
                                          ]
                                        },
                                        "min-max": {
                                          "aggregatedPayoff": [
                                            "63 130053556288825/140737488355328",
                                            "0"
                                          ],
                                          "probabilityToEnter": "81/1000",
                                          "combinedPayoff": "-13 79497014779907/562949953421312",
                                          "payoff": [
                                            "13 79497014779907/562949953421312",
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "max-min": {
                                          "aggregatedPayoff": [
                                            "63 130053556288825/140737488355328",
                                            "0"
                                          ],
                                          "probabilityToEnter": "81/1000",
                                          "combinedPayoff": "13 79497014779907/562949953421312",
                                          "payoff": [
                                            "13 79497014779907/562949953421312",
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "min-min": {
                                          "aggregatedPayoff": [
                                            "63 130053556288825/140737488355328",
                                            "0"
                                          ],
                                          "probabilityToEnter": "81/1000",
                                          "combinedPayoff": "-13 79497014779907/562949953421312",
                                          "payoff": [
                                            "13 79497014779907/562949953421312",
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "max-max": {
                                          "aggregatedPayoff": [
                                            "63 130053556288825/140737488355328",
                                            "0"
                                          ],
                                          "probabilityToEnter": "81/1000",
                                          "combinedPayoff": "13 79497014779907/562949953421312",
                                          "payoff": [
                                            "13 79497014779907/562949953421312",
                                            "0"
                                          ],
                                          "optimal": true
                                        }
                                      },
                                      "childEdges": [],
                                      "name": "Wins A-C",
                                      "code": "",
                                      "expressionScope": {
                                        "MainClaimA": 43,
                                        "ClaimB": 20,
                                        "ClaimC": 14
                                      },
                                      "folded": false,
                                      "location": {
                                        "x": 811,
                                        "y": 242
                                      },
                                      "type": "terminal",
                                      "expectedValue": 63.92408609680792,
                                      "p10": 48.921336425546365,
                                      "p90": 81.92133642554637,
                                      "charts": [
                                        {
                                          "nodeId": "a1ae636e-b86e-f9e1-0586-7e35e9522cab",
                                          "x": 60,
                                          "y": -39.000003814697266,
                                          "type": "bar",
                                          "active": false
                                        },
                                        {
                                          "nodeId": "a1ae636e-b86e-f9e1-0586-7e35e9522cab",
                                          "x": 68,
                                          "y": -40.00000190734863,
                                          "type": "distribution",
                                          "active": false
                                        },
                                        {
                                          "nodeId": "a1ae636e-b86e-f9e1-0586-7e35e9522cab",
                                          "x": 64,
                                          "y": -41.000003814697266,
                                          "type": "waterfall",
                                          "active": false
                                        },
                                        {
                                          "nodeId": "a1ae636e-b86e-f9e1-0586-7e35e9522cab",
                                          "x": 65,
                                          "y": -50.0000057220459,
                                          "type": "tornado",
                                          "active": false
                                        }
                                      ]
                                    },
                                    "expectedValue": 13.14121506591619,
                                    "p10": 7.684686797894351,
                                    "p90": 17.68468679789435,
                                    "optimal": true
                                  },
                                  {
                                    "computed": {
                                      "payoff": [
                                        "0",
                                        "0"
                                      ],
                                      "probability": "7/10",
                                      "expected-value-maximization": {
                                        "probability": "7/10",
                                        "optimal": true
                                      },
                                      "expected-value-minimization": {
                                        "probability": "7/10",
                                        "optimal": true
                                      },
                                      "maxi-min": {
                                        "probability": 1
                                      },
                                      "maxi-max": {
                                        "probability": 0
                                      },
                                      "mini-min": {
                                        "probability": 1
                                      },
                                      "mini-max": {
                                        "probability": 0
                                      },
                                      "min-max": {
                                        "probability": "7/10",
                                        "optimal": true
                                      },
                                      "max-min": {
                                        "probability": "7/10",
                                        "optimal": true
                                      },
                                      "min-min": {
                                        "probability": "7/10",
                                        "optimal": true
                                      },
                                      "max-max": {
                                        "probability": "7/10",
                                        "optimal": true
                                      }
                                    },
                                    "name": "Looses",
                                    "probability": "#",
                                    "payoff": [
                                      0,
                                      0
                                    ],
                                    "childNode": {
                                      "computed": {
                                        "expected-value-maximization": {
                                          "aggregatedPayoff": [
                                            "50 13772412824231/17592186044416"
                                          ],
                                          "probabilityToEnter": "189/1000",
                                          "payoff": [
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "expected-value-minimization": {
                                          "aggregatedPayoff": [
                                            "50 13772412824231/17592186044416"
                                          ],
                                          "probabilityToEnter": "189/1000",
                                          "payoff": [
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "maxi-min": {
                                          "aggregatedPayoff": [
                                            "50 13772412824231/17592186044416"
                                          ],
                                          "probabilityToEnter": 0,
                                          "payoff": [
                                            "0"
                                          ]
                                        },
                                        "maxi-max": {
                                          "aggregatedPayoff": [
                                            "50 13772412824231/17592186044416"
                                          ],
                                          "probabilityToEnter": 0,
                                          "payoff": [
                                            "0"
                                          ]
                                        },
                                        "mini-min": {
                                          "aggregatedPayoff": [
                                            "50 13772412824231/17592186044416"
                                          ],
                                          "probabilityToEnter": 0,
                                          "payoff": [
                                            "0"
                                          ]
                                        },
                                        "mini-max": {
                                          "aggregatedPayoff": [
                                            "50 13772412824231/17592186044416"
                                          ],
                                          "probabilityToEnter": 0,
                                          "payoff": [
                                            "0"
                                          ]
                                        },
                                        "min-max": {
                                          "aggregatedPayoff": [
                                            "50 13772412824231/17592186044416",
                                            "0"
                                          ],
                                          "probabilityToEnter": "189/1000",
                                          "combinedPayoff": "0",
                                          "payoff": [
                                            "0",
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "max-min": {
                                          "aggregatedPayoff": [
                                            "50 13772412824231/17592186044416",
                                            "0"
                                          ],
                                          "probabilityToEnter": "189/1000",
                                          "combinedPayoff": "0",
                                          "payoff": [
                                            "0",
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "min-min": {
                                          "aggregatedPayoff": [
                                            "50 13772412824231/17592186044416",
                                            "0"
                                          ],
                                          "probabilityToEnter": "189/1000",
                                          "combinedPayoff": "0",
                                          "payoff": [
                                            "0",
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "max-max": {
                                          "aggregatedPayoff": [
                                            "50 13772412824231/17592186044416",
                                            "0"
                                          ],
                                          "probabilityToEnter": "189/1000",
                                          "combinedPayoff": "0",
                                          "payoff": [
                                            "0",
                                            "0"
                                          ],
                                          "optimal": true
                                        }
                                      },
                                      "childEdges": [],
                                      "name": "Wins A",
                                      "code": "",
                                      "expressionScope": {
                                        "MainClaimA": 43,
                                        "ClaimB": 20,
                                        "ClaimC": 14
                                      },
                                      "folded": false,
                                      "location": {
                                        "x": 811,
                                        "y": 347
                                      },
                                      "type": "terminal",
                                      "expectedValue": 50.78287103089173,
                                      "p10": 36.236649627652014,
                                      "p90": 68.23664962765201,
                                      "charts": [
                                        {
                                          "nodeId": "83771ccc-ce71-6cec-f1f2-3157b5b95034",
                                          "x": 55,
                                          "y": -40.000003814697266,
                                          "type": "bar",
                                          "active": true
                                        },
                                        {
                                          "nodeId": "83771ccc-ce71-6cec-f1f2-3157b5b95034",
                                          "x": 211,
                                          "y": -40.000003814697266,
                                          "type": "distribution",
                                          "active": false
                                        },
                                        {
                                          "nodeId": "83771ccc-ce71-6cec-f1f2-3157b5b95034",
                                          "x": 366.99999237060547,
                                          "y": -40,
                                          "type": "waterfall",
                                          "active": false
                                        },
                                        {
                                          "nodeId": "83771ccc-ce71-6cec-f1f2-3157b5b95034",
                                          "x": 65,
                                          "y": -50.000003814697266,
                                          "type": "tornado",
                                          "active": false
                                        }
                                      ]
                                    },
                                    "expectedValue": 0,
                                    "p10": 0,
                                    "p90": 0,
                                    "optimal": true
                                  }
                                ],
                                "name": "Claim \nC",
                                "code": "",
                                "expressionScope": {
                                  "MainClaimA": 43,
                                  "ClaimB": 20,
                                  "ClaimC": 14
                                },
                                "folded": false,
                                "location": {
                                  "x": 651,
                                  "y": 294.5
                                },
                                "type": "chance",
                                "expectedValue": 4.03196631346813,
                                "p10": 0,
                                "p90": 15,
                                "charts": [
                                  {
                                    "nodeId": "4cda60cc-c547-e4e6-8d17-a94a168774fd",
                                    "x": -73.99999332427979,
                                    "y": -158,
                                    "type": "bar",
                                    "active": false
                                  }
                                ]
                              },
                              "expectedValue": 0,
                              "p10": 0,
                              "p90": 0,
                              "optimal": true
                            }
                          ],
                          "name": "Claim\nB",
                          "code": "",
                          "expressionScope": {
                            "MainClaimA": 43,
                            "ClaimB": 20,
                            "ClaimC": 14
                          },
                          "folded": false,
                          "location": {
                            "x": 491,
                            "y": 179
                          },
                          "type": "chance",
                          "expectedValue": 19.915604816472783,
                          "p10": 0,
                          "p90": 36,
                          "charts": [
                            {
                              "nodeId": "1e850abc-e685-4131-6c94-5669428f325d",
                              "x": -74.99999809265137,
                              "y": -238,
                              "type": "bar",
                              "active": false
                            },
                            {
                              "nodeId": "1e850abc-e685-4131-6c94-5669428f325d",
                              "x": -151.99999237060547,
                              "y": -175,
                              "type": "distribution",
                              "active": false
                            }
                          ]
                        },
                        "expectedValue": 50.78287103089173,
                        "p10": 36.236649627652014,
                        "p90": 68.23664962765201,
                        "optimal": true
                      }
                    ],
                    "name": "Main\nclaim\nA",
                    "code": "",
                    "expressionScope": {
                      "MainClaimA": 43,
                      "ClaimB": 20,
                      "ClaimC": 14
                    },
                    "folded": false,
                    "location": {
                      "x": 331,
                      "y": 179
                    },
                    "type": "chance",
                    "expectedValue": 70.69847584736453,
                    "p10": 47.236649627652014,
                    "p90": 93.23664962765201,
                    "charts": [
                      {
                        "nodeId": "7b65e73c-282e-91e3-b2b0-a351abbcb358",
                        "x": -75.00000429153442,
                        "y": -290.99998474121094,
                        "type": "distribution",
                        "active": true
                      },
                      {
                        "nodeId": "7b65e73c-282e-91e3-b2b0-a351abbcb358",
                        "x": -74.9999942779541,
                        "y": -239,
                        "type": "bar",
                        "active": false
                      }
                    ]
                  },
                  "expectedValue": 0,
                  "p10": 0,
                  "p90": 0,
                  "optimal": true
                },
                {
                  "computed": {
                    "payoff": [
                      "0",
                      "0"
                    ],
                    "probability": "1/10",
                    "expected-value-maximization": {
                      "probability": "1/10",
                      "optimal": true
                    },
                    "expected-value-minimization": {
                      "probability": "1/10",
                      "optimal": true
                    },
                    "maxi-min": {
                      "probability": 1,
                      "optimal": true
                    },
                    "maxi-max": {
                      "probability": 0,
                      "optimal": false
                    },
                    "mini-min": {
                      "probability": 1,
                      "optimal": true
                    },
                    "mini-max": {
                      "probability": 0,
                      "optimal": false
                    },
                    "min-max": {
                      "probability": "1/10",
                      "optimal": true
                    },
                    "max-min": {
                      "probability": "1/10",
                      "optimal": true
                    },
                    "min-min": {
                      "probability": "1/10",
                      "optimal": true
                    },
                    "max-max": {
                      "probability": "1/10",
                      "optimal": true
                    }
                  },
                  "name": "No",
                  "probability": "#",
                  "payoff": [
                    "0",
                    0
                  ],
                  "childNode": {
                    "computed": {
                      "expected-value-maximization": {
                        "aggregatedPayoff": [
                          "0"
                        ],
                        "probabilityToEnter": "1/10",
                        "payoff": [
                          "0"
                        ],
                        "optimal": true
                      },
                      "expected-value-minimization": {
                        "aggregatedPayoff": [
                          "0"
                        ],
                        "probabilityToEnter": "1/10",
                        "payoff": [
                          "0"
                        ],
                        "optimal": true
                      },
                      "maxi-min": {
                        "aggregatedPayoff": [
                          "0"
                        ],
                        "probabilityToEnter": "1",
                        "payoff": [
                          "0"
                        ],
                        "optimal": true
                      },
                      "maxi-max": {
                        "aggregatedPayoff": [
                          "0"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "0"
                        ]
                      },
                      "mini-min": {
                        "aggregatedPayoff": [
                          "0"
                        ],
                        "probabilityToEnter": "1",
                        "payoff": [
                          "0"
                        ],
                        "optimal": true
                      },
                      "mini-max": {
                        "aggregatedPayoff": [
                          "0"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "0"
                        ]
                      },
                      "min-max": {
                        "aggregatedPayoff": [
                          "0",
                          "0"
                        ],
                        "probabilityToEnter": "1/10",
                        "combinedPayoff": "0",
                        "payoff": [
                          "0",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-min": {
                        "aggregatedPayoff": [
                          "0",
                          "0"
                        ],
                        "probabilityToEnter": "1/10",
                        "combinedPayoff": "0",
                        "payoff": [
                          "0",
                          "0"
                        ],
                        "optimal": true
                      },
                      "min-min": {
                        "aggregatedPayoff": [
                          "0",
                          "0"
                        ],
                        "probabilityToEnter": "1/10",
                        "combinedPayoff": "0",
                        "payoff": [
                          "0",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-max": {
                        "aggregatedPayoff": [
                          "0",
                          "0"
                        ],
                        "probabilityToEnter": "1/10",
                        "combinedPayoff": "0",
                        "payoff": [
                          "0",
                          "0"
                        ],
                        "optimal": true
                      }
                    },
                    "childEdges": [],
                    "name": "All  claims \nare lost",
                    "code": "",
                    "expressionScope": {
                      "MainClaimA": 43,
                      "ClaimB": 20,
                      "ClaimC": 14
                    },
                    "folded": false,
                    "location": {
                      "x": 811,
                      "y": 473
                    },
                    "type": "terminal",
                    "expectedValue": 0,
                    "p10": 0,
                    "p90": 0,
                    "charts": [
                      {
                        "nodeId": "a69c60dd-8147-e918-3395-b14cc714cd23",
                        "x": 55,
                        "y": -41,
                        "type": "bar",
                        "active": true
                      },
                      {
                        "nodeId": "a69c60dd-8147-e918-3395-b14cc714cd23",
                        "x": 67,
                        "y": -42,
                        "type": "distribution",
                        "active": false
                      },
                      {
                        "nodeId": "a69c60dd-8147-e918-3395-b14cc714cd23",
                        "x": 64,
                        "y": -39.99999809265137,
                        "type": "waterfall",
                        "active": false
                      },
                      {
                        "nodeId": "a69c60dd-8147-e918-3395-b14cc714cd23",
                        "x": 66,
                        "y": -50.000003814697266,
                        "type": "tornado",
                        "active": false
                      }
                    ]
                  },
                  "expectedValue": 0,
                  "p10": 0,
                  "p90": 0,
                  "optimal": true
                }
              ],
              "name": "Basis for \nterminating \nthe contract",
              "code": "",
              "expressionScope": {
                "MainClaimA": 43,
                "ClaimB": 20,
                "ClaimC": 14
              },
              "folded": false,
              "location": {
                "x": 171,
                "y": 326
              },
              "type": "chance",
              "expectedValue": 64.31564359774121,
              "p10": 28,
              "p90": 93
            },
            "expectedValue": 0,
            "p10": 0,
            "p90": 0,
            "optimal": true
          }
        ],
        "name": "Range of \nfinancial gain",
        "code": "",
        "expressionScope": {
          "MainClaimA": 43,
          "ClaimB": 20,
          "ClaimC": 14
        },
        "folded": false,
        "location": {
          "x": 11,
          "y": 326
        },
        "type": "decision",
        "expectedValue": 64.31564359774119,
        "p10": 28,
        "p90": 93,
        "charts": [
          {
            "nodeId": "6ca41f5b-5636-679e-6b55-d5d8998dae0a",
            "x": -74.99999690055847,
            "y": -136.00001525878906,
            "type": "distribution",
            "active": true
          },
          {
            "nodeId": "6ca41f5b-5636-679e-6b55-d5d8998dae0a",
            "x": -72.99999370045407,
            "y": 47.99996566772461,
            "type": "tornado",
            "active": true
          },
          {
            "nodeId": "6ca41f5b-5636-679e-6b55-d5d8998dae0a",
            "x": -81.9999942779541,
            "y": -213,
            "type": "bar",
            "active": false
          }
        ]
      }
    ],
    "texts": [],
    "payoffNames": [],
    "defaultCriterion1Weight": 1,
    "weightLowerBound": 0,
    "weightUpperBound": "Infinity"
  },
  "sensitivityAnalysis": {},
  "definitionsDialog": {
    "valueList": [
      {
        "name": "MainClaimA",
        "value": "43",
        "low": 36,
        "high": 68,
        "distribution": "triangular",
        "error": ""
      },
      {
        "name": "ClaimB",
        "value": "20",
        "low": 16,
        "high": 24,
        "distribution": "triangular",
        "error": ""
      },
      {
        "name": "ClaimC",
        "value": "14",
        "low": 8,
        "high": 18,
        "distribution": "triangular",
        "error": ""
      },
      {
        "name": null,
        "value": "",
        "low": null,
        "high": null,
        "distribution": "constant",
        "error": ""
      },
      {
        "name": null,
        "value": "",
        "low": null,
        "high": null,
        "distribution": "constant",
        "error": ""
      }
    ]
  }
}