module.exports={
  "name": "silver-decisions",
  "version": "1.1.0",
  "description": "Software for creating and analyzing decision trees.",
  "main": "index.js",
  "scripts": {
    "test": "echo \"Error: no test specified\" && exit 1",
    "postinstall": "patch-package"
  },
  "repository": {
    "type": "git",
    "url": "git+https://github.com/SilverDecisions/SilverDecisions.git"
  },
  "author": "Michał Wasiluk, Bogumił Kamiński, Przemysław Szufel",
  "license": "LGPL-3.0",
  "bugs": {
    "url": "https://github.com/SilverDecisions/SilverDecisions/issues"
  },
  "homepage": "https://github.com/SilverDecisions/SilverDecisions#readme",
  "browserify": {
    "transform": [
      [
        "babelify",
        {
          "presets": [
            "@babel/preset-env"
          ],
          "plugins": [
            [
              "transform-class-properties",
              {
                "spec": true
              }
            ],
            "transform-object-assign",
            "transform-es2015-spread",
            "@babel/plugin-proposal-object-rest-spread",
            [
              "babel-plugin-transform-builtin-extend",
              {
                "globals": [
                  "Error"
                ]
              }
            ]
          ]
        }
      ]
    ]
  },
  "devDependencies": {
    "@babel/core": "^7.1.6",
    "@babel/plugin-proposal-object-rest-spread": "^7.0.0",
    "@babel/preset-env": "^7.1.6",
    "babel-plugin-transform-builtin-extend": "^1.1.2",
    "babel-plugin-transform-class-properties": "^6.24.1",
    "babel-plugin-transform-es2015-spread": "^6.22.0",
    "babel-plugin-transform-object-assign": "^6.22.0",
    "babelify": "^10.0.0",
    "browser-sync": "^2.26.3",
    "browserify": "^16.2.3",
    "browserify-resolutions": "^1.1.0",
    "browserify-shim": "^3.8.14",
    "chalk": "^2.4.1",
    "del": "^3.0.0",
    "es6-set": "^0.1.5",
    "gulp": "^4.0.0",
    "gulp-clean-css": "^3.10.0",
    "gulp-concat": "^2.6.1",
    "gulp-filelist": "^1.1.1",
    "gulp-html2js": "^0.4.2",
    "gulp-load-plugins": "^1.5.0",
    "gulp-plumber": "^1.2.0",
    "gulp-rename": "^1.4.0",
    "gulp-replace": "^1.0.0",
    "gulp-sass": "^4.0.2",
    "gulp-sourcemaps": "^2.6.4",
    "gulp-strip-debug": "^3.0.0",
    "gulp-template": "^5.0.0",
    "gulp-uglify": "^3.0.1",
    "gulp-util": "^3.0.8",
    "gulp4-run-sequence": "^1.0.1",
    "jasmine": "^3.3.0",
    "jasmine-jquery": "^2.1.1",
    "karma": "^3.1.1",
    "karma-browserify": "^5.3.0",
    "karma-chrome-launcher": "^2.2.0",
    "karma-intl-shim": "^1.0.3",
    "karma-jasmine": "^2.0.0",
    "merge-stream": "^1.0.1",
    "odc-d3": "^1.1.7",
    "patch-package": "^6.2.2",
    "run-sequence": "^2.2.1",
    "stringify": "^5.2.0",
    "svg2pdf.js": "^1.3.0",
    "vinyl-buffer": "^1.0.1",
    "vinyl-source-stream": "^2.0.0",
    "yargs": "^12.0.2"
  },
  "dependencies": {
    "autosize": "^4.0.2",
    "blueimp-canvas-to-blob": "^3.14.0",
    "d3-array": "^1.2.4",
    "d3-dispatch": "^1.0.5",
    "d3-drag": "^1.2.3",
    "d3-scale": "^1.0.7",
    "d3-selection": "^1.3.2",
    "d3-time-format": "^2.1.3",
    "file-saver": "^2.0.0",
    "hammerjs": "^2.0.8",
    "i18next": "^12.0.0",
    "jquery": "^3.3.1",
    "jquery-ui": "^1.12.1",
    "pivottable": "^2.23.0",
    "sd-computations": "0.3.6",
    "sd-model": "0.2.7",
    "sd-tree-designer": "0.1.12",
    "sd-utils": "0.1.11"
  }
}
