module.exports={
    "toolbar": {
        "newDiagram": "Nowy diagram",
        "openDiagram": "Otwórz diagram",
        "saveDiagram": "Zapisz diagram",
        "export":{
            "label": "Eksport",
            "png": "Png",
            "svg": "Svg",
            "pdf": "Pdf"
        },
        "layout":{
            "label": "Układ",
            "manual": "Ręczny",
            "tree": "Drzewo",
            "cluster": "Zgrupowany"
        },
        "viewMode": {
            "label": "Widok",
            "options": {
                "criterion1":"Kryterium 1",
                "criterion2":"Kryterium 2",
                "twoCriteria":"Dwa kryteria"
            }
        },
        "objectiveRule":{
            "label": "Reguła",
            "options": {
                "expected-value-maximization":"maksimum",
                "expected-value-minimization":"minimum",
                "maxi-min":"maxi-min",
                "maxi-max":"maxi-max",
                "mini-min":"mini-min",
                "mini-max":"mini-max",
                "min-max":"min-max",
                "max-min":"max-min",
                "min-min": "min-min",
                "max-max": "max-max"
            }
        },
        "undo": "Cofnij",
        "redo": "Ponów",
        "settings": "Ustawienia",
        "about": "Informacje",
        "sensitivityAnalysis": "Analiza wrażliwości",
        "recompute": "Przelicz"
    },
    "node":{
        "name": "Etykieta"
    },
    "edge":{
        "name": "Etykieta",
        "payoff": "Wypłata",
        "probability": "Prawdopodobieństwo"
    },
    "text":{
        "value": "Tekst"
    },
    "leagueTableDialog":{
        "title": "Tabela ligowa",
        "buttons": {
            "downloadCsv": "Pobierz CSV"
        }
    },
    "leagueTable": {
        "headers":{
            "policyNo": "Nr polityki",
            "policy": "Polityka",
            "comment": "Komentarz"
        },
        "comment":{
            "dominatedBy": "zdominowana (przez #{{policy}})",
            "extendedDominatedBy": "zdominowana rozszerzenie (przez #{{policy1}} i #{{policy2}})",
            "incratio": "inkrementalny iloraz={{incratio}}"
        },
        "plot":{
            "groups":{
                "highlighted": "Rekomendowany (dla pewnej wartości WTP w zakresie)",
                "highlighted-default": "Rekomendowany (dla domyślnej wartości WTP)",
                "extended-dominated" : "Zdominowana rozszerzenie",
                "dominated": "Zdominowana",
                "default": "Inne"
            },
            "tooltip":{
                "gradientArrow1": "Kierunek optymalizacji {{name}}",
                "gradientArrow2": "Kierunek optymalizacji {{name}}",
                "dominatedRegion": "Obszar zdominowany"
            },
            "legend":{
                "dominatedRegion": "Szary region oznacza obszar zdominowany",
                "gradientArrows": "Strzałki wskazują kierunek poprawy"
            }
        }
    },
    "sensitivityAnalysisDialog":{
        "title": "Analiza wrażliwości",
        "buttons": {
            "runJob": "Uruchom",
            "stopJob": "Zatrzymaj",
            "terminateJob": "Zakończ",
            "resumeJob": "Wznów",
            "downloadCsv": "Pobierz CSV",
            "back": "Wstecz",
            "clear": "Wyczyść"
        }
    },
    "jobParametersBuilder": {
        "buttons": {
            "removeParameterValue": "Usuń",
            "addParameterValue": "Dodaj"
        }
    },
    "jobResultTable":{
        "tooltip": {
            "multiplePoliciesInCell": "{{number}} polityk"
        },
        "pivot": {
            "aggregators":{
                "maximum": "Maksimum",
                "minimum": "Minimum"
            },
            "renderers":{
                "heatmap": "Mapa cieplna"
            }
        },
        "policyPreview": "podgląd polityki"
    },
    "job":{
        "sensitivity-analysis":{
            "name": "Analiza wrażliwości N-czynnikowa",
            "param":{
                "ruleName": "Nazwa reguły",
                "extendedPolicyDescription": {
                    "label": "Rozszerzony opis polityki"
                },
                "variables": {
                    "label": "Zmienne",
                    "name": {
                        "label": "Nazwa"
                    },
                    "min": {
                        "label": "Minimum"
                    },
                    "max": {
                        "label": "Maksimum"
                    },
                    "length": {
                        "label": "Długość"
                    },
                    "step": {
                        "label": "Krok"
                    }
                }
            },
            "errors":{
                "computations": "Błąd w obliczeniach analizy wrażliwości dla następujących parametrów:"
            },
            "warnings": {
                "largeScenariosNumber": "Liczba zdefiniowanych scenariuszy większa niż {{numberFormatted}}. Wyliczenie analizy wrażliwości może się nie powieść lub być bardzo powolne.",
                "largeParametersNumber": "Liczba parametrów większa niż {{number}}. Wyświetlenie analizy wrażliwości może się nie powieść lub być bardzo powolne."
            }
        },
        "tornado-diagram":{
            "name": "Diagram tornado",
            "param":{
                "ruleName": "Nazwa reguły",
                "extendedPolicyDescription": {
                    "label": "Rozszerzony opis polityki"
                },
                "variables": {
                    "label": "Zmienne",
                    "name": {
                        "label": "Nazwa"
                    },
                    "min": {
                        "label": "Minimum"
                    },
                    "max": {
                        "label": "Maximum"
                    },
                    "length": {
                        "label": "Długość"
                    },
                    "step": {
                        "label": "Krok"
                    },
                    "defaultValue": {
                        "label": "Wartość domyślna"
                    }
                }
            },
            "errors":{
                "computations": "Błąd w obliczeniach analizy wrażliwości dla następujących parametrów:"
            },
            "warnings": {
                "largeScenariosNumber": "Liczba zdefiniowanych scenariuszy większa niż {{numberFormatted}}. Wyliczenie analizy wrażliwości może się nie powieść lub być bardzo powolne.",
                "largeParametersNumber": "Liczba parametrów większa niż {{number}}. Wyświetlenie analizy wrażliwości może się nie powieść lub być bardzo powolne."
            },
            "plot":{
                "legend":{
                    "low": "Zmniejszenie",
                    "high": "Zwiększenie"
                },
                "xAxisTitle": "Wypłata"
            }
        },
        "probabilistic-sensitivity-analysis":{
            "name": "Probabilistyczna analiza wrażliwości",
            "param":{
                "ruleName": "Nazwa reguły",
                "numberOfRuns": {
                    "label": "Liczba powtórzeń"
                },
                "extendedPolicyDescription": {
                    "label": "Rozszerzony opis reguły"
                },
                "variables": {
                    "label": "Zmienne",
                    "name": {
                        "label": "Nazwa"
                    },
                    "formula": {
                        "label": "Formuła",
                        "help": "Wybierz wzór formuły z menu lub wpisz własny kod"
                    }
                }
            },
            "errors":{
                "computations": "Błąd w obliczeniu analizy wrażliwości dla następujących parametrów:",
                "param-computation": "Błąd w wyliczaniu wartości parametrów:"
            },
            "warnings": {
                "largeScenariosNumber": "Liczba zdefiniowanych scenariuszy większa niż {{numberFormatted}}. Wyliczenie analizy wrażliwości może się nie powieść lub być bardzo powolne."
            }
        },
        "league-table":{
            "name": "Tabela ligowa"
        },
        "spider-plot": {
            "name": "Wykres pajęczynowy",
            "param": {
                "ruleName": "Nazwa reguły",
                "extendedPolicyDescription": {
                    "label": "Rozszerzony opis polityki"
                },
                "percentageChangeRange": {
                    "label": "+/- zmiana procentowa do analizy"
                },
                "length": {
                    "label": "Liczba wartości do przetestowa",
                    "help": "Liczba wartości do przetestowa (po jednej stronie zakresu)"
                },
                "variables": {
                    "label": "Zmienne",
                    "name": {
                        "label": "Nazwa"
                    },
                    "defaultValue": {
                        "label": "Wartość domyślna"
                    }
                }
            },
            "errors":{
                "computations": "Błąd w obliczeniu analizy wrażliwości dla następujących parametrów:"
            },
            "warnings": {
                "largeScenariosNumber": "Liczba zdefiniowanych scenariuszy większa niż {{numberFormatted}}. Wyliczenie analizy wrażliwości może się nie powieść lub być bardzo powolne."
            },
            "plot": {
                "legend": {},
                "xAxisTitle": "Zmiana procentowa",
                "yAxisTitle": "Wypłata"
            }
        },
        "errors":{
            "generic": "Błąd w obliczeniach analizy wrażliwości: {{message}}",
            "params": "Błędne parametry w {{jobName}}"
        }
    },
    "settingsDialog":{
        "title": "Ustawienia",
        "general":{
            "title": "Ogólne",
            "fontSize": "Rozmiar czcionki",
            "fontFamily": "Rodzina czcionek",
            "fontWeight": "Waga czcionki",
            "fontStyle": "Styl czcionki",
            "numberFormatLocale": "Kod lokalizacji językowej liczb"
        },
        "payoff1":{
            "title": "Format wypłaty 1",
            "currency": "Waluta",
            "currencyDisplay": "Wyświetlanie waluty",
            "style": "Styl",
            "minimumFractionDigits": "Minimalna liczba miejsc po przecinku",
            "maximumFractionDigits": "Maksymalna liczba miejsc po przecinku",
            "useGrouping": "Separatory grupowania"
        },
        "payoff2":{
            "title": "Format wypłaty 2",
            "currency": "Waluta",
            "currencyDisplay": "Wyświetlanie waluty",
            "style": "Styl",
            "minimumFractionDigits": "Minimalna liczba miejsc po przecinku",
            "maximumFractionDigits": "Maksymalna liczba miejsc po przecinku",
            "useGrouping": "Separatory grupowania"
        },
        "probability":{
            "title": "Format prawdopodobieństwa",
            "style": "Styl",
            "minimumFractionDigits": "Minimalna liczba miejsc po przecinku",
            "maximumFractionDigits": "Maksymalna liczba miejsc po przecinku",
            "fontSize": "Rozmiar czcionki",
            "color": "Kolor"
        },
        "node":{
            "title": "Węzeł",
            "strokeWidth": "Szerokość krawędzi",
            "optimal":{
                "title": "Optymalny",
                "stroke": "Kolor",
                "strokeWidth": "Szerokość krawędzi"
            },
            "label": {
                "title": "Etykieta",
                "fontSize": "Rozmiar czcionki etykiety",
                "color": "Kolor etykiety"
            },
            "payoff": {
                "title": "Wypłata",
                "fontSize": "Rozmiar czcionki",
                "color": "Kolor",
                "negativeColor": "Kolor wartości ujemnej"
            },
            "decision": {
                "title": "Węzeł Decyzyjny",
                "fill": "Kolor wypełnienia",
                "stroke": "Kolor krawędzi",
                "selected": {
                    "fill": "Kolor wypełnienia po zaznaczeniu"
                }
            },
            "chance": {
                "title": "Węzeł Losowy",
                "fill": "Kolor wypełnienia",
                "stroke": "Kolor krawędzi",
                "selected": {
                    "fill": "Kolor wypełnienia po zaznaczeniu"
                }
            },
            "terminal":{
                "title": "Węzeł Końcowy",
                "fill": "Kolor wypełnienia",
                "stroke": "Kolor krawędzi",
                "selected": {
                    "fill": "Kolor wypełnienia po zaznaczeniu"
                },
                "payoff": {
                    "title": "Wypłata",
                    "fontSize": "Rozmiar czcionki",
                    "color": "Kolor",
                    "negativeColor": "Kolor wartości ujemnej"
                }
            }
        },
        "edge":{
            "title": "Krawędź",
            "stroke": "Kolor",
            "strokeWidth": "Szerokość krawędzi",
            "optimal":{
                "title": "Optymalna",
                "stroke": "Kolor",
                "strokeWidth": "Szerokość krawędzi"
            },
            "selected":{
                "title": "Zaznaczona",
                "stroke": "Kolor",
                "strokeWidth": "Szerokość krawędzi"
            },
            "label": {
                "title": "Etykieta",
                "fontSize": "Rozmiar czcionki",
                "color": "Kolor"
            },
            "payoff":{
                "title": "Wypłata",
                "fontSize": "Rozmiar czcionki",
                "color": "Kolor",
                "negativeColor": "Kolor wartości ujemnej"
            }
        },
        "diagramTitle":{
            "title": "Tytuł diagramu",
            "fontSize": "Rozmiar czcionki",
            "fontWeight": "Waga czcionki",
            "fontStyle": "Styl czcionki",
            "color": "Kolor",
            "margin":{
                "title": "Margines",
                "top": "Górny",
                "bottom": "Dolny"
            },
            "description":{
                "title": "Podtytuł (opis diagramu)",
                "show": "Wyświetl",
                "fontSize": "Rozmiar czcionki",
                "fontWeight": "Waga czcionki",
                "fontStyle": "Styl czcionki",
                "color": "Kolor",
                "marginTop": "Margines górny"
            }
        },
        "leagueTable": {
            "title": "Tabela ligowa",
            "plot": {
                "title": "Wykres",
                "maxWidth": "Maksymalna szerokość",
                "highlightedColor": "Kolor polityki rekomendowanej (dla pewnego WTP w zakresie)",
                "highlightedDefaultColor": "Kolor polityki rekomendowanej (dla domyślnego WTP)",
                "extendedDominatedColor": "Kolog polityki zdominowanej rozszerzenie",
                "dominatedColor": "Kolor polityki zdominowanej",
                "defaultColor": "Kolor innych polityk"
            }
        },
        "other":{
            "title": "Inne",
            "disableAnimations": "Wyłącz animacje",
            "forceFullEdgeRedraw": "Wymuś pełne przerysowywanie krawędzi",
            "hideLabels": "Ukryj etykiety",
            "hidePayoffs": "Ukryj wypłaty",
            "hideProbabilities": "Ukryj prawdopodobieństwa",
            "raw": "Surowy"
        }
    },
    "aboutDialog":{
        "title": "Informacje"
    },
    "help":{
        "header": "Pomoc",
        "mouse": {
            "header":"Akcje myszy:",
            "list":{
                "1": "lewy klawisz myszy: wybór węzła/krawędzi",
                "2": "prawy klawisz myszy: menu kontekstowe (dodawanie/manipulowanie węzłami)",
                "3": "podwójne kliknięcie lewym przyciskiem myszy: menu kontekstowe"
            }
        },
        "keyboard": {
            "header":"Skróty klawiszowe:",
            "list":{
                "1": "Del: usuwanie wybranych węzłów",
                "2": "Ctrl-C/X: kopiowanie/wycinanie wybranych węzłów",
                "3": "Ctrl-V: wklej skopiowane węzły jako poddrzewo wybranego węzła",
                "4": "Ctrl-Y/Z: cofnij/ponów",
                "5": "Ctrl-Alt-D/C/T: dodaj nowy węzeł Decyzyjny/Losowy/Końcowy jako dziecko <strong>wybranego węzła</strong>",
                "6": "Ctrl-Alt-D/C: Wstrzyknij nowy węzeł Decyzyjny/Losowy do <strong>wybranej krawędzi</strong>"
            }
        },
        "docs": "Dokumentacja SilverDecisions jest dostępna <a href=\"https://github.com/SilverDecisions/SilverDecisions/wiki/Documentation\" target=\"_blank\">tutaj</a>"
    },
    "definitionsDialog":{
        "title": "Definicje zmiennych",
        "scope": {
            "global": "zasięg globalny",
            "node": "zasięg wybranego węzła i poddrzewa"
        },
        "buttons":{
            "recalculate": "Przelicz"
        },
        "evaluatedVariables": "Wartości zmiennych"
    },
    "sidebarDefinitions":{
        "scope":{
            "label": "Zasięg zmiennej:",
            "global": "globalny",
            "node": "wybrany węzeł i poddrzewo"
        },
        "header": "Zmienne",
        "code": "Kod",
        "buttons":{
            "openDialog" : "Otwórz okno dialogowe",
            "recalculate": "Przelicz"
        },
        "evaluatedVariables": "Wartości zmiennych"
    },
    "multipleCriteria":{
        "header": "Wiele kryteriów",
        "defaultMaximizedCriterionName": "Efekt",
        "defaultMinimizedCriterionName": "Koszt",
        "nameOfCriterion1": "Nazwa kryterium 1",
        "nameOfCriterion2": "Nazwa kryterium 2",
        "defaultCriterion1Weight": "Domyślna waga kryerium 1",
        "weightLowerBound": "Dolna granica wagi",
        "weightUpperBound": "Górna granica wagi",
        "buttons":{
            "showLeagueTable" : "Pokaż tabelę ligową",
            "flip": "Zamień"
        }
    },
    "layoutOptions":{
        "header": "Układ",
        "marginHorizontal": "Margines w poziomie",
        "marginVertical": "Margines w pionie",
        "nodeSize": "Rozmiar węzła",
        "edgeSlantWidthMax": "Skos krawędzi (maksymalny)",
        "gridWidth": "Szerokość",
        "gridHeight": "Wysokość",
        "scale": "Skala"
    },
    "diagramDetails":{
        "header": "Szczegóły",
        "title" : "Tytuł",
        "description" : "Opis"
    },
    "objectProperties":{
        "header":{
            "node":{
                "decision":"Węzeł Decyzyjny",
                "chance":"Węzeł Losowy",
                "terminal":"Węzeł Końcowy"
            },
            "edge": "Krawędź",
            "text": "Pływający tekst"
        },
        "childrenProperties":{
            "node":{
                "header": "Połączenia",
                "child": {
                    "header": "Krawędź #{{number}}"
                }
            }
        }
    },
    "confirm":{
        "newDiagram": "Czy jesteś pewien, że chcesz porzucić obecny diagram? Wszystkie niezapisane zmiany zostaną utracone.",
        "openDiagram": "Czy jesteś pewien? Wszystkie niezapisane zmiany zostaną utracone.",
        "beforeunload": "Czy jesteś pewien, że chcesz zamknąć SilverDecisions? Wszystkie niezapisane zmiany zostaną utracone."
    },
    "error":{
        "jsonParse": "Błąd przetwarzania danych z pliku!",
        "fileApiNotSupported":"Funckja 'file API' nie jest wspierana w tej przeglądarce internetowej.",
        "inputFilesProperty":"Twoje przeglądarka nie wspiera opcji `pliki` przy otwieraniu pliku.",
        "notSilverDecisionsFile":"Plik niezgodny z formatem SilverDecisions!",
        "incorrectVersionFormat": "Niepoprawny format ciągu z numerem wersji!",
        "fileVersionNewerThanApplicationVersion": "Wersja pliku jest nowasza niż wersja aplikacji. Niektóre funkcjonalności mogą być niedostępne.",
        "objectiveComputationFailure": "Błąd podczas wyliczania celu!",
        "diagramDrawingFailure": "Błąd podczas rysowania diagramu!",
        "malformedData":"Błąd odczytu danych drzewa!",
        "pngExportNotSupported": "Eksport do obrazu PNG nie jest wspierany w Twojej przeglądarce.",
        "pngExportNotSupportedIE": "Eksport do obrazu PNG nie jest wspierany w przeglądarce Internet Explorer.",
        "svgExportNotSupported": "Eksport do obrazu SVG nie jest wspierany w Twojej przeglądarce.",
        "pdfExportNotSupported": "Eksport do PDF nie jest wspierany w Twojej przeglądarce.",
        "incorrectPayoffNumberFormatOptions": "Niewłaściwe ustawienia dla formatu wypłaty.",
        "incorrectProbabilityNumberFormatOptions": "Niewłaściwe ustawienia dla formatu prawdopodobieństw.",
        "incorrectNumberFormatOptions": "Niewłaściwe ustawiono formatowanie liczb. Przyjęto ustawienia domyślne.",
        "jsPDFisNotIncluded": "Nie włączono jsPDF!",
        "serverSideExportRequestFailure": "Nieudane zapytanie do serwera exportu!"
    }
}
