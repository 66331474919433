module.exports={
    "SilverDecisions": "1.1.0",
    "buildTimestamp": 1701170596893,
    "savetime": "2023-11-28T11:50:52.907Z",
    "lng": "en",
    "viewMode": "criterion1",
    "rule": "expected-value-maximization",
    "title": "",
    "description": "",
    "format": {
        "locales": "en",
        "payoff1": {
            "style": "currency",
            "currency": "USD",
            "currencyDisplay": "symbol",
            "minimumFractionDigits": 0,
            "maximumFractionDigits": 0,
            "useGrouping": true
        },
        "payoff2": {
            "style": "percent",
            "currency": "USD",
            "currencyDisplay": "symbol",
            "minimumFractionDigits": 0,
            "maximumFractionDigits": 0,
            "useGrouping": true
        },
        "probability": {
            "style": "percent",
            "minimumFractionDigits": 0,
            "maximumFractionDigits": 0,
            "useGrouping": true
        }
    },
    "treeDesigner": {
        "margin": {
            "left": 500,
            "right": 500,
            "top": 250,
            "bottom": 100
        },
        "scale": 1,
        "lng": "en",
        "layout": {
            "type": "cluster",
            "nodeSize": 22,
            "limitNodePositioning": true,
            "limitTextPositioning": true,
            "gridHeight": 95,
            "gridWidth": 160,
            "edgeSlantWidthMax": 20
        },
        "fontFamily": "sans-serif",
        "fontSize": "12px",
        "fontWeight": "normal",
        "fontStyle": "normal",
        "node": {
            "strokeWidth": "2px",
            "optimal": {
                "stroke": "#006f00",
                "strokeWidth": "2px"
            },
            "label": {
                "fontSize": "1em",
                "color": "black"
            },
            "payoff": {
                "fontSize": "1em",
                "color": "black",
                "negativeColor": "#b60000"
            },
            "decision": {
                "fill": "#ff7777",
                "stroke": "#660000",
                "selected": {
                    "fill": "#aa3333"
                }
            },
            "chance": {
                "fill": "#ffff44",
                "stroke": "#666600",
                "selected": {
                    "fill": "#aaaa00"
                }
            },
            "terminal": {
                "fill": "#44ff44",
                "stroke": "black",
                "selected": {
                    "fill": "#00aa00"
                },
                "payoff": {
                    "fontSize": "1em",
                    "color": "black",
                    "negativeColor": "#b60000"
                }
            }
        },
        "edge": {
            "stroke": "#424242",
            "strokeWidth": "1.5",
            "optimal": {
                "stroke": "#006f00",
                "strokeWidth": "2.4"
            },
            "selected": {
                "stroke": "#045ad1",
                "strokeWidth": "3.5"
            },
            "label": {
                "fontSize": "1em",
                "color": "back"
            },
            "payoff": {
                "fontSize": "1em",
                "color": "black",
                "negativeColor": "#b60000"
            }
        },
        "probability": {
            "fontSize": "1em",
            "color": "#0000d7"
        },
        "title": {
            "fontSize": "16px",
            "fontWeight": "bold",
            "fontStyle": "normal",
            "color": "#000000",
            "margin": {
                "top": 15,
                "bottom": 10
            }
        },
        "description": {
            "show": true,
            "fontSize": "12px",
            "fontWeight": "bold",
            "fontStyle": "normal",
            "color": "#000000",
            "margin": {
                "top": 5,
                "bottom": 10
            }
        },
        "readOnly": false,
        "disableAnimations": false,
        "forceFullEdgeRedraw": false,
        "hideLabels": false,
        "hidePayoffs": false,
        "hideProbabilities": false,
        "hideCharts": false,
        "hideTextboxes": false,
        "hideMilestones": false,
        "raw": false,
        "payoffNames": [null, null],
        "maxPayoffsToDisplay": 1,
        "milestoneNames": ["", "", "", "", "", "", "", "", "", ""]
    },
    "data": {
        "code": "",
        "expressionScope": {},
        "trees": [],
        "texts": [],
        "payoffNames": [],
        "defaultCriterion1Weight": 1,
        "weightLowerBound": 0,
        "weightUpperBound": "Infinity"
    },
    "sensitivityAnalysis": {},
    "definitionsDialog": {
        "valueList": [
            {
                "name": null,
                "value": "",
                "low": null,
                "high": null,
                "distribution": "constant",
                "error": ""
            },
            {
                "name": null,
                "value": "",
                "low": null,
                "high": null,
                "distribution": "constant",
                "error": ""
            },
            {
                "name": null,
                "value": "",
                "low": null,
                "high": null,
                "distribution": "constant",
                "error": ""
            },
            {
                "name": null,
                "value": "",
                "low": null,
                "high": null,
                "distribution": "constant",
                "error": ""
            },
            {
                "name": null,
                "value": "",
                "low": null,
                "high": null,
                "distribution": "constant",
                "error": ""
            }
        ]
    }
}
