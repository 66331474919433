import { i18n } from './i18n/i18n';

export class FileLoader {
    static openFile(callback) {
        var input = document.getElementById('sd-file-input');
        input.onchange = loadFile;

        input.click();

        function loadFile() {
            var file, fr;

            if (typeof window.FileReader !== 'function') {
                alert(i18n.t('error.fileApiNotSupported'));
                return;
            }
            input = document.getElementById('sd-file-input');
            if (!input.files) {
                alert(i18n.t('error.inputFilesProperty'));
                return;
            }

            if (!input.files[0]) {
                return;
            }

            file = input.files[0]; 
            const filename = removeFileExtension(file.name);
            fr = new FileReader();
            fr.onload = function(e) {
                callback({ filename, content: e.target.result });
                input.value = null;
            }
            fr.readAsText(file);
            
            function removeFileExtension(filename) {
                return filename.replace(/\.[^/.]+$/, "");
            }

        }
    }
}
