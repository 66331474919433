import variables_and_distributions_tutorial_1 from './tutorial-trees/variables-and-distributions-tutorial-trees/variables_and_distributions_1.json';
import variables_and_distributions_tutorial_2 from './tutorial-trees/variables-and-distributions-tutorial-trees/variables_and_distributions_2.json';
import variables_and_distributions_tutorial_3 from './tutorial-trees/variables-and-distributions-tutorial-trees/variables_and_distributions_3.json';
import variables_and_distributions_tutorial_4 from './tutorial-trees/variables-and-distributions-tutorial-trees/variables_and_distributions_4.json';
import variables_and_distributions_tutorial_5 from './tutorial-trees/variables-and-distributions-tutorial-trees/variables_and_distributions_5.json';
import variables_and_distributions_tutorial_6 from './tutorial-trees/variables-and-distributions-tutorial-trees/variables_and_distributions_6.json';
import variables_and_distributions_tutorial_7 from './tutorial-trees/variables-and-distributions-tutorial-trees/variables_and_distributions_7.json';
import variables_and_distributions_tutorial_8 from './tutorial-trees/variables-and-distributions-tutorial-trees/variables_and_distributions_8.json';
import variables_and_distributions_tutorial_9 from './tutorial-trees/variables-and-distributions-tutorial-trees/variables_and_distributions_9.json';
import variables_and_distributions_tutorial_10 from './tutorial-trees/variables-and-distributions-tutorial-trees/variables_and_distributions_10.json';
import variables_and_distributions_tutorial_11 from './tutorial-trees/variables-and-distributions-tutorial-trees/variables_and_distributions_11.json';
import variables_and_distributions_tutorial_12 from './tutorial-trees/variables-and-distributions-tutorial-trees/variables_and_distributions_12.json';
import variables_and_distributions_tutorial_13 from './tutorial-trees/variables-and-distributions-tutorial-trees/variables_and_distributions_13.json';
import variables_and_distributions_tutorial_14 from './tutorial-trees/variables-and-distributions-tutorial-trees/variables_and_distributions_14.json';
import variables_and_distributions_tutorial_15 from './tutorial-trees/variables-and-distributions-tutorial-trees/variables_and_distributions_15.json';
import variables_and_distributions_tutorial_16 from './tutorial-trees/variables-and-distributions-tutorial-trees/variables_and_distributions_16.json';
import variables_and_distributions_tutorial_17 from './tutorial-trees/variables-and-distributions-tutorial-trees/variables_and_distributions_17.json';
import variables_and_distributions_tutorial_18 from './tutorial-trees/variables-and-distributions-tutorial-trees/variables_and_distributions_18.json';
import variables_and_distributions_tutorial_19 from './tutorial-trees/variables-and-distributions-tutorial-trees/variables_and_distributions_19.json';
import variables_and_distributions_tutorial_20 from './tutorial-trees/variables-and-distributions-tutorial-trees/variables_and_distributions_20.json';
import variables_and_distributions_tutorial_21 from './tutorial-trees/variables-and-distributions-tutorial-trees/variables_and_distributions_21.json';
import variables_and_distributions_tutorial_22 from './tutorial-trees/variables-and-distributions-tutorial-trees/variables_and_distributions_22.json';
import variables_and_distributions_tutorial_23 from './tutorial-trees/variables-and-distributions-tutorial-trees/variables_and_distributions_23.json';
import variables_and_distributions_tutorial_24 from './tutorial-trees/variables-and-distributions-tutorial-trees/variables_and_distributions_24.json';
import variables_and_distributions_tutorial_25 from './tutorial-trees/variables-and-distributions-tutorial-trees/variables_and_distributions_25.json';
import variables_and_distributions_tutorial_26 from './tutorial-trees/variables-and-distributions-tutorial-trees/variables_and_distributions_26.json';
import variables_and_distributions_tutorial_27 from './tutorial-trees/variables-and-distributions-tutorial-trees/variables_and_distributions_27.json';

const variablesAndDistributionsTutorialFiles = [
    variables_and_distributions_tutorial_1,
    variables_and_distributions_tutorial_2,
    variables_and_distributions_tutorial_3,
    variables_and_distributions_tutorial_4,
    variables_and_distributions_tutorial_5,
    variables_and_distributions_tutorial_6,
    variables_and_distributions_tutorial_7,
    variables_and_distributions_tutorial_8,
    variables_and_distributions_tutorial_9,
    variables_and_distributions_tutorial_10,
    variables_and_distributions_tutorial_11,
    variables_and_distributions_tutorial_12,
    variables_and_distributions_tutorial_13,
    variables_and_distributions_tutorial_14,
    variables_and_distributions_tutorial_15,
    variables_and_distributions_tutorial_16,
    variables_and_distributions_tutorial_17,
    variables_and_distributions_tutorial_18,
    variables_and_distributions_tutorial_19,
    variables_and_distributions_tutorial_20,
    variables_and_distributions_tutorial_21,
    variables_and_distributions_tutorial_22,
    variables_and_distributions_tutorial_23,
    variables_and_distributions_tutorial_24,
    variables_and_distributions_tutorial_25,
    variables_and_distributions_tutorial_26,
    variables_and_distributions_tutorial_27,
];

export default variablesAndDistributionsTutorialFiles;