module.exports={
  "SilverDecisions": "1.1.0",
  "buildTimestamp": 1641216304572,
  "savetime": "2022-02-11T14:54:58.005Z",
  "lng": "en",
  "viewMode": "criterion1",
  "rule": "expected-value-maximization",
  "title": "Basic tree",
  "description": "",
  "format": {
    "locales": "en",
    "payoff1": {
      "style": "currency",
      "currency": "USD",
      "currencyDisplay": "symbol",
      "minimumFractionDigits": 0,
      "maximumFractionDigits": 0,
      "useGrouping": true
    },
    "payoff2": {
      "style": "percent",
      "currency": "USD",
      "currencyDisplay": "symbol",
      "minimumFractionDigits": 0,
      "maximumFractionDigits": 0,
      "useGrouping": true
    },
    "probability": {
      "style": "percent",
      "minimumFractionDigits": 0,
      "maximumFractionDigits": 0,
      "useGrouping": true
    }
  },
  "treeDesigner": {
    "margin": {
      "left": 500,
      "right": 500,
      "top": 250,
      "bottom": 100
    },
    "scale": 1,
    "lng": "en",
    "layout": {
      "type": "cluster",
      "nodeSize": 22,
      "limitNodePositioning": true,
      "limitTextPositioning": true,
      "gridHeight": 95,
      "gridWidth": 160,
      "edgeSlantWidthMax": 20
    },
    "fontFamily": "sans-serif",
    "fontSize": "12px",
    "fontWeight": "normal",
    "fontStyle": "normal",
    "node": {
      "strokeWidth": "2px",
      "optimal": {
        "stroke": "#006f00",
        "strokeWidth": "2px"
      },
      "label": {
        "fontSize": "1em",
        "color": "black"
      },
      "payoff": {
        "fontSize": "1em",
        "color": "black",
        "negativeColor": "#b60000"
      },
      "decision": {
        "fill": "#ff7777",
        "stroke": "#660000",
        "selected": {
          "fill": "#aa3333"
        }
      },
      "chance": {
        "fill": "#ffff44",
        "stroke": "#666600",
        "selected": {
          "fill": "#aaaa00"
        }
      },
      "terminal": {
        "fill": "#44ff44",
        "stroke": "black",
        "selected": {
          "fill": "#00aa00"
        },
        "payoff": {
          "fontSize": "1em",
          "color": "black",
          "negativeColor": "#b60000"
        }
      }
    },
    "edge": {
      "stroke": "#424242",
      "strokeWidth": "1.5",
      "optimal": {
        "stroke": "#006f00",
        "strokeWidth": "2.4"
      },
      "selected": {
        "stroke": "#045ad1",
        "strokeWidth": "3.5"
      },
      "label": {
        "fontSize": "1em",
        "color": "back"
      },
      "payoff": {
        "fontSize": "1em",
        "color": "black",
        "negativeColor": "#b60000"
      }
    },
    "probability": {
      "fontSize": "1em",
      "color": "#0000d7"
    },
    "title": {
      "fontSize": "16px",
      "fontWeight": "bold",
      "fontStyle": "normal",
      "color": "#000000",
      "margin": {
        "top": 15,
        "bottom": 10
      }
    },
    "description": {
      "show": true,
      "fontSize": "12px",
      "fontWeight": "bold",
      "fontStyle": "normal",
      "color": "#000000",
      "margin": {
        "top": 5,
        "bottom": 10
      }
    },
    "readOnly": false,
    "disableAnimations": false,
    "forceFullEdgeRedraw": false,
    "hideLabels": false,
    "hidePayoffs": false,
    "hideProbabilities": false,
    "hideCharts": false,
    "hideTextboxes": false,
    "hideMilestones": false,
    "raw": false,
    "payoffNames": [
      null,
      null
    ],
    "maxPayoffsToDisplay": 1,
    "milestoneNames": [
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    ]
  },
  "data": {
    "code": "INVEST=-75000\nHIGHVALUE=500000\nMODERATEVALUE=300000\nLOWVALUE=150000\n",
    "expressionScope": {
      "INVEST": -75000,
      "HIGHVALUE": 500000,
      "MODERATEVALUE": 300000,
      "LOWVALUE": 150000
    },
    "trees": [
      {
        "computed": {
          "expected-value-maximization": {
            "childrenPayoff": [
              "82500"
            ],
            "payoff": [
              "82500"
            ],
            "optimal": true
          },
          "expected-value-minimization": {
            "childrenPayoff": [
              "82500"
            ],
            "payoff": [
              "82500"
            ],
            "optimal": true
          },
          "maxi-min": {
            "childrenPayoff": [
              "-75000"
            ],
            "payoff": [
              "-75000"
            ],
            "optimal": true
          },
          "maxi-max": {
            "childrenPayoff": [
              "425000"
            ],
            "payoff": [
              "425000"
            ],
            "optimal": true
          },
          "mini-min": {
            "childrenPayoff": [
              "-75000"
            ],
            "payoff": [
              "-75000"
            ],
            "optimal": true
          },
          "mini-max": {
            "childrenPayoff": [
              "425000"
            ],
            "payoff": [
              "425000"
            ],
            "optimal": true
          },
          "min-max": {
            "childrenPayoff": [
              "82500",
              "0"
            ],
            "combinedPayoff": "-82500",
            "payoff": [
              "82500",
              "0"
            ],
            "optimal": true
          },
          "max-min": {
            "childrenPayoff": [
              "82500",
              "0"
            ],
            "combinedPayoff": "82500",
            "payoff": [
              "82500",
              "0"
            ],
            "optimal": true
          },
          "min-min": {
            "childrenPayoff": [
              "82500",
              "0"
            ],
            "combinedPayoff": "-82500",
            "payoff": [
              "82500",
              "0"
            ],
            "optimal": true
          },
          "max-max": {
            "childrenPayoff": [
              "82500",
              "0"
            ],
            "combinedPayoff": "82500",
            "payoff": [
              "82500",
              "0"
            ],
            "optimal": true
          }
        },
        "childEdges": [
          {
            "computed": {
              "payoff": [
                "-75000",
                "0"
              ],
              "expected-value-maximization": {
                "probability": 1,
                "optimal": true
              },
              "expected-value-minimization": {
                "probability": 1,
                "optimal": true
              },
              "maxi-min": {
                "probability": 1,
                "optimal": true
              },
              "maxi-max": {
                "probability": 1,
                "optimal": true
              },
              "mini-min": {
                "probability": 1,
                "optimal": true
              },
              "mini-max": {
                "probability": 1,
                "optimal": true
              },
              "min-max": {
                "probability": 1,
                "optimal": true
              },
              "max-min": {
                "probability": 1,
                "optimal": true
              },
              "min-min": {
                "probability": 1,
                "optimal": true
              },
              "max-max": {
                "probability": 1,
                "optimal": true
              }
            },
            "name": "",
            "payoff": [
              "INVEST",
              0
            ],
            "childNode": {
              "computed": {
                "expected-value-maximization": {
                  "childrenPayoff": [
                    "157500"
                  ],
                  "payoff": [
                    "82500"
                  ],
                  "optimal": true
                },
                "expected-value-minimization": {
                  "childrenPayoff": [
                    "157500"
                  ],
                  "payoff": [
                    "82500"
                  ],
                  "optimal": true
                },
                "maxi-min": {
                  "childrenPayoff": [
                    "0"
                  ],
                  "payoff": [
                    "-75000"
                  ],
                  "optimal": true
                },
                "maxi-max": {
                  "childrenPayoff": [
                    "500000"
                  ],
                  "payoff": [
                    "425000"
                  ],
                  "optimal": true
                },
                "mini-min": {
                  "childrenPayoff": [
                    "0"
                  ],
                  "payoff": [
                    "-75000"
                  ],
                  "optimal": true
                },
                "mini-max": {
                  "childrenPayoff": [
                    "500000"
                  ],
                  "payoff": [
                    "425000"
                  ],
                  "optimal": true
                },
                "min-max": {
                  "childrenPayoff": [
                    "157500",
                    "0"
                  ],
                  "combinedPayoff": "-82500",
                  "payoff": [
                    "82500",
                    "0"
                  ],
                  "optimal": true
                },
                "max-min": {
                  "childrenPayoff": [
                    "157500",
                    "0"
                  ],
                  "combinedPayoff": "82500",
                  "payoff": [
                    "82500",
                    "0"
                  ],
                  "optimal": true
                },
                "min-min": {
                  "childrenPayoff": [
                    "157500",
                    "0"
                  ],
                  "combinedPayoff": "-82500",
                  "payoff": [
                    "82500",
                    "0"
                  ],
                  "optimal": true
                },
                "max-max": {
                  "childrenPayoff": [
                    "157500",
                    "0"
                  ],
                  "combinedPayoff": "82500",
                  "payoff": [
                    "82500",
                    "0"
                  ],
                  "optimal": true
                }
              },
              "childEdges": [
                {
                  "computed": {
                    "payoff": [
                      "0",
                      "0"
                    ],
                    "probability": "1/2",
                    "expected-value-maximization": {
                      "probability": "1/2",
                      "optimal": true
                    },
                    "expected-value-minimization": {
                      "probability": "1/2",
                      "optimal": true
                    },
                    "maxi-min": {
                      "probability": 0,
                      "optimal": false
                    },
                    "maxi-max": {
                      "probability": 1,
                      "optimal": true
                    },
                    "mini-min": {
                      "probability": 0,
                      "optimal": false
                    },
                    "mini-max": {
                      "probability": 1,
                      "optimal": true
                    },
                    "min-max": {
                      "probability": "1/2",
                      "optimal": true
                    },
                    "max-min": {
                      "probability": "1/2",
                      "optimal": true
                    },
                    "min-min": {
                      "probability": "1/2",
                      "optimal": true
                    },
                    "max-max": {
                      "probability": "1/2",
                      "optimal": true
                    }
                  },
                  "name": "Success",
                  "probability": ".5",
                  "payoff": [
                    0,
                    0
                  ],
                  "childNode": {
                    "computed": {
                      "expected-value-maximization": {
                        "childrenPayoff": [
                          "315000"
                        ],
                        "payoff": [
                          "315000"
                        ],
                        "optimal": true
                      },
                      "expected-value-minimization": {
                        "childrenPayoff": [
                          "315000"
                        ],
                        "payoff": [
                          "315000"
                        ],
                        "optimal": true
                      },
                      "maxi-min": {
                        "childrenPayoff": [
                          "150000"
                        ],
                        "payoff": [
                          "150000"
                        ]
                      },
                      "maxi-max": {
                        "childrenPayoff": [
                          "500000"
                        ],
                        "payoff": [
                          "500000"
                        ],
                        "optimal": true
                      },
                      "mini-min": {
                        "childrenPayoff": [
                          "150000"
                        ],
                        "payoff": [
                          "150000"
                        ]
                      },
                      "mini-max": {
                        "childrenPayoff": [
                          "500000"
                        ],
                        "payoff": [
                          "500000"
                        ],
                        "optimal": true
                      },
                      "min-max": {
                        "childrenPayoff": [
                          "315000",
                          "0"
                        ],
                        "combinedPayoff": "-315000",
                        "payoff": [
                          "315000",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-min": {
                        "childrenPayoff": [
                          "315000",
                          "0"
                        ],
                        "combinedPayoff": "315000",
                        "payoff": [
                          "315000",
                          "0"
                        ],
                        "optimal": true
                      },
                      "min-min": {
                        "childrenPayoff": [
                          "315000",
                          "0"
                        ],
                        "combinedPayoff": "-315000",
                        "payoff": [
                          "315000",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-max": {
                        "childrenPayoff": [
                          "315000",
                          "0"
                        ],
                        "combinedPayoff": "315000",
                        "payoff": [
                          "315000",
                          "0"
                        ],
                        "optimal": true
                      }
                    },
                    "childEdges": [
                      {
                        "computed": {
                          "payoff": [
                            "500000",
                            "0"
                          ],
                          "probability": "3/10",
                          "expected-value-maximization": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "expected-value-minimization": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "maxi-min": {
                            "probability": 0
                          },
                          "maxi-max": {
                            "probability": 1,
                            "optimal": true
                          },
                          "mini-min": {
                            "probability": 0
                          },
                          "mini-max": {
                            "probability": 1,
                            "optimal": true
                          },
                          "min-max": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "max-min": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "min-min": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "max-max": {
                            "probability": "3/10",
                            "optimal": true
                          }
                        },
                        "name": "High case",
                        "probability": "#",
                        "payoff": [
                          "HIGHVALUE",
                          0
                        ],
                        "childNode": {
                          "computed": {
                            "expected-value-maximization": {
                              "aggregatedPayoff": [
                                "425000"
                              ],
                              "probabilityToEnter": "3/20",
                              "payoff": [
                                "500000"
                              ],
                              "optimal": true
                            },
                            "expected-value-minimization": {
                              "aggregatedPayoff": [
                                "425000"
                              ],
                              "probabilityToEnter": "3/20",
                              "payoff": [
                                "500000"
                              ],
                              "optimal": true
                            },
                            "maxi-min": {
                              "aggregatedPayoff": [
                                "425000"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "500000"
                              ]
                            },
                            "maxi-max": {
                              "aggregatedPayoff": [
                                "425000"
                              ],
                              "probabilityToEnter": "1",
                              "payoff": [
                                "500000"
                              ],
                              "optimal": true
                            },
                            "mini-min": {
                              "aggregatedPayoff": [
                                "425000"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "500000"
                              ]
                            },
                            "mini-max": {
                              "aggregatedPayoff": [
                                "425000"
                              ],
                              "probabilityToEnter": "1",
                              "payoff": [
                                "500000"
                              ],
                              "optimal": true
                            },
                            "min-max": {
                              "aggregatedPayoff": [
                                "425000",
                                "0"
                              ],
                              "probabilityToEnter": "3/20",
                              "combinedPayoff": "-500000",
                              "payoff": [
                                "500000",
                                "0"
                              ],
                              "optimal": true
                            },
                            "max-min": {
                              "aggregatedPayoff": [
                                "425000",
                                "0"
                              ],
                              "probabilityToEnter": "3/20",
                              "combinedPayoff": "500000",
                              "payoff": [
                                "500000",
                                "0"
                              ],
                              "optimal": true
                            },
                            "min-min": {
                              "aggregatedPayoff": [
                                "425000",
                                "0"
                              ],
                              "probabilityToEnter": "3/20",
                              "combinedPayoff": "-500000",
                              "payoff": [
                                "500000",
                                "0"
                              ],
                              "optimal": true
                            },
                            "max-max": {
                              "aggregatedPayoff": [
                                "425000",
                                "0"
                              ],
                              "probabilityToEnter": "3/20",
                              "combinedPayoff": "500000",
                              "payoff": [
                                "500000",
                                "0"
                              ],
                              "optimal": true
                            }
                          },
                          "childEdges": [],
                          "name": "",
                          "code": "",
                          "expressionScope": {
                            "INVEST": -75000,
                            "HIGHVALUE": 500000,
                            "MODERATEVALUE": 300000,
                            "LOWVALUE": 150000
                          },
                          "folded": false,
                          "location": {
                            "x": 491,
                            "y": 11
                          },
                          "type": "terminal",
                          "milestone": null,
                          "expectedValue": 425000,
                          "p10": 425000,
                          "p90": 425000
                        },
                        "expectedValue": 500000,
                        "p10": 500000,
                        "p90": 500000,
                        "optimal": true
                      },
                      {
                        "computed": {
                          "payoff": [
                            "300000",
                            "0"
                          ],
                          "probability": "2/5",
                          "expected-value-maximization": {
                            "probability": "2/5",
                            "optimal": true
                          },
                          "expected-value-minimization": {
                            "probability": "2/5",
                            "optimal": true
                          },
                          "maxi-min": {
                            "probability": 0
                          },
                          "maxi-max": {
                            "probability": 0,
                            "optimal": false
                          },
                          "mini-min": {
                            "probability": 0
                          },
                          "mini-max": {
                            "probability": 0,
                            "optimal": false
                          },
                          "min-max": {
                            "probability": "2/5",
                            "optimal": true
                          },
                          "max-min": {
                            "probability": "2/5",
                            "optimal": true
                          },
                          "min-min": {
                            "probability": "2/5",
                            "optimal": true
                          },
                          "max-max": {
                            "probability": "2/5",
                            "optimal": true
                          }
                        },
                        "name": "Base case",
                        "probability": ".4",
                        "payoff": [
                          "MODERATEVALUE",
                          0
                        ],
                        "childNode": {
                          "computed": {
                            "expected-value-maximization": {
                              "aggregatedPayoff": [
                                "225000"
                              ],
                              "probabilityToEnter": "1/5",
                              "payoff": [
                                "300000"
                              ],
                              "optimal": true
                            },
                            "expected-value-minimization": {
                              "aggregatedPayoff": [
                                "225000"
                              ],
                              "probabilityToEnter": "1/5",
                              "payoff": [
                                "300000"
                              ],
                              "optimal": true
                            },
                            "maxi-min": {
                              "aggregatedPayoff": [
                                "225000"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "300000"
                              ]
                            },
                            "maxi-max": {
                              "aggregatedPayoff": [
                                "225000"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "300000"
                              ]
                            },
                            "mini-min": {
                              "aggregatedPayoff": [
                                "225000"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "300000"
                              ]
                            },
                            "mini-max": {
                              "aggregatedPayoff": [
                                "225000"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "300000"
                              ]
                            },
                            "min-max": {
                              "aggregatedPayoff": [
                                "225000",
                                "0"
                              ],
                              "probabilityToEnter": "1/5",
                              "combinedPayoff": "-300000",
                              "payoff": [
                                "300000",
                                "0"
                              ],
                              "optimal": true
                            },
                            "max-min": {
                              "aggregatedPayoff": [
                                "225000",
                                "0"
                              ],
                              "probabilityToEnter": "1/5",
                              "combinedPayoff": "300000",
                              "payoff": [
                                "300000",
                                "0"
                              ],
                              "optimal": true
                            },
                            "min-min": {
                              "aggregatedPayoff": [
                                "225000",
                                "0"
                              ],
                              "probabilityToEnter": "1/5",
                              "combinedPayoff": "-300000",
                              "payoff": [
                                "300000",
                                "0"
                              ],
                              "optimal": true
                            },
                            "max-max": {
                              "aggregatedPayoff": [
                                "225000",
                                "0"
                              ],
                              "probabilityToEnter": "1/5",
                              "combinedPayoff": "300000",
                              "payoff": [
                                "300000",
                                "0"
                              ],
                              "optimal": true
                            }
                          },
                          "childEdges": [],
                          "name": "",
                          "code": "",
                          "expressionScope": {
                            "INVEST": -75000,
                            "HIGHVALUE": 500000,
                            "MODERATEVALUE": 300000,
                            "LOWVALUE": 150000
                          },
                          "folded": false,
                          "location": {
                            "x": 491,
                            "y": 105.99999999999999
                          },
                          "type": "terminal",
                          "expectedValue": 225000,
                          "p10": 225000,
                          "p90": 225000
                        },
                        "expectedValue": 300000,
                        "p10": 300000,
                        "p90": 300000,
                        "optimal": true
                      },
                      {
                        "computed": {
                          "payoff": [
                            "150000",
                            "0"
                          ],
                          "probability": "3/10",
                          "expected-value-maximization": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "expected-value-minimization": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "maxi-min": {
                            "probability": 1
                          },
                          "maxi-max": {
                            "probability": 0,
                            "optimal": false
                          },
                          "mini-min": {
                            "probability": 1
                          },
                          "mini-max": {
                            "probability": 0,
                            "optimal": false
                          },
                          "min-max": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "max-min": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "min-min": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "max-max": {
                            "probability": "3/10",
                            "optimal": true
                          }
                        },
                        "name": "Low case",
                        "probability": "#",
                        "payoff": [
                          "LOWVALUE",
                          0
                        ],
                        "childNode": {
                          "computed": {
                            "expected-value-maximization": {
                              "aggregatedPayoff": [
                                "75000"
                              ],
                              "probabilityToEnter": "3/20",
                              "payoff": [
                                "150000"
                              ],
                              "optimal": true
                            },
                            "expected-value-minimization": {
                              "aggregatedPayoff": [
                                "75000"
                              ],
                              "probabilityToEnter": "3/20",
                              "payoff": [
                                "150000"
                              ],
                              "optimal": true
                            },
                            "maxi-min": {
                              "aggregatedPayoff": [
                                "75000"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "150000"
                              ]
                            },
                            "maxi-max": {
                              "aggregatedPayoff": [
                                "75000"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "150000"
                              ]
                            },
                            "mini-min": {
                              "aggregatedPayoff": [
                                "75000"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "150000"
                              ]
                            },
                            "mini-max": {
                              "aggregatedPayoff": [
                                "75000"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "150000"
                              ]
                            },
                            "min-max": {
                              "aggregatedPayoff": [
                                "75000",
                                "0"
                              ],
                              "probabilityToEnter": "3/20",
                              "combinedPayoff": "-150000",
                              "payoff": [
                                "150000",
                                "0"
                              ],
                              "optimal": true
                            },
                            "max-min": {
                              "aggregatedPayoff": [
                                "75000",
                                "0"
                              ],
                              "probabilityToEnter": "3/20",
                              "combinedPayoff": "150000",
                              "payoff": [
                                "150000",
                                "0"
                              ],
                              "optimal": true
                            },
                            "min-min": {
                              "aggregatedPayoff": [
                                "75000",
                                "0"
                              ],
                              "probabilityToEnter": "3/20",
                              "combinedPayoff": "-150000",
                              "payoff": [
                                "150000",
                                "0"
                              ],
                              "optimal": true
                            },
                            "max-max": {
                              "aggregatedPayoff": [
                                "75000",
                                "0"
                              ],
                              "probabilityToEnter": "3/20",
                              "combinedPayoff": "150000",
                              "payoff": [
                                "150000",
                                "0"
                              ],
                              "optimal": true
                            }
                          },
                          "childEdges": [],
                          "name": "",
                          "code": "",
                          "expressionScope": {
                            "INVEST": -75000,
                            "HIGHVALUE": 500000,
                            "MODERATEVALUE": 300000,
                            "LOWVALUE": 150000
                          },
                          "folded": false,
                          "location": {
                            "x": 491,
                            "y": 201
                          },
                          "type": "terminal",
                          "expectedValue": 75000,
                          "p10": 75000,
                          "p90": 75000
                        },
                        "expectedValue": 150000,
                        "p10": 150000,
                        "p90": 150000,
                        "optimal": true
                      }
                    ],
                    "name": "Outcome",
                    "code": "",
                    "expressionScope": {
                      "INVEST": -75000,
                      "HIGHVALUE": 500000,
                      "MODERATEVALUE": 300000,
                      "LOWVALUE": 150000
                    },
                    "folded": false,
                    "location": {
                      "x": 331,
                      "y": 105.99999999999999
                    },
                    "type": "chance",
                    "milestone": null,
                    "expectedValue": 315000,
                    "p10": 150000,
                    "p90": 500199
                  },
                  "expectedValue": 0,
                  "p10": 0,
                  "p90": 0,
                  "optimal": true
                },
                {
                  "computed": {
                    "payoff": [
                      "0",
                      "0"
                    ],
                    "probability": "1/2",
                    "expected-value-maximization": {
                      "probability": "1/2",
                      "optimal": true
                    },
                    "expected-value-minimization": {
                      "probability": "1/2",
                      "optimal": true
                    },
                    "maxi-min": {
                      "probability": 1,
                      "optimal": true
                    },
                    "maxi-max": {
                      "probability": 0,
                      "optimal": false
                    },
                    "mini-min": {
                      "probability": 1,
                      "optimal": true
                    },
                    "mini-max": {
                      "probability": 0,
                      "optimal": false
                    },
                    "min-max": {
                      "probability": "1/2",
                      "optimal": true
                    },
                    "max-min": {
                      "probability": "1/2",
                      "optimal": true
                    },
                    "min-min": {
                      "probability": "1/2",
                      "optimal": true
                    },
                    "max-max": {
                      "probability": "1/2",
                      "optimal": true
                    }
                  },
                  "name": "Failure",
                  "probability": "#",
                  "payoff": [
                    "0",
                    0
                  ],
                  "childNode": {
                    "computed": {
                      "expected-value-maximization": {
                        "aggregatedPayoff": [
                          "-75000"
                        ],
                        "probabilityToEnter": "1/2",
                        "payoff": [
                          "0"
                        ],
                        "optimal": true
                      },
                      "expected-value-minimization": {
                        "aggregatedPayoff": [
                          "-75000"
                        ],
                        "probabilityToEnter": "1/2",
                        "payoff": [
                          "0"
                        ],
                        "optimal": true
                      },
                      "maxi-min": {
                        "aggregatedPayoff": [
                          "-75000"
                        ],
                        "probabilityToEnter": "1",
                        "payoff": [
                          "0"
                        ],
                        "optimal": true
                      },
                      "maxi-max": {
                        "aggregatedPayoff": [
                          "-75000"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "0"
                        ]
                      },
                      "mini-min": {
                        "aggregatedPayoff": [
                          "-75000"
                        ],
                        "probabilityToEnter": "1",
                        "payoff": [
                          "0"
                        ],
                        "optimal": true
                      },
                      "mini-max": {
                        "aggregatedPayoff": [
                          "-75000"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "0"
                        ]
                      },
                      "min-max": {
                        "aggregatedPayoff": [
                          "-75000",
                          "0"
                        ],
                        "probabilityToEnter": "1/2",
                        "combinedPayoff": "0",
                        "payoff": [
                          "0",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-min": {
                        "aggregatedPayoff": [
                          "-75000",
                          "0"
                        ],
                        "probabilityToEnter": "1/2",
                        "combinedPayoff": "0",
                        "payoff": [
                          "0",
                          "0"
                        ],
                        "optimal": true
                      },
                      "min-min": {
                        "aggregatedPayoff": [
                          "-75000",
                          "0"
                        ],
                        "probabilityToEnter": "1/2",
                        "combinedPayoff": "0",
                        "payoff": [
                          "0",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-max": {
                        "aggregatedPayoff": [
                          "-75000",
                          "0"
                        ],
                        "probabilityToEnter": "1/2",
                        "combinedPayoff": "0",
                        "payoff": [
                          "0",
                          "0"
                        ],
                        "optimal": true
                      }
                    },
                    "childEdges": [],
                    "name": "",
                    "code": "",
                    "expressionScope": {
                      "INVEST": -75000,
                      "HIGHVALUE": 500000,
                      "MODERATEVALUE": 300000,
                      "LOWVALUE": 150000
                    },
                    "folded": false,
                    "location": {
                      "x": 491,
                      "y": 315
                    },
                    "type": "terminal",
                    "expectedValue": -75000,
                    "p10": -75000,
                    "p90": -75000
                  },
                  "expectedValue": 0,
                  "p10": 0,
                  "p90": 0,
                  "optimal": true
                }
              ],
              "name": "Chance",
              "code": "",
              "expressionScope": {
                "INVEST": -75000,
                "HIGHVALUE": 500000,
                "MODERATEVALUE": 300000,
                "LOWVALUE": 150000
              },
              "folded": false,
              "location": {
                "x": 171,
                "y": 210.5
              },
              "type": "chance",
              "milestone": null,
              "expectedValue": 157500,
              "p10": 0,
              "p90": 499998
            },
            "expectedValue": -75000,
            "p10": -75000,
            "p90": -75000,
            "optimal": true
          }
        ],
        "name": "Decision:\nInvest\nin project A",
        "code": "",
        "expressionScope": {
          "INVEST": -75000,
          "HIGHVALUE": 500000,
          "MODERATEVALUE": 300000,
          "LOWVALUE": 150000
        },
        "folded": false,
        "location": {
          "x": 11,
          "y": 210.5
        },
        "type": "decision",
        "milestone": null,
        "expectedValue": 82500,
        "p10": -75000,
        "p90": 225600
      }
    ],
    "texts": [
      {
        "value": "Decision nodes \nare points where \ndecisions are made.",
        "location": {
          "x": -40.00222551822662,
          "y": 373.999796628952
        }
      },
      {
        "value": "Chance nodes \nare points where\nuncertainty is \nresolved.",
        "location": {
          "x": 130.99688720703125,
          "y": 374.9998035430908
        }
      },
      {
        "value": "End nodes are points that return \nthe payoff and probability for the \nassociated branch in the tree. ",
        "location": {
          "x": 400.99554443359375,
          "y": 378.9997787475586
        }
      }
    ],
    "payoffNames": [],
    "defaultCriterion1Weight": 1,
    "weightLowerBound": 0,
    "weightUpperBound": "Infinity"
  },
  "sensitivityAnalysis": {},
  "definitionsDialog": {
    "valueList": [
      {
        "name": "INVEST",
        "value": "-75000",
        "low": null,
        "high": null,
        "distribution": "constant",
        "error": ""
      },
      {
        "name": "HIGHVALUE",
        "value": "500000",
        "low": null,
        "high": null,
        "distribution": "constant",
        "error": ""
      },
      {
        "name": "MODERATEVALUE",
        "value": "300000",
        "low": null,
        "high": null,
        "distribution": "constant",
        "error": ""
      },
      {
        "name": "LOWVALUE",
        "value": "150000",
        "low": null,
        "high": null,
        "distribution": "constant",
        "error": ""
      },
      {
        "name": null,
        "value": "",
        "low": null,
        "high": null,
        "distribution": "constant",
        "error": ""
      }
    ]
  }
}