module.exports={
  "SilverDecisions": "1.1.0",
  "buildTimestamp": 1710935291638,
  "savetime": "2024-05-08T12:47:35.967Z",
  "lng": "en",
  "viewMode": "criterion1",
  "rule": "expected-value-maximization",
  "title": "Project 3",
  "description": "",
  "format": {
    "locales": "en",
    "payoff1": {
      "style": "decimal",
      "currency": "USD",
      "currencyDisplay": "symbol",
      "minimumFractionDigits": 0,
      "maximumFractionDigits": 0,
      "useGrouping": true
    },
    "payoff2": {
      "style": "percent",
      "currency": "USD",
      "currencyDisplay": "symbol",
      "minimumFractionDigits": 0,
      "maximumFractionDigits": 0,
      "useGrouping": true
    },
    "probability": {
      "style": "percent",
      "minimumFractionDigits": 0,
      "maximumFractionDigits": 0,
      "useGrouping": true
    }
  },
  "treeDesigner": {
    "margin": {
      "left": 550,
      "right": 500,
      "top": 200,
      "bottom": 100
    },
    "scale": 1,
    "lng": "en",
    "layout": {
      "type": "cluster",
      "nodeSize": 22,
      "limitNodePositioning": true,
      "limitTextPositioning": true,
      "gridHeight": 95,
      "gridWidth": 160,
      "edgeSlantWidthMax": 20
    },
    "fontFamily": "sans-serif",
    "fontSize": "12px",
    "fontWeight": "normal",
    "fontStyle": "normal",
    "node": {
      "strokeWidth": "2px",
      "optimal": {
        "stroke": "#006f00",
        "strokeWidth": "2px"
      },
      "label": {
        "fontSize": "1em",
        "color": "black"
      },
      "payoff": {
        "fontSize": "1em",
        "color": "black",
        "negativeColor": "#b60000"
      },
      "decision": {
        "fill": "#ff7777",
        "stroke": "#660000",
        "selected": {
          "fill": "#aa3333"
        }
      },
      "chance": {
        "fill": "#ffff44",
        "stroke": "#666600",
        "selected": {
          "fill": "#aaaa00"
        }
      },
      "terminal": {
        "fill": "#44ff44",
        "stroke": "black",
        "selected": {
          "fill": "#00aa00"
        },
        "payoff": {
          "fontSize": "1em",
          "color": "black",
          "negativeColor": "#b60000"
        }
      }
    },
    "edge": {
      "stroke": "#424242",
      "strokeWidth": "1.5",
      "optimal": {
        "stroke": "#006f00",
        "strokeWidth": "2.4"
      },
      "selected": {
        "stroke": "#045ad1",
        "strokeWidth": "3.5"
      },
      "label": {
        "fontSize": "1em",
        "color": "back"
      },
      "payoff": {
        "fontSize": "1em",
        "color": "black",
        "negativeColor": "#b60000"
      }
    },
    "probability": {
      "fontSize": "1em",
      "color": "#0000d7"
    },
    "title": {
      "fontSize": "16px",
      "fontWeight": "bold",
      "fontStyle": "normal",
      "color": "#000000",
      "margin": {
        "top": 15,
        "bottom": 10
      }
    },
    "description": {
      "show": true,
      "fontSize": "12px",
      "fontWeight": "bold",
      "fontStyle": "normal",
      "color": "#000000",
      "margin": {
        "top": 5,
        "bottom": 10
      }
    },
    "readOnly": false,
    "disableAnimations": false,
    "forceFullEdgeRedraw": false,
    "hideLabels": false,
    "hidePayoffs": true,
    "hideExpectedValues": true,
    "hideProbabilities": false,
    "hideCharts": false,
    "hideTextboxes": false,
    "hideMilestones": false,
    "raw": false,
    "payoffNames": [
      null,
      null
    ],
    "maxPayoffsToDisplay": 1,
    "milestoneNames": [
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    ]
  },
  "data": {
    "code": "TaskDurationA=42\nTaskDurationB=38\nTaskDurationC1=54\nTaskDurationC2=67\n",
    "expressionScope": {
      "TaskDurationA": 42,
      "TaskDurationB": 38,
      "TaskDurationC1": 54,
      "TaskDurationC2": 67
    },
    "trees": [
      {
        "computed": {
          "expected-value-maximization": {
            "childrenPayoff": [
              "157 16419373641457/17592186044416"
            ],
            "payoff": [
              "157 41048434103639/43980465111040"
            ],
            "optimal": true
          },
          "expected-value-minimization": {
            "childrenPayoff": [
              "157 41048434103639/43980465111040"
            ],
            "payoff": [
              "157 41048434103639/43980465111040"
            ],
            "optimal": true
          },
          "maxi-min": {
            "childrenPayoff": [
              "152 2932031007403/8796093022208"
            ],
            "payoff": [
              "152 2932031007403/8796093022208"
            ],
            "optimal": true
          },
          "maxi-max": {
            "childrenPayoff": [
              "171 1/17592186044416"
            ],
            "payoff": [
              "171 1/17592186044416"
            ],
            "optimal": true
          },
          "mini-min": {
            "childrenPayoff": [
              "152 2932031007403/8796093022208"
            ],
            "payoff": [
              "152 2932031007403/8796093022208"
            ],
            "optimal": true
          },
          "mini-max": {
            "childrenPayoff": [
              "171 1/17592186044416"
            ],
            "payoff": [
              "171 1/17592186044416"
            ],
            "optimal": true
          },
          "min-max": {
            "childrenPayoff": [
              "157 41048434103639/43980465111040",
              "0"
            ],
            "combinedPayoff": "-157 41048434103639/43980465111040",
            "payoff": [
              "157 41048434103639/43980465111040",
              "0"
            ],
            "optimal": true
          },
          "max-min": {
            "childrenPayoff": [
              "157 41048434103639/43980465111040",
              "0"
            ],
            "combinedPayoff": "157 41048434103639/43980465111040",
            "payoff": [
              "157 41048434103639/43980465111040",
              "0"
            ],
            "optimal": true
          },
          "min-min": {
            "childrenPayoff": [
              "157 41048434103639/43980465111040",
              "0"
            ],
            "combinedPayoff": "-157 41048434103639/43980465111040",
            "payoff": [
              "157 41048434103639/43980465111040",
              "0"
            ],
            "optimal": true
          },
          "max-max": {
            "childrenPayoff": [
              "157 41048434103639/43980465111040",
              "0"
            ],
            "combinedPayoff": "157 41048434103639/43980465111040",
            "payoff": [
              "157 41048434103639/43980465111040",
              "0"
            ],
            "optimal": true
          }
        },
        "childEdges": [
          {
            "computed": {
              "payoff": [
                "0",
                "0"
              ],
              "expected-value-maximization": {
                "probability": 1,
                "optimal": true
              },
              "expected-value-minimization": {
                "probability": 1,
                "optimal": true
              },
              "maxi-min": {
                "probability": 1,
                "optimal": true
              },
              "maxi-max": {
                "probability": 1,
                "optimal": true
              },
              "mini-min": {
                "probability": 1,
                "optimal": true
              },
              "mini-max": {
                "probability": 1,
                "optimal": true
              },
              "min-max": {
                "probability": 1,
                "optimal": true
              },
              "max-min": {
                "probability": 1,
                "optimal": true
              },
              "min-min": {
                "probability": 1,
                "optimal": true
              },
              "max-max": {
                "probability": 1,
                "optimal": true
              }
            },
            "name": "",
            "payoff": [
              0,
              0
            ],
            "childNode": {
              "computed": {
                "expected-value-maximization": {
                  "childrenPayoff": [
                    "157 32838747282913/35184372088832"
                  ],
                  "payoff": [
                    "157 41048434103639/43980465111040"
                  ],
                  "optimal": true
                },
                "expected-value-minimization": {
                  "childrenPayoff": [
                    "157 41048434103639/43980465111040"
                  ],
                  "payoff": [
                    "157 41048434103639/43980465111040"
                  ],
                  "optimal": true
                },
                "maxi-min": {
                  "childrenPayoff": [
                    "152 2932031007403/8796093022208"
                  ],
                  "payoff": [
                    "152 2932031007403/8796093022208"
                  ],
                  "optimal": true
                },
                "maxi-max": {
                  "childrenPayoff": [
                    "171 1/17592186044416"
                  ],
                  "payoff": [
                    "171 1/17592186044416"
                  ],
                  "optimal": true
                },
                "mini-min": {
                  "childrenPayoff": [
                    "152 2932031007403/8796093022208"
                  ],
                  "payoff": [
                    "152 2932031007403/8796093022208"
                  ],
                  "optimal": true
                },
                "mini-max": {
                  "childrenPayoff": [
                    "171 1/17592186044416"
                  ],
                  "payoff": [
                    "171 1/17592186044416"
                  ],
                  "optimal": true
                },
                "min-max": {
                  "childrenPayoff": [
                    "157 41048434103639/43980465111040",
                    "0"
                  ],
                  "combinedPayoff": "-157 41048434103639/43980465111040",
                  "payoff": [
                    "157 41048434103639/43980465111040",
                    "0"
                  ],
                  "optimal": true
                },
                "max-min": {
                  "childrenPayoff": [
                    "157 41048434103639/43980465111040",
                    "0"
                  ],
                  "combinedPayoff": "157 41048434103639/43980465111040",
                  "payoff": [
                    "157 41048434103639/43980465111040",
                    "0"
                  ],
                  "optimal": true
                },
                "min-min": {
                  "childrenPayoff": [
                    "157 41048434103639/43980465111040",
                    "0"
                  ],
                  "combinedPayoff": "-157 41048434103639/43980465111040",
                  "payoff": [
                    "157 41048434103639/43980465111040",
                    "0"
                  ],
                  "optimal": true
                },
                "max-max": {
                  "childrenPayoff": [
                    "157 41048434103639/43980465111040",
                    "0"
                  ],
                  "combinedPayoff": "157 41048434103639/43980465111040",
                  "payoff": [
                    "157 41048434103639/43980465111040",
                    "0"
                  ],
                  "optimal": true
                }
              },
              "childEdges": [
                {
                  "computed": {
                    "payoff": [
                      "51 23456248059221/70368744177664",
                      "0"
                    ],
                    "probability": "1",
                    "expected-value-maximization": {
                      "probability": "1",
                      "optimal": true
                    },
                    "expected-value-minimization": {
                      "probability": "1",
                      "optimal": true
                    },
                    "maxi-min": {
                      "probability": 1,
                      "optimal": true
                    },
                    "maxi-max": {
                      "probability": 1,
                      "optimal": true
                    },
                    "mini-min": {
                      "probability": 1,
                      "optimal": true
                    },
                    "mini-max": {
                      "probability": 1,
                      "optimal": true
                    },
                    "min-max": {
                      "probability": "1",
                      "optimal": true
                    },
                    "max-min": {
                      "probability": "1",
                      "optimal": true
                    },
                    "min-min": {
                      "probability": "1",
                      "optimal": true
                    },
                    "max-max": {
                      "probability": "1",
                      "optimal": true
                    }
                  },
                  "name": "",
                  "probability": "#",
                  "payoff": [
                    "TaskDurationA",
                    0
                  ],
                  "childNode": {
                    "computed": {
                      "expected-value-maximization": {
                        "childrenPayoff": [
                          "106 21110623253299/35184372088832"
                        ],
                        "payoff": [
                          "157 41048434103639/43980465111040"
                        ],
                        "optimal": true
                      },
                      "expected-value-minimization": {
                        "childrenPayoff": [
                          "106 13194139533313/21990232555520"
                        ],
                        "payoff": [
                          "157 41048434103639/43980465111040"
                        ],
                        "optimal": true
                      },
                      "maxi-min": {
                        "childrenPayoff": [
                          "101 1/35184372088832"
                        ],
                        "payoff": [
                          "152 2932031007403/8796093022208"
                        ],
                        "optimal": true
                      },
                      "maxi-max": {
                        "childrenPayoff": [
                          "119 23456248059223/35184372088832"
                        ],
                        "payoff": [
                          "171 1/17592186044416"
                        ],
                        "optimal": true
                      },
                      "mini-min": {
                        "childrenPayoff": [
                          "101 1/35184372088832"
                        ],
                        "payoff": [
                          "152 2932031007403/8796093022208"
                        ],
                        "optimal": true
                      },
                      "mini-max": {
                        "childrenPayoff": [
                          "119 23456248059223/35184372088832"
                        ],
                        "payoff": [
                          "171 1/17592186044416"
                        ],
                        "optimal": true
                      },
                      "min-max": {
                        "childrenPayoff": [
                          "106 13194139533313/21990232555520",
                          "0"
                        ],
                        "combinedPayoff": "-157 41048434103639/43980465111040",
                        "payoff": [
                          "157 41048434103639/43980465111040",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-min": {
                        "childrenPayoff": [
                          "106 13194139533313/21990232555520",
                          "0"
                        ],
                        "combinedPayoff": "157 41048434103639/43980465111040",
                        "payoff": [
                          "157 41048434103639/43980465111040",
                          "0"
                        ],
                        "optimal": true
                      },
                      "min-min": {
                        "childrenPayoff": [
                          "106 13194139533313/21990232555520",
                          "0"
                        ],
                        "combinedPayoff": "-157 41048434103639/43980465111040",
                        "payoff": [
                          "157 41048434103639/43980465111040",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-max": {
                        "childrenPayoff": [
                          "106 13194139533313/21990232555520",
                          "0"
                        ],
                        "combinedPayoff": "157 41048434103639/43980465111040",
                        "payoff": [
                          "157 41048434103639/43980465111040",
                          "0"
                        ],
                        "optimal": true
                      }
                    },
                    "childEdges": [
                      {
                        "computed": {
                          "payoff": [
                            "46 11728124029611/35184372088832",
                            "0"
                          ],
                          "probability": "1",
                          "expected-value-maximization": {
                            "probability": "1",
                            "optimal": true
                          },
                          "expected-value-minimization": {
                            "probability": "1",
                            "optimal": true
                          },
                          "maxi-min": {
                            "probability": 1,
                            "optimal": true
                          },
                          "maxi-max": {
                            "probability": 1,
                            "optimal": true
                          },
                          "mini-min": {
                            "probability": 1,
                            "optimal": true
                          },
                          "mini-max": {
                            "probability": 1,
                            "optimal": true
                          },
                          "min-max": {
                            "probability": "1",
                            "optimal": true
                          },
                          "max-min": {
                            "probability": "1",
                            "optimal": true
                          },
                          "min-min": {
                            "probability": "1",
                            "optimal": true
                          },
                          "max-max": {
                            "probability": "1",
                            "optimal": true
                          }
                        },
                        "name": "",
                        "probability": "#",
                        "payoff": [
                          "TaskDurationB",
                          0
                        ],
                        "childNode": {
                          "computed": {
                            "expected-value-maximization": {
                              "childrenPayoff": [
                                "60 18764998447377/70368744177664"
                              ],
                              "payoff": [
                                "106 13194139533313/21990232555520"
                              ],
                              "optimal": true
                            },
                            "expected-value-minimization": {
                              "childrenPayoff": [
                                "60 2932031007403/10995116277760"
                              ],
                              "payoff": [
                                "106 13194139533313/21990232555520"
                              ],
                              "optimal": true
                            },
                            "maxi-min": {
                              "childrenPayoff": [
                                "54 11728124029611/17592186044416"
                              ],
                              "payoff": [
                                "101 1/35184372088832"
                              ],
                              "optimal": true
                            },
                            "maxi-max": {
                              "childrenPayoff": [
                                "73 2932031007403/8796093022208"
                              ],
                              "payoff": [
                                "119 23456248059223/35184372088832"
                              ],
                              "optimal": true
                            },
                            "mini-min": {
                              "childrenPayoff": [
                                "54 11728124029611/17592186044416"
                              ],
                              "payoff": [
                                "101 1/35184372088832"
                              ],
                              "optimal": true
                            },
                            "mini-max": {
                              "childrenPayoff": [
                                "73 2932031007403/8796093022208"
                              ],
                              "payoff": [
                                "119 23456248059223/35184372088832"
                              ],
                              "optimal": true
                            },
                            "min-max": {
                              "childrenPayoff": [
                                "60 2932031007403/10995116277760",
                                "0"
                              ],
                              "combinedPayoff": "-106 13194139533313/21990232555520",
                              "payoff": [
                                "106 13194139533313/21990232555520",
                                "0"
                              ],
                              "optimal": true
                            },
                            "max-min": {
                              "childrenPayoff": [
                                "60 2932031007403/10995116277760",
                                "0"
                              ],
                              "combinedPayoff": "106 13194139533313/21990232555520",
                              "payoff": [
                                "106 13194139533313/21990232555520",
                                "0"
                              ],
                              "optimal": true
                            },
                            "min-min": {
                              "childrenPayoff": [
                                "60 2932031007403/10995116277760",
                                "0"
                              ],
                              "combinedPayoff": "-106 13194139533313/21990232555520",
                              "payoff": [
                                "106 13194139533313/21990232555520",
                                "0"
                              ],
                              "optimal": true
                            },
                            "max-max": {
                              "childrenPayoff": [
                                "60 2932031007403/10995116277760",
                                "0"
                              ],
                              "combinedPayoff": "106 13194139533313/21990232555520",
                              "payoff": [
                                "106 13194139533313/21990232555520",
                                "0"
                              ],
                              "optimal": true
                            }
                          },
                          "childEdges": [
                            {
                              "computed": {
                                "payoff": [
                                  "0",
                                  "0"
                                ],
                                "probability": "7/10",
                                "expected-value-maximization": {
                                  "probability": "7/10",
                                  "optimal": true
                                },
                                "expected-value-minimization": {
                                  "probability": "7/10",
                                  "optimal": true
                                },
                                "maxi-min": {
                                  "probability": 1,
                                  "optimal": true
                                },
                                "maxi-max": {
                                  "probability": 0,
                                  "optimal": false
                                },
                                "mini-min": {
                                  "probability": 1,
                                  "optimal": true
                                },
                                "mini-max": {
                                  "probability": 0,
                                  "optimal": false
                                },
                                "min-max": {
                                  "probability": "7/10",
                                  "optimal": true
                                },
                                "max-min": {
                                  "probability": "7/10",
                                  "optimal": true
                                },
                                "min-min": {
                                  "probability": "7/10",
                                  "optimal": true
                                },
                                "max-max": {
                                  "probability": "7/10",
                                  "optimal": true
                                }
                              },
                              "name": "Simple solution",
                              "probability": ".7",
                              "payoff": [
                                0,
                                0
                              ],
                              "childNode": {
                                "computed": {
                                  "expected-value-maximization": {
                                    "childrenPayoff": [
                                      "54 11728124029611/17592186044416"
                                    ],
                                    "payoff": [
                                      "54 11728124029611/17592186044416"
                                    ],
                                    "optimal": true
                                  },
                                  "expected-value-minimization": {
                                    "childrenPayoff": [
                                      "54 11728124029611/17592186044416"
                                    ],
                                    "payoff": [
                                      "54 11728124029611/17592186044416"
                                    ],
                                    "optimal": true
                                  },
                                  "maxi-min": {
                                    "childrenPayoff": [
                                      "54 11728124029611/17592186044416"
                                    ],
                                    "payoff": [
                                      "54 11728124029611/17592186044416"
                                    ],
                                    "optimal": true
                                  },
                                  "maxi-max": {
                                    "childrenPayoff": [
                                      "54 11728124029611/17592186044416"
                                    ],
                                    "payoff": [
                                      "54 11728124029611/17592186044416"
                                    ]
                                  },
                                  "mini-min": {
                                    "childrenPayoff": [
                                      "54 11728124029611/17592186044416"
                                    ],
                                    "payoff": [
                                      "54 11728124029611/17592186044416"
                                    ],
                                    "optimal": true
                                  },
                                  "mini-max": {
                                    "childrenPayoff": [
                                      "54 11728124029611/17592186044416"
                                    ],
                                    "payoff": [
                                      "54 11728124029611/17592186044416"
                                    ]
                                  },
                                  "min-max": {
                                    "childrenPayoff": [
                                      "54 11728124029611/17592186044416",
                                      "0"
                                    ],
                                    "combinedPayoff": "-54 11728124029611/17592186044416",
                                    "payoff": [
                                      "54 11728124029611/17592186044416",
                                      "0"
                                    ],
                                    "optimal": true
                                  },
                                  "max-min": {
                                    "childrenPayoff": [
                                      "54 11728124029611/17592186044416",
                                      "0"
                                    ],
                                    "combinedPayoff": "54 11728124029611/17592186044416",
                                    "payoff": [
                                      "54 11728124029611/17592186044416",
                                      "0"
                                    ],
                                    "optimal": true
                                  },
                                  "min-min": {
                                    "childrenPayoff": [
                                      "54 11728124029611/17592186044416",
                                      "0"
                                    ],
                                    "combinedPayoff": "-54 11728124029611/17592186044416",
                                    "payoff": [
                                      "54 11728124029611/17592186044416",
                                      "0"
                                    ],
                                    "optimal": true
                                  },
                                  "max-max": {
                                    "childrenPayoff": [
                                      "54 11728124029611/17592186044416",
                                      "0"
                                    ],
                                    "combinedPayoff": "54 11728124029611/17592186044416",
                                    "payoff": [
                                      "54 11728124029611/17592186044416",
                                      "0"
                                    ],
                                    "optimal": true
                                  }
                                },
                                "childEdges": [
                                  {
                                    "computed": {
                                      "payoff": [
                                        "54 11728124029611/17592186044416",
                                        "0"
                                      ],
                                      "probability": "1",
                                      "expected-value-maximization": {
                                        "probability": "1",
                                        "optimal": true
                                      },
                                      "expected-value-minimization": {
                                        "probability": "1",
                                        "optimal": true
                                      },
                                      "maxi-min": {
                                        "probability": 1,
                                        "optimal": true
                                      },
                                      "maxi-max": {
                                        "probability": 1
                                      },
                                      "mini-min": {
                                        "probability": 1,
                                        "optimal": true
                                      },
                                      "mini-max": {
                                        "probability": 1
                                      },
                                      "min-max": {
                                        "probability": "1",
                                        "optimal": true
                                      },
                                      "max-min": {
                                        "probability": "1",
                                        "optimal": true
                                      },
                                      "min-min": {
                                        "probability": "1",
                                        "optimal": true
                                      },
                                      "max-max": {
                                        "probability": "1",
                                        "optimal": true
                                      }
                                    },
                                    "name": "",
                                    "probability": "#",
                                    "payoff": [
                                      "TaskDurationC1",
                                      0
                                    ],
                                    "childNode": {
                                      "computed": {
                                        "expected-value-maximization": {
                                          "aggregatedPayoff": [
                                            "152 5864062014807/17592186044416"
                                          ],
                                          "probabilityToEnter": "7/10",
                                          "payoff": [
                                            "54 11728124029611/17592186044416"
                                          ],
                                          "optimal": true
                                        },
                                        "expected-value-minimization": {
                                          "aggregatedPayoff": [
                                            "152 2932031007403/8796093022208"
                                          ],
                                          "probabilityToEnter": "7/10",
                                          "payoff": [
                                            "54 11728124029611/17592186044416"
                                          ],
                                          "optimal": true
                                        },
                                        "maxi-min": {
                                          "aggregatedPayoff": [
                                            "152 2932031007403/8796093022208"
                                          ],
                                          "probabilityToEnter": "1",
                                          "payoff": [
                                            "54 11728124029611/17592186044416"
                                          ],
                                          "optimal": true
                                        },
                                        "maxi-max": {
                                          "aggregatedPayoff": [
                                            "152 2932031007403/8796093022208"
                                          ],
                                          "probabilityToEnter": 0,
                                          "payoff": [
                                            "54 11728124029611/17592186044416"
                                          ]
                                        },
                                        "mini-min": {
                                          "aggregatedPayoff": [
                                            "152 2932031007403/8796093022208"
                                          ],
                                          "probabilityToEnter": "1",
                                          "payoff": [
                                            "54 11728124029611/17592186044416"
                                          ],
                                          "optimal": true
                                        },
                                        "mini-max": {
                                          "aggregatedPayoff": [
                                            "152 2932031007403/8796093022208"
                                          ],
                                          "probabilityToEnter": 0,
                                          "payoff": [
                                            "54 11728124029611/17592186044416"
                                          ]
                                        },
                                        "min-max": {
                                          "aggregatedPayoff": [
                                            "152 2932031007403/8796093022208",
                                            "0"
                                          ],
                                          "probabilityToEnter": "7/10",
                                          "combinedPayoff": "-54 11728124029611/17592186044416",
                                          "payoff": [
                                            "54 11728124029611/17592186044416",
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "max-min": {
                                          "aggregatedPayoff": [
                                            "152 2932031007403/8796093022208",
                                            "0"
                                          ],
                                          "probabilityToEnter": "7/10",
                                          "combinedPayoff": "54 11728124029611/17592186044416",
                                          "payoff": [
                                            "54 11728124029611/17592186044416",
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "min-min": {
                                          "aggregatedPayoff": [
                                            "152 2932031007403/8796093022208",
                                            "0"
                                          ],
                                          "probabilityToEnter": "7/10",
                                          "combinedPayoff": "-54 11728124029611/17592186044416",
                                          "payoff": [
                                            "54 11728124029611/17592186044416",
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "max-max": {
                                          "aggregatedPayoff": [
                                            "152 2932031007403/8796093022208",
                                            "0"
                                          ],
                                          "probabilityToEnter": "7/10",
                                          "combinedPayoff": "54 11728124029611/17592186044416",
                                          "payoff": [
                                            "54 11728124029611/17592186044416",
                                            "0"
                                          ],
                                          "optimal": true
                                        }
                                      },
                                      "childEdges": [],
                                      "name": "",
                                      "code": "",
                                      "expressionScope": {
                                        "TaskDurationA": 42,
                                        "TaskDurationB": 38,
                                        "TaskDurationC1": 54,
                                        "TaskDurationC2": 67
                                      },
                                      "folded": false,
                                      "location": {
                                        "x": 811,
                                        "y": 11
                                      },
                                      "type": "terminal",
                                      "expectedValue": 152.33333333333343,
                                      "p10": 129,
                                      "p90": 176
                                    },
                                    "expectedValue": 54.666666666666686,
                                    "p10": 41,
                                    "p90": 69,
                                    "optimal": true
                                  }
                                ],
                                "name": "Task C1",
                                "code": "",
                                "expressionScope": {
                                  "TaskDurationA": 42,
                                  "TaskDurationB": 38,
                                  "TaskDurationC1": 54,
                                  "TaskDurationC2": 67
                                },
                                "folded": false,
                                "location": {
                                  "x": 651,
                                  "y": 11
                                },
                                "type": "chance",
                                "expectedValue": 54.666666666666686,
                                "p10": 41,
                                "p90": 69
                              },
                              "expectedValue": 0,
                              "p10": 0,
                              "p90": 0,
                              "optimal": true
                            },
                            {
                              "computed": {
                                "payoff": [
                                  "0",
                                  "0"
                                ],
                                "probability": "3/10",
                                "expected-value-maximization": {
                                  "probability": "3/10",
                                  "optimal": true
                                },
                                "expected-value-minimization": {
                                  "probability": "3/10",
                                  "optimal": true
                                },
                                "maxi-min": {
                                  "probability": 0,
                                  "optimal": false
                                },
                                "maxi-max": {
                                  "probability": 1,
                                  "optimal": true
                                },
                                "mini-min": {
                                  "probability": 0,
                                  "optimal": false
                                },
                                "mini-max": {
                                  "probability": 1,
                                  "optimal": true
                                },
                                "min-max": {
                                  "probability": "3/10",
                                  "optimal": true
                                },
                                "max-min": {
                                  "probability": "3/10",
                                  "optimal": true
                                },
                                "min-min": {
                                  "probability": "3/10",
                                  "optimal": true
                                },
                                "max-max": {
                                  "probability": "3/10",
                                  "optimal": true
                                }
                              },
                              "name": "Complex solution",
                              "probability": "#",
                              "payoff": [
                                0,
                                0
                              ],
                              "childNode": {
                                "computed": {
                                  "expected-value-maximization": {
                                    "childrenPayoff": [
                                      "73 2932031007403/8796093022208"
                                    ],
                                    "payoff": [
                                      "73 2932031007403/8796093022208"
                                    ],
                                    "optimal": true
                                  },
                                  "expected-value-minimization": {
                                    "childrenPayoff": [
                                      "73 2932031007403/8796093022208"
                                    ],
                                    "payoff": [
                                      "73 2932031007403/8796093022208"
                                    ],
                                    "optimal": true
                                  },
                                  "maxi-min": {
                                    "childrenPayoff": [
                                      "73 2932031007403/8796093022208"
                                    ],
                                    "payoff": [
                                      "73 2932031007403/8796093022208"
                                    ]
                                  },
                                  "maxi-max": {
                                    "childrenPayoff": [
                                      "73 2932031007403/8796093022208"
                                    ],
                                    "payoff": [
                                      "73 2932031007403/8796093022208"
                                    ],
                                    "optimal": true
                                  },
                                  "mini-min": {
                                    "childrenPayoff": [
                                      "73 2932031007403/8796093022208"
                                    ],
                                    "payoff": [
                                      "73 2932031007403/8796093022208"
                                    ]
                                  },
                                  "mini-max": {
                                    "childrenPayoff": [
                                      "73 2932031007403/8796093022208"
                                    ],
                                    "payoff": [
                                      "73 2932031007403/8796093022208"
                                    ],
                                    "optimal": true
                                  },
                                  "min-max": {
                                    "childrenPayoff": [
                                      "73 2932031007403/8796093022208",
                                      "0"
                                    ],
                                    "combinedPayoff": "-73 2932031007403/8796093022208",
                                    "payoff": [
                                      "73 2932031007403/8796093022208",
                                      "0"
                                    ],
                                    "optimal": true
                                  },
                                  "max-min": {
                                    "childrenPayoff": [
                                      "73 2932031007403/8796093022208",
                                      "0"
                                    ],
                                    "combinedPayoff": "73 2932031007403/8796093022208",
                                    "payoff": [
                                      "73 2932031007403/8796093022208",
                                      "0"
                                    ],
                                    "optimal": true
                                  },
                                  "min-min": {
                                    "childrenPayoff": [
                                      "73 2932031007403/8796093022208",
                                      "0"
                                    ],
                                    "combinedPayoff": "-73 2932031007403/8796093022208",
                                    "payoff": [
                                      "73 2932031007403/8796093022208",
                                      "0"
                                    ],
                                    "optimal": true
                                  },
                                  "max-max": {
                                    "childrenPayoff": [
                                      "73 2932031007403/8796093022208",
                                      "0"
                                    ],
                                    "combinedPayoff": "73 2932031007403/8796093022208",
                                    "payoff": [
                                      "73 2932031007403/8796093022208",
                                      "0"
                                    ],
                                    "optimal": true
                                  }
                                },
                                "childEdges": [
                                  {
                                    "computed": {
                                      "payoff": [
                                        "73 2932031007403/8796093022208",
                                        "0"
                                      ],
                                      "probability": "1",
                                      "expected-value-maximization": {
                                        "probability": "1",
                                        "optimal": true
                                      },
                                      "expected-value-minimization": {
                                        "probability": "1",
                                        "optimal": true
                                      },
                                      "maxi-min": {
                                        "probability": 1
                                      },
                                      "maxi-max": {
                                        "probability": 1,
                                        "optimal": true
                                      },
                                      "mini-min": {
                                        "probability": 1
                                      },
                                      "mini-max": {
                                        "probability": 1,
                                        "optimal": true
                                      },
                                      "min-max": {
                                        "probability": "1",
                                        "optimal": true
                                      },
                                      "max-min": {
                                        "probability": "1",
                                        "optimal": true
                                      },
                                      "min-min": {
                                        "probability": "1",
                                        "optimal": true
                                      },
                                      "max-max": {
                                        "probability": "1",
                                        "optimal": true
                                      }
                                    },
                                    "name": "",
                                    "probability": "#",
                                    "payoff": [
                                      "TaskDurationC2",
                                      0
                                    ],
                                    "childNode": {
                                      "computed": {
                                        "expected-value-maximization": {
                                          "aggregatedPayoff": [
                                            "171 5/35184372088832"
                                          ],
                                          "probabilityToEnter": "3/10",
                                          "payoff": [
                                            "73 2932031007403/8796093022208"
                                          ],
                                          "optimal": true
                                        },
                                        "expected-value-minimization": {
                                          "aggregatedPayoff": [
                                            "171 1/17592186044416"
                                          ],
                                          "probabilityToEnter": "3/10",
                                          "payoff": [
                                            "73 2932031007403/8796093022208"
                                          ],
                                          "optimal": true
                                        },
                                        "maxi-min": {
                                          "aggregatedPayoff": [
                                            "171 1/17592186044416"
                                          ],
                                          "probabilityToEnter": 0,
                                          "payoff": [
                                            "73 2932031007403/8796093022208"
                                          ]
                                        },
                                        "maxi-max": {
                                          "aggregatedPayoff": [
                                            "171 1/17592186044416"
                                          ],
                                          "probabilityToEnter": "1",
                                          "payoff": [
                                            "73 2932031007403/8796093022208"
                                          ],
                                          "optimal": true
                                        },
                                        "mini-min": {
                                          "aggregatedPayoff": [
                                            "171 1/17592186044416"
                                          ],
                                          "probabilityToEnter": 0,
                                          "payoff": [
                                            "73 2932031007403/8796093022208"
                                          ]
                                        },
                                        "mini-max": {
                                          "aggregatedPayoff": [
                                            "171 1/17592186044416"
                                          ],
                                          "probabilityToEnter": "1",
                                          "payoff": [
                                            "73 2932031007403/8796093022208"
                                          ],
                                          "optimal": true
                                        },
                                        "min-max": {
                                          "aggregatedPayoff": [
                                            "171 1/17592186044416",
                                            "0"
                                          ],
                                          "probabilityToEnter": "3/10",
                                          "combinedPayoff": "-73 2932031007403/8796093022208",
                                          "payoff": [
                                            "73 2932031007403/8796093022208",
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "max-min": {
                                          "aggregatedPayoff": [
                                            "171 1/17592186044416",
                                            "0"
                                          ],
                                          "probabilityToEnter": "3/10",
                                          "combinedPayoff": "73 2932031007403/8796093022208",
                                          "payoff": [
                                            "73 2932031007403/8796093022208",
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "min-min": {
                                          "aggregatedPayoff": [
                                            "171 1/17592186044416",
                                            "0"
                                          ],
                                          "probabilityToEnter": "3/10",
                                          "combinedPayoff": "-73 2932031007403/8796093022208",
                                          "payoff": [
                                            "73 2932031007403/8796093022208",
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "max-max": {
                                          "aggregatedPayoff": [
                                            "171 1/17592186044416",
                                            "0"
                                          ],
                                          "probabilityToEnter": "3/10",
                                          "combinedPayoff": "73 2932031007403/8796093022208",
                                          "payoff": [
                                            "73 2932031007403/8796093022208",
                                            "0"
                                          ],
                                          "optimal": true
                                        }
                                      },
                                      "childEdges": [],
                                      "name": "",
                                      "code": "",
                                      "expressionScope": {
                                        "TaskDurationA": 42,
                                        "TaskDurationB": 38,
                                        "TaskDurationC1": 54,
                                        "TaskDurationC2": 67
                                      },
                                      "folded": false,
                                      "location": {
                                        "x": 811,
                                        "y": 125
                                      },
                                      "type": "terminal",
                                      "expectedValue": 171.00000000000014,
                                      "p10": 143,
                                      "p90": 200
                                    },
                                    "expectedValue": 73.33333333333337,
                                    "p10": 53,
                                    "p90": 96,
                                    "optimal": true
                                  }
                                ],
                                "name": "Task C2",
                                "code": "",
                                "expressionScope": {
                                  "TaskDurationA": 42,
                                  "TaskDurationB": 38,
                                  "TaskDurationC1": 54,
                                  "TaskDurationC2": 67
                                },
                                "folded": false,
                                "location": {
                                  "x": 651,
                                  "y": 125
                                },
                                "type": "chance",
                                "expectedValue": 73.33333333333337,
                                "p10": 53,
                                "p90": 96
                              },
                              "expectedValue": 0,
                              "p10": 0,
                              "p90": 0,
                              "optimal": true
                            }
                          ],
                          "name": "Risk",
                          "code": "",
                          "expressionScope": {
                            "TaskDurationA": 42,
                            "TaskDurationB": 38,
                            "TaskDurationC1": 54,
                            "TaskDurationC2": 67
                          },
                          "folded": false,
                          "location": {
                            "x": 491,
                            "y": 68
                          },
                          "type": "chance",
                          "expectedValue": 60.266666666666666,
                          "p10": 43,
                          "p90": 80
                        },
                        "expectedValue": 46.33333333333334,
                        "p10": 33,
                        "p90": 62,
                        "optimal": true
                      }
                    ],
                    "name": "Task B",
                    "code": "",
                    "expressionScope": {
                      "TaskDurationA": 42,
                      "TaskDurationB": 38,
                      "TaskDurationC1": 54,
                      "TaskDurationC2": 67
                    },
                    "folded": false,
                    "location": {
                      "x": 331,
                      "y": 68
                    },
                    "type": "chance",
                    "expectedValue": 106.6,
                    "p10": 84,
                    "p90": 131
                  },
                  "expectedValue": 51.33333333333333,
                  "p10": 39,
                  "p90": 66,
                  "optimal": true
                }
              ],
              "name": "Task A",
              "code": "",
              "expressionScope": {
                "TaskDurationA": 42,
                "TaskDurationB": 38,
                "TaskDurationC1": 54,
                "TaskDurationC2": 67
              },
              "folded": false,
              "location": {
                "x": 171,
                "y": 68
              },
              "type": "chance",
              "expectedValue": 157.93333333333342,
              "p10": 132,
              "p90": 186
            },
            "expectedValue": 0,
            "p10": 0,
            "p90": 0,
            "optimal": true
          }
        ],
        "name": "Total duration",
        "code": "",
        "expressionScope": {
          "TaskDurationA": 42,
          "TaskDurationB": 38,
          "TaskDurationC1": 54,
          "TaskDurationC2": 67
        },
        "folded": false,
        "location": {
          "x": 11,
          "y": 68
        },
        "type": "decision",
        "expectedValue": 157.93333333333345,
        "p10": 132,
        "p90": 186,
        "charts": [
          {
            "nodeId": "7da346b2-3058-2a66-cb46-8317f5c6395d",
            "x": -73.999995470047,
            "y": 108,
            "type": "distribution",
            "active": true
          }
        ]
      }
    ],
    "texts": [
      {
        "value": "PROJECT TIMELINE",
        "location": {
          "x": -60.99999165534973,
          "y": -68.99826049804688
        }
      }
    ],
    "payoffNames": [],
    "defaultCriterion1Weight": 1,
    "weightLowerBound": 0,
    "weightUpperBound": "Infinity"
  },
  "sensitivityAnalysis": {},
  "definitionsDialog": {
    "valueList": [
      {
        "name": "TaskDurationA",
        "value": "42",
        "low": 37,
        "high": 63,
        "distribution": "triangular",
        "error": ""
      },
      {
        "name": "TaskDurationB",
        "value": "38",
        "low": 31,
        "high": 59,
        "distribution": "triangular",
        "error": ""
      },
      {
        "name": "TaskDurationC1",
        "value": "54",
        "low": 41,
        "high": 68,
        "distribution": "triangular",
        "error": ""
      },
      {
        "name": "TaskDurationC2",
        "value": "67",
        "low": 51,
        "high": 93,
        "distribution": "triangular",
        "error": ""
      }
    ]
  }
}