module.exports={
  "SilverDecisions": "1.1.0",
  "buildTimestamp": 1710935291638,
  "savetime": "2024-05-08T12:35:39.012Z",
  "lng": "en",
  "viewMode": "criterion1",
  "rule": "expected-value-maximization",
  "title": "Step15 (2)",
  "description": "",
  "format": {
    "locales": "en",
    "payoff1": {
      "style": "currency",
      "currency": "USD",
      "currencyDisplay": "symbol",
      "minimumFractionDigits": 0,
      "maximumFractionDigits": 0,
      "useGrouping": true
    },
    "payoff2": {
      "style": "percent",
      "currency": "USD",
      "currencyDisplay": "symbol",
      "minimumFractionDigits": 0,
      "maximumFractionDigits": 0,
      "useGrouping": true
    },
    "probability": {
      "style": "percent",
      "minimumFractionDigits": 0,
      "maximumFractionDigits": 0,
      "useGrouping": true
    }
  },
  "treeDesigner": {
    "margin": {
      "left": 550,
      "right": 500,
      "top": 200,
      "bottom": 100
    },
    "scale": 1,
    "lng": "en",
    "layout": {
      "type": "cluster",
      "nodeSize": 22,
      "limitNodePositioning": true,
      "limitTextPositioning": true,
      "gridHeight": 110,
      "gridWidth": 275,
      "edgeSlantWidthMax": 0
    },
    "fontFamily": "sans-serif",
    "fontSize": "12px",
    "fontWeight": "normal",
    "fontStyle": "normal",
    "node": {
      "strokeWidth": "2px",
      "optimal": {
        "stroke": "#006f00",
        "strokeWidth": "2px"
      },
      "label": {
        "fontSize": "1em",
        "color": "black"
      },
      "payoff": {
        "fontSize": "1em",
        "color": "black",
        "negativeColor": "#b60000"
      },
      "decision": {
        "fill": "#ff7777",
        "stroke": "#660000",
        "selected": {
          "fill": "#aa3333"
        }
      },
      "chance": {
        "fill": "#ffff44",
        "stroke": "#666600",
        "selected": {
          "fill": "#aaaa00"
        }
      },
      "terminal": {
        "fill": "#44ff44",
        "stroke": "black",
        "selected": {
          "fill": "#00aa00"
        },
        "payoff": {
          "fontSize": "1em",
          "color": "black",
          "negativeColor": "#b60000"
        }
      }
    },
    "edge": {
      "stroke": "#424242",
      "strokeWidth": "1.5",
      "optimal": {
        "stroke": "#006f00",
        "strokeWidth": "2.4"
      },
      "selected": {
        "stroke": "#045ad1",
        "strokeWidth": "3.5"
      },
      "label": {
        "fontSize": "1em",
        "color": "back"
      },
      "payoff": {
        "fontSize": "1em",
        "color": "black",
        "negativeColor": "#b60000"
      }
    },
    "probability": {
      "fontSize": "1em",
      "color": "#0000d7"
    },
    "title": {
      "fontSize": "16px",
      "fontWeight": "bold",
      "fontStyle": "normal",
      "color": "#000000",
      "margin": {
        "top": 15,
        "bottom": 10
      }
    },
    "description": {
      "show": true,
      "fontSize": "12px",
      "fontWeight": "bold",
      "fontStyle": "normal",
      "color": "#000000",
      "margin": {
        "top": 5,
        "bottom": 10
      }
    },
    "readOnly": false,
    "disableAnimations": false,
    "forceFullEdgeRedraw": false,
    "hideLabels": false,
    "hidePayoffs": false,
    "hideExpectedValues": false,
    "hideProbabilities": false,
    "hideCharts": false,
    "hideTextboxes": false,
    "hideMilestones": false,
    "raw": false,
    "payoffNames": [
      null,
      null
    ],
    "maxPayoffsToDisplay": 1,
    "milestoneNames": [
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    ]
  },
  "data": {
    "code": "PointA=148\nPointB=163\nPointC=-42\nPointD=87\nPointE=32\n",
    "expressionScope": {
      "PointA": 148,
      "PointB": 163,
      "PointC": -42,
      "PointD": 87,
      "PointE": 32
    },
    "trees": [
      {
        "computed": {
          "expected-value-maximization": {
            "childrenPayoff": [
              "142 15246561238495/17592186044416"
            ],
            "payoff": [
              "142 4764550387029/5497558138880"
            ],
            "optimal": true
          },
          "expected-value-minimization": {
            "childrenPayoff": [
              "80 4401998035373/8796093022208"
            ],
            "payoff": [
              "80 4401998035373/8796093022208"
            ],
            "optimal": true
          },
          "maxi-min": {
            "childrenPayoff": [
              "70 1/70368744177664"
            ],
            "payoff": [
              "70 1/70368744177664"
            ],
            "optimal": true
          },
          "maxi-max": {
            "childrenPayoff": [
              "220 11728124029609/17592186044416"
            ],
            "payoff": [
              "220 11728124029609/17592186044416"
            ],
            "optimal": true
          },
          "mini-min": {
            "childrenPayoff": [
              "-38 23456248059221/35184372088832"
            ],
            "payoff": [
              "-38 23456248059221/35184372088832"
            ],
            "optimal": true
          },
          "mini-max": {
            "childrenPayoff": [
              "91 31612194151/35184372088832"
            ],
            "payoff": [
              "91 31612194151/35184372088832"
            ],
            "optimal": true
          },
          "min-max": {
            "childrenPayoff": [
              "80 4401998035373/8796093022208",
              "0"
            ],
            "combinedPayoff": "-80 4401998035373/8796093022208",
            "payoff": [
              "80 4401998035373/8796093022208",
              "0"
            ],
            "optimal": true
          },
          "max-min": {
            "childrenPayoff": [
              "142 4764550387029/5497558138880",
              "0"
            ],
            "combinedPayoff": "142 4764550387029/5497558138880",
            "payoff": [
              "142 4764550387029/5497558138880",
              "0"
            ],
            "optimal": true
          },
          "min-min": {
            "childrenPayoff": [
              "80 4401998035373/8796093022208",
              "0"
            ],
            "combinedPayoff": "-80 4401998035373/8796093022208",
            "payoff": [
              "80 4401998035373/8796093022208",
              "0"
            ],
            "optimal": true
          },
          "max-max": {
            "childrenPayoff": [
              "142 4764550387029/5497558138880",
              "0"
            ],
            "combinedPayoff": "142 4764550387029/5497558138880",
            "payoff": [
              "142 4764550387029/5497558138880",
              "0"
            ],
            "optimal": true
          }
        },
        "childEdges": [
          {
            "computed": {
              "payoff": [
                "0",
                "0"
              ],
              "expected-value-maximization": {
                "probability": 1,
                "optimal": true
              },
              "expected-value-minimization": {
                "probability": 0,
                "optimal": false
              },
              "maxi-min": {
                "probability": 0,
                "optimal": false
              },
              "maxi-max": {
                "probability": 1,
                "optimal": true
              },
              "mini-min": {
                "probability": 1,
                "optimal": true
              },
              "mini-max": {
                "probability": 0,
                "optimal": false
              },
              "min-max": {
                "probability": 0,
                "optimal": false
              },
              "max-min": {
                "probability": 1,
                "optimal": true
              },
              "min-min": {
                "probability": 0,
                "optimal": false
              },
              "max-max": {
                "probability": 1,
                "optimal": true
              }
            },
            "name": "Experimental",
            "payoff": [
              0,
              0
            ],
            "childNode": {
              "computed": {
                "expected-value-maximization": {
                  "childrenPayoff": [
                    "142 7623280619247/8796093022208"
                  ],
                  "payoff": [
                    "142 4764550387029/5497558138880"
                  ],
                  "optimal": true
                },
                "expected-value-minimization": {
                  "childrenPayoff": [
                    "94 2727199869269/27487790694400"
                  ],
                  "payoff": [
                    "94 2727199869269/27487790694400"
                  ]
                },
                "maxi-min": {
                  "childrenPayoff": [
                    "-38 23456248059221/35184372088832"
                  ],
                  "payoff": [
                    "-38 23456248059221/35184372088832"
                  ]
                },
                "maxi-max": {
                  "childrenPayoff": [
                    "220 11728124029609/17592186044416"
                  ],
                  "payoff": [
                    "220 11728124029609/17592186044416"
                  ],
                  "optimal": true
                },
                "mini-min": {
                  "childrenPayoff": [
                    "-38 23456248059221/35184372088832"
                  ],
                  "payoff": [
                    "-38 23456248059221/35184372088832"
                  ],
                  "optimal": true
                },
                "mini-max": {
                  "childrenPayoff": [
                    "150 8786228244987/8796093022208"
                  ],
                  "payoff": [
                    "150 8786228244987/8796093022208"
                  ]
                },
                "min-max": {
                  "childrenPayoff": [
                    "94 2727199869269/27487790694400",
                    "0"
                  ],
                  "combinedPayoff": "-94 2727199869269/27487790694400",
                  "payoff": [
                    "94 2727199869269/27487790694400",
                    "0"
                  ]
                },
                "max-min": {
                  "childrenPayoff": [
                    "142 4764550387029/5497558138880",
                    "0"
                  ],
                  "combinedPayoff": "142 4764550387029/5497558138880",
                  "payoff": [
                    "142 4764550387029/5497558138880",
                    "0"
                  ],
                  "optimal": true
                },
                "min-min": {
                  "childrenPayoff": [
                    "94 2727199869269/27487790694400",
                    "0"
                  ],
                  "combinedPayoff": "-94 2727199869269/27487790694400",
                  "payoff": [
                    "94 2727199869269/27487790694400",
                    "0"
                  ]
                },
                "max-max": {
                  "childrenPayoff": [
                    "142 4764550387029/5497558138880",
                    "0"
                  ],
                  "combinedPayoff": "142 4764550387029/5497558138880",
                  "payoff": [
                    "142 4764550387029/5497558138880",
                    "0"
                  ],
                  "optimal": true
                }
              },
              "childEdges": [
                {
                  "computed": {
                    "payoff": [
                      "0",
                      "0"
                    ],
                    "probability": "7/10",
                    "expected-value-maximization": {
                      "probability": "7/10",
                      "optimal": true
                    },
                    "expected-value-minimization": {
                      "probability": "7/10"
                    },
                    "maxi-min": {
                      "probability": 0
                    },
                    "maxi-max": {
                      "probability": 1,
                      "optimal": true
                    },
                    "mini-min": {
                      "probability": 0,
                      "optimal": false
                    },
                    "mini-max": {
                      "probability": 1
                    },
                    "min-max": {
                      "probability": "7/10"
                    },
                    "max-min": {
                      "probability": "7/10",
                      "optimal": true
                    },
                    "min-min": {
                      "probability": "7/10"
                    },
                    "max-max": {
                      "probability": "7/10",
                      "optimal": true
                    }
                  },
                  "name": "Success",
                  "probability": ".7",
                  "payoff": [
                    0,
                    0
                  ],
                  "childNode": {
                    "computed": {
                      "expected-value-maximization": {
                        "childrenPayoff": [
                          "220 11728124029609/17592186044416"
                        ],
                        "payoff": [
                          "220 11728124029609/17592186044416"
                        ],
                        "optimal": true
                      },
                      "expected-value-minimization": {
                        "childrenPayoff": [
                          "150 8786228244987/8796093022208"
                        ],
                        "payoff": [
                          "150 8786228244987/8796093022208"
                        ]
                      },
                      "maxi-min": {
                        "childrenPayoff": [
                          "220 11728124029609/17592186044416"
                        ],
                        "payoff": [
                          "220 11728124029609/17592186044416"
                        ]
                      },
                      "maxi-max": {
                        "childrenPayoff": [
                          "220 11728124029609/17592186044416"
                        ],
                        "payoff": [
                          "220 11728124029609/17592186044416"
                        ],
                        "optimal": true
                      },
                      "mini-min": {
                        "childrenPayoff": [
                          "150 8786228244987/8796093022208"
                        ],
                        "payoff": [
                          "150 8786228244987/8796093022208"
                        ]
                      },
                      "mini-max": {
                        "childrenPayoff": [
                          "150 8786228244987/8796093022208"
                        ],
                        "payoff": [
                          "150 8786228244987/8796093022208"
                        ]
                      },
                      "min-max": {
                        "childrenPayoff": [
                          "150 8786228244987/8796093022208",
                          "0"
                        ],
                        "combinedPayoff": "-150 8786228244987/8796093022208",
                        "payoff": [
                          "150 8786228244987/8796093022208",
                          "0"
                        ]
                      },
                      "max-min": {
                        "childrenPayoff": [
                          "220 11728124029609/17592186044416",
                          "0"
                        ],
                        "combinedPayoff": "220 11728124029609/17592186044416",
                        "payoff": [
                          "220 11728124029609/17592186044416",
                          "0"
                        ],
                        "optimal": true
                      },
                      "min-min": {
                        "childrenPayoff": [
                          "150 8786228244987/8796093022208",
                          "0"
                        ],
                        "combinedPayoff": "-150 8786228244987/8796093022208",
                        "payoff": [
                          "150 8786228244987/8796093022208",
                          "0"
                        ]
                      },
                      "max-max": {
                        "childrenPayoff": [
                          "220 11728124029609/17592186044416",
                          "0"
                        ],
                        "combinedPayoff": "220 11728124029609/17592186044416",
                        "payoff": [
                          "220 11728124029609/17592186044416",
                          "0"
                        ],
                        "optimal": true
                      }
                    },
                    "childEdges": [
                      {
                        "computed": {
                          "payoff": [
                            "220 11728124029609/17592186044416",
                            "0"
                          ],
                          "expected-value-maximization": {
                            "probability": 1,
                            "optimal": true
                          },
                          "expected-value-minimization": {
                            "probability": 0
                          },
                          "maxi-min": {
                            "probability": 1
                          },
                          "maxi-max": {
                            "probability": 1,
                            "optimal": true
                          },
                          "mini-min": {
                            "probability": 0
                          },
                          "mini-max": {
                            "probability": 0
                          },
                          "min-max": {
                            "probability": 0
                          },
                          "max-min": {
                            "probability": 1,
                            "optimal": true
                          },
                          "min-min": {
                            "probability": 0
                          },
                          "max-max": {
                            "probability": 1,
                            "optimal": true
                          }
                        },
                        "name": "Invest in new",
                        "payoff": [
                          "PointA",
                          0
                        ],
                        "childNode": {
                          "computed": {
                            "expected-value-maximization": {
                              "aggregatedPayoff": [
                                "220 11728124029609/17592186044416"
                              ],
                              "probabilityToEnter": "7/10",
                              "payoff": [
                                "220 11728124029609/17592186044416"
                              ],
                              "optimal": true
                            },
                            "expected-value-minimization": {
                              "aggregatedPayoff": [
                                "220 11728124029609/17592186044416"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "220 11728124029609/17592186044416"
                              ]
                            },
                            "maxi-min": {
                              "aggregatedPayoff": [
                                "220 11728124029609/17592186044416"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "220 11728124029609/17592186044416"
                              ]
                            },
                            "maxi-max": {
                              "aggregatedPayoff": [
                                "220 11728124029609/17592186044416"
                              ],
                              "probabilityToEnter": "1",
                              "payoff": [
                                "220 11728124029609/17592186044416"
                              ],
                              "optimal": true
                            },
                            "mini-min": {
                              "aggregatedPayoff": [
                                "220 11728124029609/17592186044416"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "220 11728124029609/17592186044416"
                              ]
                            },
                            "mini-max": {
                              "aggregatedPayoff": [
                                "220 11728124029609/17592186044416"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "220 11728124029609/17592186044416"
                              ]
                            },
                            "min-max": {
                              "aggregatedPayoff": [
                                "220 11728124029609/17592186044416",
                                "0"
                              ],
                              "probabilityToEnter": 0,
                              "combinedPayoff": "-220 11728124029609/17592186044416",
                              "payoff": [
                                "220 11728124029609/17592186044416",
                                "0"
                              ]
                            },
                            "max-min": {
                              "aggregatedPayoff": [
                                "220 11728124029609/17592186044416",
                                "0"
                              ],
                              "probabilityToEnter": "7/10",
                              "combinedPayoff": "220 11728124029609/17592186044416",
                              "payoff": [
                                "220 11728124029609/17592186044416",
                                "0"
                              ],
                              "optimal": true
                            },
                            "min-min": {
                              "aggregatedPayoff": [
                                "220 11728124029609/17592186044416",
                                "0"
                              ],
                              "probabilityToEnter": 0,
                              "combinedPayoff": "-220 11728124029609/17592186044416",
                              "payoff": [
                                "220 11728124029609/17592186044416",
                                "0"
                              ]
                            },
                            "max-max": {
                              "aggregatedPayoff": [
                                "220 11728124029609/17592186044416",
                                "0"
                              ],
                              "probabilityToEnter": "7/10",
                              "combinedPayoff": "220 11728124029609/17592186044416",
                              "payoff": [
                                "220 11728124029609/17592186044416",
                                "0"
                              ],
                              "optimal": true
                            }
                          },
                          "childEdges": [],
                          "name": "A",
                          "code": "",
                          "expressionScope": {
                            "PointA": 148,
                            "PointB": 163,
                            "PointC": -42,
                            "PointD": 87,
                            "PointE": 32
                          },
                          "folded": false,
                          "location": {
                            "x": 836,
                            "y": 11
                          },
                          "type": "terminal",
                          "milestone": null,
                          "expectedValue": 220.66666666666657,
                          "p10": 96,
                          "p90": 368,
                          "charts": [
                            {
                              "nodeId": "ad7c0d1d-00e2-ab9a-f20d-fad417f1440f",
                              "x": 51,
                              "y": -38.99999809265137,
                              "type": "distribution",
                              "active": true
                            }
                          ]
                        },
                        "expectedValue": 220.66666666666657,
                        "p10": 96,
                        "p90": 368,
                        "optimal": true
                      },
                      {
                        "computed": {
                          "payoff": [
                            "150 8786228244987/8796093022208",
                            "0"
                          ],
                          "expected-value-maximization": {
                            "probability": 0,
                            "optimal": false
                          },
                          "expected-value-minimization": {
                            "probability": 1
                          },
                          "maxi-min": {
                            "probability": 0
                          },
                          "maxi-max": {
                            "probability": 0,
                            "optimal": false
                          },
                          "mini-min": {
                            "probability": 1
                          },
                          "mini-max": {
                            "probability": 1
                          },
                          "min-max": {
                            "probability": 1
                          },
                          "max-min": {
                            "probability": 0,
                            "optimal": false
                          },
                          "min-min": {
                            "probability": 1
                          },
                          "max-max": {
                            "probability": 0,
                            "optimal": false
                          }
                        },
                        "name": "Adapt old",
                        "payoff": [
                          "PointB",
                          0
                        ],
                        "childNode": {
                          "computed": {
                            "expected-value-maximization": {
                              "aggregatedPayoff": [
                                "150 8786228244987/8796093022208"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "150 8786228244987/8796093022208"
                              ]
                            },
                            "expected-value-minimization": {
                              "aggregatedPayoff": [
                                "150 8786228244987/8796093022208"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "150 8786228244987/8796093022208"
                              ]
                            },
                            "maxi-min": {
                              "aggregatedPayoff": [
                                "150 8786228244987/8796093022208"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "150 8786228244987/8796093022208"
                              ]
                            },
                            "maxi-max": {
                              "aggregatedPayoff": [
                                "150 8786228244987/8796093022208"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "150 8786228244987/8796093022208"
                              ]
                            },
                            "mini-min": {
                              "aggregatedPayoff": [
                                "150 8786228244987/8796093022208"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "150 8786228244987/8796093022208"
                              ]
                            },
                            "mini-max": {
                              "aggregatedPayoff": [
                                "150 8786228244987/8796093022208"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "150 8786228244987/8796093022208"
                              ]
                            },
                            "min-max": {
                              "aggregatedPayoff": [
                                "150 8786228244987/8796093022208",
                                "0"
                              ],
                              "probabilityToEnter": 0,
                              "combinedPayoff": "-150 8786228244987/8796093022208",
                              "payoff": [
                                "150 8786228244987/8796093022208",
                                "0"
                              ]
                            },
                            "max-min": {
                              "aggregatedPayoff": [
                                "150 8786228244987/8796093022208",
                                "0"
                              ],
                              "probabilityToEnter": 0,
                              "combinedPayoff": "150 8786228244987/8796093022208",
                              "payoff": [
                                "150 8786228244987/8796093022208",
                                "0"
                              ]
                            },
                            "min-min": {
                              "aggregatedPayoff": [
                                "150 8786228244987/8796093022208",
                                "0"
                              ],
                              "probabilityToEnter": 0,
                              "combinedPayoff": "-150 8786228244987/8796093022208",
                              "payoff": [
                                "150 8786228244987/8796093022208",
                                "0"
                              ]
                            },
                            "max-max": {
                              "aggregatedPayoff": [
                                "150 8786228244987/8796093022208",
                                "0"
                              ],
                              "probabilityToEnter": 0,
                              "combinedPayoff": "150 8786228244987/8796093022208",
                              "payoff": [
                                "150 8786228244987/8796093022208",
                                "0"
                              ]
                            }
                          },
                          "childEdges": [],
                          "name": "B",
                          "code": "",
                          "expressionScope": {
                            "PointA": 148,
                            "PointB": 163,
                            "PointC": -42,
                            "PointD": 87,
                            "PointE": 32
                          },
                          "folded": false,
                          "location": {
                            "x": 836,
                            "y": 121
                          },
                          "type": "terminal",
                          "expectedValue": 150.99887850467292,
                          "p10": 90,
                          "p90": 208,
                          "charts": [
                            {
                              "nodeId": "1ef06609-ca9d-d0d6-7c85-ff5bee870f04",
                              "x": 50,
                              "y": -38,
                              "type": "distribution",
                              "active": true
                            }
                          ]
                        },
                        "expectedValue": 150.99887850467292,
                        "p10": 90,
                        "p90": 208,
                        "optimal": false
                      }
                    ],
                    "name": "Production \nfacility",
                    "code": "",
                    "expressionScope": {
                      "PointA": 148,
                      "PointB": 163,
                      "PointC": -42,
                      "PointD": 87,
                      "PointE": 32
                    },
                    "folded": false,
                    "location": {
                      "x": 561,
                      "y": 66
                    },
                    "type": "decision",
                    "milestone": null,
                    "expectedValue": 220.66666666666657,
                    "p10": 96,
                    "p90": 368
                  },
                  "expectedValue": 0,
                  "p10": 0,
                  "p90": 0,
                  "optimal": true
                },
                {
                  "computed": {
                    "payoff": [
                      "-38 23456248059221/35184372088832",
                      "0"
                    ],
                    "probability": "3/10",
                    "expected-value-maximization": {
                      "probability": "3/10",
                      "optimal": true
                    },
                    "expected-value-minimization": {
                      "probability": "3/10"
                    },
                    "maxi-min": {
                      "probability": 1
                    },
                    "maxi-max": {
                      "probability": 0,
                      "optimal": false
                    },
                    "mini-min": {
                      "probability": 1,
                      "optimal": true
                    },
                    "mini-max": {
                      "probability": 0
                    },
                    "min-max": {
                      "probability": "3/10"
                    },
                    "max-min": {
                      "probability": "3/10",
                      "optimal": true
                    },
                    "min-min": {
                      "probability": "3/10"
                    },
                    "max-max": {
                      "probability": "3/10",
                      "optimal": true
                    }
                  },
                  "name": "Failure",
                  "probability": ".3",
                  "payoff": [
                    "PointC",
                    0
                  ],
                  "childNode": {
                    "computed": {
                      "expected-value-maximization": {
                        "aggregatedPayoff": [
                          "-38 23456248059221/35184372088832"
                        ],
                        "probabilityToEnter": "3/10",
                        "payoff": [
                          "-38 23456248059221/35184372088832"
                        ],
                        "optimal": true
                      },
                      "expected-value-minimization": {
                        "aggregatedPayoff": [
                          "-38 23456248059221/35184372088832"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "-38 23456248059221/35184372088832"
                        ]
                      },
                      "maxi-min": {
                        "aggregatedPayoff": [
                          "-38 23456248059221/35184372088832"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "-38 23456248059221/35184372088832"
                        ]
                      },
                      "maxi-max": {
                        "aggregatedPayoff": [
                          "-38 23456248059221/35184372088832"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "-38 23456248059221/35184372088832"
                        ]
                      },
                      "mini-min": {
                        "aggregatedPayoff": [
                          "-38 23456248059221/35184372088832"
                        ],
                        "probabilityToEnter": "1",
                        "payoff": [
                          "-38 23456248059221/35184372088832"
                        ],
                        "optimal": true
                      },
                      "mini-max": {
                        "aggregatedPayoff": [
                          "-38 23456248059221/35184372088832"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "-38 23456248059221/35184372088832"
                        ]
                      },
                      "min-max": {
                        "aggregatedPayoff": [
                          "-38 23456248059221/35184372088832",
                          "0"
                        ],
                        "probabilityToEnter": 0,
                        "combinedPayoff": "38 23456248059221/35184372088832",
                        "payoff": [
                          "-38 23456248059221/35184372088832",
                          "0"
                        ]
                      },
                      "max-min": {
                        "aggregatedPayoff": [
                          "-38 23456248059221/35184372088832",
                          "0"
                        ],
                        "probabilityToEnter": "3/10",
                        "combinedPayoff": "-38 23456248059221/35184372088832",
                        "payoff": [
                          "-38 23456248059221/35184372088832",
                          "0"
                        ],
                        "optimal": true
                      },
                      "min-min": {
                        "aggregatedPayoff": [
                          "-38 23456248059221/35184372088832",
                          "0"
                        ],
                        "probabilityToEnter": 0,
                        "combinedPayoff": "38 23456248059221/35184372088832",
                        "payoff": [
                          "-38 23456248059221/35184372088832",
                          "0"
                        ]
                      },
                      "max-max": {
                        "aggregatedPayoff": [
                          "-38 23456248059221/35184372088832",
                          "0"
                        ],
                        "probabilityToEnter": "3/10",
                        "combinedPayoff": "-38 23456248059221/35184372088832",
                        "payoff": [
                          "-38 23456248059221/35184372088832",
                          "0"
                        ],
                        "optimal": true
                      }
                    },
                    "childEdges": [],
                    "name": "C",
                    "code": "",
                    "expressionScope": {
                      "PointA": 148,
                      "PointB": 163,
                      "PointC": -42,
                      "PointD": 87,
                      "PointE": 32
                    },
                    "folded": false,
                    "location": {
                      "x": 836,
                      "y": 253.00000000000003
                    },
                    "type": "terminal",
                    "expectedValue": -38.66666666666666,
                    "p10": -68,
                    "p90": -8,
                    "charts": [
                      {
                        "nodeId": "d27593c1-d0cb-30a3-2676-416dc8141dad",
                        "x": 50,
                        "y": -41.000003814697266,
                        "type": "distribution",
                        "active": true
                      }
                    ]
                  },
                  "expectedValue": -38.66666666666666,
                  "p10": -68,
                  "p90": -8,
                  "optimal": true
                }
              ],
              "name": "R&D \noutcome,\n(X)",
              "code": "",
              "expressionScope": {
                "PointA": 148,
                "PointB": 163,
                "PointC": -42,
                "PointD": 87,
                "PointE": 32
              },
              "folded": false,
              "location": {
                "x": 286,
                "y": 159.5
              },
              "type": "chance",
              "milestone": null,
              "expectedValue": 142.86666666666667,
              "p10": -48,
              "p90": 342
            },
            "expectedValue": 0,
            "p10": 0,
            "p90": 0,
            "optimal": true
          },
          {
            "computed": {
              "payoff": [
                "0",
                "0"
              ],
              "expected-value-maximization": {
                "probability": 0,
                "optimal": false
              },
              "expected-value-minimization": {
                "probability": 1,
                "optimal": true
              },
              "maxi-min": {
                "probability": 1,
                "optimal": true
              },
              "maxi-max": {
                "probability": 0,
                "optimal": false
              },
              "mini-min": {
                "probability": 0,
                "optimal": false
              },
              "mini-max": {
                "probability": 1,
                "optimal": true
              },
              "min-max": {
                "probability": 1,
                "optimal": true
              },
              "max-min": {
                "probability": 0,
                "optimal": false
              },
              "min-min": {
                "probability": 1,
                "optimal": true
              },
              "max-max": {
                "probability": 0,
                "optimal": false
              }
            },
            "name": "Proven",
            "payoff": [
              0,
              0
            ],
            "childNode": {
              "computed": {
                "expected-value-maximization": {
                  "childrenPayoff": [
                    "80 35215984282983/70368744177664"
                  ],
                  "payoff": [
                    "80 4401998035373/8796093022208"
                  ]
                },
                "expected-value-minimization": {
                  "childrenPayoff": [
                    "80 4401998035373/8796093022208"
                  ],
                  "payoff": [
                    "80 4401998035373/8796093022208"
                  ],
                  "optimal": true
                },
                "maxi-min": {
                  "childrenPayoff": [
                    "70 1/70368744177664"
                  ],
                  "payoff": [
                    "70 1/70368744177664"
                  ],
                  "optimal": true
                },
                "maxi-max": {
                  "childrenPayoff": [
                    "91 31612194151/35184372088832"
                  ],
                  "payoff": [
                    "91 31612194151/35184372088832"
                  ]
                },
                "mini-min": {
                  "childrenPayoff": [
                    "70 1/70368744177664"
                  ],
                  "payoff": [
                    "70 1/70368744177664"
                  ]
                },
                "mini-max": {
                  "childrenPayoff": [
                    "91 31612194151/35184372088832"
                  ],
                  "payoff": [
                    "91 31612194151/35184372088832"
                  ],
                  "optimal": true
                },
                "min-max": {
                  "childrenPayoff": [
                    "80 4401998035373/8796093022208",
                    "0"
                  ],
                  "combinedPayoff": "-80 4401998035373/8796093022208",
                  "payoff": [
                    "80 4401998035373/8796093022208",
                    "0"
                  ],
                  "optimal": true
                },
                "max-min": {
                  "childrenPayoff": [
                    "80 4401998035373/8796093022208",
                    "0"
                  ],
                  "combinedPayoff": "80 4401998035373/8796093022208",
                  "payoff": [
                    "80 4401998035373/8796093022208",
                    "0"
                  ]
                },
                "min-min": {
                  "childrenPayoff": [
                    "80 4401998035373/8796093022208",
                    "0"
                  ],
                  "combinedPayoff": "-80 4401998035373/8796093022208",
                  "payoff": [
                    "80 4401998035373/8796093022208",
                    "0"
                  ],
                  "optimal": true
                },
                "max-max": {
                  "childrenPayoff": [
                    "80 4401998035373/8796093022208",
                    "0"
                  ],
                  "combinedPayoff": "80 4401998035373/8796093022208",
                  "payoff": [
                    "80 4401998035373/8796093022208",
                    "0"
                  ]
                }
              },
              "childEdges": [
                {
                  "computed": {
                    "payoff": [
                      "91 31612194151/35184372088832",
                      "0"
                    ],
                    "probability": "1/2",
                    "expected-value-maximization": {
                      "probability": "1/2"
                    },
                    "expected-value-minimization": {
                      "probability": "1/2",
                      "optimal": true
                    },
                    "maxi-min": {
                      "probability": 0,
                      "optimal": false
                    },
                    "maxi-max": {
                      "probability": 1
                    },
                    "mini-min": {
                      "probability": 0
                    },
                    "mini-max": {
                      "probability": 1,
                      "optimal": true
                    },
                    "min-max": {
                      "probability": "1/2",
                      "optimal": true
                    },
                    "max-min": {
                      "probability": "1/2"
                    },
                    "min-min": {
                      "probability": "1/2",
                      "optimal": true
                    },
                    "max-max": {
                      "probability": "1/2"
                    }
                  },
                  "name": "Regained market share",
                  "probability": "#",
                  "payoff": [
                    "PointD",
                    0
                  ],
                  "childNode": {
                    "computed": {
                      "expected-value-maximization": {
                        "aggregatedPayoff": [
                          "91 15806097075/17592186044416"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "91 31612194151/35184372088832"
                        ]
                      },
                      "expected-value-minimization": {
                        "aggregatedPayoff": [
                          "91 31612194151/35184372088832"
                        ],
                        "probabilityToEnter": "1/2",
                        "payoff": [
                          "91 31612194151/35184372088832"
                        ],
                        "optimal": true
                      },
                      "maxi-min": {
                        "aggregatedPayoff": [
                          "91 31612194151/35184372088832"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "91 31612194151/35184372088832"
                        ]
                      },
                      "maxi-max": {
                        "aggregatedPayoff": [
                          "91 31612194151/35184372088832"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "91 31612194151/35184372088832"
                        ]
                      },
                      "mini-min": {
                        "aggregatedPayoff": [
                          "91 31612194151/35184372088832"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "91 31612194151/35184372088832"
                        ]
                      },
                      "mini-max": {
                        "aggregatedPayoff": [
                          "91 31612194151/35184372088832"
                        ],
                        "probabilityToEnter": "1",
                        "payoff": [
                          "91 31612194151/35184372088832"
                        ],
                        "optimal": true
                      },
                      "min-max": {
                        "aggregatedPayoff": [
                          "91 31612194151/35184372088832",
                          "0"
                        ],
                        "probabilityToEnter": "1/2",
                        "combinedPayoff": "-91 31612194151/35184372088832",
                        "payoff": [
                          "91 31612194151/35184372088832",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-min": {
                        "aggregatedPayoff": [
                          "91 31612194151/35184372088832",
                          "0"
                        ],
                        "probabilityToEnter": 0,
                        "combinedPayoff": "91 31612194151/35184372088832",
                        "payoff": [
                          "91 31612194151/35184372088832",
                          "0"
                        ]
                      },
                      "min-min": {
                        "aggregatedPayoff": [
                          "91 31612194151/35184372088832",
                          "0"
                        ],
                        "probabilityToEnter": "1/2",
                        "combinedPayoff": "-91 31612194151/35184372088832",
                        "payoff": [
                          "91 31612194151/35184372088832",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-max": {
                        "aggregatedPayoff": [
                          "91 31612194151/35184372088832",
                          "0"
                        ],
                        "probabilityToEnter": 0,
                        "combinedPayoff": "91 31612194151/35184372088832",
                        "payoff": [
                          "91 31612194151/35184372088832",
                          "0"
                        ]
                      }
                    },
                    "childEdges": [],
                    "name": "D",
                    "code": "",
                    "expressionScope": {
                      "PointA": 148,
                      "PointB": 163,
                      "PointC": -42,
                      "PointD": 87,
                      "PointE": 32
                    },
                    "folded": false,
                    "location": {
                      "x": 836,
                      "y": 385.00000000000006
                    },
                    "type": "terminal",
                    "expectedValue": 91.00089847259659,
                    "p10": 54,
                    "p90": 128,
                    "charts": [
                      {
                        "nodeId": "816ce791-beb5-a1de-5aa5-426f96ed6f79",
                        "x": 51,
                        "y": -38,
                        "type": "distribution",
                        "active": true
                      }
                    ]
                  },
                  "expectedValue": 91.00089847259662,
                  "p10": 54,
                  "p90": 128,
                  "optimal": false
                },
                {
                  "computed": {
                    "payoff": [
                      "70 1/70368744177664",
                      "0"
                    ],
                    "probability": "1/2",
                    "expected-value-maximization": {
                      "probability": "1/2"
                    },
                    "expected-value-minimization": {
                      "probability": "1/2",
                      "optimal": true
                    },
                    "maxi-min": {
                      "probability": 1,
                      "optimal": true
                    },
                    "maxi-max": {
                      "probability": 0
                    },
                    "mini-min": {
                      "probability": 1
                    },
                    "mini-max": {
                      "probability": 0,
                      "optimal": false
                    },
                    "min-max": {
                      "probability": "1/2",
                      "optimal": true
                    },
                    "max-min": {
                      "probability": "1/2"
                    },
                    "min-min": {
                      "probability": "1/2",
                      "optimal": true
                    },
                    "max-max": {
                      "probability": "1/2"
                    }
                  },
                  "name": "Declining market share",
                  "probability": "#",
                  "payoff": [
                    "PointE",
                    0
                  ],
                  "childNode": {
                    "computed": {
                      "expected-value-maximization": {
                        "aggregatedPayoff": [
                          "70 1/70368744177664"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "70 1/70368744177664"
                        ]
                      },
                      "expected-value-minimization": {
                        "aggregatedPayoff": [
                          "70 1/70368744177664"
                        ],
                        "probabilityToEnter": "1/2",
                        "payoff": [
                          "70 1/70368744177664"
                        ],
                        "optimal": true
                      },
                      "maxi-min": {
                        "aggregatedPayoff": [
                          "70 1/70368744177664"
                        ],
                        "probabilityToEnter": "1",
                        "payoff": [
                          "70 1/70368744177664"
                        ],
                        "optimal": true
                      },
                      "maxi-max": {
                        "aggregatedPayoff": [
                          "70 1/70368744177664"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "70 1/70368744177664"
                        ]
                      },
                      "mini-min": {
                        "aggregatedPayoff": [
                          "70 1/70368744177664"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "70 1/70368744177664"
                        ]
                      },
                      "mini-max": {
                        "aggregatedPayoff": [
                          "70 1/70368744177664"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "70 1/70368744177664"
                        ]
                      },
                      "min-max": {
                        "aggregatedPayoff": [
                          "70 1/70368744177664",
                          "0"
                        ],
                        "probabilityToEnter": "1/2",
                        "combinedPayoff": "-70 1/70368744177664",
                        "payoff": [
                          "70 1/70368744177664",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-min": {
                        "aggregatedPayoff": [
                          "70 1/70368744177664",
                          "0"
                        ],
                        "probabilityToEnter": 0,
                        "combinedPayoff": "70 1/70368744177664",
                        "payoff": [
                          "70 1/70368744177664",
                          "0"
                        ]
                      },
                      "min-min": {
                        "aggregatedPayoff": [
                          "70 1/70368744177664",
                          "0"
                        ],
                        "probabilityToEnter": "1/2",
                        "combinedPayoff": "-70 1/70368744177664",
                        "payoff": [
                          "70 1/70368744177664",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-max": {
                        "aggregatedPayoff": [
                          "70 1/70368744177664",
                          "0"
                        ],
                        "probabilityToEnter": 0,
                        "combinedPayoff": "70 1/70368744177664",
                        "payoff": [
                          "70 1/70368744177664",
                          "0"
                        ]
                      }
                    },
                    "childEdges": [],
                    "name": "E",
                    "code": "",
                    "expressionScope": {
                      "PointA": 148,
                      "PointB": 163,
                      "PointC": -42,
                      "PointD": 87,
                      "PointE": 32
                    },
                    "folded": false,
                    "location": {
                      "x": 836,
                      "y": 495
                    },
                    "type": "terminal",
                    "expectedValue": 70.00000000000001,
                    "p10": 24,
                    "p90": 128,
                    "charts": [
                      {
                        "nodeId": "23b9b8d0-b5d9-c1cb-085e-1ab1653cbc49",
                        "x": 50,
                        "y": -41,
                        "type": "distribution",
                        "active": true
                      }
                    ]
                  },
                  "expectedValue": 70.00000000000001,
                  "p10": 24,
                  "p90": 128,
                  "optimal": false
                }
              ],
              "name": "Revised \nmarketing strategy,\n(Y)",
              "code": "",
              "expressionScope": {
                "PointA": 148,
                "PointB": 163,
                "PointC": -42,
                "PointD": 87,
                "PointE": 32
              },
              "folded": false,
              "location": {
                "x": 561,
                "y": 440
              },
              "type": "chance",
              "milestone": null,
              "expectedValue": 80.50044923629831,
              "p10": 34,
              "p90": 128
            },
            "expectedValue": 0,
            "p10": 0,
            "p90": 0,
            "optimal": false
          }
        ],
        "name": "Which tech?",
        "code": "",
        "expressionScope": {
          "PointA": 148,
          "PointB": 163,
          "PointC": -42,
          "PointD": 87,
          "PointE": 32
        },
        "folded": false,
        "location": {
          "x": 11,
          "y": 299.75
        },
        "type": "decision",
        "expectedValue": 142.86666666666673,
        "p10": -48,
        "p90": 342,
        "charts": [
          {
            "nodeId": "a6d2a885-8076-0b80-b924-fa8e6fd1d57a",
            "x": -73.99999380111694,
            "y": -327.99998474121094,
            "type": "distribution",
            "active": true
          }
        ]
      }
    ],
    "texts": [],
    "payoffNames": [],
    "defaultCriterion1Weight": 1,
    "weightLowerBound": 0,
    "weightUpperBound": "Infinity"
  },
  "sensitivityAnalysis": {},
  "definitionsDialog": {
    "valueList": [
      {
        "name": "PointA",
        "value": "148",
        "low": 77,
        "high": 340,
        "distribution": "triangular",
        "error": ""
      },
      {
        "name": "PointB",
        "value": "163",
        "low": 96,
        "high": 211,
        "distribution": "triangular",
        "error": ""
      },
      {
        "name": "PointC",
        "value": "-42",
        "low": -68,
        "high": -11,
        "distribution": "triangular",
        "error": ""
      },
      {
        "name": "PointD",
        "value": "87",
        "low": 54,
        "high": 127,
        "distribution": "triangular",
        "error": ""
      },
      {
        "name": "PointE",
        "value": "32",
        "low": 15,
        "high": 112,
        "distribution": "triangular",
        "error": ""
      }
    ]
  }
}