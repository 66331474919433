import * as d3 from './d3';
import {i18n} from './i18n/i18n';
import {log, Utils} from 'sd-utils';
import {AppUtils} from './app-utils';
import * as model from 'sd-model';
import {TreeDesigner} from 'sd-tree-designer';
import {Templates} from './templates';
import {Sidebar} from './sidebar';
import {Toolbar} from './toolbar';
import {SettingsDialog} from './dialogs/settings-dialog';
import {AboutDialog} from './dialogs/about-dialog';
import {HelpDialog} from './dialogs/help-dialog';
import {Exporter} from './exporter';
import {FileLoader} from './file-loader';
import {DefinitionsDialog} from './dialogs/definitions-dialog';
import {ComputationsManager} from 'sd-computations';
import {SensitivityAnalysisDialog} from './dialogs/sensitivity-analysis-dialog';
import {LoadingIndicator} from './loading-indicator';
import {LeagueTableDialog} from './league-table/league-table-dialog';
import {initAvailableMoveGestures} from '../../components/zoom-canvas';
import blank_canvas from './tree-templates/blank_canvas.json';
import basic_tree_structure from './tree-templates/basic_tree_structure.json';
import decisiontree_explained from './tree-templates/decisiontree_explained.json';
import value_of_information_with_uncertainty from './tree-templates/value_of_information with_uncertainty.json';

import theBasicsTutorialFiles from './get-the-basics-tutorial';
import variablesAndDistributionsTutorialFiles from './get-variables-and-distributions-tutorial.js';
import chartsForInsightTutorialFiles from './get-charts-for-insight-tutorial.js';

import theBasicsTutorialHtml from './tutorial-html/the-basics-tutorial-html.js';
import variablesAndDistributionsTutorialHtml from './tutorial-html/variables-and-distributions-tutorial-html.js';
import chartsForInsightTutorialHtml from './tutorial-html/charts-for-insight-tutorial-html.js';

var buildConfig = require('../tmp/build-config.js');

export class AppConfig {
    readOnly = false;
    logLevel = 'warn';
    workerUrl = null;
    jobRepositoryType = 'idb';
    clearRepository = false;
    buttons = {
        new: true,
        save: true,
        open: true,
        exportToPng: true,
        exportToSvg: true,
        exportToPdf: true,
    };
    exports = {
        show: true,
        serverUrl: 'http://export.highcharts.com', //url of the export server
        pdf: {
            mode: 'client', // available options: 'client', 'server', 'fallback',
        },
        png: {
            mode: 'client', // available options: 'client', 'server', 'fallback',
        },
    };
    showDetails = true;
    showDefinitions = true;
    jsonFileDownload = true;
    width = 200;
    height = 100;
    rule = 'expected-value-maximization';
    lng = 'en';
    format = {
        // NumberFormat  options
        locales: 'en',
        payoff1: {
            style: 'currency',
            currency: 'USD',
            currencyDisplay: 'symbol',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            // minimumSignificantDigits: 1,
            useGrouping: true,
        },
        payoff2: {
            style: 'percent',
            currency: 'USD',
            currencyDisplay: 'symbol',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            // minimumSignificantDigits: 1,
            useGrouping: true,
        },
        probability: {
            // NumberFormat  options
            style: 'percent',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            useGrouping: true,
        },
    };
    title = '';
    description = '';
    treeDesigner = {
        //  hidePayoffs: true,
        layout: {
            type: 'tree',
            nodeSize: 22,
            limitNodePositioning: true,
            limitTextPositioning: true,
            gridHeight: 102,
            gridWidth: 202,
            edgeSlantWidthMax: 0,
        },
        margin: {
            left: 500,
            top: 250,
            right: 500,
            bottom: 100,
        },
    };
    leagueTable = {
        plot: {
            maxWidth: '800px',
            groups: {
                highlighted: {
                    color: '#008000',
                },
                'highlighted-default': {
                    color: '#00bd00',
                },
                'extended-dominated': {
                    color: '#ffa500',
                },
                dominated: {
                    color: '#ff0000',
                },
                default: {
                    color: '#000000',
                },
            },
        },
    };

    //https://github.com/d3/d3-format/blob/master/README.md#format

    constructor(custom) {
        if (custom) {
            Utils.deepExtend(this, custom);
        }
    }
}

// dnodes.io-colors
const ChartBackground = 'white';
const ChartBorder = '#c0c0c0';
const ChartNodeLine = '#424242';
const ChartHeaderBar = '#c0c0c0';

export class App {
    static version = ''; // version is set from package.json
    static buildTimestamp = buildConfig.buildTimestamp;
    static utils = Utils;
    static appUtils = AppUtils;
    static d3 = d3;
    
    config;
    container;
    dataModel; //Data model manager
    expressionEngine;
    computationsManager;
    treeDesigner;
    toolbar;
    sidebar;
    viewModes = [];
    currentViewMode;
    definitionsDialog;
    payoffsMaximization = [true, false];
    charts;
    treeStorage;
    tutorialDisplayStatus = ['false,0,0']; // [displayTutorialElement, tutorialNumber, tutorialStep]
    currentTutorial = 0;
    tutorialTitles = ["The Basics", "Variables and distributions", "Charts for insight"];
    interactiveTutorialDiv = document.getElementById('interactive-tutorial');
    tutorialOnClicksInitialized = false;
    userId = 'unregistered';
    currentTab = 0;
    maxNumberOfTabs = 3;
    upgradeModal = document.getElementById('upgrade-modal');
    upgradeModalHeader = document.getElementById('upgrade-modal-header');
    upgradeModalInfo = document.getElementById('upgrade-modal-info');
    tabs = Array.from(document.getElementsByClassName('tab'));
    defaultTabSize;
    windowWidth = window.innerWidth;
    getExplosionColor = function () {
        var _color =
        'rgb(' +
        [this.r(0, 255), this.r(0, 255), this.r(0, 255)].join(',') +
        ')'; // multi color
        // var _color = '#bbbbbb'; // dust
        return _color;
    };
    angleTools = {
        getAngle: function (t, n) {
            var a = n.x - t.x,
            e = n.y - t.y;
            return (Math.atan2(e, a) / Math.PI) * 180;
        },
        getDistance: function (t, n) {
            var a = t.x - n.x,
            e = t.y - n.y;
            return Math.sqrt(a * a + e * e);
        },
        moveOnAngle: function (t, n) {
            var a = this.getOneFrameDistance(t, n);
            (t.x += a.x), (t.y += a.y);
        },
        getOneFrameDistance: function (t, n) {
            return {
                x: n * Math.cos((t.rotation * Math.PI) / 180),
                y: n * Math.sin((t.rotation * Math.PI) / 180),
            };
        },
    };
    
    constructor(containerIdOrElem, config, diagramData) {
        this.redirectIfUsedOnMobileDevice();
        var p = Promise.resolve();
        this.setConfig(config);
        this.initI18n();
        this.initContainer(containerIdOrElem);

        this.initViewModes();
        this.initDataModel();
        p = this.initComputationsManager();
        this.initProbabilityNumberFormat();
        this.initPayoffNumberFormat();
        this.initTreeDesigner();
        this.initSidebar();
        this.initSettingsDialog();
        this.initAboutDialog();
        this.initHelpDialog();
        this.initDefinitionsDialog();
        this.initSensitivityAnalysisDialog();
        this.initLeagueTableDialog();
        this.initKeyCodes();
        this.initEventListenerForContextMenu();
        if (localStorage.getItem('current_id') !== null)
            this.userId = localStorage.getItem('current_id');
        this.treeStorage =
            this.userId === 'unregistered' ? sessionStorage : localStorage;
        this.initTabs();
        this.initCurrentFileOptionButtons();
        this.initFileDropdownButtons();
        this.initSettingsButton();
        this.initHelpDropdownButtons();
        this.initLandingPageButtons();
        this.checkIfMacOS();
        this.initBroadcastChannel();
        this.defaultTabSize = parseInt(this.tabs[0].style.width);
        p.then(() => {
            this.initToolbar();
            if (this.userId !== 'unregistered') {
                this.maxNumberOfTabs =
                    localStorage.getItem('full_access') ===
                    'f02cb0277828bb3084e147'
                        ? 1000
                        : 5;
                this.checkIfRegisteredUserHasTreesInSessionStorageAndPutThemInLocalStorage();
            }
            const numOfTreesSaved = this.getNumberOfTreesSaved();
            if (diagramData) {
                this.openDiagram(diagramData);
            } else if (numOfTreesSaved > 0) {
                for (
                    let i = 1;
                    i < numOfTreesSaved && i < this.maxNumberOfTabs;
                    i++
                ) {
                    this.addTab();
                }

                this.openDiagram(this.treeStorage.getItem(this.userId + '_0'));
                this.setCurrentTab(0);
            } else {
                const mouseActionsBox =
                    document.getElementById('mouse-actions-box');
                mouseActionsBox.hidden = false;
                this.newDiagram();
            }
            this.tabs.forEach((tab, index) => {
                let treeTitle = '';
                if (index === 0) {
                    treeTitle = this.config.title;
                } else {
                    let tree = JSON.parse(
                        this.treeStorage.getItem(this.userId + '_' + index),
                    );
                    treeTitle = tree.title;
                }
                if (treeTitle === '') {
                    treeTitle = 'Project ' + (index + 1);
                }
                tab.firstChild.textContent = treeTitle;
            });

            if (this.maxNumberOfTabs <= 5) this.initUpgradeModalButtons();

            this.handleTutorialUrlParam();
        }).catch((e) => {
            log.error(e);
        });
    }

    setConfig(config) {
        if (!config) {
            this.config = new AppConfig();
        } else {
            this.config = new AppConfig(config);
        }
        this.setLogLevel(this.config.logLevel);
        return this;
    }

    static growl() {
        return AppUtils.growl(arguments);
    }

    setLogLevel(level) {
        log.setLevel(level);
    }

    initContainer(containerIdOrElem) {
        if (Utils.isString(containerIdOrElem)) {
            var selector = containerIdOrElem.trim();

            if (
                !Utils.startsWith(selector, '#') &&
                !Utils.startsWith(selector, '.')
            ) {
                selector = '#' + selector;
            }
            this.container = d3.select(selector);
        } else {
            this.container = d3.select(containerIdOrElem);
        }
        var self = this;

        let html = Templates.get('main', {
            version: App.version,
            buildTimestamp: App.buildTimestamp,
            lng: self.config.lng,
        });
        this.container.html(html);

        this.container
            .select('#silver-decisions')
            .classed('sd-read-only', this.config.readOnly);
    }

    initI18n() {
        i18n.init(this.config.lng);
    }

    initDataModel() {
        var self = this;
        this.dataModel = new model.DataModel();
        // this.dataModel.nodeAddedCallback = this.dataModel.nodeRemovedCallback = ()=>self.onNodeAddedOrRemoved();
        this.dataModel.nodeAddedCallback = this.dataModel.nodeRemovedCallback =
            (node) =>
                Utils.waitForFinalEvent(
                    () => this.onNodeAddedOrRemoved(),
                    'onNodeAddedOrRemoved',
                    5,
                );

        this.dataModel.textAddedCallback = (text) =>
            Utils.waitForFinalEvent(() => {
                this.onTextAdded(text);
                this.sidebar.updateObjectPropertiesView(text);
            }, 'onTextAdded');
        this.dataModel.textRemovedCallback = (text) =>
            Utils.waitForFinalEvent(
                () => this.onTextRemoved(text),
                'onTextAdded',
            );
    }

    initComputationsManager() {
        this.computationsManager = new ComputationsManager(
            {
                ruleName: this.config.ruleName,
                worker: {
                    url: this.config.workerUrl,
                },
                jobRepositoryType: this.config.jobRepositoryType,
                clearRepository: this.config.clearRepository,
            },
            this.dataModel,
        );
        this.expressionEngine = this.computationsManager.expressionEngine;
        return this.checkValidityAndRecomputeObjective(
            false,
            false,
            false,
            true,
        );
    }

    initSidebar() {
        this.sidebar = new Sidebar(this.container.select('#sd-sidebar'), this);
    }

    initSettingsDialog() {
        this.settingsDialog = new SettingsDialog(this);
        const settingsDialog = document.getElementById("sd-settings-dialog");
        settingsDialog.addEventListener('click', (e) => {
            if (e.target.id === 'sd-settings-dialog') {
                this.settingsDialog.close();
            }
        });
        
    }

    initAboutDialog() {
        this.aboutDialog = new AboutDialog(this);
    }

    initHelpDialog() {
        this.helpDialog = new HelpDialog(this);
    }

    initDefinitionsDialog() {
        this.definitionsDialog = new DefinitionsDialog(this);
        this.definitionsDialog.onClosed = () => this.recompute(true, true);
    }

    initLeagueTableDialog() {
        this.leagueTableDialog = new LeagueTableDialog(this);
    }

    isLeagueTableAvailable() {
        return (
            this.isMultipleCriteria() &&
            this.dataModel.getRoots().length === 1 &&
            this.computationsManager.isValid() &&
            this.leagueTableDialog.validateParams()
        );
    }

    initSensitivityAnalysisDialog() {
        this.sensitivityAnalysisDialog = new SensitivityAnalysisDialog(this);
    }

    isSensitivityAnalysisAvailable() {
        return (
            !this.isMultipleCriteria() &&
            this.dataModel.getRoots().length === 1 &&
            this.computationsManager.isValid() &&
            this.dataModel.getGlobalVariableNames(true).length
        );
    }

    initToolbar() {
        const self = this;
        this.toolbar = new Toolbar(this.container.select('#sd-toolbar'), this);
        const inputs = Array.from(document.getElementsByClassName('toolbar-input'));
        if (inputs){
            inputs.forEach((input) => {
                input.addEventListener('click', () => {
                    self.updateLocalStorage();
                });
            });
        }
        const sliders = Array.from(document.getElementsByClassName('toolbar-slider'));
        if (sliders){
            sliders.forEach((slider) => {
                slider.addEventListener('mouseup', () => {
                    setTimeout(function () {
                        self.updateLocalStorage();
                    }, 100);
                });
            });
        }
    }

    initPayoffNumberFormat() {
        this.payoffNumberFormat = [
            new Intl.NumberFormat(
                this.config.format.locales,
                this.config.format.payoff1,
            ),
            new Intl.NumberFormat(
                this.config.format.locales,
                this.config.format.payoff2,
            ),
        ];
    }

    initProbabilityNumberFormat() {
        this.probabilityNumberFormat = new Intl.NumberFormat(
            this.config.format.locales,
            this.config.format.probability,
        );
    }

    initTreeDesigner() {
        var self = this;
        var config = this.getTreeDesignerInitialConfig();
        let container2 = this.container.select('#tree-designer-container');
        this.treeDesigner = new TreeDesigner(
            container2,
            this.dataModel,
            config,
        );
        initAvailableMoveGestures(this.treeDesigner);
    }

    initEventListenerForContextMenu() {
        var self = this;
        document.addEventListener('ShowChart', function (e) {
            var { node, type } = e.detail;
            if (!node.charts) {
                node.charts = [];
            }
            if (node.charts.some((c) => type == c.type)) {
                self.dataModel.saveState();
                let chart = node.charts.find((c) => c.type == type);
                if (!chart.active) {
                    chart.active = true;
                    self.drawCanvasForDistribution(chart);
                }
            } else {
                let initialPosition = self.getInitialCanvasPosition(type);
                node.charts.push({
                    nodeId: node.$id,
                    x: initialPosition.x,
                    y: initialPosition.y,
                    type: type,
                    active: false,
                });
                self.drawCanvasForDistribution(
                    node.charts[node.charts.length - 1],
                );
                self.dataModel.saveState();
                let chart = node.charts.find((c) => c.type == type);
                chart.active = true;
            }
            self.updateDistributionCharts();
            self.treeDesigner.updatePlottingRegionSize();
            setTimeout(function () {
                self.treeDesigner.updatePlottingRegionSize();
            }, 100);
            self.updateLocalStorage();
        });
        document.addEventListener('UnfoldChart', function (e) {
            setTimeout(() => {
                self.drawCanvasForDistribution(e.detail.chart);
                AppUtils.dispatchEvent('SilverDecisionsRecomputedEvent', self);
            }, 50);
        });
    }

    getInitialCanvasPosition = (type) =>
        type == 'bar'
            ? { x: 55, y: -95 }
            : type == 'waterfall'
            ? { x: 60, y: -90 }
            : type == 'tornado'
            ? { x: 65, y: -90 }
            : { x: 50, y: -100 };

    redrawLoadedCharts(charts, concat = true) {
        if (charts && charts.length > 0) {
            charts.forEach((c) => {
                let node = this.dataModel.findNodeById(c.nodeId);
                if (node) {
                    if (node.charts && node.charts.length > 0) {
                        if (concat) node.charts = node.charts.concat(c);
                    } else {
                        node.charts = [c];
                    }
                    if (!d3.select('#' + c.type + '-' + c.nodeId).empty()) {
                        d3.select('#' + c.type + '-' + c.nodeId).remove();
                        d3.select(
                            '#node-chart-line-' + c.type + c.nodeId,
                        ).remove();
                    }
                    if (c.active) {
                        this.drawCanvasForDistribution(c);
                    }
                }
            });
        }
        this.updateDistributionCharts();
    }

    updateDistributionCharts() {
        var nodes = this.dataModel.nodes;
        var nodesToUpdate = [];
        for (var i = 0; i < nodes.length; i++) {
            let node = nodes[i];
            if (
                node.charts &&
                node.charts.length > 0 &&
                node.charts.some((c) => c.active)
            ) {
                nodesToUpdate.push(node);
            }
        }
        this.treeDesigner.updatePlottingRegionSize();
        if (nodesToUpdate.length > 0) {
            document.dispatchEvent(
                new CustomEvent('DrawChartsOnCanvases', {
                    detail: {
                        nodes: nodesToUpdate,
                        variables: this.definitionsDialog.valueList,
                        formatter: this.payoffNumberFormat,
                    },
                }),
            );
        }
        this.updateLocalStorage();
    }

    isValidParentEdge(node) {
        var parent = node.$parent;
        if (parent == undefined) {
            return true;
        }
        var edge = parent.childEdges.find(
            (edge) => edge.childNode.$id == node.$id && edge,
        );
        if (!edge.$fieldStatus.payoff[0].valid.value) {
            return false;
        }
        return true;
    }

    removeDistributionChart(chart) {
        var node = this.dataModel.findNodeById(chart.nodeId);
        node.charts.map((c) => {
            if (c.type == chart.type) {
                c.active = false;
            }
        });
        d3.select('#' + chart.type + '-' + chart.nodeId).remove();
        d3.select('#node-chart-line-' + chart.type + chart.nodeId).remove();
        this.updateDistributionCharts();
    }

    //Set size of charts. Remember to change in showChart.js
    getCanvasSize = (type) =>
        type == 'tornado'
            ? { height: 100, width: 150 }
            : { height: 80, width: 150 };

    getNormalizedVector(vector) {
        let directionVector = [
            vector[0][0] - vector[1][0],
            vector[0][1] - vector[1][1],
        ];
        let vectorLength = Math.sqrt(
            directionVector[0] ** 2 + directionVector[1] ** 2,
        );
        return [
            directionVector[0] / vectorLength,
            directionVector[1] / vectorLength,
        ];
    }

    drawCanvasForDistribution(chart) {
        var self = this;
        var tempState = {};
        var canvasSize = this.getCanvasSize(chart.type);
        let nodeElement = d3.select('#node-' + chart.nodeId);
        const MAX_SCALE = 4;
        const SCALER = 0.8;
        nodeElement.raise();

        let chartNode = nodeElement
            .append('g')
            .datum({ x: chart.x, y: chart.y })
            .attr('transform', function (d) {
                return 'translate(' + d.x + ' ' + d.y + ')';
            })
            .attr('id', chart.type + '-' + chart.nodeId);

        var line = nodeElement
            .append('line')
            .attr('id', 'node-chart-line-' + chart.type + chart.nodeId)
            .style('stroke', ChartNodeLine)
            .style('opacity', 0.5)
            .attr('x1', 0)
            .attr('y1', 0)
            .attr('x2', canvasSize.width / 2 + chart.x)
            .attr('y2', canvasSize.height / 2 + chart.y)
            .lower();

        chartNode
            .append('rect')
            .attr('width', canvasSize.width)
            .attr('height', canvasSize.height)
            .attr('fill', ChartBackground)
            .attr('stroke', ChartBorder);

        var blackBarHeight = 15;
        chartNode
            .append('rect')
            .attr('width', canvasSize.width)
            .attr('height', blackBarHeight)
            .attr('fill', ChartHeaderBar)
            .attr('stroke', ChartHeaderBar);

        var expandChartButton = chartNode
            .append('text')
            .text('+')
            .attr('y', 12)
            .attr('x', 5)
            .attr('font-family', 'sans-serif')
            .attr('font-size', '14px')
            .attr('fill', '#636363')
            .style('cursor', 'pointer');

        var shrinkChartButton = chartNode
            .append('text')
            .text('-')
            .attr('y', 12)
            .attr('x', 20)
            .attr('font-family', 'sans-serif')
            .attr('font-size', '14px')
            .attr('fill', '#636363')
            .attr('display', 'none')
            .style('cursor', 'pointer');

        var closeArea = chartNode
            .append('text')
            .text('x')
            .attr('y', 12)
            .attr('x', canvasSize.width - 11)
            .attr('font-family', 'sans-serif')
            .attr('font-size', '14px')
            .attr('fill', '#636363')
            .style('cursor', 'pointer');

        var self = this;

        expandChartButton.on('click', function () {
            shrinkChartButton.attr('display', 'true');
            var button = d3.select(this);
            chartNode.attr('transform', function (d) {
                var currentTransformation = this.getAttribute('transform');
                if (currentTransformation.includes('scale')) {
                    var onlyTransform = currentTransformation.split('scale')[0];
                    var scaleValue = parseFloat(
                        currentTransformation.split('scale')[1].slice(1, -1),
                    );
                    if (scaleValue + SCALER >= MAX_SCALE) {
                        button.attr('display', 'none');
                    }
                    return onlyTransform + `scale(${scaleValue + SCALER})`;
                }

                return currentTransformation + ` scale(${1 + SCALER})`;
            });
            d3.event.stopPropagation();
        });

        shrinkChartButton.on('click', function () {
            var button = d3.select(this);
            expandChartButton.attr('display', 'true');
            chartNode.attr('transform', function (d) {
                var currentTransformation = this.getAttribute('transform');
                var onlyTransform = currentTransformation.split('scale')[0];
                var scaleValue = parseFloat(
                    currentTransformation.split('scale')[1].slice(1, -1),
                );
                if (scaleValue - SCALER <= 1.1) {
                    button.attr('display', 'none');
                }
                return onlyTransform + `scale(${scaleValue - SCALER})`;
            });
            d3.event.stopPropagation();
        });

        closeArea.on('click', function () {
            self.removeDistributionChart(chart);
            d3.event.stopPropagation();
        });

        var drag = d3
            .drag()
            .on('start', function () {
                tempState = self.dataModel.createStateSnapshot();
            })
            .on('drag', function () {
                nodeElement.raise();
                nodeElement.select('.aggregated-payoff').raise();
                d3.select(this).attr('transform', function (d) {
                    var currentTransformation = this.getAttribute('transform');
                    var isScaled = currentTransformation.includes('scale');
                    if (isScaled) {
                        var scaleValue = parseFloat(
                            currentTransformation
                                .split('scale')[1]
                                .slice(1, -1),
                        );
                    }
                    let translation =
                        'translate( ' +
                        [(d.x += d3.event.dx), (d.y += d3.event.dy)] +
                        ' )';
                    return isScaled
                        ? `${translation} scale(${scaleValue})`
                        : translation;
                });
                line.attr('x2', canvasSize.width / 2 + d3.event.x).attr(
                    'y2',
                    canvasSize.height / 2 + d3.event.y,
                );
            })
            .on('end', function (d) {
                chart.x = d.x;
                chart.y = d.y;
                self.dataModel.saveStateFromSnapshot(tempState);
                self.treeDesigner.updatePlottingRegionSize();
                setTimeout(function () {
                    self.treeDesigner.updatePlottingRegionSize();
                }, 100);
                self.updateLocalStorage();
            });
        chartNode.call(drag);
    }

    getTreeDesignerInitialConfig() {
        var self = this;

        return Utils.deepExtend(
            {
                lng: self.config.lng,
                readOnly: self.config.readOnly,
                onNodeSelected: function (node) {
                    self.onObjectSelected(node);
                },
                onEdgeSelected: function (edge) {
                    self.onObjectSelected(edge);
                },
                onTextSelected: function (text) {
                    self.onObjectSelected(text);
                },
                onSelectionCleared: function () {
                    self.onSelectionCleared();
                    self.updateLocalStorage();
                },
                onDoubleClick: function () {
                    self.sidebar.updateObjectPropertiesView(
                        self.selectedObject,
                    );
                    self.updateVariableDefinitions();
                    self.treeDesigner.updatePlottingRegionSize();
                },
                payoffNumberFormatter: (v, i) => {
                    let prefix = '';
                    if (self.currentViewMode.multiCriteria) {
                        prefix = self.dataModel.payoffNames[i].charAt(0) + ': ';
                    }

                    return (
                        prefix +
                        self.payoffNumberFormat[
                            i || self.currentViewMode.payoffIndex || 0
                        ].format(v)
                    );
                },
                probabilityNumberFormatter: (v) =>
                    self.probabilityNumberFormat.format(v),
                operationsForObject: (o) =>
                    self.computationsManager.operationsForObject(o),
            },
            self.config.treeDesigner,
        );
    }

    onObjectSelected(object) {
        var self = this;
        if (this.selectedObject === object) {
            return;
        }
        this.selectedObject = object;
        setTimeout(function () {
            self.updateVariableDefinitions();
            self.treeDesigner.updatePlottingRegionSize();
        }, 10);
    }

    onSelectionCleared() {
        var self = this;
        this.selectedObject = null;
        this.sidebar.hideObjectProperties();
        setTimeout(function () {
            self.updateVariableDefinitions();
            self.treeDesigner.updatePlottingRegionSize();
        }, 10);
    }

    getCurrentVariableDefinitionsSourceObject() {
        if (this.selectedObject) {
            if (this.selectedObject instanceof model.domain.Node) {
                return this.selectedObject;
            }
            if (this.selectedObject instanceof model.domain.Edge) {
                return this.selectedObject.parentNode;
            }
        }
        return this.dataModel;
    }

    updateVariableDefinitions() {
        var self = this;
        var definitionsSourceObject =
            self.getCurrentVariableDefinitionsSourceObject();
        var readOnly =
            this.selectedObject instanceof model.domain.Edge ||
            this.selectedObject instanceof model.domain.TerminalNode;
        self.sidebar.updateDefinitions(
            definitionsSourceObject,
            readOnly,
            (code) => {
                // self.dataModel.saveState();
                definitionsSourceObject.code = code;
                self.recompute(true, true);
            },
        );
    }

    openDefinitionsDialog() {
        var definitionsSourceObject =
            this.getCurrentVariableDefinitionsSourceObject();
        this.definitionsDialog.open(definitionsSourceObject, (code) => {
            // this.dataModel.saveState();
            definitionsSourceObject.code = code;
            this.recompute(true, true);
        });
    }

    updateView(withTransitions = true) {
        var self = this;
        this.treeDesigner.redraw(withTransitions);
        // this.sidebar.updateObjectPropertiesView(this.selectedObject);
        this.updateVariableDefinitions();
        this.toolbar.update();
        this.sidebar.updateLayoutOptions();
        this.sidebar.updateDiagramDetails();
        this.sidebar.updateMultipleCriteria();
        this.treeDesigner.updatePlottingRegionSize();
        this.updateLocalStorage();
    }

    undo() {
        let self = this;
        self.dataModel.undo();
        self.initPayoffNames();
        if (self.selectedObject) {
            self.selectedObject = self.dataModel.findById(
                self.selectedObject.$id,
            );
        }
        return this.checkValidityAndRecomputeObjective(
            false,
            false,
            false,
        ).then(() => {
            self.updateView();
            self.updateCharts();
            setTimeout(function () {
                self.treeDesigner.updatePlottingRegionSize();
            }, 300);
        });
    }

    redo() {
        let self = this;
        self.dataModel.redo();
        self.initPayoffNames();
        if (self.selectedObject) {
            self.selectedObject = self.dataModel.findById(
                self.selectedObject.$id,
            );
        }

        return this.checkValidityAndRecomputeObjective(
            false,
            false,
            false,
        ).then(() => {
            self.updateView();
            self.updateCharts();
            setTimeout(function () {
                self.treeDesigner.updatePlottingRegionSize();
            }, 300);
        });
    }

    updateCharts() {
        var nodes = this.dataModel.nodes;
        var charts = [];

        for (var i = 0; i < nodes.length; i++) {
            if (!nodes[i].charts) continue;
            for (var j = 0; j < nodes[i].charts.length; j++) {
                var chart = nodes[i].charts[j];
                charts.push(chart);
            }
        }

        this.redrawLoadedCharts(charts, false);
        this.treeDesigner.updatePlottingRegionSize();
    }

    onNodeAddedOrRemoved() {
        document.dispatchEvent(new CustomEvent('NodeAddedOrRemoved'));
        var self = this;
        return this.checkValidityAndRecomputeObjective().then(() => {
            self.updateView();

            self.sidebar.updateObjectChildrenProperties(this.selectedObject);
        });
    }

    onTextAdded(text) {
        return this.onObjectSelected(text);
    }

    onTextRemoved(text) {
        this.updateView();
    }

    onObjectUpdated(object, fieldName) {
        var self = this;
        var p = Promise.resolve();
        if (!(object instanceof model.domain.Text) && fieldName !== 'name') {
            p = p.then(() => this.checkValidityAndRecomputeObjective());
        }
        // this.sidebar.updateObjectPropertiesView(this.selectedObject);
        return p.then(() => {
            setTimeout(function () {
                self.treeDesigner.redraw(true);
            }, 1);
        });
    }

    onMultiCriteriaUpdated(fieldName) {
        var self = this;
        var p = Promise.resolve();
        if (fieldName === 'defaultCriterion1Weight') {
            p = p.then(() => this.checkValidityAndRecomputeObjective());
        }
        this.sidebar.updateMultipleCriteria();

        return p.then(() => {
            setTimeout(function () {
                self.treeDesigner.redraw(true);
                // self.sidebar.updateObjectPropertiesView(self.selectedObject);
            }, 1);
        });
    }

    setObjectiveRule(
        ruleName,
        evalCode = false,
        evalNumeric = false,
        updateView = true,
        recompute = true,
    ) {
        let prevRule = this.computationsManager.getCurrentRule();
        this.computationsManager.setCurrentRuleByName(ruleName);
        let currentRule = this.computationsManager.getCurrentRule();
        let multiCriteria = currentRule.multiCriteria;
        this.treeDesigner.config.maxPayoffsToDisplay = multiCriteria ? 2 : 1;

        if (multiCriteria) {
            this.payoffsMaximization = currentRule.payoffCoeffs.map(
                (c) => c > 0,
            );
            this.initPayoffNames();
            this.treeDesigner.config.payoffNames = this.dataModel.payoffNames;
        } else {
            this.payoffsMaximization[this.currentViewMode.payoffIndex] =
                currentRule.maximization;
            this.treeDesigner.config.payoffNames = [null, null];
        }
        if (!recompute) {
            return Promise.resolve();
        }

        return this.checkValidityAndRecomputeObjective(
            false,
            evalCode,
            evalNumeric,
        ).then(() => {
            if (updateView) {
                this.updateView(false);
            }
        });
    }

    initPayoffNames() {
        if (
            this.currentViewMode.multiCriteria &&
            !this.dataModel.payoffNames.length
        ) {
            this.dataModel.payoffNames.push(
                (this.dataModel.payoffNames[0] = i18n.t(
                    'multipleCriteria.defaultMinimizedCriterionName',
                )),
                (this.dataModel.payoffNames[1] = i18n.t(
                    'multipleCriteria.defaultMaximizedCriterionName',
                )),
            );
        }
    }

    isMultipleCriteria() {
        return this.computationsManager.getCurrentRule().multiCriteria;
    }

    flipCriteria() {
        let tmp = this.config.format.payoff1;
        this.config.format.payoff1 = this.config.format.payoff2;
        this.config.format.payoff2 = tmp;
        this.initPayoffNumberFormat();

        this.computationsManager
            .flipCriteria()
            .then(() => {
                this.updateView(false);
            })
            .catch((e) => {
                log.error(e);
            });
    }

    getCurrentObjectiveRule() {
        return this.computationsManager.getCurrentRule();
    }

    getObjectiveRules() {
        return this.computationsManager
            .getObjectiveRules()
            .filter(
                (rule) =>
                    rule.multiCriteria === this.currentViewMode.multiCriteria,
            );
    }

    initViewModes() {
        this.viewModes.push({
            name: 'criterion1',
            multiCriteria: false,
            payoffIndex: 0,
        });

        this.viewModes.push({
            name: 'criterion2',
            multiCriteria: false,
            payoffIndex: 1,
        });

        this.viewModes.push({
            name: 'twoCriteria',
            multiCriteria: true,
            payoffIndex: null,
        });
        this.currentViewMode = this.viewModes[0];
    }

    getCurrentViewMode() {
        return this.currentViewMode;
    }

    setViewModeByName(name, recompute = true, updateView = true) {
        return this.setViewMode(
            Utils.find(this.viewModes, (mode) => mode.name === name),
            recompute,
            updateView,
        );
    }

    setViewMode(mode, recompute = true, updateView = true) {
        let prevMode = this.currentViewMode;
        this.currentViewMode = mode;

        this.computationsManager.objectiveRulesManager.setPayoffIndex(
            this.currentViewMode.payoffIndex,
        );

        if (!recompute) {
            return Promise.resolve();
        }
        let rules = this.getObjectiveRules();
        let prevRule = this.computationsManager.getCurrentRule();
        let newRule = rules[0];

        if (this.currentViewMode.payoffIndex !== null) {
            newRule = Utils.find(
                rules,
                (r) =>
                    r.maximization ==
                    this.payoffsMaximization[this.currentViewMode.payoffIndex],
            );
        } else {
            newRule = Utils.find(rules, (r) =>
                r.payoffCoeffs
                    .map((c) => c > 0)
                    .every((max, i) => this.payoffsMaximization[i] === max),
            );
        }

        this.setObjectiveRule(
            newRule.name,
            false,
            false,
            updateView,
            recompute,
        );
    }

    setDefaultViewModeForRule(rule, recompute = true, updateView = true) {
        return this.setViewMode(
            Utils.find(
                this.viewModes,
                (mode) => mode.multiCriteria === rule.multiCriteria,
            ),
            recompute,
            updateView,
        );
    }

    getViewModes() {
        return this.viewModes;
    }

    showLeagueTable() {
        this.leagueTableDialog.open();
    }

    openSensitivityAnalysis() {
        let self = this;
        setTimeout(function () {
            if (!self.isSensitivityAnalysisAvailable()) {
                return;
            }
            self.sensitivityAnalysisDialog.open();
        }, 200);
    }

    showTreePreview(dataDTO, closeCallback, autoLayout = true) {
        var self = this;
        this.originalDataModelSnapshot = this.dataModel.createStateSnapshot();
        this.dataModel.loadFromDTO(
            dataDTO,
            this.computationsManager.expressionEngine.getJsonReviver(),
        );
        this.computationsManager.updateDisplayValues(this.dataModel);
        this.updateView(false);
        setTimeout(function () {
            self.updateView(false);
            setTimeout(function () {
                var svgString = Exporter.getSVGString(
                    self.treeDesigner.svg.node(),
                );
                AppUtils.showFullScreenPopup('', svgString, () => {
                    if (closeCallback) {
                        self.dataModel._setNewState(
                            self.originalDataModelSnapshot,
                        );
                        self.updateView(false);

                        closeCallback();
                        setTimeout(function () {
                            self.updateView(false);
                        }, 1);
                    }
                });
            }, 300);
        }, 1);
    }

    showPolicyPreview(title, policy, closeCallback) {
        var self = this;
        this.originalDataModelSnapshot = this.dataModel.createStateSnapshot();
        this.computationsManager.displayPolicy(policy);
        this.updateView(false);
        AppUtils.showFullScreenPopup(title, '');
        LoadingIndicator.show();
        setTimeout(function () {
            self.updateView(false);
            setTimeout(function () {
                var svgString = Exporter.getSVGString(
                    self.treeDesigner.svg.node(),
                    true,
                );
                LoadingIndicator.hide();
                AppUtils.showFullScreenPopup(title, svgString, () => {
                    self.dataModel._setNewState(self.originalDataModelSnapshot);

                    // self.computationsManager.updateDisplayValues(self.dataModel);
                    self.updateView(false);
                    if (closeCallback) {
                        closeCallback();
                    }
                    setTimeout(function () {
                        self.updateView(false);
                    }, 1);
                });
            }, 500);
        }, 1);
    }

    recompute(
        updateView = true,
        debounce = false,
        forceWhenAutoIsDisabled = true,
    ) {
        if (debounce) {
            if (!this.debouncedRecompute) {
                this.debouncedRecompute = Utils.debounce(
                    (updateView) => this.recompute(updateView, false),
                    200,
                );
            }
            this.debouncedRecompute(updateView);
            return;
        }

        return this.checkValidityAndRecomputeObjective(
            false,
            true,
            true,
            forceWhenAutoIsDisabled,
        ).then(() => {
            if (updateView) {
                this.updateView();
            }
        });
    }

    onRawOptionChanged() {
        if (this.isAutoRecalculationEnabled()) {
            return this.checkValidityAndRecomputeObjective(false, false).then(
                () => {
                    this.updateView();
                },
            );
        }
    }

    isAutoRecalculationEnabled() {
        return !this.treeDesigner.config.raw;
    }

    checkValidityAndRecomputeObjective(
        allRules,
        evalCode = false,
        evalNumeric = true,
        forceWhenAutoIsDisabled = false,
    ) {
        if (!forceWhenAutoIsDisabled && !this.isAutoRecalculationEnabled()) {
            return Promise.resolve();
        }

        return this.computationsManager
            .checkValidityAndRecomputeObjective(allRules, evalCode, evalNumeric)
            .then(() => {
                this.updateValidationMessages();
                AppUtils.dispatchEvent('SilverDecisionsRecomputedEvent', this);
            })
            .catch((e) => {
                log.error(e);
            });
    }

    updateValidationMessages() {
        var self = this;
        setTimeout(function () {
            self.treeDesigner.updateValidationMessages();
        }, 1);
    }

    newDiagram() {
        this.clear();
        this.updateView();
    }

    clear() {
        this.dataModel.clear();
        this.currentViewMode = this.viewModes[0];
        this.computationsManager.setCurrentRuleByName(
            this.computationsManager.getObjectiveRules()[0].name,
        );
        this.setDiagramTitle('', true);
        this.setDiagramDescription('', true);
        this.treeDesigner.setConfig(
            Utils.deepExtend(this.getTreeDesignerInitialConfig()),
        );
        this.onSelectionCleared();
        this.sensitivityAnalysisDialog.clear(true, true);
        this.definitionsDialog.clearValues();
    }

    openDiagram(diagramData) {
        var self = this;
        var errors = [];

        if (Utils.isString(diagramData)) {
            try {
                diagramData = JSON.parse(
                    diagramData,
                    self.computationsManager.expressionEngine.getJsonReviver(),
                );
            } catch (e) {
                errors.push('error.jsonParse');
                alert(i18n.t('error.jsonParse'));
                log.error(e);
                return Promise.resolve(errors);
            }
        }

        var dataModelObject = diagramData.data;

        this.clear();
        this.definitionsDialog.updateAndWriteValues(
            diagramData.definitionsDialog,
        );

        let charts = [];
        dataModelObject.trees.forEach((root) => {
            function retainIds(root) {
                if (root.charts && root.charts.length > 0) {
                    charts = charts.concat(root.charts);
                    root.$id = root.charts[0].nodeId;
                }
                if (root.childEdges.length == 0) {
                    return;
                }
                for (let i = 0; i < root.childEdges.length; i++) {
                    retainIds(root.childEdges[i].childNode);
                }
            }
            retainIds(root);
        });

        if (!diagramData.SilverDecisions) {
            errors.push('error.notSilverDecisionsFile');
            alert(i18n.t('error.notSilverDecisionsFile'));
            return Promise.resolve(errors);
        }

        if (!Utils.isValidVersionString(diagramData.SilverDecisions)) {
            errors.push('error.incorrectVersionFormat');
            alert(i18n.t('error.incorrectVersionFormat'));
        } else {
            //Check if version in file is newer than version of application
            if (
                Utils.compareVersionNumbers(
                    diagramData.SilverDecisions,
                    App.version,
                ) > 0
            ) {
                errors.push('error.fileVersionNewerThanApplicationVersion');
                alert(i18n.t('error.fileVersionNewerThanApplicationVersion'));
            }

            if (
                Utils.compareVersionNumbers(
                    diagramData.SilverDecisions,
                    '0.7.0',
                ) < 0
            ) {
                dataModelObject = {
                    code: diagramData.code,
                    expressionScope: diagramData.expressionScope,
                    trees: diagramData.trees,
                    texts: diagramData.texts,
                };
            }
        }

        try {
            if (diagramData.lng) {
                this.config.lng = diagramData.lng;
            }

            if (diagramData.rule) {
                if (this.computationsManager.isRuleName(diagramData.rule)) {
                    this.config.rule = diagramData.rule;
                } else {
                    delete this.config.rule;
                }
            }

            if (diagramData.viewMode) {
                this.setViewModeByName(diagramData.viewMode);
            } else {
                this.setDefaultViewModeForRule(
                    this.computationsManager.getObjectiveRuleByName(
                        this.config.rule,
                    ),
                    false,
                    false,
                );
            }

            if (diagramData.format) {
                this.config.format = diagramData.format;
            }

            this.setConfig(this.config);
            this.dataModel.load(dataModelObject);

            if (diagramData.treeDesigner) {
                this.treeDesigner.setConfig(
                    Utils.deepExtend(
                        self.getTreeDesignerInitialConfig(),
                        diagramData.treeDesigner,
                    ),
                );
            }
            this.treeDesigner.updateVisibility();
            this.setDiagramTitle(diagramData.title || '', true);
            this.setDiagramDescription(diagramData.description || '', true);
            if (diagramData.sensitivityAnalysis) {
                this.sensitivityAnalysisDialog.loadSavedParamValues(
                    diagramData.sensitivityAnalysis,
                );
            }
        } catch (e) {
            errors.push('error.malformedData');
            alert(i18n.t('error.malformedData'));
            this.clear();
            log.error('malformedData', e);
            return Promise.resolve(errors);
        }
        try {
            this.updateNumberFormats(false);
        } catch (e) {
            log.error('incorrectNumberFormatOptions', e);
            errors.push('error.incorrectNumberFormatOptions');
            alert(i18n.t('error.incorrectNumberFormatOptions'));
            delete this.config.format;
            this.setConfig(this.config);
            this.updateNumberFormats(false);
        }
        return this.setObjectiveRule(this.config.rule, false, true, false)
            .catch((e) => {
                log.error('diagramDrawingFailure', e);
                errors.push('error.diagramDrawingFailure');
                alert(i18n.t('error.diagramDrawingFailure'));
                this.clear();
                return errors;
            })
            .then(() => {
                this.updateView(false);
                this.redrawLoadedCharts(charts);

                return errors;
            })
            .catch((e) => {
                log.error('diagramDrawingFailure', e);
                errors.push('error.diagramDrawingFailure');
                alert(i18n.t('error.diagramDrawingFailure'));
                this.clear();
                this.updateView(false);
                return errors;
            });
    }

    serialize(
        filterLocation = false,
        filterComputed = false,
        filterPrivate = true,
    ) {
        var self = this;
        if (
            self.config.title !==
            self.tabs[self.currentTab].firstChild.textContent
        ) {
            self.config.title =
                self.tabs[self.currentTab].firstChild.textContent;
        }
        return self
            .checkValidityAndRecomputeObjective(true, false, false, true)
            .then(() => {
                var obj = {
                    SilverDecisions: App.version,
                    buildTimestamp: App.buildTimestamp,
                    savetime: d3.isoFormat(new Date()),
                    lng: self.config.lng,
                    viewMode: self.currentViewMode.name,
                    rule: self.computationsManager.getCurrentRule().name,
                    title: self.config.title,
                    description: self.config.description,
                    format: self.config.format,
                    treeDesigner: self.treeDesigner.config,
                    data: self.dataModel.serialize(false),
                    sensitivityAnalysis:
                        this.sensitivityAnalysisDialog.jobNameToParamValues,
                    definitionsDialog: {
                        valueList: self.definitionsDialog.valueList,
                    },
                };

                return Utils.stringify(
                    obj,
                    self.dataModel.getJsonReplacer(
                        filterLocation,
                        filterComputed,
                        self.computationsManager.expressionEngine.getJsonReplacer(),
                        filterPrivate,
                    ),
                    filterPrivate ? ['$'] : [],
                );
            });
    }

    saveToFile(
        filterLocation = false,
        filterComputed = false,
        filterPrivate = true,
    ) {
        var self = this;
        this.serialize(filterLocation, filterComputed, filterPrivate).then(
            (json) => {
                AppUtils.dispatchEvent('SilverDecisionsSaveEvent', json);
                if (this.config.jsonFileDownload) {
                    var blob = new Blob([json], { type: 'application/json' });
                    Exporter.saveAs(
                        blob,
                        Exporter.getExportFileName(
                            'dNodesIO',
                            self.config.title,
                        ),
                    );
                }
            },
        );
    }

    updateNumberFormats(updateView = true) {
        this.initPayoffNumberFormat();
        this.initProbabilityNumberFormat();
        if (updateView) {
            this.updateView();
        }
    }

    updatePayoffNumberFormat(updateView = true) {
        this.initPayoffNumberFormat();
        if (updateView) {
            this.updateView();
        }
    }

    updateProbabilityNumberFormat(updateView = true) {
        this.initProbabilityNumberFormat();
        if (updateView) {
            this.updateView();
        }
    }

    initOnBeforeUnload() {
        var self = this;
        window.addEventListener('beforeunload', function (e) {
            if (
                !(
                    self.dataModel.isUndoAvailable() ||
                    self.dataModel.isRedoAvailable()
                )
            ) {
                return;
            }

            var dialogText = i18n.t('confirm.beforeunload');
            e.returnValue = dialogText;
            return dialogText;
        });
    }

    setConfigParam(path, value, withoutStateSaving, callback) {
        var self = this;
        var prevValue = Utils.get(this.config, path);

        if (prevValue == value) {
            return;
        }
        if (!withoutStateSaving) {
            this.dataModel.saveState({
                data: {
                    prevValue: prevValue,
                },
                onUndo: (data) => {
                    self.setConfigParam(path, data.prevValue, true, callback);
                },
                onRedo: (data) => {
                    self.setConfigParam(path, value, true, callback);
                },
            });
            self.updateLocalStorage();
        }
        Utils.set(this.config, path, value);
        if (callback) {
            callback(value);
        }
    }

    setDiagramTitle(title, withoutStateSaving) {
        this.setConfigParam('title', title, withoutStateSaving, (v) =>
            this.treeDesigner.updateDiagramTitle(v),
        );
    }

    setDiagramDescription(description, withoutStateSaving) {
        this.setConfigParam(
            'description',
            description,
            withoutStateSaving,
            (v) => this.treeDesigner.updateDiagramDescription(v),
        );
    }

    initKeyCodes() {
        this.container.on('keydown', (d) => {
            let srcElement = d3.event.target || d3.event.srcElement;

            if (
                srcElement &&
                ['INPUT', 'TEXTAREA'].indexOf(
                    srcElement.nodeName.toUpperCase(),
                ) > -1
            ) {
                //ignore events from input and textarea elements
                return;
            }

            var keyCode = d3.event.keyCode;
            var key = d3.event.key;
            if (keyCode == 16) {
                //shift
                this.treeDesigner.shiftPressed = true;
                return;
            }

            if (this.selectedObject && key.length == 1) {
                this.sidebar.addToInputBuffer(key);
                if (
                    !this.sidebar.container
                        .select('#object-properties')
                        .classed('visible')
                ) {
                    this.sidebar.updateObjectPropertiesView(
                        this.selectedObject,
                    );
                }
            }
        });
        this.container.on('keyup', (d) => {
            let srcElement = d3.event.target || d3.event.srcElement;

            if (
                srcElement &&
                ['INPUT', 'TEXTAREA'].indexOf(
                    srcElement.nodeName.toUpperCase(),
                ) > -1
            ) {
                //ignore events from input and textarea elements
                return;
            }

            var key = d3.event.keyCode;
            if (key == 16) {
                // shift
                this.treeDesigner.shiftPressed = false;
            }

            if (key == 46 || key == 8) {
                // delete
                this.treeDesigner.removeSelectedNodes();
                this.treeDesigner.removeSelectedTexts();
                return;
            }
            if (!d3.event.ctrlKey) {
                return;
            }

            if (d3.event.altKey) {
                if (this.selectedObject instanceof model.domain.Node) {
                    let selectedNode = this.selectedObject;
                    if (selectedNode instanceof model.domain.TerminalNode) {
                        return;
                    }
                    if (key == 68) {
                        // ctrl + alt + d
                        this.treeDesigner.addDecisionNode(selectedNode);
                    } else if (key == 67) {
                        // ctrl + alt + c
                        this.treeDesigner.addChanceNode(selectedNode);
                    } else if (key == 84) {
                        // ctrl + alt + t
                        this.treeDesigner.addTerminalNode(selectedNode);
                    }
                    return;
                } else if (this.selectedObject instanceof model.domain.Edge) {
                    if (key == 68) {
                        // ctrl + alt + d
                        this.treeDesigner.injectDecisionNode(
                            this.selectedObject,
                        );
                    } else if (key == 67) {
                        // ctrl + alt + c
                        this.treeDesigner.injectChanceNode(this.selectedObject);
                    }
                }
            }

            if (key == 90) {
                //ctrl + z
                this.undo();
                return;
            }
            if (key == 89) {
                //ctrl + y
                this.redo();
                return;
            }

            var selectedNodes = this.treeDesigner.getSelectedNodes();
            if (key == 86) {
                //ctrl + v
                if (selectedNodes.length == 1) {
                    let selectedNode = selectedNodes[0];
                    if (selectedNode instanceof model.domain.TerminalNode) {
                        return;
                    }
                    this.treeDesigner.pasteToNode(selectedNode);
                } else if (selectedNodes.length == 0) {
                }
                return;
            }

            if (!selectedNodes.length) {
                return;
            }

            if (key == 88) {
                //ctrl + x
                this.treeDesigner.cutSelectedNodes();
            } else if (key == 67) {
                //ctrl + c
                this.treeDesigner.copySelectedNodes();
            }
        });
    }

    updateLocalStorage(tutorialName = null) {
        var self = this;
        var signedIn = self.userId !== 'unregistered';
        if (signedIn && self.interactiveTutorialDiv.hidden) {
            var savedLocallyText =
                document.getElementById('saved-locally-text');

            var check = document.getElementById('save-check');
            var loader = document.getElementById('save-loader');
            savedLocallyText.textContent = 'Saving...';
            check.hidden = true;
            loader.hidden = false;
        }
        this.serialize(false, false, true).then((json) => {
            self.treeStorage.setItem(self.userId + '_' + self.currentTab, json);
            // If a tutorial name is specified (meaning that the function is called from handleTutorialUrlParam), load the correct tutorial
            if(tutorialName)
                self.loadTutorialByName(tutorialName);
        });
        if (signedIn && self.interactiveTutorialDiv.hidden) {
            setTimeout(() => {
                savedLocallyText.textContent = 'Saved locally';
                loader.hidden = true;
                check.hidden = false;
            }, 1000);
        }
    }

    initTutorial(tutorial) {
        var self = this;
        self.currentTutorial = tutorial;
        self.interactiveTutorialDiv.hidden = false;
        self.updateTutorialParameters();

        if (!self.tutorialOnClicksInitialized) {
            var prevButton = document.getElementById('tutorial-previous-step');
            prevButton.onclick = () => {
                self.executeTutorialStep(false);
            };

            var nextButton = document.getElementById('tutorial-next-step');
            nextButton.onclick = () => {
                self.executeTutorialStep(true);
            };

            const interactiveTutorialQuitButton = document.getElementById(
                'interactive-tutorial-quit',
            );
            interactiveTutorialQuitButton.onclick = () => self.handleTutorialQuit();

            document.addEventListener('keydown', function (event) {
                if (!self.interactiveTutorialDiv.hidden) {
                    var currentStep = parseInt(self.tutorialDisplayStatus[self.currentTab].split(',',)[2]);
                    const numberOfTutorialFiles = self.getNumberOfTutorialFiles();
                    switch (event.key) {
                        case 'ArrowRight':
                            if (currentStep < numberOfTutorialFiles - 1) {
                                self.executeTutorialStep(true);
                            }
                            break;
                        case 'ArrowLeft':
                            if (currentStep > 0) {
                                self.executeTutorialStep(false);
                            }
                            break;
                    }
                }
            });            
            self.tutorialOnClicksInitialized = true;
        }
        document.getElementById('mouse-actions-box').hidden = true;
        document.getElementById('mouse-actions-widget').hidden = false;

        self.setMarginsForVariablesInTutorial();
    }

    handleTutorialQuit(){
        const self = this;
        document.getElementById('interactive-tutorial').hidden = true;
        self.resetMarginsAndScale();
        self.tutorialDisplayStatus[self.currentTab] = 'false,' + self.currentTutorial + ',0';
        sessionStorage.setItem(
            'tutorial_display_status',
            JSON.stringify(self.tutorialDisplayStatus),
        );
    }

    setMarginsForVariablesInTutorial(){
        const variablesParent = document.getElementById("sd-definitions-dialog").children[0].children[0];
        variablesParent.style.marginLeft = '450px';
    }

    resetMarginsForVariables(){
        const variablesParent = document.getElementById("sd-definitions-dialog").children[0].children[0];
        variablesParent.style.removeProperty('margin-left');
    }

    resetMarginsAndScale(){
        const self = this;
        self.treeDesigner.config.margin = {
            left: 500,
            right: 500,
            top: 250,
            bottom: 100
        };
        self.treeDesigner.config.scale = 1;
        self.updateView();
        self.resetMarginsForVariables()
    }
    
    loadTutorialFile() {
        var self = this;
        var filenames = []
        switch(self.currentTutorial){
            case 0:
                filenames = theBasicsTutorialFiles;
                break;
            case 1:
                filenames = variablesAndDistributionsTutorialFiles;
                break;
            case 2:
                filenames = chartsForInsightTutorialFiles;
                break;
        }
        var currentStep = parseInt(
            self.tutorialDisplayStatus[self.currentTab].split(',')[2],
        );
        self.openDiagram(JSON.stringify(filenames[currentStep]));
    }

    getNumberOfTutorialFiles() {
        var self = this;
        switch(self.currentTutorial){
            case 0:
                return theBasicsTutorialFiles.length;
            case 1:
                return variablesAndDistributionsTutorialFiles.length;
            case 2:
                return chartsForInsightTutorialFiles.length;
        }
    }

    executeTutorialStep(forward) {
        var self = this;
        const interactiveTutorialText = self.getTutorialHtml()

        var currentStep = parseInt(
            self.tutorialDisplayStatus[self.currentTab].split(',')[2],
        );
        forward ? currentStep++ : currentStep--;
        
        self.tutorialDisplayStatus[self.currentTab] = 'true,' + self.currentTutorial + ',' + currentStep;
        self.loadTutorialFile();
        var text = document.getElementById('interactive-tutorial-text');
        text.innerHTML = interactiveTutorialText[currentStep];
        self.updateTutorialButtons();
        switch (self.currentTutorial){
            case 0:
                self.executeTheBasicsTutorialActions(currentStep);
                break;
            case 1:
                self.executeVariablesAndDistributionsTutorialActions(currentStep);
                break;
            case 2:
                self.executeChartsForInsightTutorialActions(currentStep);
                break;
            default: //Do nothing
        }

    }

    executeTheBasicsTutorialActions(currentStep){
        const self = this;
        const hideMilestonesCheckbox = document.getElementById("hide-milestones");
        const hideTextboxesCheckbox = document.getElementById("hide-textboxes");
        switch (currentStep){
            case 27:
                self.handleLayoutMenuTutorial(false);
                break;
            case 28:
                hideMilestonesCheckbox.checked = false;
                hideTextboxesCheckbox.checked = false;
                self.handleLayoutMenuTutorial(true);
                break;
            case 29:
                hideMilestonesCheckbox.checked = true;
                hideTextboxesCheckbox.checked = true;
                self.handleLayoutMenuTutorial(true);
                break;
            case 30:
                self.handleLayoutMenuTutorial(true);
                break;
            case 31:
                self.handleLayoutMenuTutorial(false);
                break;
            case 34:
                const nextTutorialBtn = document.getElementById("next-tutorial-button");
                if(nextTutorialBtn){
                    nextTutorialBtn.onclick = () => {
                        self.openOtherTutorialFromInsideTutorialDiv(1);
                    }
                }
                break;
            default: //Do nothing
        }
    }

    executeVariablesAndDistributionsTutorialActions(currentStep){
        const self = this;
        switch (currentStep){
            case 0: case 1: case 3: case 4: case 6: case 7: case 8: case 9: case 10: case 11: 
            case 12: case 14: case 15: case 16: case 17: case 18: case 19: case 20: case 22: case 24:
                self.handleApplicationTabMenuTutorial(false);
                break;
            case 5: case 13: case 21: case 23: case 25:
                self.handleApplicationTabMenuTutorial(true);
                break;
            case 2:
                self.handleApplicationTabMenuTutorial(false);
                const link = document.getElementById('previous-tutorial-link');
                if(link){
                    link.onclick = () => {
                        self.openOtherTutorialFromInsideTutorialDiv(0)
                    }
                };
                break;
            case 26:
                self.handleApplicationTabMenuTutorial(true);
                const prevTutorialBtn = document.getElementById("previous-tutorial-button");
                const nextTutorialBtn = document.getElementById("next-tutorial-button");
                if(prevTutorialBtn && nextTutorialBtn){
                    prevTutorialBtn.onclick = () => {
                        self.openOtherTutorialFromInsideTutorialDiv(0);
                    }
                    nextTutorialBtn.onclick = () => {
                        self.openOtherTutorialFromInsideTutorialDiv(2);
                    }
                }
                break;
            default: //Do nothing
        }
    }

    executeChartsForInsightTutorialActions(currentStep){
        const self = this;
        switch (currentStep){
            case 0: case 1: case 2: case 3: case 4: case 5: case 7: case 8: case 9: case 10: case 11: 
            case 12: case 13: case 14: case 15: case 16: case 17: case 18: case 19: case 20: case 21:
                self.handleApplicationTabMenuTutorial(false);
                break;
            case 6:
                self.handleApplicationTabMenuTutorial(true);
                break;
            case 22:
                self.handleApplicationTabMenuTutorial(false);
                const prevTutorialBtn = document.getElementById("previous-tutorial-button");
                if(prevTutorialBtn){
                    prevTutorialBtn.onclick = () => {
                        self.openOtherTutorialFromInsideTutorialDiv(1);
                    }
                }
                break;
            default: //Do nothing
        }
    }

    openOtherTutorialFromInsideTutorialDiv(tutorial){
        const self = this;
        self.handleApplicationTabMenuTutorial(false);
        self.tutorialDisplayStatus[self.currentTab] = 'true,' + tutorial + ',0';
        self.tabs[self.currentTab].firstChild.textContent = self.tutorialTitles[tutorial];
        document.getElementById("tutorial-title").textContent = self.tutorialTitles[tutorial];
        self.initTutorial(tutorial);
    }

    handleLayoutMenuTutorial(setOpen){
        const toggleDiv = document.getElementById('toggle-div');
        const toolbarToggle = document.getElementById('toolbar-toggle');
        const toolbarArrow = document.getElementById("toolbar-arrow");
        const body = document.body;
        if (setOpen){
            toggleDiv.classList.add('open');
            toolbarToggle.classList.add('open');
            toolbarArrow.classList.add('open');
            body.classList.add('application-toolbar-open');
        } else {
            toggleDiv.classList.remove('open');
            toolbarToggle.classList.remove('open');
            toolbarArrow.classList.remove('open');
            body.classList.remove('application-toolbar-open');
        }
    }

    handleApplicationTabMenuTutorial(variableTabOpen){
        const self = this;
        const applicationTabMenuGroup = document.getElementsByClassName("application-tabmenu-group")[0];
        const treeTabButton = applicationTabMenuGroup.children[0];
        const variablesTabButton = applicationTabMenuGroup.children[1];
        if (variableTabOpen){
            treeTabButton.classList.remove('active');
            variablesTabButton.classList.add('active');

            document.querySelector('.application-edit').style.display = "none";
            document.getElementById("mouse-actions-box").hidden = true;
            document.getElementById("mouse-actions-widget").hidden = true;
            document.getElementById("toggle-div").classList.add('hide');
            self.openDefinitionsDialog();
        }
        else {
            variablesTabButton.classList.remove('active');
            treeTabButton.classList.add('active');

            document.querySelector('.application-edit').style.display = "flex";
            document.getElementById("mouse-actions-widget").hidden = false;
            document.getElementById("toggle-div").classList.remove('hide');
            self.definitionsDialog.close();
        }
    }
    
    getTutorialHtml() {
        var self = this;
        switch(self.currentTutorial){
            case 0:
                return theBasicsTutorialHtml;
            case 1:
                return variablesAndDistributionsTutorialHtml;
            case 2:
                return chartsForInsightTutorialHtml;
        }
    }

    updateTutorialParameters() {
        var self = this;
        const tutorialText = self.getTutorialHtml();

        var currentStep = parseInt(
            self.tutorialDisplayStatus[self.currentTab].split(',')[2],
        );
        self.tutorialDisplayStatus[self.currentTab] = 'true,' + self.currentTutorial + ',' + currentStep;
        sessionStorage.setItem(
            'tutorial_display_status',
            JSON.stringify(self.tutorialDisplayStatus),
        );
        self.loadTutorialFile();
        var text = document.getElementById('interactive-tutorial-text');
        text.innerHTML = tutorialText[currentStep];
        self.updateTutorialButtons();
    }

    updateTutorialButtons() {
        var self = this;
        var currentStep = parseInt(
            self.tutorialDisplayStatus[self.currentTab].split(',')[2],
        );
        var progress = document.getElementById('tutorial-progress');
        var stepNumber = currentStep + 1;
        const numberOfTutorialFiles = self.getNumberOfTutorialFiles()
        progress.innerHTML = '&nbsp;' + stepNumber + ' / ' + numberOfTutorialFiles + '&nbsp;';
        var prevButton = document.getElementById('tutorial-previous-step');
        var nextButton = document.getElementById('tutorial-next-step');
        prevButton.hidden = currentStep === 0;
        nextButton.hidden = !(currentStep < numberOfTutorialFiles - 1);
    }
    
    initTabs() {
        var self = this;

        Array.prototype.forEach.call(self.tabs, function (tab) {
            tab.style.width = tab.offsetWidth + 'px';
        });

        var aTabs = Array.from(document.getElementsByClassName('a-tabs'));
        aTabs.forEach((a) => {
            a.ondblclick = () => {
                self.startEdit(a);
            };
        });

        self.initializeTabs();

        document.getElementById('add-tab').onclick = () => {
            if (self.addTab()) {
                self.treeStorage.setItem(
                    self.userId + '_' + self.currentTab,
                    JSON.stringify(blank_canvas),
                );
                self.setCurrentTab(self.currentTab);
            }
        };

        window.onresize = (event) => {
            const windowExpanded =
                window.innerWidth > self.windowWidth ? true : false;
            self.windowWidth = window.innerWidth;
            self.resizeTabs(windowExpanded);
        };
        
        self.handleDraggingAndDroppingOfTabs();
    }

    initializeTabs() {
        var self = this;
        self.tabs.forEach((tab, index) => {
            tab.onclick = (event) => {
                self.setCurrentTab(index);
            };
        });
    }

    addTab() {
        var self = this;

        if (self.tabs.length >= self.maxNumberOfTabs) {
            self.upgradeModalHeader.textContent =                 
                self.maxNumberOfTabs === 3
                    ? 'You are using 3 out of 3 projects'
                    : 'You are using 5 out of 5 projects';
            self.upgradeModalInfo.textContent =
                self.maxNumberOfTabs === 3
                    ? 'As a guest user you get 3 projects. Delete a project or upgrade for more flexibility.'
                    : 'As a basic user you get 5 projects. Delete a project or upgrade for more flexibility.';
            self.upgradeModal.style.display = 'block';
            return false;
        }

        const tabs = document.querySelector('.ul-tabs');
        self.tabs[self.currentTab].classList.remove('selected');

        const newTab = document.createElement('li');
        newTab.classList.add('tab', 'selected');
        newTab.draggable = true;

        const tabText = document.createElement('a');
        tabText.classList.add('a-tabs');
        tabText.textContent = 'Project ' + (self.tabs.length + 1);
        tabText.ondblclick = () => {
            self.startEdit(tabText);
        };

        newTab.style.width = self.tabs[self.currentTab].width + 'px';
        newTab.appendChild(tabText);
        tabs.insertBefore(newTab, document.getElementById('add-tab'));

        // Update tabs array and re-attach event listeners
        self.tabs = Array.from(document.getElementsByClassName('tab'));
        self.resizeTabs(false);
        self.initializeTabs();
        self.currentTab = self.tabs.length - 1;
        self.tutorialDisplayStatus.push('false,0,0');
        sessionStorage.setItem(
            'tutorial_display_status',
            JSON.stringify(self.tutorialDisplayStatus),
        );
        return true;
    }

    loadTreeFromTabIndex(index) {
        var self = this;
        self.openDiagram(self.treeStorage.getItem(self.userId + '_' + index));
    }

    setCurrentTab(index) {
        var self = this;
        self.tabs[self.currentTab].classList.remove('selected');
        self.tabs[index].classList.add('selected');
        self.currentTab = index;
        self.loadTreeFromTabIndex(index);

        self.handleDisplayOfTutorial();
    }

    startEdit(element) {
        var self = this;
        // Create an input element
        var input = document.createElement('input');
        input.id = 'tab-input';
        input.type = 'text';
        input.value = element.textContent.trim();

        // Replace the text with the input field
        element.innerHTML = '';
        element.appendChild(input);

        // Focus on the input field
        input.focus();
        input.style.backgroundColor = '#F9F9F9';
        input.style.border = 'none';
        input.style.outline = 'none';
        input.style.overflow = 'hidden';
        input.style.width = '100px';

        // Add an event listener to handle the input change
        input.addEventListener('blur', function () {
            // Update the text content when the input loses focus
            element.textContent = input.value;
            
            // Remove the input element
            self.tabs[self.currentTab].firstChild.textContent = input.value;
            self.updateLocalStorage();
            input.remove();
        });
    }

    getOffset(el) {
        var rect = el.getBoundingClientRect(),
            scrollLeft =
                window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop =
                window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    }

    deleteTargetTreeAndRenameLaterTrees(index) {
        var self = this;
        var lastIndex = self.tabs.length - 1;
        if (index === lastIndex) {
            self.treeStorage.removeItem(self.userId + '_' + index);
            return;
        }
        for (var i = index + 1; i <= lastIndex; i++) {
            var tempTree = self.treeStorage.getItem(self.userId + '_' + i);
            self.treeStorage.setItem(self.userId + '_' + (i - 1), tempTree);
            self.treeStorage.removeItem(self.userId + '_' + i);
        }
    }

    explode() {
        var self = this;
        var targetTab = self.tabs[self.currentTab];
        if (!targetTab) {
            console.error('Target tab is undefined');
            return;
        }
        var alert = 'Are you sure you want to delete this project?';
        if (!confirm(alert)) {
            return;
        }
        if (self.tabs.length === 1) {
            self.newDiagram();
            return;
        }

        var tabOffsets = self.getOffset(targetTab);
        var x = tabOffsets.left;
        var y = tabOffsets.top + 10;
        var c = document.createElement('canvas');
        var ctx = c.getContext('2d');
        var ratio = window.devicePixelRatio;
        var particles = [];

        self.tutorialDisplayStatus.splice(self.currentTab, 1);
        sessionStorage.setItem(
            'tutorial_display_status',
            JSON.stringify(self.tutorialDisplayStatus),
        );

        if (self.currentTab !== self.tabs.length - 1) {
            var index = self.currentTab + 1;
        } else {
            var index = self.currentTab - 1;
            self.currentTab = index;
        }
        self.tabs[index].classList.add('selected');
        self.loadTreeFromTabIndex(index);
        self.deleteTargetTreeAndRenameLaterTrees(self.currentTab);
        targetTab.style.minWidth = '';
        targetTab.classList.add('tabClosed');

        window.setTimeout(function () {
            document.body.appendChild(c);

            c.style.position = 'absolute';
            c.style.left = x - 40 + 'px';
            c.style.top = y - 150 + 'px';
            c.style.pointerEvents = 'none';
            c.style.width = 80 + 'px';
            c.style.height = 300 + 'px';
            c.width = 80 * ratio;
            c.height = 300 * ratio;

            function Particle() {
                return {
                    x: c.width / 2,
                    y: c.height / 2,
                    radius: self.r(20, 30),
                    color: self.getExplosionColor(),
                    rotation: self.r(0, 360, true),
                    speed: self.r(8, 12),
                    friction: 0.9,
                    opacity: self.r(0, 0.5, true),
                    yVel: 0,
                    gravity: 0.1,
                };
            }

            for (var i = 0; ++i < 25; ) {
                particles.push(Particle());
            }

            function render() {
                ctx.clearRect(0, 0, c.width, c.height);

                particles.forEach(function (p, i) {
                    self.angleTools.moveOnAngle(p, p.speed);

                    p.opacity -= 0.01;
                    p.speed *= p.friction;
                    p.radius *= p.friction;

                    p.yVel += p.gravity;
                    p.y += p.yVel;

                    if (p.opacity < 0) return;
                    if (p.radius < 0) return;

                    ctx.beginPath();
                    ctx.globalAlpha = p.opacity;
                    ctx.fillStyle = p.color;
                    ctx.arc(p.x, p.y, p.radius, 0, 2 * Math.PI, false);
                    ctx.fill();
                });
            }

            (function renderLoop() {
                requestAnimationFrame(renderLoop);
                render();
            })();

            setTimeout(function () {
                document.body.removeChild(c);
            }, 3000);

            targetTab.remove();
            self.tabs = Array.from(document.getElementsByClassName('tab'));
            self.resizeTabs(true);
            self.initializeTabs();
            self.handleDisplayOfTutorial();
        }, 150);
    }

    r(a, b, c) {
        return parseFloat(
            (Math.random() * ((a ? a : 1) - (b ? b : 0)) + (b ? b : 0)).toFixed(
                c ? c : 0,
            ),
        );
    }

    getNumberOfTreesSaved() {
        var self = this;
        var count = 0;
        for (var key in self.treeStorage) {
            if (
                self.treeStorage.hasOwnProperty(key) &&
                key.startsWith(self.userId)
            ) {
                count++;
            }
        }
        return count;
    }

    checkIfRegisteredUserHasTreesInSessionStorageAndPutThemInLocalStorage() {
        var self = this;
        var count = 0;
        for (var key in sessionStorage) {
            if (
                sessionStorage.hasOwnProperty(key) &&
                key.startsWith('unregistered')
            ) {
                var tree = sessionStorage.getItem(key);
                sessionStorage.removeItem(key);
                localStorage.setItem(self.userId + '_' + count, tree);
                count++;
            }
        }
    }

    initCurrentFileOptionButtons() {
        var self = this;

        const downloadButton = document.getElementById('download-file');
        downloadButton.onclick = () => {
            if (self.maxNumberOfTabs > 5) {
                self.saveToFile();
            } else {
                self.upgradeModalHeader.textContent =
                    'Need to download a file?';
                self.upgradeModalInfo.textContent =
                    'In order to download files you need to upgrade to professional.';
                self.upgradeModal.style.display = 'block';
            }
        };

        const saveAsPngButton = document.getElementById('save-as-png');
        saveAsPngButton.onclick = () => {
            if (self.maxNumberOfTabs > 5) {
                var svg = self.treeDesigner.svg;
                Exporter.saveAsPng(svg, self.config.exports);
            } else {
                self.upgradeModalHeader.textContent = 'Need to export to PNG?';
                self.upgradeModalInfo.textContent =
                    'In order to export to PNG you need to upgrade to professional.';
                self.upgradeModal.style.display = 'block';
            }
        };

        const saveAsSvgButton = document.getElementById('save-as-svg');
        saveAsSvgButton.onclick = () => {
            if (self.maxNumberOfTabs > 5) {
                var svg = self.treeDesigner.svg;
                Exporter.saveAsSvg(svg);
            } else {
                self.upgradeModalHeader.textContent = 'Need to export to SVG?';
                self.upgradeModalInfo.textContent =
                    'In order to export to SVG you need to upgrade to professional.';
                self.upgradeModal.style.display = 'block';
            }
        };

        const deleteButton = document.getElementById('delete-file');
        deleteButton.onclick = () => {
            self.explode();
        };
        const hideArrowsButton = document.getElementById('hide-arrows-file');
        if (hideArrowsButton) {
            hideArrowsButton.onclick = () => {
                hideArrowsButton.hidden = true;
                const arrows = document.getElementsByClassName('inform-arrow');
                Array.from(arrows).forEach((arrow) => {
                    arrow.hidden = true;
                });
            };
        }

        const signUpButton = document.getElementById('signup-button-navbar');
        if (signUpButton) {
            signUpButton.onclick = () => {
                self.upgradeModalHeader.textContent = 'Plans';
                self.upgradeModalInfo.textContent =
                    'Choose which plan you want to sign up for. You can always change your plan later.';
                self.upgradeModal.style.display = 'block';
            };
        }
    }

    initFileDropdownButtons() {
        var self = this;

        var openFileButton = document.getElementById('file-dropdown-open-file');
        openFileButton.onclick = () => {
            if (self.maxNumberOfTabs > 5) {
                self.addTab();
                self.newDiagram();
                self.handleDisplayOfTutorial();
                FileLoader.openFile((fileData) => {
                    const model = fileData.content;
                    self.tabs[self.currentTab].firstChild.textContent = fileData.filename;
                    self.openDiagram(model);
                });
            } else {
                self.upgradeModalHeader.textContent = 'Need to open a file?';
                self.upgradeModalInfo.textContent =
                    'In order to open a file you need to upgrade to professional.';
                self.upgradeModal.style.display = 'block';
            }
        };
    }
    
    initSettingsButton() {
        const self = this;
        const settingsButton = document.getElementById('settings');
        if (settingsButton){
            settingsButton.onclick = () => {
                self.settingsDialog.open();
            };
        }
    }

    initHelpDropdownButtons() {
        let self = this;

        const openTheBasicsTutorialButton = document.getElementById(
            'help-dropdown-the-basics',
        );
        if (openTheBasicsTutorialButton) {
            openTheBasicsTutorialButton.onclick = () => {
                if (self.addTab()) {
                    self.initTutorial(0);
                    self.handleDisplayOfTutorial();
                    document.getElementById('mouse-actions-box').hidden = true;
                    document.getElementById('mouse-actions-widget').hidden = false;
                }
            };
        }

        const openVariablesAndDistributionsTutorialButton = document.getElementById(
            'help-dropdown-variables-and-distributions',
        );
        if (openVariablesAndDistributionsTutorialButton) {
            openVariablesAndDistributionsTutorialButton.onclick = () => {
                if (self.addTab()) {
                    self.initTutorial(1);
                    self.handleDisplayOfTutorial();
                    document.getElementById('mouse-actions-box').hidden = true;
                    document.getElementById('mouse-actions-widget').hidden = false;
                }
            };
        }

        const openChartsForInsightTutorialButton = document.getElementById(
            'help-dropdown-charts-for-insight',
        );
        if (openChartsForInsightTutorialButton) {
            openChartsForInsightTutorialButton.onclick = () => {
                if (self.addTab()) {
                    self.initTutorial(2);
                    self.handleDisplayOfTutorial();
                    document.getElementById('mouse-actions-box').hidden = true;
                    document.getElementById('mouse-actions-widget').hidden = false;
                }
            };
        }

        const openBasicTree = document.getElementById(
            'help-dropdown-basic-tree',
        );
        openBasicTree.onclick = () => {
            if(self.addTab()){
                self.tabs[self.currentTab].firstChild.textContent = basic_tree_structure.title;
                self.handleDisplayOfTutorial();
                self.openDiagram(JSON.stringify(basic_tree_structure));
            }
        };

        const openDecisionTreeExplained = document.getElementById(
            'help-dropdown-decisiontree-explained',
        );
        openDecisionTreeExplained.onclick = () => {
            if(self.addTab()){
                self.tabs[self.currentTab].firstChild.textContent = decisiontree_explained.title;
                self.handleDisplayOfTutorial();
                self.openDiagram(JSON.stringify(decisiontree_explained));
            }
        };

        const openUncertainties = document.getElementById(
            'help-dropdown-uncertainties',
        );
        openUncertainties.onclick = () => {
            if(self.addTab()){
                self.tabs[self.currentTab].firstChild.textContent = value_of_information_with_uncertainty.title;
                self.handleDisplayOfTutorial();
                self.openDiagram(JSON.stringify(value_of_information_with_uncertainty));
            }
        };
    }

    initLandingPageButtons() {
        const self = this;

        const landingPageModal = document.getElementById('landing-page-modal');
        let showLandingPage = JSON.parse(
            localStorage.getItem('show_landing_page'),
        );
        const urlParams = new URLSearchParams(window.location.search);
        const summary = urlParams.get('Summary');
        if (summary) {
            self.initSummaryPage();
        } else if (showLandingPage === null || showLandingPage)
            landingPageModal.style.display = 'block';

        const closeButton = document.getElementById('close-landing-page');
        closeButton.onclick = function () {
            self.closeLandingPage();
        };

        window.addEventListener('click', function (event) {
            if (event.target === landingPageModal) {
                self.closeLandingPage();
            }
        });

        const tutorialButton1 = document.getElementById('tutorial-button-1');
        tutorialButton1.onclick = () => {
            if(self.addTab()){
                self.initTutorial(0);
                self.handleDisplayOfTutorial();
            }
            self.closeLandingPage()
        };
        if(document.getElementById('tutorial-button-2')){
            const tutorialButton2 = document.getElementById('tutorial-button-2');
            tutorialButton2.onclick = () => {
                if(self.addTab()){
                    self.initTutorial(1);
                    self.handleDisplayOfTutorial();
                }
                self.closeLandingPage()
            };
        }
        if(document.getElementById('tutorial-button-3')){
            const tutorialButton3 = document.getElementById('tutorial-button-3');
            tutorialButton3.onclick = () => {
                if(self.addTab()){
                    self.initTutorial(2);
                    self.handleDisplayOfTutorial();
                }
                self.closeLandingPage()
            };
        }

        var supportPageLink = document.getElementById('visit-support-page');
        supportPageLink.onclick = () => {
            window.open('https://www.dnodes.io/support/');
        };

        const openLandingPage = document.getElementById('open-landing-page');
        openLandingPage.onclick = () => {
            document.getElementById('landing-page-modal').style.display =
                'block';
            showLandingPage = JSON.parse(
                localStorage.getItem('show_landing_page'),
            );
            document.getElementById('dont-show-again-checkbox').checked =
                !showLandingPage;
        };
    }

    initSummaryPage() {
        const self = this;

        const summaryPage = document.getElementById('summary-page-modal');
        const showLandingPage = localStorage.getItem('show_landing_page');
        const closeButton = document.getElementById('close-summary-page');
        const text = document.getElementById('summary-page-modal-text');

        summaryPage.style.display = 'block';
        const urlString = window.location.search;
        const urlParams = new URLSearchParams(urlString);
        const quantity = parseInt(urlParams.get('quantity'));
        if (quantity > 1) {
            const paragraph = self.makeSummaryTextWithQuantity();
            text.appendChild(paragraph);
        }
        const free = urlParams.get('free');
        if (free === 'true') {
            text.innerHTML =
                'You have successfully created a free account. You can now create up to 5 projects. <br><br> To create more projects and unlock more functionality, you can upgrade to a professional account.';
        }

        if (closeButton) {
            closeButton.onclick = function () {
                self.closeSummaryPage();
                if (showLandingPage === null || showLandingPage === 'true')
                    document.getElementById(
                        'landing-page-modal',
                    ).style.display = 'block';
            };
        }
        window.addEventListener('click', function () {
            if (event.target === summaryPage) {
                self.closeSummaryPage();
                if (showLandingPage === null || showLandingPage === 'true')
                    document.getElementById(
                        'landing-page-modal',
                    ).style.display = 'block';
            }
        });
    }

    initUpgradeModalButtons() {
        var self = this;
        var upgradeModalGuestButton = document.getElementById('guest-button');
        var upgradeModalBasicButton = document.getElementById(
            'register-free-account-button',
        );
        var upgradeModalProfessionalButton = document.getElementById(
            'upgrade-to-professional-button',
        );

        upgradeModalBasicButton.onclick = () =>
            (window.location.href =
                window.location.origin + '/Identity/Account/RegisterFree');
        upgradeModalProfessionalButton.onclick = () =>
            (window.location.href =
                self.userId === 'unregistered'
                    ? window.location.origin +
                        '/Identity/Account/RegisterForProfessional'
                    : window.location.origin +
                        '/Identity/Account/UpgradeToProfessional');

        const upgradeModalGuestLabel = document.getElementById(
            'current-plan-label-guest',
        );
        const upgradeModalBasicLabel = document.getElementById(
            'current-plan-label-basic',
        );
        const upgradeModalProfessionalLabel = document.getElementById(
            'current-plan-label-professional',
        );
        upgradeModalGuestLabel.hidden = true;
        upgradeModalBasicLabel.hidden = true;
        upgradeModalProfessionalLabel.hidden = true;

        if (self.maxNumberOfTabs === 3) {
            upgradeModalGuestLabel.hidden = false;
        } else if (self.maxNumberOfTabs === 5) {
            upgradeModalBasicLabel.hidden = false;
            upgradeModalGuestButton.hidden = true;
            upgradeModalBasicButton.textContent = 'Current plan';
            upgradeModalBasicButton.disabled = true;
        } else {
            upgradeModalProfessionalLabel.hidden = false;
        }
    }

    handleDisplayOfTutorial() {
        var self = this;
        const tutorialStatusCurrentTab =
            self.tutorialDisplayStatus[self.currentTab].split(',');
        const displayTutorial = tutorialStatusCurrentTab[0];
        const variableViewClosed = !document
            .getElementById('toggle-div')
            .classList.contains('hide');
        if (displayTutorial === 'true') {
            self.currentTutorial = parseInt(tutorialStatusCurrentTab[1]);
            self.tabs[self.currentTab].firstChild.textContent = self.tutorialTitles[self.currentTutorial];
            document.getElementById("tutorial-title").textContent = self.tutorialTitles[self.currentTutorial];
            self.updateTutorialParameters();
            self.setMarginsForVariablesInTutorial();
            if (variableViewClosed) {
                self.interactiveTutorialDiv.hidden = false;
            }
        }
        else {
            self.resetMarginsForVariables();
            self.interactiveTutorialDiv.hidden = true;
        }
    }

    checkIfMacOS() {
        let platform;
        if (navigator && navigator.userAgentData && navigator.userAgentData.platform) {
            platform = navigator.userAgentData.platform;
        } else if (navigator && navigator.platform) {
            platform = navigator.platform;
        }
        if (platform.includes('Mac') || (navigator.userAgent.includes('Mac'))) {
            const helpKeyboardActions = document.getElementById("help-dropdown-keyboard-actions");
            helpKeyboardActions.hidden = true;
        }
    }

    resizeTabs(windowExpandedOrTabClosed) {
        var self = this;
        const firstTab = self.tabs[0];
        const currentTabWidth = firstTab.offsetWidth;
        if (
            currentTabWidth === self.defaultTabSize &&
            windowExpandedOrTabClosed
        )
            return;
        const numOfTabs = self.tabs.length;

        const horizontalPaddingAndBorder =
            firstTab.style.padding * 2 + firstTab.style.border * 2;
        const parentWidth = document.querySelector('.tabs-wrapper').offsetWidth;
        const navLeftWrapper = document.querySelector('.nav-left-wrapper');
        const navLeftWrapperStyle = window.getComputedStyle(navLeftWrapper);
        const navLeftWrapperGap = parseFloat(navLeftWrapperStyle.gap) || 0;
        const navBarBrand = document.getElementById('nav-bar-brand');
        const paddingRight =
            navLeftWrapper.offsetWidth -
            parentWidth -
            navBarBrand.offsetWidth -
            parseFloat(window.getComputedStyle(navBarBrand).marginLeft) -
            document.getElementById('file-dropdown').offsetWidth -
            navLeftWrapperGap * 2;
        const addTabWidth = document.getElementById('add-tab').offsetWidth;
        const spaceForTabs = parentWidth + paddingRight - addTabWidth;

        const newTabWidth = Math.min(
            spaceForTabs / numOfTabs - horizontalPaddingAndBorder,
            self.defaultTabSize,
        );

        self.tabs.forEach((tab) => {
            tab.style.width = newTabWidth + 'px';
        });
    }

    closeLandingPage() {
        const hideArrowsButton = document.getElementById('hide-arrows-file');
        hideArrowsButton.hidden = false;
        const arrows = document.getElementsByClassName('inform-arrow');
        Array.from(arrows).forEach((arrow) => {
            arrow.hidden = false;
        });
        document.getElementById('landing-page-modal').style.display = 'none';
        const checkbox = document.getElementById('dont-show-again-checkbox');
        const showLandingPage = checkbox.checked ? 'false' : 'true';
        localStorage.setItem('show_landing_page', showLandingPage);
    }

    closeSummaryPage() {
        const url = window.location.origin + '/Tree';
        history.replaceState(null, '', url);
        document.getElementById('summary-page-modal').style.display = 'none';
    }

    makeSummaryTextWithQuantity() {
        const paragraph = document.createElement('p');
        const sentence = 'To administer your licenses, you can go ';
        const link = document.createElement('a');
        const subscriptionsUrl =
            window.location.origin + '/Identity/Account/Manage/Subscriptions';
        link.setAttribute('href', subscriptionsUrl);
        link.textContent = 'here';
        const period = '.';
        paragraph.innerHTML = `<br>${sentence}${link.outerHTML}${period}`;
        return paragraph;
    }

    handleDraggingAndDroppingOfTabs(){
        var self = this;

        let draggedItem = null;
        let draggedItemIndex = null

        document.addEventListener('dragstart', handleDragStart);
        document.addEventListener('dragover', handleDragOver);
        document.addEventListener('drop', handleDrop);
        document.addEventListener('dragleave', handleDragLeave)
        let dropline = createDropline();
        
        function handleDragStart(event) {
            const targetItem = event.target.closest('.tab');
            if (!targetItem) return;
            
            draggedItem = targetItem;
            draggedItemIndex = [...draggedItem.parentNode.children].indexOf(draggedItem);
            event.dataTransfer.effectAllowed = 'move';
            event.dataTransfer.setData('text/html', draggedItem.outerHTML);
        }
        
        function handleDragOver(event) {
            event.preventDefault();
            event.dataTransfer.dropEffect = 'move';
            draggedItem.style.opacity = '0.5';

            const targetItem = event.target.closest('.tab');
            if (targetItem){
                if (event.clientX > targetItem.getBoundingClientRect().left + (targetItem.offsetWidth / 2)) {
                    targetItem.parentNode.insertBefore(dropline, targetItem.nextSibling);
                } else {
                    targetItem.parentNode.insertBefore(dropline, targetItem);
                }
            }
        }

        function handleDrop(event) {
            event.preventDefault();
            dropline.remove();

            draggedItem.style.opacity = '';
            const targetItem = event.target.closest('.tab');
            if (!targetItem || targetItem === draggedItem) return;
            if (event.clientX > targetItem.getBoundingClientRect().left + (targetItem.offsetWidth / 2)) {
                targetItem.parentNode.insertBefore(draggedItem, targetItem.nextSibling);
            } else {
                targetItem.parentNode.insertBefore(draggedItem, targetItem);
            }
            let newIndex = [...draggedItem.parentNode.children].indexOf(draggedItem);
            self.renameTreesAfterTabIsMoved(draggedItemIndex, newIndex);
            self.updateTutorialDisplayStatusAfterTabIsMoved(draggedItemIndex, newIndex);
            self.tabs = Array.from(document.getElementsByClassName('tab'));
            self.initializeTabs();
            self.updateCurrentTabAfterTabIsMoved();

            draggedItem = null;
            draggedItemIndex = null;
        }

        function handleDragLeave(event){
            event.preventDefault();
            dropline.remove();
            draggedItem.style.opacity = '';
        }
        
        function createDropline() {
            const dropline = document.createElement('div');
            dropline.style.borderLeft= '2px solid #4E6E7E';
            dropline.style.height='45px';
            return dropline;
        }
    }

    renameTreesAfterTabIsMoved(oldIndex, newIndex) {
        const self = this;
        if (oldIndex === newIndex)
            return;
        let movedTabTree = self.treeStorage.getItem(self.userId + '_' + oldIndex);
        self.treeStorage.removeItem(self.userId + '_' + oldIndex); 
        if(newIndex > oldIndex){
            for (let i = oldIndex+1; i <= newIndex; i++) {
                let tempTree = self.treeStorage.getItem(self.userId + '_' + i);
                self.treeStorage.removeItem(self.userId + '_' + i);
                self.treeStorage.setItem(self.userId + '_' + (i - 1), tempTree);
            }
        }
        else{
            for (let i = oldIndex-1; i >= newIndex; i--) {
                let tempTree = self.treeStorage.getItem(self.userId + '_' + i);
                self.treeStorage.removeItem(self.userId + '_' + i);
                self.treeStorage.setItem(self.userId + '_' + (i + 1), tempTree);
            }
        }
        self.treeStorage.setItem(self.userId + '_' + newIndex, movedTabTree);
    }

    updateTutorialDisplayStatusAfterTabIsMoved(oldIndex, newIndex){
        const self = this;
        let element = self.tutorialDisplayStatus.splice(oldIndex, 1)[0];
        self.tutorialDisplayStatus.splice(newIndex, 0, element);
        sessionStorage.setItem("tutorial_display_status", JSON.stringify(self.tutorialDisplayStatus));
    }

    updateCurrentTabAfterTabIsMoved(){
        const self = this;
        self.tabs.forEach((tab, index) => {
            if(tab.classList.contains("selected")){
                self.currentTab = index;
            }
        });
    }

    initBroadcastChannel(){
        const channel = new BroadcastChannel('tab');
        let isOriginal = true;

        channel.postMessage('another_tab');
        channel.addEventListener('message', (msg) => {
            if (msg.data === 'another_tab' && isOriginal) {
                channel.postMessage('already_open');
            }
            if (msg.data === 'already_open') {
                isOriginal = false;
                alert('dNodes.io is open in at least one other browser tab. You can only construct decision trees in one browser tab at a time. Please close this tab to continue.');
                location.reload();
            }
        });
    }

    redirectIfUsedOnMobileDevice() {
        var mobilePattern = /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/;
        var tabletPattern = /Tablet|iPad|PlayBook|Silk/i;
    
        var userAgent = navigator.userAgent;
        var isMobile = mobilePattern.test(userAgent);
        var isTablet = tabletPattern.test(userAgent);
    
        if(isMobile || isTablet){
            window.location.href = window.location.origin + "/MobileDeviceRedirect";
        }
    }

    handleTutorialUrlParam(){
        const self = this;
        const urlParams = new URLSearchParams(window.location.search);
        const tutorial = urlParams.get('tutorial');
        if(tutorial){
            document.getElementById('landing-page-modal').style.display = 'none';
            // Initial tree must be saved in local storage before tutorial tree is opened
            self.updateLocalStorage(tutorial);
        }
    }

    loadTutorialByName(tutorial){
        const self = this;
        if(self.addTab()){
            switch(tutorial){
                case "the-basics":
                    self.initTutorial(0);
                    self.handleDisplayOfTutorial();
                    break;
                case "variables-and-distributions":
                    self.initTutorial(1);
                    self.handleDisplayOfTutorial();
                    break;
                case "charts-for-insight":
                    self.initTutorial(2);
                    self.handleDisplayOfTutorial();
                    break;
            }
        }
    }
    
}
