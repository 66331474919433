module.exports={
  "SilverDecisions": "1.1.0",
  "buildTimestamp": 1669186055643,
  "savetime": "2024-03-02T11:07:39.588Z",
  "lng": "en",
  "viewMode": "criterion1",
  "rule": "expected-value-maximization",
  "title": "",
  "description": "",
  "format": {
    "locales": "en",
    "payoff1": {
      "style": "currency",
      "currency": "USD",
      "currencyDisplay": "symbol",
      "minimumFractionDigits": 0,
      "maximumFractionDigits": 0,
      "useGrouping": true
    },
    "payoff2": {
      "style": "percent",
      "currency": "USD",
      "currencyDisplay": "symbol",
      "minimumFractionDigits": 0,
      "maximumFractionDigits": 0,
      "useGrouping": true
    },
    "probability": {
      "style": "percent",
      "minimumFractionDigits": 0,
      "maximumFractionDigits": 0,
      "useGrouping": true
    }
  },
  "treeDesigner": {
    "margin": {
      "left": 550,
      "right": 500,
      "top": 200,
      "bottom": 100
    },
    "scale": 1,
    "lng": "en",
    "layout": {
      "type": "tree",
      "nodeSize": 22,
      "limitNodePositioning": true,
      "limitTextPositioning": false,
      "gridHeight": 102,
      "gridWidth": 260,
      "edgeSlantWidthMax": 0
    },
    "fontFamily": "sans-serif",
    "fontSize": "12px",
    "fontWeight": "normal",
    "fontStyle": "normal",
    "node": {
      "strokeWidth": "1px",
      "optimal": {
        "stroke": "#006f00",
        "strokeWidth": "1.5px"
      },
      "label": {
        "fontSize": "1em",
        "color": "black"
      },
      "payoff": {
        "fontSize": "1em",
        "color": "black",
        "negativeColor": "#b60000"
      },
      "decision": {
        "fill": "#ff7777",
        "stroke": "#660000",
        "selected": {
          "fill": "#aa3333"
        }
      },
      "chance": {
        "fill": "#ffff44",
        "stroke": "#666600",
        "selected": {
          "fill": "#aaaa00"
        }
      },
      "terminal": {
        "fill": "#44ff44",
        "stroke": "black",
        "selected": {
          "fill": "#00aa00"
        },
        "payoff": {
          "fontSize": "1em",
          "color": "black",
          "negativeColor": "#b60000"
        }
      }
    },
    "edge": {
      "stroke": "#424242",
      "strokeWidth": "1.5",
      "optimal": {
        "stroke": "#006f00",
        "strokeWidth": "2.4"
      },
      "selected": {
        "stroke": "#045ad1",
        "strokeWidth": "3.5"
      },
      "label": {
        "fontSize": "1em",
        "color": "back"
      },
      "payoff": {
        "fontSize": "1em",
        "color": "black",
        "negativeColor": "#b60000"
      }
    },
    "probability": {
      "fontSize": "1em",
      "color": "#0000d7"
    },
    "title": {
      "fontSize": "16px",
      "fontWeight": "bold",
      "fontStyle": "normal",
      "color": "#000000",
      "margin": {
        "top": 15,
        "bottom": 10
      }
    },
    "description": {
      "show": true,
      "fontSize": "12px",
      "fontWeight": "bold",
      "fontStyle": "normal",
      "color": "#000000",
      "margin": {
        "top": 5,
        "bottom": 10
      }
    },
    "readOnly": false,
    "disableAnimations": false,
    "forceFullEdgeRedraw": false,
    "hideLabels": false,
    "hidePayoffs": false,
    "hideProbabilities": false,
    "hideCharts": false,
    "hideTextboxes": false,
    "hideMilestones": false,
    "raw": false,
    "payoffNames": [
      null,
      null
    ],
    "maxPayoffsToDisplay": 1,
    "milestoneNames": [
      "",
      "Main Decision Point",
      "Milestone 1",
      "Milestone 2",
      "",
      "",
      "",
      "",
      "",
      ""
    ]
  },
  "data": {
    "code": "Alternative_A=-1000\nAlternative_B=-2000\nOutcome_A=300\nOutcome_B=500\nOutcome_C=200\nOutcome_D=600\nAlternative_X=500\nAlternative_Y=1200\n",
    "expressionScope": {
      "Alternative_A": -1000,
      "Alternative_B": -2000,
      "Outcome_A": 300,
      "Outcome_B": 500,
      "Outcome_C": 200,
      "Outcome_D": 600,
      "Alternative_X": 500,
      "Alternative_Y": 1200
    },
    "trees": [
      {
        "computed": {
          "expected-value-maximization": {
            "childrenPayoff": [
              "0"
            ],
            "payoff": [
              "0"
            ],
            "optimal": true
          },
          "expected-value-minimization": {
            "childrenPayoff": [
              "0"
            ],
            "payoff": [
              "0"
            ],
            "optimal": true
          },
          "maxi-min": {
            "childrenPayoff": [
              "0"
            ],
            "payoff": [
              "0"
            ],
            "optimal": true
          },
          "maxi-max": {
            "childrenPayoff": [
              "0"
            ],
            "payoff": [
              "0"
            ],
            "optimal": true
          },
          "mini-min": {
            "childrenPayoff": [
              "0"
            ],
            "payoff": [
              "0"
            ],
            "optimal": true
          },
          "mini-max": {
            "childrenPayoff": [
              "0"
            ],
            "payoff": [
              "0"
            ],
            "optimal": true
          },
          "min-max": {
            "childrenPayoff": [
              "0",
              "0"
            ],
            "combinedPayoff": "0",
            "payoff": [
              "0",
              "0"
            ],
            "optimal": true
          },
          "max-min": {
            "childrenPayoff": [
              "0",
              "0"
            ],
            "combinedPayoff": "0",
            "payoff": [
              "0",
              "0"
            ],
            "optimal": true
          },
          "min-min": {
            "childrenPayoff": [
              "0",
              "0"
            ],
            "combinedPayoff": "0",
            "payoff": [
              "0",
              "0"
            ],
            "optimal": true
          },
          "max-max": {
            "childrenPayoff": [
              "0",
              "0"
            ],
            "combinedPayoff": "0",
            "payoff": [
              "0",
              "0"
            ],
            "optimal": true
          }
        },
        "childEdges": [
          {
            "computed": {
              "payoff": [
                "0",
                "0"
              ],
              "expected-value-maximization": {
                "probability": 1,
                "optimal": true
              },
              "expected-value-minimization": {
                "probability": 1,
                "optimal": true
              },
              "maxi-min": {
                "probability": 1,
                "optimal": true
              },
              "maxi-max": {
                "probability": 1,
                "optimal": true
              },
              "mini-min": {
                "probability": 1,
                "optimal": true
              },
              "mini-max": {
                "probability": 1,
                "optimal": true
              },
              "min-max": {
                "probability": 1,
                "optimal": true
              },
              "max-min": {
                "probability": 1,
                "optimal": true
              },
              "min-min": {
                "probability": 1,
                "optimal": true
              },
              "max-max": {
                "probability": 1,
                "optimal": true
              }
            },
            "name": "",
            "payoff": [
              0,
              0
            ],
            "childNode": {
              "computed": {
                "expected-value-maximization": {
                  "childrenPayoff": [
                    "0"
                  ],
                  "payoff": [
                    "0"
                  ],
                  "optimal": true
                },
                "expected-value-minimization": {
                  "childrenPayoff": [
                    "0"
                  ],
                  "payoff": [
                    "0"
                  ],
                  "optimal": true
                },
                "maxi-min": {
                  "childrenPayoff": [
                    "0"
                  ],
                  "payoff": [
                    "0"
                  ],
                  "optimal": true
                },
                "maxi-max": {
                  "childrenPayoff": [
                    "0"
                  ],
                  "payoff": [
                    "0"
                  ],
                  "optimal": true
                },
                "mini-min": {
                  "childrenPayoff": [
                    "0"
                  ],
                  "payoff": [
                    "0"
                  ],
                  "optimal": true
                },
                "mini-max": {
                  "childrenPayoff": [
                    "0"
                  ],
                  "payoff": [
                    "0"
                  ],
                  "optimal": true
                },
                "min-max": {
                  "childrenPayoff": [
                    "0",
                    "0"
                  ],
                  "combinedPayoff": "0",
                  "payoff": [
                    "0",
                    "0"
                  ],
                  "optimal": true
                },
                "max-min": {
                  "childrenPayoff": [
                    "0",
                    "0"
                  ],
                  "combinedPayoff": "0",
                  "payoff": [
                    "0",
                    "0"
                  ],
                  "optimal": true
                },
                "min-min": {
                  "childrenPayoff": [
                    "0",
                    "0"
                  ],
                  "combinedPayoff": "0",
                  "payoff": [
                    "0",
                    "0"
                  ],
                  "optimal": true
                },
                "max-max": {
                  "childrenPayoff": [
                    "0",
                    "0"
                  ],
                  "combinedPayoff": "0",
                  "payoff": [
                    "0",
                    "0"
                  ],
                  "optimal": true
                }
              },
              "childEdges": [
                {
                  "computed": {
                    "payoff": [
                      "0",
                      "0"
                    ],
                    "probability": "1",
                    "expected-value-maximization": {
                      "probability": "1",
                      "optimal": true
                    },
                    "expected-value-minimization": {
                      "probability": "1",
                      "optimal": true
                    },
                    "maxi-min": {
                      "probability": 1,
                      "optimal": true
                    },
                    "maxi-max": {
                      "probability": 1,
                      "optimal": true
                    },
                    "mini-min": {
                      "probability": 1,
                      "optimal": true
                    },
                    "mini-max": {
                      "probability": 1,
                      "optimal": true
                    },
                    "min-max": {
                      "probability": "1",
                      "optimal": true
                    },
                    "max-min": {
                      "probability": "1",
                      "optimal": true
                    },
                    "min-min": {
                      "probability": "1",
                      "optimal": true
                    },
                    "max-max": {
                      "probability": "1",
                      "optimal": true
                    }
                  },
                  "name": "",
                  "probability": "#",
                  "payoff": [
                    0,
                    0
                  ],
                  "childNode": {
                    "computed": {
                      "expected-value-maximization": {
                        "aggregatedPayoff": [
                          "0"
                        ],
                        "probabilityToEnter": "1",
                        "payoff": [
                          "0"
                        ],
                        "optimal": true
                      },
                      "expected-value-minimization": {
                        "aggregatedPayoff": [
                          "0"
                        ],
                        "probabilityToEnter": "1",
                        "payoff": [
                          "0"
                        ],
                        "optimal": true
                      },
                      "maxi-min": {
                        "aggregatedPayoff": [
                          "0"
                        ],
                        "probabilityToEnter": "1",
                        "payoff": [
                          "0"
                        ],
                        "optimal": true
                      },
                      "maxi-max": {
                        "aggregatedPayoff": [
                          "0"
                        ],
                        "probabilityToEnter": "1",
                        "payoff": [
                          "0"
                        ],
                        "optimal": true
                      },
                      "mini-min": {
                        "aggregatedPayoff": [
                          "0"
                        ],
                        "probabilityToEnter": "1",
                        "payoff": [
                          "0"
                        ],
                        "optimal": true
                      },
                      "mini-max": {
                        "aggregatedPayoff": [
                          "0"
                        ],
                        "probabilityToEnter": "1",
                        "payoff": [
                          "0"
                        ],
                        "optimal": true
                      },
                      "min-max": {
                        "aggregatedPayoff": [
                          "0",
                          "0"
                        ],
                        "probabilityToEnter": "1",
                        "combinedPayoff": "0",
                        "payoff": [
                          "0",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-min": {
                        "aggregatedPayoff": [
                          "0",
                          "0"
                        ],
                        "probabilityToEnter": "1",
                        "combinedPayoff": "0",
                        "payoff": [
                          "0",
                          "0"
                        ],
                        "optimal": true
                      },
                      "min-min": {
                        "aggregatedPayoff": [
                          "0",
                          "0"
                        ],
                        "probabilityToEnter": "1",
                        "combinedPayoff": "0",
                        "payoff": [
                          "0",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-max": {
                        "aggregatedPayoff": [
                          "0",
                          "0"
                        ],
                        "probabilityToEnter": "1",
                        "combinedPayoff": "0",
                        "payoff": [
                          "0",
                          "0"
                        ],
                        "optimal": true
                      }
                    },
                    "childEdges": [],
                    "name": "Terminal node / Payoff",
                    "code": "",
                    "expressionScope": {
                      "Alternative_A": -1000,
                      "Alternative_B": -2000,
                      "Outcome_A": 300,
                      "Outcome_B": 500,
                      "Outcome_C": 200,
                      "Outcome_D": 600,
                      "Alternative_X": 500,
                      "Alternative_Y": 1200
                    },
                    "folded": false,
                    "location": {
                      "x": 531,
                      "y": 11
                    },
                    "type": "terminal",
                    "expectedValue": 0,
                    "p10": 0,
                    "p90": 0
                  },
                  "expectedValue": 0,
                  "p10": 0,
                  "p90": 0,
                  "optimal": true
                }
              ],
              "name": "Chance node",
              "code": "",
              "expressionScope": {
                "Alternative_A": -1000,
                "Alternative_B": -2000,
                "Outcome_A": 300,
                "Outcome_B": 500,
                "Outcome_C": 200,
                "Outcome_D": 600,
                "Alternative_X": 500,
                "Alternative_Y": 1200
              },
              "folded": false,
              "location": {
                "x": 271,
                "y": 11
              },
              "type": "chance",
              "expectedValue": 0,
              "p10": 0,
              "p90": 0
            },
            "expectedValue": 0,
            "p10": 0,
            "p90": 0,
            "optimal": true
          }
        ],
        "name": "Decision nodes",
        "code": "",
        "expressionScope": {
          "Alternative_A": -1000,
          "Alternative_B": -2000,
          "Outcome_A": 300,
          "Outcome_B": 500,
          "Outcome_C": 200,
          "Outcome_D": 600,
          "Alternative_X": 500,
          "Alternative_Y": 1200
        },
        "folded": false,
        "location": {
          "x": 11,
          "y": 11
        },
        "type": "decision",
        "expectedValue": 0,
        "p10": 0,
        "p90": 0
      }
    ],
    "texts": [],
    "payoffNames": [],
    "defaultCriterion1Weight": 1,
    "weightLowerBound": 0,
    "weightUpperBound": "Infinity"
  },
  "sensitivityAnalysis": {},
  "definitionsDialog": {
    "valueList": [
      {
        "name": "Alternative_A",
        "value": "-1000",
        "low": -1500,
        "high": -500,
        "distribution": "triangular",
        "error": ""
      },
      {
        "name": "Alternative_B",
        "value": "-2000",
        "low": -3000,
        "high": -1000,
        "distribution": "triangular",
        "error": ""
      },
      {
        "name": "Outcome_A",
        "value": "300",
        "low": 100,
        "high": 500,
        "distribution": "triangular",
        "error": ""
      },
      {
        "name": "Outcome_B",
        "value": "500",
        "low": 400,
        "high": 900,
        "distribution": "triangular",
        "error": ""
      },
      {
        "name": "Outcome_C",
        "value": "200",
        "low": 100,
        "high": 300,
        "distribution": "triangular",
        "error": ""
      },
      {
        "name": "Outcome_D",
        "value": "600",
        "low": 500,
        "high": 1100,
        "distribution": "triangular",
        "error": ""
      },
      {
        "name": "Alternative_X",
        "value": "500",
        "low": 300,
        "high": 700,
        "distribution": "triangular",
        "error": ""
      },
      {
        "name": "Alternative_Y",
        "value": "1200",
        "low": 700,
        "high": 2200,
        "distribution": "triangular",
        "error": ""
      }
    ]
  }
}