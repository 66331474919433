module.exports={
  "SilverDecisions": "1.1.0",
  "buildTimestamp": 1710935291638,
  "savetime": "2024-05-08T12:52:06.460Z",
  "lng": "en",
  "viewMode": "criterion1",
  "rule": "expected-value-maximization",
  "title": "Project 10",
  "description": "",
  "format": {
    "locales": "en",
    "payoff1": {
      "style": "decimal",
      "currency": "USD",
      "currencyDisplay": "name",
      "minimumFractionDigits": 0,
      "maximumFractionDigits": 0,
      "useGrouping": true
    },
    "payoff2": {
      "style": "percent",
      "currency": "USD",
      "currencyDisplay": "symbol",
      "minimumFractionDigits": 0,
      "maximumFractionDigits": 0,
      "useGrouping": true
    },
    "probability": {
      "style": "percent",
      "minimumFractionDigits": 0,
      "maximumFractionDigits": 0,
      "useGrouping": true
    }
  },
  "treeDesigner": {
    "margin": {
      "left": 550,
      "right": 500,
      "top": 200,
      "bottom": 100
    },
    "scale": 1,
    "lng": "en",
    "layout": {
      "type": "cluster",
      "nodeSize": 22,
      "limitNodePositioning": true,
      "limitTextPositioning": true,
      "gridHeight": 95,
      "gridWidth": 160,
      "edgeSlantWidthMax": 0
    },
    "fontFamily": "sans-serif",
    "fontSize": "12px",
    "fontWeight": "normal",
    "fontStyle": "normal",
    "node": {
      "strokeWidth": "2px",
      "optimal": {
        "stroke": "#006f00",
        "strokeWidth": "2px"
      },
      "label": {
        "fontSize": "1em",
        "color": "black"
      },
      "payoff": {
        "fontSize": "1em",
        "color": "black",
        "negativeColor": "#b60000"
      },
      "decision": {
        "fill": "#ff7777",
        "stroke": "#660000",
        "selected": {
          "fill": "#aa3333"
        }
      },
      "chance": {
        "fill": "#ffff44",
        "stroke": "#666600",
        "selected": {
          "fill": "#aaaa00"
        }
      },
      "terminal": {
        "fill": "#44ff44",
        "stroke": "black",
        "selected": {
          "fill": "#00aa00"
        },
        "payoff": {
          "fontSize": "1em",
          "color": "black",
          "negativeColor": "#b60000"
        }
      }
    },
    "edge": {
      "stroke": "#424242",
      "strokeWidth": "1.5",
      "optimal": {
        "stroke": "#006f00",
        "strokeWidth": "2.4"
      },
      "selected": {
        "stroke": "#045ad1",
        "strokeWidth": "3.5"
      },
      "label": {
        "fontSize": "1em",
        "color": "back"
      },
      "payoff": {
        "fontSize": "1em",
        "color": "black",
        "negativeColor": "#b60000"
      }
    },
    "probability": {
      "fontSize": "1em",
      "color": "#0000d7"
    },
    "title": {
      "fontSize": "16px",
      "fontWeight": "bold",
      "fontStyle": "normal",
      "color": "#000000",
      "margin": {
        "top": 15,
        "bottom": 10
      }
    },
    "description": {
      "show": true,
      "fontSize": "12px",
      "fontWeight": "bold",
      "fontStyle": "normal",
      "color": "#000000",
      "margin": {
        "top": 5,
        "bottom": 10
      }
    },
    "readOnly": false,
    "disableAnimations": false,
    "forceFullEdgeRedraw": false,
    "hideLabels": false,
    "hidePayoffs": true,
    "hideExpectedValues": false,
    "hideProbabilities": false,
    "hideCharts": false,
    "hideTextboxes": false,
    "hideMilestones": false,
    "raw": false,
    "payoffNames": [
      null,
      null
    ],
    "maxPayoffsToDisplay": 1,
    "milestoneNames": [
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    ]
  },
  "data": {
    "code": "Europe=745000\nNorthAmerica=865000\nAsia=300000\n",
    "expressionScope": {
      "Europe": 745000,
      "NorthAmerica": 865000,
      "Asia": 300000
    },
    "trees": [
      {
        "computed": {
          "expected-value-maximization": {
            "childrenPayoff": [
              "1996192 1345397133/4294967296"
            ],
            "payoff": [
              "1996713 15200115/134217728"
            ],
            "optimal": true
          },
          "expected-value-minimization": {
            "childrenPayoff": [
              "1996713 15200115/134217728"
            ],
            "payoff": [
              "1996713 15200115/134217728"
            ],
            "optimal": true
          },
          "maxi-min": {
            "childrenPayoff": [
              "1786713 486403681/4294967296"
            ],
            "payoff": [
              "1786713 486403681/4294967296"
            ],
            "optimal": true
          },
          "maxi-max": {
            "childrenPayoff": [
              "2086713 15200115/134217728"
            ],
            "payoff": [
              "2086713 15200115/134217728"
            ],
            "optimal": true
          },
          "mini-min": {
            "childrenPayoff": [
              "1786713 486403681/4294967296"
            ],
            "payoff": [
              "1786713 486403681/4294967296"
            ],
            "optimal": true
          },
          "mini-max": {
            "childrenPayoff": [
              "2086713 15200115/134217728"
            ],
            "payoff": [
              "2086713 15200115/134217728"
            ],
            "optimal": true
          },
          "min-max": {
            "childrenPayoff": [
              "1996713 15200115/134217728",
              "0"
            ],
            "combinedPayoff": "-1996713 15200115/134217728",
            "payoff": [
              "1996713 15200115/134217728",
              "0"
            ],
            "optimal": true
          },
          "max-min": {
            "childrenPayoff": [
              "1996713 15200115/134217728",
              "0"
            ],
            "combinedPayoff": "1996713 15200115/134217728",
            "payoff": [
              "1996713 15200115/134217728",
              "0"
            ],
            "optimal": true
          },
          "min-min": {
            "childrenPayoff": [
              "1996713 15200115/134217728",
              "0"
            ],
            "combinedPayoff": "-1996713 15200115/134217728",
            "payoff": [
              "1996713 15200115/134217728",
              "0"
            ],
            "optimal": true
          },
          "max-max": {
            "childrenPayoff": [
              "1996713 15200115/134217728",
              "0"
            ],
            "combinedPayoff": "1996713 15200115/134217728",
            "payoff": [
              "1996713 15200115/134217728",
              "0"
            ],
            "optimal": true
          }
        },
        "childEdges": [
          {
            "computed": {
              "payoff": [
                "0",
                "0"
              ],
              "expected-value-maximization": {
                "probability": 1,
                "optimal": true
              },
              "expected-value-minimization": {
                "probability": 1,
                "optimal": true
              },
              "maxi-min": {
                "probability": 1,
                "optimal": true
              },
              "maxi-max": {
                "probability": 1,
                "optimal": true
              },
              "mini-min": {
                "probability": 1,
                "optimal": true
              },
              "mini-max": {
                "probability": 1,
                "optimal": true
              },
              "min-max": {
                "probability": 1,
                "optimal": true
              },
              "max-min": {
                "probability": 1,
                "optimal": true
              },
              "min-min": {
                "probability": 1,
                "optimal": true
              },
              "max-max": {
                "probability": 1,
                "optimal": true
              }
            },
            "name": "",
            "payoff": [
              0,
              0
            ],
            "childNode": {
              "computed": {
                "expected-value-maximization": {
                  "childrenPayoff": [
                    "1996192 1345397125/4294967296"
                  ],
                  "payoff": [
                    "1996713 15200115/134217728"
                  ],
                  "optimal": true
                },
                "expected-value-minimization": {
                  "childrenPayoff": [
                    "1996713 15200115/134217728"
                  ],
                  "payoff": [
                    "1996713 15200115/134217728"
                  ],
                  "optimal": true
                },
                "maxi-min": {
                  "childrenPayoff": [
                    "1786713 486403681/4294967296"
                  ],
                  "payoff": [
                    "1786713 486403681/4294967296"
                  ],
                  "optimal": true
                },
                "maxi-max": {
                  "childrenPayoff": [
                    "2086713 15200115/134217728"
                  ],
                  "payoff": [
                    "2086713 15200115/134217728"
                  ],
                  "optimal": true
                },
                "mini-min": {
                  "childrenPayoff": [
                    "1786713 486403681/4294967296"
                  ],
                  "payoff": [
                    "1786713 486403681/4294967296"
                  ],
                  "optimal": true
                },
                "mini-max": {
                  "childrenPayoff": [
                    "2086713 15200115/134217728"
                  ],
                  "payoff": [
                    "2086713 15200115/134217728"
                  ],
                  "optimal": true
                },
                "min-max": {
                  "childrenPayoff": [
                    "1996713 15200115/134217728",
                    "0"
                  ],
                  "combinedPayoff": "-1996713 15200115/134217728",
                  "payoff": [
                    "1996713 15200115/134217728",
                    "0"
                  ],
                  "optimal": true
                },
                "max-min": {
                  "childrenPayoff": [
                    "1996713 15200115/134217728",
                    "0"
                  ],
                  "combinedPayoff": "1996713 15200115/134217728",
                  "payoff": [
                    "1996713 15200115/134217728",
                    "0"
                  ],
                  "optimal": true
                },
                "min-min": {
                  "childrenPayoff": [
                    "1996713 15200115/134217728",
                    "0"
                  ],
                  "combinedPayoff": "-1996713 15200115/134217728",
                  "payoff": [
                    "1996713 15200115/134217728",
                    "0"
                  ],
                  "optimal": true
                },
                "max-max": {
                  "childrenPayoff": [
                    "1996713 15200115/134217728",
                    "0"
                  ],
                  "combinedPayoff": "1996713 15200115/134217728",
                  "payoff": [
                    "1996713 15200115/134217728",
                    "0"
                  ],
                  "optimal": true
                }
              },
              "childEdges": [
                {
                  "computed": {
                    "payoff": [
                      "813595 2516910677/8589934592",
                      "0"
                    ],
                    "probability": "1",
                    "expected-value-maximization": {
                      "probability": "1",
                      "optimal": true
                    },
                    "expected-value-minimization": {
                      "probability": "1",
                      "optimal": true
                    },
                    "maxi-min": {
                      "probability": 1,
                      "optimal": true
                    },
                    "maxi-max": {
                      "probability": 1,
                      "optimal": true
                    },
                    "mini-min": {
                      "probability": 1,
                      "optimal": true
                    },
                    "mini-max": {
                      "probability": 1,
                      "optimal": true
                    },
                    "min-max": {
                      "probability": "1",
                      "optimal": true
                    },
                    "max-min": {
                      "probability": "1",
                      "optimal": true
                    },
                    "min-min": {
                      "probability": "1",
                      "optimal": true
                    },
                    "max-max": {
                      "probability": "1",
                      "optimal": true
                    }
                  },
                  "name": "",
                  "probability": "#",
                  "payoff": [
                    "Europe",
                    0
                  ],
                  "childNode": {
                    "computed": {
                      "expected-value-maximization": {
                        "childrenPayoff": [
                          "1182597 86941801/4294967296"
                        ],
                        "payoff": [
                          "1996713 15200115/134217728"
                        ],
                        "optimal": true
                      },
                      "expected-value-minimization": {
                        "childrenPayoff": [
                          "1183117 1761457819/2147483648"
                        ],
                        "payoff": [
                          "1996713 15200115/134217728"
                        ],
                        "optimal": true
                      },
                      "maxi-min": {
                        "childrenPayoff": [
                          "973117 7045831277/8589934592"
                        ],
                        "payoff": [
                          "1786713 486403681/4294967296"
                        ],
                        "optimal": true
                      },
                      "maxi-max": {
                        "childrenPayoff": [
                          "1273117 1761457819/2147483648"
                        ],
                        "payoff": [
                          "2086713 15200115/134217728"
                        ],
                        "optimal": true
                      },
                      "mini-min": {
                        "childrenPayoff": [
                          "973117 7045831277/8589934592"
                        ],
                        "payoff": [
                          "1786713 486403681/4294967296"
                        ],
                        "optimal": true
                      },
                      "mini-max": {
                        "childrenPayoff": [
                          "1273117 1761457819/2147483648"
                        ],
                        "payoff": [
                          "2086713 15200115/134217728"
                        ],
                        "optimal": true
                      },
                      "min-max": {
                        "childrenPayoff": [
                          "1183117 1761457819/2147483648",
                          "0"
                        ],
                        "combinedPayoff": "-1996713 15200115/134217728",
                        "payoff": [
                          "1996713 15200115/134217728",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-min": {
                        "childrenPayoff": [
                          "1183117 1761457819/2147483648",
                          "0"
                        ],
                        "combinedPayoff": "1996713 15200115/134217728",
                        "payoff": [
                          "1996713 15200115/134217728",
                          "0"
                        ],
                        "optimal": true
                      },
                      "min-min": {
                        "childrenPayoff": [
                          "1183117 1761457819/2147483648",
                          "0"
                        ],
                        "combinedPayoff": "-1996713 15200115/134217728",
                        "payoff": [
                          "1996713 15200115/134217728",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-max": {
                        "childrenPayoff": [
                          "1183117 1761457819/2147483648",
                          "0"
                        ],
                        "combinedPayoff": "1996713 15200115/134217728",
                        "payoff": [
                          "1996713 15200115/134217728",
                          "0"
                        ],
                        "optimal": true
                      }
                    },
                    "childEdges": [
                      {
                        "computed": {
                          "payoff": [
                            "973117 7045831277/8589934592",
                            "0"
                          ],
                          "probability": "1",
                          "expected-value-maximization": {
                            "probability": "1",
                            "optimal": true
                          },
                          "expected-value-minimization": {
                            "probability": "1",
                            "optimal": true
                          },
                          "maxi-min": {
                            "probability": 1,
                            "optimal": true
                          },
                          "maxi-max": {
                            "probability": 1,
                            "optimal": true
                          },
                          "mini-min": {
                            "probability": 1,
                            "optimal": true
                          },
                          "mini-max": {
                            "probability": 1,
                            "optimal": true
                          },
                          "min-max": {
                            "probability": "1",
                            "optimal": true
                          },
                          "max-min": {
                            "probability": "1",
                            "optimal": true
                          },
                          "min-min": {
                            "probability": "1",
                            "optimal": true
                          },
                          "max-max": {
                            "probability": "1",
                            "optimal": true
                          }
                        },
                        "name": "",
                        "probability": "#",
                        "payoff": [
                          "NorthAmerica",
                          0
                        ],
                        "childNode": {
                          "computed": {
                            "expected-value-maximization": {
                              "childrenPayoff": [
                                "210000"
                              ],
                              "payoff": [
                                "1183117 1761457819/2147483648"
                              ],
                              "optimal": true
                            },
                            "expected-value-minimization": {
                              "childrenPayoff": [
                                "210000"
                              ],
                              "payoff": [
                                "1183117 1761457819/2147483648"
                              ],
                              "optimal": true
                            },
                            "maxi-min": {
                              "childrenPayoff": [
                                "0"
                              ],
                              "payoff": [
                                "973117 7045831277/8589934592"
                              ],
                              "optimal": true
                            },
                            "maxi-max": {
                              "childrenPayoff": [
                                "300000"
                              ],
                              "payoff": [
                                "1273117 1761457819/2147483648"
                              ],
                              "optimal": true
                            },
                            "mini-min": {
                              "childrenPayoff": [
                                "0"
                              ],
                              "payoff": [
                                "973117 7045831277/8589934592"
                              ],
                              "optimal": true
                            },
                            "mini-max": {
                              "childrenPayoff": [
                                "300000"
                              ],
                              "payoff": [
                                "1273117 1761457819/2147483648"
                              ],
                              "optimal": true
                            },
                            "min-max": {
                              "childrenPayoff": [
                                "210000",
                                "0"
                              ],
                              "combinedPayoff": "-1183117 1761457819/2147483648",
                              "payoff": [
                                "1183117 1761457819/2147483648",
                                "0"
                              ],
                              "optimal": true
                            },
                            "max-min": {
                              "childrenPayoff": [
                                "210000",
                                "0"
                              ],
                              "combinedPayoff": "1183117 1761457819/2147483648",
                              "payoff": [
                                "1183117 1761457819/2147483648",
                                "0"
                              ],
                              "optimal": true
                            },
                            "min-min": {
                              "childrenPayoff": [
                                "210000",
                                "0"
                              ],
                              "combinedPayoff": "-1183117 1761457819/2147483648",
                              "payoff": [
                                "1183117 1761457819/2147483648",
                                "0"
                              ],
                              "optimal": true
                            },
                            "max-max": {
                              "childrenPayoff": [
                                "210000",
                                "0"
                              ],
                              "combinedPayoff": "1183117 1761457819/2147483648",
                              "payoff": [
                                "1183117 1761457819/2147483648",
                                "0"
                              ],
                              "optimal": true
                            }
                          },
                          "childEdges": [
                            {
                              "computed": {
                                "payoff": [
                                  "0",
                                  "0"
                                ],
                                "probability": "7/10",
                                "expected-value-maximization": {
                                  "probability": "7/10",
                                  "optimal": true
                                },
                                "expected-value-minimization": {
                                  "probability": "7/10",
                                  "optimal": true
                                },
                                "maxi-min": {
                                  "probability": 0,
                                  "optimal": false
                                },
                                "maxi-max": {
                                  "probability": 1,
                                  "optimal": true
                                },
                                "mini-min": {
                                  "probability": 0,
                                  "optimal": false
                                },
                                "mini-max": {
                                  "probability": 1,
                                  "optimal": true
                                },
                                "min-max": {
                                  "probability": "7/10",
                                  "optimal": true
                                },
                                "max-min": {
                                  "probability": "7/10",
                                  "optimal": true
                                },
                                "min-min": {
                                  "probability": "7/10",
                                  "optimal": true
                                },
                                "max-max": {
                                  "probability": "7/10",
                                  "optimal": true
                                }
                              },
                              "name": "Yes",
                              "probability": ".7",
                              "payoff": [
                                0,
                                0
                              ],
                              "childNode": {
                                "computed": {
                                  "expected-value-maximization": {
                                    "childrenPayoff": [
                                      "300000"
                                    ],
                                    "payoff": [
                                      "300000"
                                    ],
                                    "optimal": true
                                  },
                                  "expected-value-minimization": {
                                    "childrenPayoff": [
                                      "300000"
                                    ],
                                    "payoff": [
                                      "300000"
                                    ],
                                    "optimal": true
                                  },
                                  "maxi-min": {
                                    "childrenPayoff": [
                                      "300000"
                                    ],
                                    "payoff": [
                                      "300000"
                                    ]
                                  },
                                  "maxi-max": {
                                    "childrenPayoff": [
                                      "300000"
                                    ],
                                    "payoff": [
                                      "300000"
                                    ],
                                    "optimal": true
                                  },
                                  "mini-min": {
                                    "childrenPayoff": [
                                      "300000"
                                    ],
                                    "payoff": [
                                      "300000"
                                    ]
                                  },
                                  "mini-max": {
                                    "childrenPayoff": [
                                      "300000"
                                    ],
                                    "payoff": [
                                      "300000"
                                    ],
                                    "optimal": true
                                  },
                                  "min-max": {
                                    "childrenPayoff": [
                                      "300000",
                                      "0"
                                    ],
                                    "combinedPayoff": "-300000",
                                    "payoff": [
                                      "300000",
                                      "0"
                                    ],
                                    "optimal": true
                                  },
                                  "max-min": {
                                    "childrenPayoff": [
                                      "300000",
                                      "0"
                                    ],
                                    "combinedPayoff": "300000",
                                    "payoff": [
                                      "300000",
                                      "0"
                                    ],
                                    "optimal": true
                                  },
                                  "min-min": {
                                    "childrenPayoff": [
                                      "300000",
                                      "0"
                                    ],
                                    "combinedPayoff": "-300000",
                                    "payoff": [
                                      "300000",
                                      "0"
                                    ],
                                    "optimal": true
                                  },
                                  "max-max": {
                                    "childrenPayoff": [
                                      "300000",
                                      "0"
                                    ],
                                    "combinedPayoff": "300000",
                                    "payoff": [
                                      "300000",
                                      "0"
                                    ],
                                    "optimal": true
                                  }
                                },
                                "childEdges": [
                                  {
                                    "computed": {
                                      "payoff": [
                                        "300000",
                                        "0"
                                      ],
                                      "probability": "1",
                                      "expected-value-maximization": {
                                        "probability": "1",
                                        "optimal": true
                                      },
                                      "expected-value-minimization": {
                                        "probability": "1",
                                        "optimal": true
                                      },
                                      "maxi-min": {
                                        "probability": 1
                                      },
                                      "maxi-max": {
                                        "probability": 1,
                                        "optimal": true
                                      },
                                      "mini-min": {
                                        "probability": 1
                                      },
                                      "mini-max": {
                                        "probability": 1,
                                        "optimal": true
                                      },
                                      "min-max": {
                                        "probability": "1",
                                        "optimal": true
                                      },
                                      "max-min": {
                                        "probability": "1",
                                        "optimal": true
                                      },
                                      "min-min": {
                                        "probability": "1",
                                        "optimal": true
                                      },
                                      "max-max": {
                                        "probability": "1",
                                        "optimal": true
                                      }
                                    },
                                    "name": "",
                                    "probability": "#",
                                    "payoff": [
                                      "Asia",
                                      0
                                    ],
                                    "childNode": {
                                      "computed": {
                                        "expected-value-maximization": {
                                          "aggregatedPayoff": [
                                            "2085969 121600919/1073741824"
                                          ],
                                          "probabilityToEnter": "7/10",
                                          "payoff": [
                                            "300000"
                                          ],
                                          "optimal": true
                                        },
                                        "expected-value-minimization": {
                                          "aggregatedPayoff": [
                                            "2086713 486403681/4294967296"
                                          ],
                                          "probabilityToEnter": "7/10",
                                          "payoff": [
                                            "300000"
                                          ],
                                          "optimal": true
                                        },
                                        "maxi-min": {
                                          "aggregatedPayoff": [
                                            "2086713 486403681/4294967296"
                                          ],
                                          "probabilityToEnter": 0,
                                          "payoff": [
                                            "300000"
                                          ]
                                        },
                                        "maxi-max": {
                                          "aggregatedPayoff": [
                                            "2086713 486403681/4294967296"
                                          ],
                                          "probabilityToEnter": "1",
                                          "payoff": [
                                            "300000"
                                          ],
                                          "optimal": true
                                        },
                                        "mini-min": {
                                          "aggregatedPayoff": [
                                            "2086713 486403681/4294967296"
                                          ],
                                          "probabilityToEnter": 0,
                                          "payoff": [
                                            "300000"
                                          ]
                                        },
                                        "mini-max": {
                                          "aggregatedPayoff": [
                                            "2086713 486403681/4294967296"
                                          ],
                                          "probabilityToEnter": "1",
                                          "payoff": [
                                            "300000"
                                          ],
                                          "optimal": true
                                        },
                                        "min-max": {
                                          "aggregatedPayoff": [
                                            "2086713 486403681/4294967296",
                                            "0"
                                          ],
                                          "probabilityToEnter": "7/10",
                                          "combinedPayoff": "-300000",
                                          "payoff": [
                                            "300000",
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "max-min": {
                                          "aggregatedPayoff": [
                                            "2086713 486403681/4294967296",
                                            "0"
                                          ],
                                          "probabilityToEnter": "7/10",
                                          "combinedPayoff": "300000",
                                          "payoff": [
                                            "300000",
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "min-min": {
                                          "aggregatedPayoff": [
                                            "2086713 486403681/4294967296",
                                            "0"
                                          ],
                                          "probabilityToEnter": "7/10",
                                          "combinedPayoff": "-300000",
                                          "payoff": [
                                            "300000",
                                            "0"
                                          ],
                                          "optimal": true
                                        },
                                        "max-max": {
                                          "aggregatedPayoff": [
                                            "2086713 486403681/4294967296",
                                            "0"
                                          ],
                                          "probabilityToEnter": "7/10",
                                          "combinedPayoff": "300000",
                                          "payoff": [
                                            "300000",
                                            "0"
                                          ],
                                          "optimal": true
                                        }
                                      },
                                      "childEdges": [],
                                      "name": "",
                                      "code": "",
                                      "expressionScope": {
                                        "Europe": 745000,
                                        "NorthAmerica": 865000,
                                        "Asia": 300000
                                      },
                                      "folded": false,
                                      "location": {
                                        "x": 811,
                                        "y": 11
                                      },
                                      "type": "terminal",
                                      "expectedValue": 2085969.113249681,
                                      "p10": 1728136,
                                      "p90": 2458752,
                                      "charts": [
                                        {
                                          "nodeId": "289740c5-f27f-51f5-cc1c-e23a251a1ca5",
                                          "x": 50,
                                          "y": -100,
                                          "type": "distribution",
                                          "active": false
                                        },
                                        {
                                          "nodeId": "289740c5-f27f-51f5-cc1c-e23a251a1ca5",
                                          "x": 50,
                                          "y": -100,
                                          "type": "distribution",
                                          "active": false
                                        },
                                        {
                                          "nodeId": "289740c5-f27f-51f5-cc1c-e23a251a1ca5",
                                          "x": 50,
                                          "y": -100,
                                          "type": "distribution",
                                          "active": false
                                        },
                                        {
                                          "nodeId": "289740c5-f27f-51f5-cc1c-e23a251a1ca5",
                                          "x": 50,
                                          "y": -100,
                                          "type": "distribution",
                                          "active": false
                                        }
                                      ]
                                    },
                                    "expectedValue": 300000,
                                    "p10": 196808,
                                    "p90": 401704,
                                    "optimal": true
                                  }
                                ],
                                "name": "Asia",
                                "code": "",
                                "expressionScope": {
                                  "Europe": 745000,
                                  "NorthAmerica": 865000,
                                  "Asia": 300000
                                },
                                "folded": false,
                                "location": {
                                  "x": 651,
                                  "y": 11
                                },
                                "type": "chance",
                                "expectedValue": 300000,
                                "p10": 196808,
                                "p90": 401704
                              },
                              "expectedValue": 0,
                              "p10": 0,
                              "p90": 0,
                              "optimal": true
                            },
                            {
                              "computed": {
                                "payoff": [
                                  "0",
                                  "0"
                                ],
                                "probability": "3/10",
                                "expected-value-maximization": {
                                  "probability": "3/10",
                                  "optimal": true
                                },
                                "expected-value-minimization": {
                                  "probability": "3/10",
                                  "optimal": true
                                },
                                "maxi-min": {
                                  "probability": 1,
                                  "optimal": true
                                },
                                "maxi-max": {
                                  "probability": 0,
                                  "optimal": false
                                },
                                "mini-min": {
                                  "probability": 1,
                                  "optimal": true
                                },
                                "mini-max": {
                                  "probability": 0,
                                  "optimal": false
                                },
                                "min-max": {
                                  "probability": "3/10",
                                  "optimal": true
                                },
                                "max-min": {
                                  "probability": "3/10",
                                  "optimal": true
                                },
                                "min-min": {
                                  "probability": "3/10",
                                  "optimal": true
                                },
                                "max-max": {
                                  "probability": "3/10",
                                  "optimal": true
                                }
                              },
                              "name": "No",
                              "probability": "#",
                              "payoff": [
                                0,
                                0
                              ],
                              "childNode": {
                                "computed": {
                                  "expected-value-maximization": {
                                    "aggregatedPayoff": [
                                      "1786713 486403677/4294967296"
                                    ],
                                    "probabilityToEnter": "3/10",
                                    "payoff": [
                                      "0"
                                    ],
                                    "optimal": true
                                  },
                                  "expected-value-minimization": {
                                    "aggregatedPayoff": [
                                      "1786713 486403681/4294967296"
                                    ],
                                    "probabilityToEnter": "3/10",
                                    "payoff": [
                                      "0"
                                    ],
                                    "optimal": true
                                  },
                                  "maxi-min": {
                                    "aggregatedPayoff": [
                                      "1786713 486403681/4294967296"
                                    ],
                                    "probabilityToEnter": "1",
                                    "payoff": [
                                      "0"
                                    ],
                                    "optimal": true
                                  },
                                  "maxi-max": {
                                    "aggregatedPayoff": [
                                      "1786713 486403681/4294967296"
                                    ],
                                    "probabilityToEnter": 0,
                                    "payoff": [
                                      "0"
                                    ]
                                  },
                                  "mini-min": {
                                    "aggregatedPayoff": [
                                      "1786713 486403681/4294967296"
                                    ],
                                    "probabilityToEnter": "1",
                                    "payoff": [
                                      "0"
                                    ],
                                    "optimal": true
                                  },
                                  "mini-max": {
                                    "aggregatedPayoff": [
                                      "1786713 486403681/4294967296"
                                    ],
                                    "probabilityToEnter": 0,
                                    "payoff": [
                                      "0"
                                    ]
                                  },
                                  "min-max": {
                                    "aggregatedPayoff": [
                                      "1786713 486403681/4294967296",
                                      "0"
                                    ],
                                    "probabilityToEnter": "3/10",
                                    "combinedPayoff": "0",
                                    "payoff": [
                                      "0",
                                      "0"
                                    ],
                                    "optimal": true
                                  },
                                  "max-min": {
                                    "aggregatedPayoff": [
                                      "1786713 486403681/4294967296",
                                      "0"
                                    ],
                                    "probabilityToEnter": "3/10",
                                    "combinedPayoff": "0",
                                    "payoff": [
                                      "0",
                                      "0"
                                    ],
                                    "optimal": true
                                  },
                                  "min-min": {
                                    "aggregatedPayoff": [
                                      "1786713 486403681/4294967296",
                                      "0"
                                    ],
                                    "probabilityToEnter": "3/10",
                                    "combinedPayoff": "0",
                                    "payoff": [
                                      "0",
                                      "0"
                                    ],
                                    "optimal": true
                                  },
                                  "max-max": {
                                    "aggregatedPayoff": [
                                      "1786713 486403681/4294967296",
                                      "0"
                                    ],
                                    "probabilityToEnter": "3/10",
                                    "combinedPayoff": "0",
                                    "payoff": [
                                      "0",
                                      "0"
                                    ],
                                    "optimal": true
                                  }
                                },
                                "childEdges": [],
                                "name": "",
                                "code": "",
                                "expressionScope": {
                                  "Europe": 745000,
                                  "NorthAmerica": 865000,
                                  "Asia": 300000
                                },
                                "folded": false,
                                "location": {
                                  "x": 811,
                                  "y": 125
                                },
                                "type": "terminal",
                                "expectedValue": 1786713.1132496812,
                                "p10": 1442360,
                                "p90": 2146016
                              },
                              "expectedValue": 0,
                              "p10": 0,
                              "p90": 0,
                              "optimal": true
                            }
                          ],
                          "name": "Ready to \nlaunch in Asia",
                          "code": "",
                          "expressionScope": {
                            "Europe": 745000,
                            "NorthAmerica": 865000,
                            "Asia": 300000
                          },
                          "folded": false,
                          "location": {
                            "x": 491,
                            "y": 68
                          },
                          "type": "chance",
                          "expectedValue": 210000,
                          "p10": 0,
                          "p90": 390920
                        },
                        "expectedValue": 973117.8202427157,
                        "p10": 717136,
                        "p90": 1264424,
                        "optimal": true
                      }
                    ],
                    "name": "North America",
                    "code": "",
                    "expressionScope": {
                      "Europe": 745000,
                      "NorthAmerica": 865000,
                      "Asia": 300000
                    },
                    "folded": false,
                    "location": {
                      "x": 331,
                      "y": 68
                    },
                    "type": "chance",
                    "expectedValue": 1182597.0202427155,
                    "p10": 851936,
                    "p90": 1523240
                  },
                  "expectedValue": 813595.2930069665,
                  "p10": 585032,
                  "p90": 1064920,
                  "optimal": true
                }
              ],
              "name": "Europe",
              "code": "",
              "expressionScope": {
                "Europe": 745000,
                "NorthAmerica": 865000,
                "Asia": 300000
              },
              "folded": false,
              "location": {
                "x": 171,
                "y": 68
              },
              "type": "chance",
              "expectedValue": 1996192.3132496786,
              "p10": 1596032,
              "p90": 2402136
            },
            "expectedValue": 0,
            "p10": 0,
            "p90": 0,
            "optimal": true
          }
        ],
        "name": "Total demand",
        "code": "",
        "expressionScope": {
          "Europe": 745000,
          "NorthAmerica": 865000,
          "Asia": 300000
        },
        "folded": false,
        "location": {
          "x": 11,
          "y": 68
        },
        "type": "decision",
        "expectedValue": 1996192.3132496804,
        "p10": 1596032,
        "p90": 2402136,
        "charts": [
          {
            "nodeId": "b471c14b-cbe4-2229-5b7a-89f9aa07c367",
            "x": -73.99999570846558,
            "y": 58,
            "type": "distribution",
            "active": true
          },
          {
            "nodeId": "b471c14b-cbe4-2229-5b7a-89f9aa07c367",
            "x": -73.99999570846558,
            "y": 58,
            "type": "distribution",
            "active": true
          },
          {
            "nodeId": "b471c14b-cbe4-2229-5b7a-89f9aa07c367",
            "x": -73.99999570846558,
            "y": 58,
            "type": "distribution",
            "active": true
          },
          {
            "nodeId": "b471c14b-cbe4-2229-5b7a-89f9aa07c367",
            "x": -73.99999570846558,
            "y": 58,
            "type": "distribution",
            "active": true
          }
        ]
      }
    ],
    "texts": [
      {
        "value": "PRODUCT DEMAND",
        "location": {
          "x": -54.99999439716339,
          "y": -49.33161163330078
        }
      }
    ],
    "payoffNames": [],
    "defaultCriterion1Weight": 1,
    "weightLowerBound": 0,
    "weightUpperBound": "Infinity"
  },
  "sensitivityAnalysis": {},
  "definitionsDialog": {
    "valueList": [
      {
        "name": "Europe",
        "value": "745000",
        "low": 563000,
        "high": 1040000,
        "distribution": "triangular",
        "error": ""
      },
      {
        "name": "NorthAmerica",
        "value": "865000",
        "low": 685000,
        "high": 1224000,
        "distribution": "triangular",
        "error": ""
      },
      {
        "name": "Asia",
        "value": "",
        "low": 200000,
        "high": 400000,
        "distribution": "uniform",
        "error": ""
      }
    ]
  }
}