module.exports={
  "SilverDecisions": "1.1.0",
  "buildTimestamp": 1641216304572,
  "savetime": "2022-02-11T14:56:54.749Z",
  "lng": "en",
  "viewMode": "criterion1",
  "rule": "expected-value-maximization",
  "title": "Decision tree explained",
  "description": "",
  "format": {
    "locales": "en",
    "payoff1": {
      "style": "currency",
      "currency": "USD",
      "currencyDisplay": "symbol",
      "minimumFractionDigits": 0,
      "maximumFractionDigits": 0,
      "useGrouping": true
    },
    "payoff2": {
      "style": "percent",
      "currency": "USD",
      "currencyDisplay": "symbol",
      "minimumFractionDigits": 0,
      "maximumFractionDigits": 0,
      "useGrouping": true
    },
    "probability": {
      "style": "percent",
      "minimumFractionDigits": 0,
      "maximumFractionDigits": 0,
      "useGrouping": true
    }
  },
  "treeDesigner": {
    "margin": {
      "left": 150,
      "right": 50,
      "top": 150,
      "bottom": 150
    },
    "scale": 1,
    "lng": "en",
    "layout": {
      "type": "tree",
      "nodeSize": 22,
      "limitNodePositioning": true,
      "limitTextPositioning": false,
      "gridHeight": 102,
      "gridWidth": 260,
      "edgeSlantWidthMax": 0
    },
    "fontFamily": "sans-serif",
    "fontSize": "12px",
    "fontWeight": "normal",
    "fontStyle": "normal",
    "node": {
      "strokeWidth": "1px",
      "optimal": {
        "stroke": "#006f00",
        "strokeWidth": "1.5px"
      },
      "label": {
        "fontSize": "1em",
        "color": "black"
      },
      "payoff": {
        "fontSize": "1em",
        "color": "black",
        "negativeColor": "#b60000"
      },
      "decision": {
        "fill": "#ff7777",
        "stroke": "#660000",
        "selected": {
          "fill": "#aa3333"
        }
      },
      "chance": {
        "fill": "#ffff44",
        "stroke": "#666600",
        "selected": {
          "fill": "#aaaa00"
        }
      },
      "terminal": {
        "fill": "#44ff44",
        "stroke": "black",
        "selected": {
          "fill": "#00aa00"
        },
        "payoff": {
          "fontSize": "1em",
          "color": "black",
          "negativeColor": "#b60000"
        }
      }
    },
    "edge": {
      "stroke": "#424242",
      "strokeWidth": "1.5",
      "optimal": {
        "stroke": "#006f00",
        "strokeWidth": "2.4"
      },
      "selected": {
        "stroke": "#045ad1",
        "strokeWidth": "3.5"
      },
      "label": {
        "fontSize": "1em",
        "color": "back"
      },
      "payoff": {
        "fontSize": "1em",
        "color": "black",
        "negativeColor": "#b60000"
      }
    },
    "probability": {
      "fontSize": "1em",
      "color": "#0000d7"
    },
    "title": {
      "fontSize": "16px",
      "fontWeight": "bold",
      "fontStyle": "normal",
      "color": "#000000",
      "margin": {
        "top": 15,
        "bottom": 10
      }
    },
    "description": {
      "show": true,
      "fontSize": "12px",
      "fontWeight": "bold",
      "fontStyle": "normal",
      "color": "#000000",
      "margin": {
        "top": 5,
        "bottom": 10
      }
    },
    "readOnly": false,
    "disableAnimations": false,
    "forceFullEdgeRedraw": false,
    "hideLabels": false,
    "hidePayoffs": false,
    "hideProbabilities": false,
    "hideCharts": false,
    "hideTextboxes": false,
    "hideMilestones": false,
    "raw": false,
    "payoffNames": [
      null,
      null
    ],
    "maxPayoffsToDisplay": 1,
    "milestoneNames": [
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    ]
  },
  "data": {
    "code": "Alternative_A=-1000\nAlternative_B=-2000\nOutcome_A=300\nOutcome_B=500\nOutcome_C=200\nOutcome_D=600\nAlternative_X=500\nAlternative_Y=1200\n",
    "expressionScope": {
      "Alternative_A": -1000,
      "Alternative_B": -2000,
      "Outcome_A": 300,
      "Outcome_B": 500,
      "Outcome_C": 200,
      "Outcome_D": 600,
      "Alternative_X": 500,
      "Alternative_Y": 1200
    },
    "trees": [
      {
        "computed": {
          "expected-value-maximization": {
            "childrenPayoff": [
              "465 10795732517909/17592186044416"
            ],
            "payoff": [
              "465 78141146221/687194767360"
            ],
            "optimal": true
          },
          "expected-value-minimization": {
            "childrenPayoff": [
              "-1479 318138261801/2199023255552"
            ],
            "payoff": [
              "-1479 318138261801/2199023255552"
            ],
            "optimal": true
          },
          "maxi-min": {
            "childrenPayoff": [
              "-316 1283402710515/4398046511104"
            ],
            "payoff": [
              "-316 1283402710515/4398046511104"
            ],
            "optimal": true
          },
          "maxi-max": {
            "childrenPayoff": [
              "800 7878352497/4398046511104"
            ],
            "payoff": [
              "800 7878352497/4398046511104"
            ],
            "optimal": true
          },
          "mini-min": {
            "childrenPayoff": [
              "-1800"
            ],
            "payoff": [
              "-1800"
            ],
            "optimal": true
          },
          "mini-max": {
            "childrenPayoff": [
              "-1158 1272553047203/4398046511104"
            ],
            "payoff": [
              "-1158 1272553047203/4398046511104"
            ],
            "optimal": true
          },
          "min-max": {
            "childrenPayoff": [
              "-1479 318138261801/2199023255552",
              "0"
            ],
            "combinedPayoff": "1479 318138261801/2199023255552",
            "payoff": [
              "-1479 318138261801/2199023255552",
              "0"
            ],
            "optimal": true
          },
          "max-min": {
            "childrenPayoff": [
              "465 78141146221/687194767360",
              "0"
            ],
            "combinedPayoff": "465 78141146221/687194767360",
            "payoff": [
              "465 78141146221/687194767360",
              "0"
            ],
            "optimal": true
          },
          "min-min": {
            "childrenPayoff": [
              "-1479 318138261801/2199023255552",
              "0"
            ],
            "combinedPayoff": "1479 318138261801/2199023255552",
            "payoff": [
              "-1479 318138261801/2199023255552",
              "0"
            ],
            "optimal": true
          },
          "max-max": {
            "childrenPayoff": [
              "465 78141146221/687194767360",
              "0"
            ],
            "combinedPayoff": "465 78141146221/687194767360",
            "payoff": [
              "465 78141146221/687194767360",
              "0"
            ],
            "optimal": true
          }
        },
        "childEdges": [
          {
            "computed": {
              "payoff": [
                "-1000",
                "0"
              ],
              "expected-value-maximization": {
                "probability": 1,
                "optimal": true
              },
              "expected-value-minimization": {
                "probability": 0,
                "optimal": false
              },
              "maxi-min": {
                "probability": 1,
                "optimal": true
              },
              "maxi-max": {
                "probability": 1,
                "optimal": true
              },
              "mini-min": {
                "probability": 0,
                "optimal": false
              },
              "mini-max": {
                "probability": 0,
                "optimal": false
              },
              "min-max": {
                "probability": 0,
                "optimal": false
              },
              "max-min": {
                "probability": 1,
                "optimal": true
              },
              "min-min": {
                "probability": 0,
                "optimal": false
              },
              "max-max": {
                "probability": 1,
                "optimal": true
              }
            },
            "name": "Alternative A",
            "payoff": [
              "Alternative_A",
              0
            ],
            "childNode": {
              "computed": {
                "expected-value-maximization": {
                  "childrenPayoff": [
                    "1466 77069442979/274877906944"
                  ],
                  "payoff": [
                    "465 78141146221/687194767360"
                  ],
                  "optimal": true
                },
                "expected-value-minimization": {
                  "childrenPayoff": [
                    "765 618235611333/5497558138880"
                  ],
                  "payoff": [
                    "-234 4879322527547/5497558138880"
                  ]
                },
                "maxi-min": {
                  "childrenPayoff": [
                    "683 3114643800589/4398046511104"
                  ],
                  "payoff": [
                    "-316 1283402710515/4398046511104"
                  ],
                  "optimal": true
                },
                "maxi-max": {
                  "childrenPayoff": [
                    "1800 7878352497/4398046511104"
                  ],
                  "payoff": [
                    "800 7878352497/4398046511104"
                  ],
                  "optimal": true
                },
                "mini-min": {
                  "childrenPayoff": [
                    "683 3114643800589/4398046511104"
                  ],
                  "payoff": [
                    "-316 1283402710515/4398046511104"
                  ]
                },
                "mini-max": {
                  "childrenPayoff": [
                    "800"
                  ],
                  "payoff": [
                    "-200"
                  ]
                },
                "min-max": {
                  "childrenPayoff": [
                    "765 618235611333/5497558138880",
                    "0"
                  ],
                  "combinedPayoff": "234 4879322527547/5497558138880",
                  "payoff": [
                    "-234 4879322527547/5497558138880",
                    "0"
                  ]
                },
                "max-min": {
                  "childrenPayoff": [
                    "1465 78141146221/687194767360",
                    "0"
                  ],
                  "combinedPayoff": "465 78141146221/687194767360",
                  "payoff": [
                    "465 78141146221/687194767360",
                    "0"
                  ],
                  "optimal": true
                },
                "min-min": {
                  "childrenPayoff": [
                    "765 618235611333/5497558138880",
                    "0"
                  ],
                  "combinedPayoff": "234 4879322527547/5497558138880",
                  "payoff": [
                    "-234 4879322527547/5497558138880",
                    "0"
                  ]
                },
                "max-max": {
                  "childrenPayoff": [
                    "1465 78141146221/687194767360",
                    "0"
                  ],
                  "combinedPayoff": "465 78141146221/687194767360",
                  "payoff": [
                    "465 78141146221/687194767360",
                    "0"
                  ],
                  "optimal": true
                }
              },
              "childEdges": [
                {
                  "computed": {
                    "payoff": [
                      "300",
                      "0"
                    ],
                    "probability": "7/10",
                    "expected-value-maximization": {
                      "probability": "7/10",
                      "optimal": true
                    },
                    "expected-value-minimization": {
                      "probability": "7/10"
                    },
                    "maxi-min": {
                      "probability": 0,
                      "optimal": false
                    },
                    "maxi-max": {
                      "probability": 1,
                      "optimal": true
                    },
                    "mini-min": {
                      "probability": 0
                    },
                    "mini-max": {
                      "probability": 1
                    },
                    "min-max": {
                      "probability": "7/10"
                    },
                    "max-min": {
                      "probability": "7/10",
                      "optimal": true
                    },
                    "min-min": {
                      "probability": "7/10"
                    },
                    "max-max": {
                      "probability": "7/10",
                      "optimal": true
                    }
                  },
                  "name": "Outcome A",
                  "probability": ".7",
                  "payoff": [
                    "Outcome_A",
                    0
                  ],
                  "childNode": {
                    "computed": {
                      "expected-value-maximization": {
                        "childrenPayoff": [
                          "1500 7878352497/4398046511104"
                        ],
                        "payoff": [
                          "1800 7878352497/4398046511104"
                        ],
                        "optimal": true
                      },
                      "expected-value-minimization": {
                        "childrenPayoff": [
                          "500"
                        ],
                        "payoff": [
                          "800"
                        ]
                      },
                      "maxi-min": {
                        "childrenPayoff": [
                          "1500 7878352497/4398046511104"
                        ],
                        "payoff": [
                          "1800 7878352497/4398046511104"
                        ]
                      },
                      "maxi-max": {
                        "childrenPayoff": [
                          "1500 7878352497/4398046511104"
                        ],
                        "payoff": [
                          "1800 7878352497/4398046511104"
                        ],
                        "optimal": true
                      },
                      "mini-min": {
                        "childrenPayoff": [
                          "500"
                        ],
                        "payoff": [
                          "800"
                        ]
                      },
                      "mini-max": {
                        "childrenPayoff": [
                          "500"
                        ],
                        "payoff": [
                          "800"
                        ]
                      },
                      "min-max": {
                        "childrenPayoff": [
                          "500",
                          "0"
                        ],
                        "combinedPayoff": "-800",
                        "payoff": [
                          "800",
                          "0"
                        ]
                      },
                      "max-min": {
                        "childrenPayoff": [
                          "1500 7878352497/4398046511104",
                          "0"
                        ],
                        "combinedPayoff": "1800 7878352497/4398046511104",
                        "payoff": [
                          "1800 7878352497/4398046511104",
                          "0"
                        ],
                        "optimal": true
                      },
                      "min-min": {
                        "childrenPayoff": [
                          "500",
                          "0"
                        ],
                        "combinedPayoff": "-800",
                        "payoff": [
                          "800",
                          "0"
                        ]
                      },
                      "max-max": {
                        "childrenPayoff": [
                          "1500 7878352497/4398046511104",
                          "0"
                        ],
                        "combinedPayoff": "1800 7878352497/4398046511104",
                        "payoff": [
                          "1800 7878352497/4398046511104",
                          "0"
                        ],
                        "optimal": true
                      }
                    },
                    "childEdges": [
                      {
                        "computed": {
                          "payoff": [
                            "500",
                            "0"
                          ],
                          "expected-value-maximization": {
                            "probability": 0,
                            "optimal": false
                          },
                          "expected-value-minimization": {
                            "probability": 1
                          },
                          "maxi-min": {
                            "probability": 0
                          },
                          "maxi-max": {
                            "probability": 0,
                            "optimal": false
                          },
                          "mini-min": {
                            "probability": 1
                          },
                          "mini-max": {
                            "probability": 1
                          },
                          "min-max": {
                            "probability": 1
                          },
                          "max-min": {
                            "probability": 0,
                            "optimal": false
                          },
                          "min-min": {
                            "probability": 1
                          },
                          "max-max": {
                            "probability": 0,
                            "optimal": false
                          }
                        },
                        "name": "Alternative X",
                        "payoff": [
                          "Alternative_X",
                          0
                        ],
                        "childNode": {
                          "computed": {
                            "expected-value-maximization": {
                              "aggregatedPayoff": [
                                "-200"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "500"
                              ]
                            },
                            "expected-value-minimization": {
                              "aggregatedPayoff": [
                                "-200"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "500"
                              ]
                            },
                            "maxi-min": {
                              "aggregatedPayoff": [
                                "-200"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "500"
                              ]
                            },
                            "maxi-max": {
                              "aggregatedPayoff": [
                                "-200"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "500"
                              ]
                            },
                            "mini-min": {
                              "aggregatedPayoff": [
                                "-200"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "500"
                              ]
                            },
                            "mini-max": {
                              "aggregatedPayoff": [
                                "-200"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "500"
                              ]
                            },
                            "min-max": {
                              "aggregatedPayoff": [
                                "-200",
                                "0"
                              ],
                              "probabilityToEnter": 0,
                              "combinedPayoff": "-500",
                              "payoff": [
                                "500",
                                "0"
                              ]
                            },
                            "max-min": {
                              "aggregatedPayoff": [
                                "-200",
                                "0"
                              ],
                              "probabilityToEnter": 0,
                              "combinedPayoff": "500",
                              "payoff": [
                                "500",
                                "0"
                              ]
                            },
                            "min-min": {
                              "aggregatedPayoff": [
                                "-200",
                                "0"
                              ],
                              "probabilityToEnter": 0,
                              "combinedPayoff": "-500",
                              "payoff": [
                                "500",
                                "0"
                              ]
                            },
                            "max-max": {
                              "aggregatedPayoff": [
                                "-200",
                                "0"
                              ],
                              "probabilityToEnter": 0,
                              "combinedPayoff": "500",
                              "payoff": [
                                "500",
                                "0"
                              ]
                            }
                          },
                          "childEdges": [],
                          "name": "Payoff",
                          "code": "",
                          "expressionScope": {
                            "Alternative_A": -1000,
                            "Alternative_B": -2000,
                            "Outcome_A": 300,
                            "Outcome_B": 500,
                            "Outcome_C": 200,
                            "Outcome_D": 600,
                            "Alternative_X": 500,
                            "Alternative_Y": 1200
                          },
                          "folded": false,
                          "location": {
                            "x": 791,
                            "y": 11
                          },
                          "type": "terminal",
                          "milestone": 2,
                          "expectedValue": -200,
                          "p10": -759,
                          "p90": 363,
                          "charts": [
                            {
                              "nodeId": "75fbc32b-9fab-8390-812a-0a853155a6fe",
                              "x": 89,
                              "y": -41,
                              "type": "distribution",
                              "active": true
                            },
                            {
                              "nodeId": "75fbc32b-9fab-8390-812a-0a853155a6fe",
                              "x": 248,
                              "y": -41.000003814697266,
                              "type": "waterfall",
                              "active": true
                            },
                            {
                              "nodeId": "75fbc32b-9fab-8390-812a-0a853155a6fe",
                              "x": 403.00000762939453,
                              "y": -41.000003814697266,
                              "type": "tornado",
                              "active": false
                            }
                          ]
                        },
                        "expectedValue": 500,
                        "p10": 297,
                        "p90": 704,
                        "optimal": false
                      },
                      {
                        "computed": {
                          "payoff": [
                            "1500 7878352497/4398046511104",
                            "0"
                          ],
                          "expected-value-maximization": {
                            "probability": 1,
                            "optimal": true
                          },
                          "expected-value-minimization": {
                            "probability": 0
                          },
                          "maxi-min": {
                            "probability": 1
                          },
                          "maxi-max": {
                            "probability": 1,
                            "optimal": true
                          },
                          "mini-min": {
                            "probability": 0
                          },
                          "mini-max": {
                            "probability": 0
                          },
                          "min-max": {
                            "probability": 0
                          },
                          "max-min": {
                            "probability": 1,
                            "optimal": true
                          },
                          "min-min": {
                            "probability": 0
                          },
                          "max-max": {
                            "probability": 1,
                            "optimal": true
                          }
                        },
                        "name": "Alternative Y",
                        "payoff": [
                          "Alternative_Y",
                          0
                        ],
                        "childNode": {
                          "computed": {
                            "expected-value-maximization": {
                              "aggregatedPayoff": [
                                "801 3842445301/2199023255552"
                              ],
                              "probabilityToEnter": "7/10",
                              "payoff": [
                                "1500 7878352497/4398046511104"
                              ],
                              "optimal": true
                            },
                            "expected-value-minimization": {
                              "aggregatedPayoff": [
                                "800 7878352497/4398046511104"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "1500 7878352497/4398046511104"
                              ]
                            },
                            "maxi-min": {
                              "aggregatedPayoff": [
                                "800 7878352497/4398046511104"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "1500 7878352497/4398046511104"
                              ]
                            },
                            "maxi-max": {
                              "aggregatedPayoff": [
                                "800 7878352497/4398046511104"
                              ],
                              "probabilityToEnter": "1",
                              "payoff": [
                                "1500 7878352497/4398046511104"
                              ],
                              "optimal": true
                            },
                            "mini-min": {
                              "aggregatedPayoff": [
                                "800 7878352497/4398046511104"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "1500 7878352497/4398046511104"
                              ]
                            },
                            "mini-max": {
                              "aggregatedPayoff": [
                                "800 7878352497/4398046511104"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "1500 7878352497/4398046511104"
                              ]
                            },
                            "min-max": {
                              "aggregatedPayoff": [
                                "800 7878352497/4398046511104",
                                "0"
                              ],
                              "probabilityToEnter": 0,
                              "combinedPayoff": "-1500 7878352497/4398046511104",
                              "payoff": [
                                "1500 7878352497/4398046511104",
                                "0"
                              ]
                            },
                            "max-min": {
                              "aggregatedPayoff": [
                                "800 7878352497/4398046511104",
                                "0"
                              ],
                              "probabilityToEnter": "7/10",
                              "combinedPayoff": "1500 7878352497/4398046511104",
                              "payoff": [
                                "1500 7878352497/4398046511104",
                                "0"
                              ],
                              "optimal": true
                            },
                            "min-min": {
                              "aggregatedPayoff": [
                                "800 7878352497/4398046511104",
                                "0"
                              ],
                              "probabilityToEnter": 0,
                              "combinedPayoff": "-1500 7878352497/4398046511104",
                              "payoff": [
                                "1500 7878352497/4398046511104",
                                "0"
                              ]
                            },
                            "max-max": {
                              "aggregatedPayoff": [
                                "800 7878352497/4398046511104",
                                "0"
                              ],
                              "probabilityToEnter": "7/10",
                              "combinedPayoff": "1500 7878352497/4398046511104",
                              "payoff": [
                                "1500 7878352497/4398046511104",
                                "0"
                              ],
                              "optimal": true
                            }
                          },
                          "childEdges": [],
                          "name": "Payoff",
                          "code": "",
                          "expressionScope": {
                            "Alternative_A": -1000,
                            "Alternative_B": -2000,
                            "Outcome_A": 300,
                            "Outcome_B": 500,
                            "Outcome_C": 200,
                            "Outcome_D": 600,
                            "Alternative_X": 500,
                            "Alternative_Y": 1200
                          },
                          "folded": false,
                          "location": {
                            "x": 791,
                            "y": 113.00000000000001
                          },
                          "type": "terminal",
                          "milestone": 2,
                          "expectedValue": 801.0017473418216,
                          "p10": -88,
                          "p90": 1738,
                          "charts": [
                            {
                              "nodeId": "b3ecbdeb-cac0-cb2d-0ed6-e162639fd48b",
                              "x": 89,
                              "y": -41.000003814697266,
                              "type": "distribution",
                              "active": true
                            },
                            {
                              "nodeId": "b3ecbdeb-cac0-cb2d-0ed6-e162639fd48b",
                              "x": 248.00000762939453,
                              "y": -40.999996185302734,
                              "type": "waterfall",
                              "active": true
                            },
                            {
                              "nodeId": "b3ecbdeb-cac0-cb2d-0ed6-e162639fd48b",
                              "x": 406.00000762939453,
                              "y": -40.99999809265137,
                              "type": "tornado",
                              "active": true
                            }
                          ]
                        },
                        "expectedValue": 1500.0017913299637,
                        "p10": 781,
                        "p90": 2310,
                        "optimal": true
                      }
                    ],
                    "name": "Decision",
                    "code": "",
                    "expressionScope": {
                      "Alternative_A": -1000,
                      "Alternative_B": -2000,
                      "Outcome_A": 300,
                      "Outcome_B": 500,
                      "Outcome_C": 200,
                      "Outcome_D": 600,
                      "Alternative_X": 500,
                      "Alternative_Y": 1200
                    },
                    "folded": false,
                    "location": {
                      "x": 531,
                      "y": 62
                    },
                    "type": "decision",
                    "milestone": 1,
                    "expectedValue": 1500.0017913299637,
                    "p10": 781,
                    "p90": 2310
                  },
                  "expectedValue": 300,
                  "p10": 99,
                  "p90": 506,
                  "optimal": true
                },
                {
                  "computed": {
                    "payoff": [
                      "683 3114643800589/4398046511104",
                      "0"
                    ],
                    "probability": "3/10",
                    "expected-value-maximization": {
                      "probability": "3/10",
                      "optimal": true
                    },
                    "expected-value-minimization": {
                      "probability": "3/10"
                    },
                    "maxi-min": {
                      "probability": 1,
                      "optimal": true
                    },
                    "maxi-max": {
                      "probability": 0,
                      "optimal": false
                    },
                    "mini-min": {
                      "probability": 1
                    },
                    "mini-max": {
                      "probability": 0
                    },
                    "min-max": {
                      "probability": "3/10"
                    },
                    "max-min": {
                      "probability": "3/10",
                      "optimal": true
                    },
                    "min-min": {
                      "probability": "3/10"
                    },
                    "max-max": {
                      "probability": "3/10",
                      "optimal": true
                    }
                  },
                  "name": "Outcome B",
                  "probability": "#",
                  "payoff": [
                    "Outcome_B",
                    0
                  ],
                  "childNode": {
                    "computed": {
                      "expected-value-maximization": {
                        "aggregatedPayoff": [
                          "-316 8431254359617/8796093022208"
                        ],
                        "probabilityToEnter": "3/10",
                        "payoff": [
                          "683 3114643800589/4398046511104"
                        ],
                        "optimal": true
                      },
                      "expected-value-minimization": {
                        "aggregatedPayoff": [
                          "-316 1283402710515/4398046511104"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "683 3114643800589/4398046511104"
                        ]
                      },
                      "maxi-min": {
                        "aggregatedPayoff": [
                          "-316 1283402710515/4398046511104"
                        ],
                        "probabilityToEnter": "1",
                        "payoff": [
                          "683 3114643800589/4398046511104"
                        ],
                        "optimal": true
                      },
                      "maxi-max": {
                        "aggregatedPayoff": [
                          "-316 1283402710515/4398046511104"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "683 3114643800589/4398046511104"
                        ]
                      },
                      "mini-min": {
                        "aggregatedPayoff": [
                          "-316 1283402710515/4398046511104"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "683 3114643800589/4398046511104"
                        ]
                      },
                      "mini-max": {
                        "aggregatedPayoff": [
                          "-316 1283402710515/4398046511104"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "683 3114643800589/4398046511104"
                        ]
                      },
                      "min-max": {
                        "aggregatedPayoff": [
                          "-316 1283402710515/4398046511104",
                          "0"
                        ],
                        "probabilityToEnter": 0,
                        "combinedPayoff": "-683 3114643800589/4398046511104",
                        "payoff": [
                          "683 3114643800589/4398046511104",
                          "0"
                        ]
                      },
                      "max-min": {
                        "aggregatedPayoff": [
                          "-316 1283402710515/4398046511104",
                          "0"
                        ],
                        "probabilityToEnter": "3/10",
                        "combinedPayoff": "683 3114643800589/4398046511104",
                        "payoff": [
                          "683 3114643800589/4398046511104",
                          "0"
                        ],
                        "optimal": true
                      },
                      "min-min": {
                        "aggregatedPayoff": [
                          "-316 1283402710515/4398046511104",
                          "0"
                        ],
                        "probabilityToEnter": 0,
                        "combinedPayoff": "-683 3114643800589/4398046511104",
                        "payoff": [
                          "683 3114643800589/4398046511104",
                          "0"
                        ]
                      },
                      "max-max": {
                        "aggregatedPayoff": [
                          "-316 1283402710515/4398046511104",
                          "0"
                        ],
                        "probabilityToEnter": "3/10",
                        "combinedPayoff": "683 3114643800589/4398046511104",
                        "payoff": [
                          "683 3114643800589/4398046511104",
                          "0"
                        ],
                        "optimal": true
                      }
                    },
                    "childEdges": [],
                    "name": "Payoff",
                    "code": "",
                    "expressionScope": {
                      "Alternative_A": -1000,
                      "Alternative_B": -2000,
                      "Outcome_A": 300,
                      "Outcome_B": 500,
                      "Outcome_C": 200,
                      "Outcome_D": 600,
                      "Alternative_X": 500,
                      "Alternative_Y": 1200
                    },
                    "folded": false,
                    "location": {
                      "x": 531,
                      "y": 164
                    },
                    "type": "terminal",
                    "milestone": 1,
                    "expectedValue": -316.95852264617145,
                    "p10": -869,
                    "p90": 242
                  },
                  "expectedValue": 683.7081880086364,
                  "p10": 451,
                  "p90": 968,
                  "optimal": true
                }
              ],
              "name": "Chance",
              "code": "",
              "expressionScope": {
                "Alternative_A": -1000,
                "Alternative_B": -2000,
                "Outcome_A": 300,
                "Outcome_B": 500,
                "Outcome_C": 200,
                "Outcome_D": 600,
                "Alternative_X": 500,
                "Alternative_Y": 1200
              },
              "folded": false,
              "location": {
                "x": 271,
                "y": 113.00000000000001
              },
              "type": "chance",
              "expectedValue": 1466.280377000232,
              "p10": 561,
              "p90": 2486,
              "charts": [
                {
                  "nodeId": "76ec9544-8cee-05d2-13ef-37325dec725d",
                  "x": -75,
                  "y": -187,
                  "type": "distribution",
                  "active": true
                }
              ]
            },
            "expectedValue": -1000,
            "p10": -1507,
            "p90": -495,
            "optimal": true
          },
          {
            "computed": {
              "payoff": [
                "-2000",
                "0"
              ],
              "expected-value-maximization": {
                "probability": 0,
                "optimal": false
              },
              "expected-value-minimization": {
                "probability": 1,
                "optimal": true
              },
              "maxi-min": {
                "probability": 0,
                "optimal": false
              },
              "maxi-max": {
                "probability": 0,
                "optimal": false
              },
              "mini-min": {
                "probability": 1,
                "optimal": true
              },
              "mini-max": {
                "probability": 1,
                "optimal": true
              },
              "min-max": {
                "probability": 1,
                "optimal": true
              },
              "max-min": {
                "probability": 0,
                "optimal": false
              },
              "min-min": {
                "probability": 1,
                "optimal": true
              },
              "max-max": {
                "probability": 0,
                "optimal": false
              }
            },
            "name": "Alternative B",
            "payoff": [
              "Alternative_B",
              0
            ],
            "childNode": {
              "computed": {
                "expected-value-maximization": {
                  "childrenPayoff": [
                    "520 1651171923353/8796093022208"
                  ],
                  "payoff": [
                    "-1479 318138261801/2199023255552"
                  ]
                },
                "expected-value-minimization": {
                  "childrenPayoff": [
                    "520 7523539975005/8796093022208"
                  ],
                  "payoff": [
                    "-1479 318138261801/2199023255552"
                  ],
                  "optimal": true
                },
                "maxi-min": {
                  "childrenPayoff": [
                    "200"
                  ],
                  "payoff": [
                    "-1800"
                  ]
                },
                "maxi-max": {
                  "childrenPayoff": [
                    "841 3125493463901/4398046511104"
                  ],
                  "payoff": [
                    "-1158 1272553047203/4398046511104"
                  ]
                },
                "mini-min": {
                  "childrenPayoff": [
                    "200"
                  ],
                  "payoff": [
                    "-1800"
                  ],
                  "optimal": true
                },
                "mini-max": {
                  "childrenPayoff": [
                    "841 3125493463901/4398046511104"
                  ],
                  "payoff": [
                    "-1158 1272553047203/4398046511104"
                  ],
                  "optimal": true
                },
                "min-max": {
                  "childrenPayoff": [
                    "520 7523539975005/8796093022208",
                    "0"
                  ],
                  "combinedPayoff": "1479 318138261801/2199023255552",
                  "payoff": [
                    "-1479 318138261801/2199023255552",
                    "0"
                  ],
                  "optimal": true
                },
                "max-min": {
                  "childrenPayoff": [
                    "520 7523539975005/8796093022208",
                    "0"
                  ],
                  "combinedPayoff": "-1479 318138261801/2199023255552",
                  "payoff": [
                    "-1479 318138261801/2199023255552",
                    "0"
                  ]
                },
                "min-min": {
                  "childrenPayoff": [
                    "520 7523539975005/8796093022208",
                    "0"
                  ],
                  "combinedPayoff": "1479 318138261801/2199023255552",
                  "payoff": [
                    "-1479 318138261801/2199023255552",
                    "0"
                  ],
                  "optimal": true
                },
                "max-max": {
                  "childrenPayoff": [
                    "520 7523539975005/8796093022208",
                    "0"
                  ],
                  "combinedPayoff": "-1479 318138261801/2199023255552",
                  "payoff": [
                    "-1479 318138261801/2199023255552",
                    "0"
                  ]
                }
              },
              "childEdges": [
                {
                  "computed": {
                    "payoff": [
                      "200",
                      "0"
                    ],
                    "probability": "1/2",
                    "expected-value-maximization": {
                      "probability": "1/2"
                    },
                    "expected-value-minimization": {
                      "probability": "1/2",
                      "optimal": true
                    },
                    "maxi-min": {
                      "probability": 1
                    },
                    "maxi-max": {
                      "probability": 0
                    },
                    "mini-min": {
                      "probability": 1,
                      "optimal": true
                    },
                    "mini-max": {
                      "probability": 0,
                      "optimal": false
                    },
                    "min-max": {
                      "probability": "1/2",
                      "optimal": true
                    },
                    "max-min": {
                      "probability": "1/2"
                    },
                    "min-min": {
                      "probability": "1/2",
                      "optimal": true
                    },
                    "max-max": {
                      "probability": "1/2"
                    }
                  },
                  "name": "Outcome C",
                  "probability": "#",
                  "payoff": [
                    "Outcome_C",
                    0
                  ],
                  "childNode": {
                    "computed": {
                      "expected-value-maximization": {
                        "aggregatedPayoff": [
                          "-1800"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "200"
                        ]
                      },
                      "expected-value-minimization": {
                        "aggregatedPayoff": [
                          "-1800"
                        ],
                        "probabilityToEnter": "1/2",
                        "payoff": [
                          "200"
                        ],
                        "optimal": true
                      },
                      "maxi-min": {
                        "aggregatedPayoff": [
                          "-1800"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "200"
                        ]
                      },
                      "maxi-max": {
                        "aggregatedPayoff": [
                          "-1800"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "200"
                        ]
                      },
                      "mini-min": {
                        "aggregatedPayoff": [
                          "-1800"
                        ],
                        "probabilityToEnter": "1",
                        "payoff": [
                          "200"
                        ],
                        "optimal": true
                      },
                      "mini-max": {
                        "aggregatedPayoff": [
                          "-1800"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "200"
                        ]
                      },
                      "min-max": {
                        "aggregatedPayoff": [
                          "-1800",
                          "0"
                        ],
                        "probabilityToEnter": "1/2",
                        "combinedPayoff": "-200",
                        "payoff": [
                          "200",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-min": {
                        "aggregatedPayoff": [
                          "-1800",
                          "0"
                        ],
                        "probabilityToEnter": 0,
                        "combinedPayoff": "200",
                        "payoff": [
                          "200",
                          "0"
                        ]
                      },
                      "min-min": {
                        "aggregatedPayoff": [
                          "-1800",
                          "0"
                        ],
                        "probabilityToEnter": "1/2",
                        "combinedPayoff": "-200",
                        "payoff": [
                          "200",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-max": {
                        "aggregatedPayoff": [
                          "-1800",
                          "0"
                        ],
                        "probabilityToEnter": 0,
                        "combinedPayoff": "200",
                        "payoff": [
                          "200",
                          "0"
                        ]
                      }
                    },
                    "childEdges": [],
                    "name": "Payoff",
                    "code": "",
                    "expressionScope": {
                      "Alternative_A": -1000,
                      "Alternative_B": -2000,
                      "Outcome_A": 300,
                      "Outcome_B": 500,
                      "Outcome_C": 200,
                      "Outcome_D": 600,
                      "Alternative_X": 500,
                      "Alternative_Y": 1200
                    },
                    "folded": false,
                    "location": {
                      "x": 531,
                      "y": 286.40000000000003
                    },
                    "type": "terminal",
                    "milestone": 1,
                    "expectedValue": -1800,
                    "p10": -2805,
                    "p90": -792,
                    "charts": [
                      {
                        "nodeId": "80b8946a-d854-7316-a867-c2020aab8fbb",
                        "x": 87,
                        "y": -39.999996185302734,
                        "type": "distribution",
                        "active": true
                      },
                      {
                        "nodeId": "80b8946a-d854-7316-a867-c2020aab8fbb",
                        "x": 504.0000305175781,
                        "y": -41,
                        "type": "waterfall",
                        "active": false
                      }
                    ]
                  },
                  "expectedValue": 200,
                  "p10": 99,
                  "p90": 297,
                  "optimal": false
                },
                {
                  "computed": {
                    "payoff": [
                      "841 3125493463901/4398046511104",
                      "0"
                    ],
                    "probability": "1/2",
                    "expected-value-maximization": {
                      "probability": "1/2"
                    },
                    "expected-value-minimization": {
                      "probability": "1/2",
                      "optimal": true
                    },
                    "maxi-min": {
                      "probability": 0
                    },
                    "maxi-max": {
                      "probability": 1
                    },
                    "mini-min": {
                      "probability": 0,
                      "optimal": false
                    },
                    "mini-max": {
                      "probability": 1,
                      "optimal": true
                    },
                    "min-max": {
                      "probability": "1/2",
                      "optimal": true
                    },
                    "max-min": {
                      "probability": "1/2"
                    },
                    "min-min": {
                      "probability": "1/2",
                      "optimal": true
                    },
                    "max-max": {
                      "probability": "1/2"
                    }
                  },
                  "name": "Outcome D",
                  "probability": "#",
                  "payoff": [
                    "Outcome_D",
                    0
                  ],
                  "childNode": {
                    "computed": {
                      "expected-value-maximization": {
                        "aggregatedPayoff": [
                          "-1159 2738656666617/4398046511104"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "841 3125493463901/4398046511104"
                        ]
                      },
                      "expected-value-minimization": {
                        "aggregatedPayoff": [
                          "-1158 1272553047203/4398046511104"
                        ],
                        "probabilityToEnter": "1/2",
                        "payoff": [
                          "841 3125493463901/4398046511104"
                        ],
                        "optimal": true
                      },
                      "maxi-min": {
                        "aggregatedPayoff": [
                          "-1158 1272553047203/4398046511104"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "841 3125493463901/4398046511104"
                        ]
                      },
                      "maxi-max": {
                        "aggregatedPayoff": [
                          "-1158 1272553047203/4398046511104"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "841 3125493463901/4398046511104"
                        ]
                      },
                      "mini-min": {
                        "aggregatedPayoff": [
                          "-1158 1272553047203/4398046511104"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "841 3125493463901/4398046511104"
                        ]
                      },
                      "mini-max": {
                        "aggregatedPayoff": [
                          "-1158 1272553047203/4398046511104"
                        ],
                        "probabilityToEnter": "1",
                        "payoff": [
                          "841 3125493463901/4398046511104"
                        ],
                        "optimal": true
                      },
                      "min-max": {
                        "aggregatedPayoff": [
                          "-1158 1272553047203/4398046511104",
                          "0"
                        ],
                        "probabilityToEnter": "1/2",
                        "combinedPayoff": "-841 3125493463901/4398046511104",
                        "payoff": [
                          "841 3125493463901/4398046511104",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-min": {
                        "aggregatedPayoff": [
                          "-1158 1272553047203/4398046511104",
                          "0"
                        ],
                        "probabilityToEnter": 0,
                        "combinedPayoff": "841 3125493463901/4398046511104",
                        "payoff": [
                          "841 3125493463901/4398046511104",
                          "0"
                        ]
                      },
                      "min-min": {
                        "aggregatedPayoff": [
                          "-1158 1272553047203/4398046511104",
                          "0"
                        ],
                        "probabilityToEnter": "1/2",
                        "combinedPayoff": "-841 3125493463901/4398046511104",
                        "payoff": [
                          "841 3125493463901/4398046511104",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-max": {
                        "aggregatedPayoff": [
                          "-1158 1272553047203/4398046511104",
                          "0"
                        ],
                        "probabilityToEnter": 0,
                        "combinedPayoff": "841 3125493463901/4398046511104",
                        "payoff": [
                          "841 3125493463901/4398046511104",
                          "0"
                        ]
                      }
                    },
                    "childEdges": [],
                    "name": "Payoff",
                    "code": "",
                    "expressionScope": {
                      "Alternative_A": -1000,
                      "Alternative_B": -2000,
                      "Outcome_A": 300,
                      "Outcome_B": 500,
                      "Outcome_C": 200,
                      "Outcome_D": 600,
                      "Alternative_X": 500,
                      "Alternative_Y": 1200
                    },
                    "folded": false,
                    "location": {
                      "x": 531,
                      "y": 388.40000000000003
                    },
                    "type": "terminal",
                    "milestone": 1,
                    "expectedValue": -1159.6226984320658,
                    "p10": -2200,
                    "p90": -121,
                    "charts": [
                      {
                        "nodeId": "eb907bcf-c00a-679f-e7ed-51d0d6072c6e",
                        "x": 87,
                        "y": -38.99999809265137,
                        "type": "distribution",
                        "active": true
                      }
                    ]
                  },
                  "expectedValue": 841.710654936461,
                  "p10": 561,
                  "p90": 1188,
                  "optimal": false
                }
              ],
              "name": "Chance",
              "code": "",
              "expressionScope": {
                "Alternative_A": -1000,
                "Alternative_B": -2000,
                "Outcome_A": 300,
                "Outcome_B": 500,
                "Outcome_C": 200,
                "Outcome_D": 600,
                "Alternative_X": 500,
                "Alternative_Y": 1200
              },
              "folded": false,
              "location": {
                "x": 271,
                "y": 337.40000000000003
              },
              "type": "chance",
              "expectedValue": 520.1877165145007,
              "p10": 132,
              "p90": 1067,
              "charts": [
                {
                  "nodeId": "9a95bd38-3fd3-f5ac-9b05-6c2b6023d0ac",
                  "x": -77,
                  "y": 114,
                  "type": "distribution",
                  "active": true
                }
              ]
            },
            "expectedValue": -2000,
            "p10": -3003,
            "p90": -1001,
            "optimal": false
          }
        ],
        "name": "Decision",
        "code": "",
        "expressionScope": {
          "Alternative_A": -1000,
          "Alternative_B": -2000,
          "Outcome_A": 300,
          "Outcome_B": 500,
          "Outcome_C": 200,
          "Outcome_D": 600,
          "Alternative_X": 500,
          "Alternative_Y": 1200
        },
        "folded": false,
        "location": {
          "x": 11,
          "y": 225.20000000000002
        },
        "type": "decision",
        "expectedValue": 465.61366634542475,
        "p10": -561,
        "p90": 1573,
        "charts": [
          {
            "nodeId": "ee2f7811-1bcb-0325-9811-805f580e9d20",
            "x": -143,
            "y": 43,
            "type": "distribution",
            "active": true
          },
          {
            "nodeId": "ee2f7811-1bcb-0325-9811-805f580e9d20",
            "x": 55,
            "y": -95,
            "type": "bar",
            "active": false
          },
          {
            "nodeId": "ee2f7811-1bcb-0325-9811-805f580e9d20",
            "x": -143,
            "y": 129,
            "type": "tornado",
            "active": true
          }
        ]
      }
    ],
    "texts": [
      {
        "value": "Decision nodes \nare points where \ndecisions are made.",
        "location": {
          "x": -113.993896484375,
          "y": 151.46615934371948
        }
      },
      {
        "value": "Chance nodes \nare points where\nuncertainty is \nresolved.",
        "location": {
          "x": 162.73875427246094,
          "y": 32.376731872558594
        }
      },
      {
        "value": "End nodes are points that return \nthe payoff and probability for the \nassociated branch in the tree. ",
        "location": {
          "x": 640.580810546875,
          "y": -68.63452760378505
        }
      },
      {
        "value": "Payoffs with uncertainty",
        "location": {
          "x": 891.0538330078125,
          "y": -41.283836364746094
        }
      }
    ],
    "payoffNames": [],
    "defaultCriterion1Weight": 1,
    "weightLowerBound": 0,
    "weightUpperBound": "Infinity"
  },
  "sensitivityAnalysis": {},
  "definitionsDialog": {
    "valueList": [
      {
        "name": "Alternative_A",
        "value": "-1000",
        "low": -1500,
        "high": -500,
        "distribution": "triangular",
        "error": ""
      },
      {
        "name": "Alternative_B",
        "value": "-2000",
        "low": -3000,
        "high": -1000,
        "distribution": "triangular",
        "error": ""
      },
      {
        "name": "Outcome_A",
        "value": "300",
        "low": 100,
        "high": 500,
        "distribution": "triangular",
        "error": ""
      },
      {
        "name": "Outcome_B",
        "value": "500",
        "low": 400,
        "high": 900,
        "distribution": "triangular",
        "error": ""
      },
      {
        "name": "Outcome_C",
        "value": "200",
        "low": 100,
        "high": 300,
        "distribution": "triangular",
        "error": ""
      },
      {
        "name": "Outcome_D",
        "value": "600",
        "low": 500,
        "high": 1100,
        "distribution": "triangular",
        "error": ""
      },
      {
        "name": "Alternative_X",
        "value": "500",
        "low": 300,
        "high": 700,
        "distribution": "triangular",
        "error": ""
      },
      {
        "name": "Alternative_Y",
        "value": "1200",
        "low": 700,
        "high": 2200,
        "distribution": "triangular",
        "error": ""
      }
    ]
  }
}