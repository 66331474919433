module.exports={
  "SilverDecisions": "1.1.0",
  "buildTimestamp": 1717415076588,
  "savetime": "2024-06-03T11:49:14.538Z",
  "lng": "en",
  "viewMode": "criterion1",
  "rule": "expected-value-maximization",
  "title": "Variables and distributions",
  "description": "",
  "format": {
    "locales": "en",
    "payoff1": {
      "style": "currency",
      "currency": "USD",
      "currencyDisplay": "symbol",
      "minimumFractionDigits": 0,
      "maximumFractionDigits": 0,
      "useGrouping": true
    },
    "payoff2": {
      "style": "percent",
      "currency": "USD",
      "currencyDisplay": "symbol",
      "minimumFractionDigits": 0,
      "maximumFractionDigits": 0,
      "useGrouping": true
    },
    "probability": {
      "style": "percent",
      "minimumFractionDigits": 0,
      "maximumFractionDigits": 0,
      "useGrouping": true
    }
  },
  "treeDesigner": {
    "margin": {
      "left": 550,
      "right": 500,
      "top": 200,
      "bottom": 100
    },
    "scale": 1,
    "lng": "en",
    "layout": {
      "type": "cluster",
      "nodeSize": 22,
      "limitNodePositioning": true,
      "limitTextPositioning": true,
      "gridHeight": 95,
      "gridWidth": 240,
      "edgeSlantWidthMax": 0
    },
    "fontFamily": "sans-serif",
    "fontSize": "12px",
    "fontWeight": "normal",
    "fontStyle": "normal",
    "node": {
      "strokeWidth": "2px",
      "optimal": {
        "stroke": "#006f00",
        "strokeWidth": "2px"
      },
      "label": {
        "fontSize": "1em",
        "color": "black"
      },
      "payoff": {
        "fontSize": "1em",
        "color": "black",
        "negativeColor": "#b60000"
      },
      "decision": {
        "fill": "#ff7777",
        "stroke": "#660000",
        "selected": {
          "fill": "#aa3333"
        }
      },
      "chance": {
        "fill": "#ffff44",
        "stroke": "#666600",
        "selected": {
          "fill": "#aaaa00"
        }
      },
      "terminal": {
        "fill": "#44ff44",
        "stroke": "black",
        "selected": {
          "fill": "#00aa00"
        },
        "payoff": {
          "fontSize": "1em",
          "color": "black",
          "negativeColor": "#b60000"
        }
      }
    },
    "edge": {
      "stroke": "#424242",
      "strokeWidth": "1.5",
      "optimal": {
        "stroke": "#006f00",
        "strokeWidth": "2.4"
      },
      "selected": {
        "stroke": "#045ad1",
        "strokeWidth": "3.5"
      },
      "label": {
        "fontSize": "1em",
        "color": "back"
      },
      "payoff": {
        "fontSize": "1em",
        "color": "black",
        "negativeColor": "#b60000"
      }
    },
    "probability": {
      "fontSize": "1em",
      "color": "#0000d7"
    },
    "title": {
      "fontSize": "16px",
      "fontWeight": "bold",
      "fontStyle": "normal",
      "color": "#000000",
      "margin": {
        "top": 15,
        "bottom": 10
      }
    },
    "description": {
      "show": true,
      "fontSize": "12px",
      "fontWeight": "bold",
      "fontStyle": "normal",
      "color": "#000000",
      "margin": {
        "top": 5,
        "bottom": 10
      }
    },
    "readOnly": false,
    "disableAnimations": false,
    "forceFullEdgeRedraw": false,
    "hideLabels": false,
    "hidePayoffs": false,
    "hideExpectedValues": false,
    "hideProbabilities": false,
    "hideCharts": false,
    "hideTextboxes": false,
    "hideMilestones": false,
    "raw": false,
    "payoffNames": [
      null,
      null
    ],
    "maxPayoffsToDisplay": 1,
    "milestoneNames": [
      "",
      "Decision point",
      "Information point",
      "Uncertainty",
      "End point",
      "",
      "",
      "",
      "",
      ""
    ]
  },
  "data": {
    "code": "RemainingUncertainty=10\n",
    "expressionScope": {
      "RemainingUncertainty": 10
    },
    "trees": [
      {
        "computed": {
          "expected-value-maximization": {
            "childrenPayoff": [
              "7"
            ],
            "payoff": [
              "7"
            ],
            "optimal": true
          },
          "expected-value-minimization": {
            "childrenPayoff": [
              "0"
            ],
            "payoff": [
              "0"
            ],
            "optimal": true
          },
          "maxi-min": {
            "childrenPayoff": [
              "0"
            ],
            "payoff": [
              "0"
            ],
            "optimal": true
          },
          "maxi-max": {
            "childrenPayoff": [
              "15"
            ],
            "payoff": [
              "15"
            ],
            "optimal": true
          },
          "mini-min": {
            "childrenPayoff": [
              "0"
            ],
            "payoff": [
              "0"
            ],
            "optimal": true
          },
          "mini-max": {
            "childrenPayoff": [
              "0"
            ],
            "payoff": [
              "0"
            ],
            "optimal": true
          },
          "min-max": {
            "childrenPayoff": [
              "0",
              "0"
            ],
            "combinedPayoff": "0",
            "payoff": [
              "0",
              "0"
            ],
            "optimal": true
          },
          "max-min": {
            "childrenPayoff": [
              "7",
              "0"
            ],
            "combinedPayoff": "7",
            "payoff": [
              "7",
              "0"
            ],
            "optimal": true
          },
          "min-min": {
            "childrenPayoff": [
              "0",
              "0"
            ],
            "combinedPayoff": "0",
            "payoff": [
              "0",
              "0"
            ],
            "optimal": true
          },
          "max-max": {
            "childrenPayoff": [
              "7",
              "0"
            ],
            "combinedPayoff": "7",
            "payoff": [
              "7",
              "0"
            ],
            "optimal": true
          }
        },
        "childEdges": [
          {
            "computed": {
              "payoff": [
                "0",
                "0"
              ],
              "expected-value-maximization": {
                "probability": 1,
                "optimal": true
              },
              "expected-value-minimization": {
                "probability": 0,
                "optimal": false
              },
              "maxi-min": {
                "probability": 1,
                "optimal": true
              },
              "maxi-max": {
                "probability": 1,
                "optimal": true
              },
              "mini-min": {
                "probability": 1,
                "optimal": true
              },
              "mini-max": {
                "probability": 0,
                "optimal": false
              },
              "min-max": {
                "probability": 0,
                "optimal": false
              },
              "max-min": {
                "probability": 1,
                "optimal": true
              },
              "min-min": {
                "probability": 0,
                "optimal": false
              },
              "max-max": {
                "probability": 1,
                "optimal": true
              }
            },
            "name": "",
            "payoff": [
              0,
              0
            ],
            "childNode": {
              "computed": {
                "expected-value-maximization": {
                  "childrenPayoff": [
                    "7"
                  ],
                  "payoff": [
                    "7"
                  ],
                  "optimal": true
                },
                "expected-value-minimization": {
                  "childrenPayoff": [
                    "7"
                  ],
                  "payoff": [
                    "7"
                  ]
                },
                "maxi-min": {
                  "childrenPayoff": [
                    "0"
                  ],
                  "payoff": [
                    "0"
                  ],
                  "optimal": true
                },
                "maxi-max": {
                  "childrenPayoff": [
                    "15"
                  ],
                  "payoff": [
                    "15"
                  ],
                  "optimal": true
                },
                "mini-min": {
                  "childrenPayoff": [
                    "0"
                  ],
                  "payoff": [
                    "0"
                  ],
                  "optimal": true
                },
                "mini-max": {
                  "childrenPayoff": [
                    "15"
                  ],
                  "payoff": [
                    "15"
                  ]
                },
                "min-max": {
                  "childrenPayoff": [
                    "7",
                    "0"
                  ],
                  "combinedPayoff": "-7",
                  "payoff": [
                    "7",
                    "0"
                  ]
                },
                "max-min": {
                  "childrenPayoff": [
                    "7",
                    "0"
                  ],
                  "combinedPayoff": "7",
                  "payoff": [
                    "7",
                    "0"
                  ],
                  "optimal": true
                },
                "min-min": {
                  "childrenPayoff": [
                    "7",
                    "0"
                  ],
                  "combinedPayoff": "-7",
                  "payoff": [
                    "7",
                    "0"
                  ]
                },
                "max-max": {
                  "childrenPayoff": [
                    "7",
                    "0"
                  ],
                  "combinedPayoff": "7",
                  "payoff": [
                    "7",
                    "0"
                  ],
                  "optimal": true
                }
              },
              "childEdges": [
                {
                  "computed": {
                    "payoff": [
                      "0",
                      "0"
                    ],
                    "probability": "7/10",
                    "expected-value-maximization": {
                      "probability": "7/10",
                      "optimal": true
                    },
                    "expected-value-minimization": {
                      "probability": "7/10"
                    },
                    "maxi-min": {
                      "probability": 0,
                      "optimal": false
                    },
                    "maxi-max": {
                      "probability": 1,
                      "optimal": true
                    },
                    "mini-min": {
                      "probability": 0,
                      "optimal": false
                    },
                    "mini-max": {
                      "probability": 1
                    },
                    "min-max": {
                      "probability": "7/10"
                    },
                    "max-min": {
                      "probability": "7/10",
                      "optimal": true
                    },
                    "min-min": {
                      "probability": "7/10"
                    },
                    "max-max": {
                      "probability": "7/10",
                      "optimal": true
                    }
                  },
                  "name": "",
                  "probability": ".7",
                  "payoff": [
                    "0",
                    0
                  ],
                  "childNode": {
                    "computed": {
                      "expected-value-maximization": {
                        "childrenPayoff": [
                          "10"
                        ],
                        "payoff": [
                          "10"
                        ],
                        "optimal": true
                      },
                      "expected-value-minimization": {
                        "childrenPayoff": [
                          "10"
                        ],
                        "payoff": [
                          "10"
                        ]
                      },
                      "maxi-min": {
                        "childrenPayoff": [
                          "5"
                        ],
                        "payoff": [
                          "5"
                        ]
                      },
                      "maxi-max": {
                        "childrenPayoff": [
                          "15"
                        ],
                        "payoff": [
                          "15"
                        ],
                        "optimal": true
                      },
                      "mini-min": {
                        "childrenPayoff": [
                          "5"
                        ],
                        "payoff": [
                          "5"
                        ]
                      },
                      "mini-max": {
                        "childrenPayoff": [
                          "15"
                        ],
                        "payoff": [
                          "15"
                        ]
                      },
                      "min-max": {
                        "childrenPayoff": [
                          "10",
                          "0"
                        ],
                        "combinedPayoff": "-10",
                        "payoff": [
                          "10",
                          "0"
                        ]
                      },
                      "max-min": {
                        "childrenPayoff": [
                          "10",
                          "0"
                        ],
                        "combinedPayoff": "10",
                        "payoff": [
                          "10",
                          "0"
                        ],
                        "optimal": true
                      },
                      "min-min": {
                        "childrenPayoff": [
                          "10",
                          "0"
                        ],
                        "combinedPayoff": "-10",
                        "payoff": [
                          "10",
                          "0"
                        ]
                      },
                      "max-max": {
                        "childrenPayoff": [
                          "10",
                          "0"
                        ],
                        "combinedPayoff": "10",
                        "payoff": [
                          "10",
                          "0"
                        ],
                        "optimal": true
                      }
                    },
                    "childEdges": [
                      {
                        "computed": {
                          "payoff": [
                            "15",
                            "0"
                          ],
                          "probability": "3/10",
                          "expected-value-maximization": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "expected-value-minimization": {
                            "probability": "3/10"
                          },
                          "maxi-min": {
                            "probability": 0
                          },
                          "maxi-max": {
                            "probability": 1,
                            "optimal": true
                          },
                          "mini-min": {
                            "probability": 0
                          },
                          "mini-max": {
                            "probability": 1
                          },
                          "min-max": {
                            "probability": "3/10"
                          },
                          "max-min": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "min-min": {
                            "probability": "3/10"
                          },
                          "max-max": {
                            "probability": "3/10",
                            "optimal": true
                          }
                        },
                        "name": "High",
                        "probability": ".3",
                        "payoff": [
                          "15",
                          0
                        ],
                        "childNode": {
                          "computed": {
                            "expected-value-maximization": {
                              "aggregatedPayoff": [
                                "15"
                              ],
                              "probabilityToEnter": "21/100",
                              "payoff": [
                                "15"
                              ],
                              "optimal": true
                            },
                            "expected-value-minimization": {
                              "aggregatedPayoff": [
                                "15"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "15"
                              ]
                            },
                            "maxi-min": {
                              "aggregatedPayoff": [
                                "15"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "15"
                              ]
                            },
                            "maxi-max": {
                              "aggregatedPayoff": [
                                "15"
                              ],
                              "probabilityToEnter": "1",
                              "payoff": [
                                "15"
                              ],
                              "optimal": true
                            },
                            "mini-min": {
                              "aggregatedPayoff": [
                                "15"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "15"
                              ]
                            },
                            "mini-max": {
                              "aggregatedPayoff": [
                                "15"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "15"
                              ]
                            },
                            "min-max": {
                              "aggregatedPayoff": [
                                "15",
                                "0"
                              ],
                              "probabilityToEnter": 0,
                              "combinedPayoff": "-15",
                              "payoff": [
                                "15",
                                "0"
                              ]
                            },
                            "max-min": {
                              "aggregatedPayoff": [
                                "15",
                                "0"
                              ],
                              "probabilityToEnter": "21/100",
                              "combinedPayoff": "15",
                              "payoff": [
                                "15",
                                "0"
                              ],
                              "optimal": true
                            },
                            "min-min": {
                              "aggregatedPayoff": [
                                "15",
                                "0"
                              ],
                              "probabilityToEnter": 0,
                              "combinedPayoff": "-15",
                              "payoff": [
                                "15",
                                "0"
                              ]
                            },
                            "max-max": {
                              "aggregatedPayoff": [
                                "15",
                                "0"
                              ],
                              "probabilityToEnter": "21/100",
                              "combinedPayoff": "15",
                              "payoff": [
                                "15",
                                "0"
                              ],
                              "optimal": true
                            }
                          },
                          "childEdges": [],
                          "name": "Terminal \nnode",
                          "code": "",
                          "expressionScope": {
                            "RemainingUncertainty": 10
                          },
                          "folded": false,
                          "location": {
                            "x": 731,
                            "y": 11
                          },
                          "type": "terminal",
                          "milestone": 4,
                          "expectedValue": 15,
                          "p10": 15,
                          "p90": 15,
                          "charts": [
                            {
                              "nodeId": "b2ec6db1-7af2-10e3-812a-bbd46e8248c6",
                              "x": 66,
                              "y": -41.000003814697266,
                              "type": "distribution",
                              "active": false
                            }
                          ]
                        },
                        "expectedValue": 15,
                        "p10": 15,
                        "p90": 15,
                        "optimal": true
                      },
                      {
                        "computed": {
                          "payoff": [
                            "10",
                            "0"
                          ],
                          "probability": "2/5",
                          "expected-value-maximization": {
                            "probability": "2/5",
                            "optimal": true
                          },
                          "expected-value-minimization": {
                            "probability": "2/5"
                          },
                          "maxi-min": {
                            "probability": 0
                          },
                          "maxi-max": {
                            "probability": 0,
                            "optimal": false
                          },
                          "mini-min": {
                            "probability": 0
                          },
                          "mini-max": {
                            "probability": 0
                          },
                          "min-max": {
                            "probability": "2/5"
                          },
                          "max-min": {
                            "probability": "2/5",
                            "optimal": true
                          },
                          "min-min": {
                            "probability": "2/5"
                          },
                          "max-max": {
                            "probability": "2/5",
                            "optimal": true
                          }
                        },
                        "name": "Base",
                        "probability": ".4",
                        "payoff": [
                          "10",
                          0
                        ],
                        "childNode": {
                          "computed": {
                            "expected-value-maximization": {
                              "aggregatedPayoff": [
                                "10"
                              ],
                              "probabilityToEnter": "7/25",
                              "payoff": [
                                "10"
                              ],
                              "optimal": true
                            },
                            "expected-value-minimization": {
                              "aggregatedPayoff": [
                                "10"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "10"
                              ]
                            },
                            "maxi-min": {
                              "aggregatedPayoff": [
                                "10"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "10"
                              ]
                            },
                            "maxi-max": {
                              "aggregatedPayoff": [
                                "10"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "10"
                              ]
                            },
                            "mini-min": {
                              "aggregatedPayoff": [
                                "10"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "10"
                              ]
                            },
                            "mini-max": {
                              "aggregatedPayoff": [
                                "10"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "10"
                              ]
                            },
                            "min-max": {
                              "aggregatedPayoff": [
                                "10",
                                "0"
                              ],
                              "probabilityToEnter": 0,
                              "combinedPayoff": "-10",
                              "payoff": [
                                "10",
                                "0"
                              ]
                            },
                            "max-min": {
                              "aggregatedPayoff": [
                                "10",
                                "0"
                              ],
                              "probabilityToEnter": "7/25",
                              "combinedPayoff": "10",
                              "payoff": [
                                "10",
                                "0"
                              ],
                              "optimal": true
                            },
                            "min-min": {
                              "aggregatedPayoff": [
                                "10",
                                "0"
                              ],
                              "probabilityToEnter": 0,
                              "combinedPayoff": "-10",
                              "payoff": [
                                "10",
                                "0"
                              ]
                            },
                            "max-max": {
                              "aggregatedPayoff": [
                                "10",
                                "0"
                              ],
                              "probabilityToEnter": "7/25",
                              "combinedPayoff": "10",
                              "payoff": [
                                "10",
                                "0"
                              ],
                              "optimal": true
                            }
                          },
                          "childEdges": [],
                          "name": "",
                          "code": "",
                          "expressionScope": {
                            "RemainingUncertainty": 10
                          },
                          "folded": false,
                          "location": {
                            "x": 731,
                            "y": 106
                          },
                          "type": "terminal",
                          "expectedValue": 10,
                          "p10": 10,
                          "p90": 10
                        },
                        "expectedValue": 10,
                        "p10": 10,
                        "p90": 10,
                        "optimal": true
                      },
                      {
                        "computed": {
                          "payoff": [
                            "5",
                            "0"
                          ],
                          "probability": "3/10",
                          "expected-value-maximization": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "expected-value-minimization": {
                            "probability": "3/10"
                          },
                          "maxi-min": {
                            "probability": 1
                          },
                          "maxi-max": {
                            "probability": 0,
                            "optimal": false
                          },
                          "mini-min": {
                            "probability": 1
                          },
                          "mini-max": {
                            "probability": 0
                          },
                          "min-max": {
                            "probability": "3/10"
                          },
                          "max-min": {
                            "probability": "3/10",
                            "optimal": true
                          },
                          "min-min": {
                            "probability": "3/10"
                          },
                          "max-max": {
                            "probability": "3/10",
                            "optimal": true
                          }
                        },
                        "name": "Low",
                        "probability": "#",
                        "payoff": [
                          "5",
                          0
                        ],
                        "childNode": {
                          "computed": {
                            "expected-value-maximization": {
                              "aggregatedPayoff": [
                                "5"
                              ],
                              "probabilityToEnter": "21/100",
                              "payoff": [
                                "5"
                              ],
                              "optimal": true
                            },
                            "expected-value-minimization": {
                              "aggregatedPayoff": [
                                "5"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "5"
                              ]
                            },
                            "maxi-min": {
                              "aggregatedPayoff": [
                                "5"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "5"
                              ]
                            },
                            "maxi-max": {
                              "aggregatedPayoff": [
                                "5"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "5"
                              ]
                            },
                            "mini-min": {
                              "aggregatedPayoff": [
                                "5"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "5"
                              ]
                            },
                            "mini-max": {
                              "aggregatedPayoff": [
                                "5"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "5"
                              ]
                            },
                            "min-max": {
                              "aggregatedPayoff": [
                                "5",
                                "0"
                              ],
                              "probabilityToEnter": 0,
                              "combinedPayoff": "-5",
                              "payoff": [
                                "5",
                                "0"
                              ]
                            },
                            "max-min": {
                              "aggregatedPayoff": [
                                "5",
                                "0"
                              ],
                              "probabilityToEnter": "21/100",
                              "combinedPayoff": "5",
                              "payoff": [
                                "5",
                                "0"
                              ],
                              "optimal": true
                            },
                            "min-min": {
                              "aggregatedPayoff": [
                                "5",
                                "0"
                              ],
                              "probabilityToEnter": 0,
                              "combinedPayoff": "-5",
                              "payoff": [
                                "5",
                                "0"
                              ]
                            },
                            "max-max": {
                              "aggregatedPayoff": [
                                "5",
                                "0"
                              ],
                              "probabilityToEnter": "21/100",
                              "combinedPayoff": "5",
                              "payoff": [
                                "5",
                                "0"
                              ],
                              "optimal": true
                            }
                          },
                          "childEdges": [],
                          "name": "",
                          "code": "",
                          "expressionScope": {
                            "RemainingUncertainty": 10
                          },
                          "folded": false,
                          "location": {
                            "x": 731,
                            "y": 201
                          },
                          "type": "terminal",
                          "expectedValue": 5,
                          "p10": 5,
                          "p90": 5
                        },
                        "expectedValue": 5,
                        "p10": 5,
                        "p90": 5,
                        "optimal": true
                      }
                    ],
                    "name": "Remaining \nuncertainty",
                    "code": "",
                    "expressionScope": {
                      "RemainingUncertainty": 10
                    },
                    "folded": false,
                    "location": {
                      "x": 491,
                      "y": 106
                    },
                    "type": "chance",
                    "milestone": 3,
                    "expectedValue": 10,
                    "p10": 5,
                    "p90": 15
                  },
                  "expectedValue": 0,
                  "p10": 0,
                  "p90": 0,
                  "optimal": true
                },
                {
                  "computed": {
                    "payoff": [
                      "0",
                      "0"
                    ],
                    "probability": "3/10",
                    "expected-value-maximization": {
                      "probability": "3/10",
                      "optimal": true
                    },
                    "expected-value-minimization": {
                      "probability": "3/10"
                    },
                    "maxi-min": {
                      "probability": 1,
                      "optimal": true
                    },
                    "maxi-max": {
                      "probability": 0,
                      "optimal": false
                    },
                    "mini-min": {
                      "probability": 1,
                      "optimal": true
                    },
                    "mini-max": {
                      "probability": 0
                    },
                    "min-max": {
                      "probability": "3/10"
                    },
                    "max-min": {
                      "probability": "3/10",
                      "optimal": true
                    },
                    "min-min": {
                      "probability": "3/10"
                    },
                    "max-max": {
                      "probability": "3/10",
                      "optimal": true
                    }
                  },
                  "name": "",
                  "probability": "#",
                  "payoff": [
                    "0",
                    0
                  ],
                  "childNode": {
                    "computed": {
                      "expected-value-maximization": {
                        "aggregatedPayoff": [
                          "0"
                        ],
                        "probabilityToEnter": "3/10",
                        "payoff": [
                          "0"
                        ],
                        "optimal": true
                      },
                      "expected-value-minimization": {
                        "aggregatedPayoff": [
                          "0"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "0"
                        ]
                      },
                      "maxi-min": {
                        "aggregatedPayoff": [
                          "0"
                        ],
                        "probabilityToEnter": "1",
                        "payoff": [
                          "0"
                        ],
                        "optimal": true
                      },
                      "maxi-max": {
                        "aggregatedPayoff": [
                          "0"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "0"
                        ]
                      },
                      "mini-min": {
                        "aggregatedPayoff": [
                          "0"
                        ],
                        "probabilityToEnter": "1",
                        "payoff": [
                          "0"
                        ],
                        "optimal": true
                      },
                      "mini-max": {
                        "aggregatedPayoff": [
                          "0"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "0"
                        ]
                      },
                      "min-max": {
                        "aggregatedPayoff": [
                          "0",
                          "0"
                        ],
                        "probabilityToEnter": 0,
                        "combinedPayoff": "0",
                        "payoff": [
                          "0",
                          "0"
                        ]
                      },
                      "max-min": {
                        "aggregatedPayoff": [
                          "0",
                          "0"
                        ],
                        "probabilityToEnter": "3/10",
                        "combinedPayoff": "0",
                        "payoff": [
                          "0",
                          "0"
                        ],
                        "optimal": true
                      },
                      "min-min": {
                        "aggregatedPayoff": [
                          "0",
                          "0"
                        ],
                        "probabilityToEnter": 0,
                        "combinedPayoff": "0",
                        "payoff": [
                          "0",
                          "0"
                        ]
                      },
                      "max-max": {
                        "aggregatedPayoff": [
                          "0",
                          "0"
                        ],
                        "probabilityToEnter": "3/10",
                        "combinedPayoff": "0",
                        "payoff": [
                          "0",
                          "0"
                        ],
                        "optimal": true
                      }
                    },
                    "childEdges": [],
                    "name": "Stop",
                    "code": "",
                    "expressionScope": {
                      "RemainingUncertainty": 10
                    },
                    "folded": false,
                    "location": {
                      "x": 731,
                      "y": 315
                    },
                    "type": "terminal",
                    "milestone": null,
                    "expectedValue": 0,
                    "p10": 0,
                    "p90": 0
                  },
                  "expectedValue": 0,
                  "p10": 0,
                  "p90": 0,
                  "optimal": true
                }
              ],
              "name": "Chance \nnode",
              "code": "",
              "expressionScope": {
                "RemainingUncertainty": 10
              },
              "folded": false,
              "location": {
                "x": 251,
                "y": 210.5
              },
              "type": "chance",
              "milestone": 2,
              "expectedValue": 7,
              "p10": 0,
              "p90": 15
            },
            "expectedValue": 0,
            "p10": 0,
            "p90": 0,
            "optimal": true
          },
          {
            "computed": {
              "payoff": [
                "0",
                "0"
              ],
              "expected-value-maximization": {
                "probability": 0,
                "optimal": false
              },
              "expected-value-minimization": {
                "probability": 1,
                "optimal": true
              },
              "maxi-min": {
                "probability": 1,
                "optimal": true
              },
              "maxi-max": {
                "probability": 0,
                "optimal": false
              },
              "mini-min": {
                "probability": 1,
                "optimal": true
              },
              "mini-max": {
                "probability": 1,
                "optimal": true
              },
              "min-max": {
                "probability": 1,
                "optimal": true
              },
              "max-min": {
                "probability": 0,
                "optimal": false
              },
              "min-min": {
                "probability": 1,
                "optimal": true
              },
              "max-max": {
                "probability": 0,
                "optimal": false
              }
            },
            "name": "",
            "payoff": [
              0,
              0
            ],
            "childNode": {
              "computed": {
                "expected-value-maximization": {
                  "aggregatedPayoff": [
                    "0"
                  ],
                  "probabilityToEnter": 0,
                  "payoff": [
                    "0"
                  ]
                },
                "expected-value-minimization": {
                  "aggregatedPayoff": [
                    "0"
                  ],
                  "probabilityToEnter": "1",
                  "payoff": [
                    "0"
                  ],
                  "optimal": true
                },
                "maxi-min": {
                  "aggregatedPayoff": [
                    "0"
                  ],
                  "probabilityToEnter": "1",
                  "payoff": [
                    "0"
                  ],
                  "optimal": true
                },
                "maxi-max": {
                  "aggregatedPayoff": [
                    "0"
                  ],
                  "probabilityToEnter": 0,
                  "payoff": [
                    "0"
                  ]
                },
                "mini-min": {
                  "aggregatedPayoff": [
                    "0"
                  ],
                  "probabilityToEnter": "1",
                  "payoff": [
                    "0"
                  ],
                  "optimal": true
                },
                "mini-max": {
                  "aggregatedPayoff": [
                    "0"
                  ],
                  "probabilityToEnter": "1",
                  "payoff": [
                    "0"
                  ],
                  "optimal": true
                },
                "min-max": {
                  "aggregatedPayoff": [
                    "0",
                    "0"
                  ],
                  "probabilityToEnter": "1",
                  "combinedPayoff": "0",
                  "payoff": [
                    "0",
                    "0"
                  ],
                  "optimal": true
                },
                "max-min": {
                  "aggregatedPayoff": [
                    "0",
                    "0"
                  ],
                  "probabilityToEnter": 0,
                  "combinedPayoff": "0",
                  "payoff": [
                    "0",
                    "0"
                  ]
                },
                "min-min": {
                  "aggregatedPayoff": [
                    "0",
                    "0"
                  ],
                  "probabilityToEnter": "1",
                  "combinedPayoff": "0",
                  "payoff": [
                    "0",
                    "0"
                  ],
                  "optimal": true
                },
                "max-max": {
                  "aggregatedPayoff": [
                    "0",
                    "0"
                  ],
                  "probabilityToEnter": 0,
                  "combinedPayoff": "0",
                  "payoff": [
                    "0",
                    "0"
                  ]
                }
              },
              "childEdges": [],
              "name": "",
              "code": "",
              "expressionScope": {
                "RemainingUncertainty": 10
              },
              "folded": false,
              "location": {
                "x": 731,
                "y": 429
              },
              "type": "terminal",
              "milestone": null,
              "expectedValue": 0,
              "p10": 0,
              "p90": 0
            },
            "expectedValue": 0,
            "p10": 0,
            "p90": 0,
            "optimal": false
          }
        ],
        "name": "Decision\nnode",
        "code": "",
        "expressionScope": {
          "RemainingUncertainty": 10
        },
        "folded": false,
        "location": {
          "x": 11,
          "y": 319.75
        },
        "type": "decision",
        "milestone": 1,
        "expectedValue": 7,
        "p10": 0,
        "p90": 15
      }
    ],
    "texts": [
      {
        "value": "Decision maker\nhas control",
        "location": {
          "x": 21.000003814697266,
          "y": -150
        }
      },
      {
        "value": "Uncertainty is \npartly resolved",
        "location": {
          "x": 262,
          "y": -150
        }
      },
      {
        "value": "Discrete \noutcome",
        "location": {
          "x": 740.9999847412109,
          "y": -150
        }
      },
      {
        "value": "Traditional way \nto model remaining \nuncertainty",
        "location": {
          "x": 501,
          "y": -150
        }
      }
    ],
    "payoffNames": [],
    "defaultCriterion1Weight": 1,
    "weightLowerBound": 0,
    "weightUpperBound": "Infinity"
  },
  "sensitivityAnalysis": {},
  "definitionsDialog": {
    "valueList": [
      {
        "name": null,
        "value": "",
        "low": null,
        "high": null,
        "distribution": "constant",
        "error": ""
      },
      {
        "name": null,
        "value": "",
        "low": null,
        "high": null,
        "distribution": "constant",
        "error": ""
      },
      {
        "name": null,
        "value": "",
        "low": null,
        "high": null,
        "distribution": "constant",
        "error": ""
      },
      {
        "name": null,
        "value": "",
        "low": null,
        "high": null,
        "distribution": "constant",
        "error": ""
      },
      {
        "name": null,
        "value": "",
        "low": null,
        "high": null,
        "distribution": "constant",
        "error": ""
      }
    ]
  }
}