const variablesAndDistributionsTutorialHtml = [
    "<p>Ready to learn some powerful modelling techniques using variables and distributions?</p> <p><u>As in previous tutorials</u><br>Use your <strong>ARROW KEYS</strong> ← → on the keyboard to click through the tutorial.</p> <p>You can play around and tweak the tree as we go along, testing your own ideas and assumptions.</p> <p>Let's get started!</p>",

    "<p><u>Tip</u><br>This and other tutorials are readily available under the Help menu.</p> <img src=\"/build/images/tutorial-images/help-menu-screenshot.png\" alt=\"\"> <br><br> <p><u>Mouse actions</u><br>You’ll find them under the mouse icon in the top left corner of the canvas.</p><img src=\"/build/images/tutorial-images/mouse-actions-2.png\" alt=\"\" style=\"width: 219px;\">",

    "<i>Tutorial overview</i><p>1. In the <a href=\"#\" id=\"previous-tutorial-link\">previous tutorial</a>, you learned the basics of building a decision tree.</p> <p class='tutorial-text-opacity'>2. You also gained an understanding of how the tree is calculated.</p> <p class='tutorial-text-opacity'>3. This tutorial will focus on simplifying large decision trees.</p> <p class='tutorial-text-opacity'>4. We will achieve this by defining variables and distributions.</p> <p class='tutorial-text-opacity'>5. This will help us perform advanced calculations easily.</p>",
    
    "<i>Tutorial overview</i><p class='tutorial-text-opacity'>1. In the previous tutorial, you learned the basics of building a decision tree.</p> <p>2. You also gained an understanding of how the tree is calculated.</p> <p class='tutorial-text-opacity'>3. This tutorial will focus on simplifying large decision trees.</p> <p class='tutorial-text-opacity'>4. We will achieve this by defining variables and distributions.</p> <p class='tutorial-text-opacity'>5. This will help us perform advanced calculations easily.</p>",
    
    "<i>Tutorial overview</i><p class='tutorial-text-opacity'>1. In the previous tutorial, you learned the basics of building a decision tree.</p> <p class='tutorial-text-opacity'>2. You also gained an understanding of how the tree is calculated.</p> <p>3. This tutorial will focus on simplifying large decision trees.</p> <p class='tutorial-text-opacity'>4. We will achieve this by defining variables and distributions.</p> <p class='tutorial-text-opacity'>5. This will help us perform advanced calculations easily.</p>",
    
    "<i>Tutorial overview</i><p class='tutorial-text-opacity'>1. In the previous tutorial, you learned the basics of building a decision tree.</p> <p class='tutorial-text-opacity'>2. You also gained an understanding of how the tree is calculated.</p> <p class='tutorial-text-opacity'>3. This tutorial will focus on simplifying large decision trees.</p> <p>4. We will achieve this by defining variables and distributions.</p> <p class='tutorial-text-opacity'>5. This will help us perform advanced calculations easily.</p>",
    
    "<i>Tutorial overview</i><p class='tutorial-text-opacity'>1. In the previous tutorial, you learned the basics of building a decision tree.</p> <p class='tutorial-text-opacity'>2. You also gained an understanding of how the tree is calculated.</p> <p class='tutorial-text-opacity'>3. This tutorial will focus on simplifying large decision trees.</p> <p class='tutorial-text-opacity'>4. We will achieve this by defining variables and distributions.</p> <p>5. This will help us perform advanced calculations easily.</p>",

    "<p>It’s useful to keep the following in mind when working with uncertainties:</p> <p><i>Decision node = decision point where the decision maker is in control.</i></p> <p><i>Chance node = information point where uncertainty is resolved.</i></p> <p><i>Terminal node =  represents the remaining uncertainty.</i></p> <p>There are exceptions to this, but it serves as a good starting point for most situations.</p> <p>You can always break the rules later.</p>",

    "<p>The simple tree in the previous step would typically be represented with three discrete outcomes: High, Base, and Low.</p> <p>However, this approach has limitations.</p> <p><i>Firstly</i>, it doesn't fully capture uncertainty in real-life situations.</p> <p><i>Secondly</i>, using distributions instead of discrete outcomes helps manage the size of the tree.</p>",

    "<p>Let's apply distributions to a case you are familiar with from the Basic Tutorial.</p> <p><u>Tech case</u><br><i>We have to decide whether to switch from a proven technology to an experimental one. </i></p> <p><i>If the experimental tech is successful, we'll invest in new facilities or adapt the old ones.</i></p> <p><i>On the other hand, sticking with the proven tech means revising the marketing strategy to regain market share.</i></p>",
    
    "<p>We assume that the goal of the modeling is to determine which set of decisions maximises the economic value of the project and to gain a better understanding of the uncertainty involved.</p>",

    "<p>We have <strong>2 information points</strong> in the tech case, X and Y. At these points, information is revealed. </p> <p><i>Point X</i><br>Here we will know the outcome of the experimental R&D program: Whether it is successful or not.</p> <p><i>Point Y</i><br>Here we will know the outcome of the Revised marketing strategy: Whether it will help to regain the lost market share or not. </p>",

    "<p>We have <strong>5 points of remaining uncertainty</strong> in the tree, A, B, C, D and E.</p> <p><i>Point A, B and C</i><br>Experimental tech cases.</p> <p><i>Point D and E</i><br>Proven tech cases.</p>",

    "<p>To estimate the project's economic value, we establish a cash flow model. This model is adapted to points A to E.</p> <p>At each point (A to E), we create base, low, and high scenarios.</p> <p>These scenarios form five triangular distributions that can be connected in the decision tree.</p> <p>All input in the example is in thousands of USD.</p>",

    "<p>The variables and distributions are linked up by double-clicking on the relevant nodes or edges of the tree.</p> <p>In the input panel that appears, click inside the <strong>payoff</strong> input area and hit the <strong>spacebar</strong>.</p>",

    "<p>A list of all your variables will appear for you to select from.</p>  <img src=\"/build/images/tutorial-images/payoff-menu.png\" alt=\"\" style=\"height: 160px;\"> <br><br> <p>Alternatively, just start writing the name of the variable you want to select and it will appear as you write.</p>",

    "<p>As we link up each of the variables for Point A, B, C, D and E, the tree is being instantly calculated.</p> <p>Right-click on any terminal node and select “Distribution” to see the graph.</p> <img src=\"/build/images/tutorial-images/charts-menu.png\" alt=\"\" style=\"width: 150px;\"> <br><br> <p>Do you recognise the distributions we just defined inside the Variables module?</p>",

    "<p>Now, we move to the very first node in the decision tree and right-click to display a distribution for this node as well.</p> <p><u>Tip</u><br>Click on the + or - sign in the top left corner of the graph window to adjust its size</p> <img src=\"/build/images/tutorial-images/distribution-chart.png\" alt=\"\" style=\"width: 230px;\">",

    "<p>At the root node, the project's expected value is $143. This is calculated by weighing the values of outcomes A and C, which represent the optimum path. </p> <p>The Low value is -$48, with a 90% chance the project value is higher. The High value is $342, with a 10% chance the project value exceeds this amount.</p>",

    "<p>There are many other useful ways to use distributions and decision trees than to calculate the expected economic value of a project. Here are three examples:</p> <p><strong>1) Project timeline</strong></p> <p><strong>2) Legal dispute</strong></p> <p><strong>3) Product demand</strong></p> <p>Let’s start with the project timeline.</p>",

    "<p><strong>1) Project timeline</strong></p> <p><i>In project management, distributions can be used to estimate the <strong><u>total duration</u></strong> or cost of a project that involves various uncertain tasks or activities. By summing the distributions of individual task durations or costs, project managers can obtain a more accurate estimate of the overall project timeline or budget.</i></p> <p class='tutorial-text-opacity'>2) Legal dispute</p> <p class='tutorial-text-opacity'>3) Product demand</p>",
    
    "<p><strong>1) Project timeline</strong></p> <p><i>In project management, distributions can be used to estimate the total duration or cost of a project that involves various uncertain tasks or activities. By summing the distributions of <strong><u>individual task durations</strong></u> or costs, project managers can obtain a more accurate estimate of the overall project timeline or budget.</i></p> <p class='tutorial-text-opacity'>2) Legal dispute</p> <p class='tutorial-text-opacity'>3) Product demand</p>",

    "<p class='tutorial-text-opacity'>1) Project timeline</p> <p><strong>2) Legal dispute</strong></p> <p><i>In the legal field, distributions can be applied to assess the potential <strong><u>range of financial gain</strong></u> in a legal case involving multiple claims. It can help legal professionals make more informed decisions during settlement negotiations or trial proceedings, ensuring fair and accurate outcomes for all parties involved in the case.</i></p> <p class='tutorial-text-opacity'>3) Product demand</p>",
    
    "<p class='tutorial-text-opacity'>1) Project timeline</p> <p><strong>2) Legal dispute</strong></p> <p><i>In the legal field, distributions can be applied to assess the potential range of financial gain in a legal case involving <strong><u>multiple claims</strong></u>. It can help legal professionals make more informed decisions during settlement negotiations or trial proceedings, ensuring fair and accurate outcomes for all parties involved in the case.</i></p> <p class='tutorial-text-opacity'>3) Product demand</p>",

    "<p class='tutorial-text-opacity'>1) Project timeline</p> <p class='tutorial-text-opacity'>2) Legal dispute</p> <p><strong>3) Product demand</strong></p> <p><i>In supply chain management, distributions can be utilized to forecast the <strong><u>total demand</strong></u> for products across different locations. By summing the distributions of individual demand forecasts, supply chain planners can optimize inventory levels to meet customer needs.</i></p>",
    
    "<p class='tutorial-text-opacity'>1) Project timeline</p> <p class='tutorial-text-opacity'>2) Legal dispute</p> <p><strong>3) Product demand</strong></p> <p><i>In supply chain management, distributions can be utilized to forecast the total demand for products across different locations. By summing the distributions of <strong><u>individual demand</strong></u> forecasts, supply chain planners can optimize inventory levels to meet customer needs.</i></p>",

    "<p>That’s it - well done on finishing this tutorial!</p> <p>Feel free to keep learning by clicking on the next tutorial.</p> <div class=\"tutorial-button-container\"><button id=\"previous-tutorial-button\" class=\"prev-tutorial-btn\">← PREVIOUS TUTORIAL</button> <button id=\"next-tutorial-button\" class=\"next-tutorial-btn\">→ NEXT TUTORIAL</button></div> <br> <p>Alternatively, dive in and start exploring the tool on your own.</p> <p>Have fun!</p>"
];

export default variablesAndDistributionsTutorialHtml;