module.exports={
  "SilverDecisions": "1.1.0",
  "buildTimestamp": 1710935291638,
  "savetime": "2024-05-08T10:02:17.640Z",
  "lng": "en",
  "viewMode": "criterion1",
  "rule": "expected-value-maximization",
  "title": "Step 5",
  "description": "",
  "format": {
    "locales": "en",
    "payoff1": {
      "style": "currency",
      "currency": "USD",
      "currencyDisplay": "symbol",
      "minimumFractionDigits": 0,
      "maximumFractionDigits": 0,
      "useGrouping": true
    },
    "payoff2": {
      "style": "percent",
      "currency": "USD",
      "currencyDisplay": "symbol",
      "minimumFractionDigits": 0,
      "maximumFractionDigits": 0,
      "useGrouping": true
    },
    "probability": {
      "style": "percent",
      "minimumFractionDigits": 0,
      "maximumFractionDigits": 0,
      "useGrouping": true
    }
  },
  "treeDesigner": {
    "margin": {
      "left": 550,
      "right": 500,
      "top": 200,
      "bottom": 100
    },
    "scale": 1,
    "lng": "en",
    "layout": {
      "type": "cluster",
      "nodeSize": 22,
      "limitNodePositioning": true,
      "limitTextPositioning": true,
      "gridHeight": 85,
      "gridWidth": 185,
      "edgeSlantWidthMax": 0
    },
    "fontFamily": "sans-serif",
    "fontSize": "12px",
    "fontWeight": "normal",
    "fontStyle": "normal",
    "node": {
      "strokeWidth": "2px",
      "optimal": {
        "stroke": "#006f00",
        "strokeWidth": "2px"
      },
      "label": {
        "fontSize": "1em",
        "color": "black"
      },
      "payoff": {
        "fontSize": "1em",
        "color": "black",
        "negativeColor": "#b60000"
      },
      "decision": {
        "fill": "#ff7777",
        "stroke": "#660000",
        "selected": {
          "fill": "#aa3333"
        }
      },
      "chance": {
        "fill": "#ffff44",
        "stroke": "#666600",
        "selected": {
          "fill": "#aaaa00"
        }
      },
      "terminal": {
        "fill": "#44ff44",
        "stroke": "black",
        "selected": {
          "fill": "#00aa00"
        },
        "payoff": {
          "fontSize": "1em",
          "color": "black",
          "negativeColor": "#b60000"
        }
      }
    },
    "edge": {
      "stroke": "#424242",
      "strokeWidth": "1.5",
      "optimal": {
        "stroke": "#006f00",
        "strokeWidth": "2.4"
      },
      "selected": {
        "stroke": "#045ad1",
        "strokeWidth": "3.5"
      },
      "label": {
        "fontSize": "1em",
        "color": "back"
      },
      "payoff": {
        "fontSize": "1em",
        "color": "black",
        "negativeColor": "#b60000"
      }
    },
    "probability": {
      "fontSize": "1em",
      "color": "#0000d7"
    },
    "title": {
      "fontSize": "16px",
      "fontWeight": "bold",
      "fontStyle": "normal",
      "color": "#000000",
      "margin": {
        "top": 15,
        "bottom": 10
      }
    },
    "description": {
      "show": true,
      "fontSize": "12px",
      "fontWeight": "bold",
      "fontStyle": "normal",
      "color": "#000000",
      "margin": {
        "top": 5,
        "bottom": 10
      }
    },
    "readOnly": false,
    "disableAnimations": false,
    "forceFullEdgeRedraw": false,
    "hideLabels": false,
    "hidePayoffs": false,
    "hideExpectedValues": false,
    "hideProbabilities": true,
    "hideCharts": false,
    "hideTextboxes": false,
    "hideMilestones": false,
    "raw": false,
    "payoffNames": [
      null,
      null
    ],
    "maxPayoffsToDisplay": 1,
    "milestoneNames": [
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    ]
  },
  "data": {
    "code": "APos=10\nBPos=15\nANeg=-5\nBNeg=-12\n",
    "expressionScope": {
      "APos": 10,
      "BPos": 15,
      "ANeg": -5,
      "BNeg": -12
    },
    "trees": [
      {
        "computed": {
          "expected-value-maximization": {
            "childrenPayoff": [
              "3 1/4"
            ],
            "payoff": [
              "3 1/4"
            ],
            "optimal": true
          },
          "expected-value-minimization": {
            "childrenPayoff": [
              "0"
            ],
            "payoff": [
              "0"
            ],
            "optimal": true
          },
          "maxi-min": {
            "childrenPayoff": [
              "0"
            ],
            "payoff": [
              "0"
            ],
            "optimal": true
          },
          "maxi-max": {
            "childrenPayoff": [
              "25"
            ],
            "payoff": [
              "25"
            ],
            "optimal": true
          },
          "mini-min": {
            "childrenPayoff": [
              "-5"
            ],
            "payoff": [
              "-5"
            ],
            "optimal": true
          },
          "mini-max": {
            "childrenPayoff": [
              "0"
            ],
            "payoff": [
              "0"
            ],
            "optimal": true
          },
          "min-max": {
            "childrenPayoff": [
              "0",
              "0"
            ],
            "combinedPayoff": "0",
            "payoff": [
              "0",
              "0"
            ],
            "optimal": true
          },
          "max-min": {
            "childrenPayoff": [
              "3 1/4",
              "0"
            ],
            "combinedPayoff": "3 1/4",
            "payoff": [
              "3 1/4",
              "0"
            ],
            "optimal": true
          },
          "min-min": {
            "childrenPayoff": [
              "0",
              "0"
            ],
            "combinedPayoff": "0",
            "payoff": [
              "0",
              "0"
            ],
            "optimal": true
          },
          "max-max": {
            "childrenPayoff": [
              "3 1/4",
              "0"
            ],
            "combinedPayoff": "3 1/4",
            "payoff": [
              "3 1/4",
              "0"
            ],
            "optimal": true
          }
        },
        "childEdges": [
          {
            "computed": {
              "payoff": [
                "0",
                "0"
              ],
              "expected-value-maximization": {
                "probability": 1,
                "optimal": true
              },
              "expected-value-minimization": {
                "probability": 0,
                "optimal": false
              },
              "maxi-min": {
                "probability": 0,
                "optimal": false
              },
              "maxi-max": {
                "probability": 1,
                "optimal": true
              },
              "mini-min": {
                "probability": 1,
                "optimal": true
              },
              "mini-max": {
                "probability": 0,
                "optimal": false
              },
              "min-max": {
                "probability": 0,
                "optimal": false
              },
              "max-min": {
                "probability": 1,
                "optimal": true
              },
              "min-min": {
                "probability": 0,
                "optimal": false
              },
              "max-max": {
                "probability": 1,
                "optimal": true
              }
            },
            "name": "Approve",
            "payoff": [
              0,
              0
            ],
            "childNode": {
              "computed": {
                "expected-value-maximization": {
                  "childrenPayoff": [
                    "3 1/4"
                  ],
                  "payoff": [
                    "3 1/4"
                  ],
                  "optimal": true
                },
                "expected-value-minimization": {
                  "childrenPayoff": [
                    "2 1/2"
                  ],
                  "payoff": [
                    "2 1/2"
                  ]
                },
                "maxi-min": {
                  "childrenPayoff": [
                    "-5"
                  ],
                  "payoff": [
                    "-5"
                  ]
                },
                "maxi-max": {
                  "childrenPayoff": [
                    "25"
                  ],
                  "payoff": [
                    "25"
                  ],
                  "optimal": true
                },
                "mini-min": {
                  "childrenPayoff": [
                    "-5"
                  ],
                  "payoff": [
                    "-5"
                  ],
                  "optimal": true
                },
                "mini-max": {
                  "childrenPayoff": [
                    "10"
                  ],
                  "payoff": [
                    "10"
                  ]
                },
                "min-max": {
                  "childrenPayoff": [
                    "2 1/2",
                    "0"
                  ],
                  "combinedPayoff": "-2 1/2",
                  "payoff": [
                    "2 1/2",
                    "0"
                  ]
                },
                "max-min": {
                  "childrenPayoff": [
                    "3 1/4",
                    "0"
                  ],
                  "combinedPayoff": "3 1/4",
                  "payoff": [
                    "3 1/4",
                    "0"
                  ],
                  "optimal": true
                },
                "min-min": {
                  "childrenPayoff": [
                    "2 1/2",
                    "0"
                  ],
                  "combinedPayoff": "-2 1/2",
                  "payoff": [
                    "2 1/2",
                    "0"
                  ]
                },
                "max-max": {
                  "childrenPayoff": [
                    "3 1/4",
                    "0"
                  ],
                  "combinedPayoff": "3 1/4",
                  "payoff": [
                    "3 1/4",
                    "0"
                  ],
                  "optimal": true
                }
              },
              "childEdges": [
                {
                  "computed": {
                    "payoff": [
                      "10",
                      "0"
                    ],
                    "probability": "1/2",
                    "expected-value-maximization": {
                      "probability": "1/2",
                      "optimal": true
                    },
                    "expected-value-minimization": {
                      "probability": "1/2"
                    },
                    "maxi-min": {
                      "probability": 0
                    },
                    "maxi-max": {
                      "probability": 1,
                      "optimal": true
                    },
                    "mini-min": {
                      "probability": 0,
                      "optimal": false
                    },
                    "mini-max": {
                      "probability": 1
                    },
                    "min-max": {
                      "probability": "1/2"
                    },
                    "max-min": {
                      "probability": "1/2",
                      "optimal": true
                    },
                    "min-min": {
                      "probability": "1/2"
                    },
                    "max-max": {
                      "probability": "1/2",
                      "optimal": true
                    }
                  },
                  "name": "Positive\noutcome",
                  "probability": "#",
                  "payoff": [
                    "APos",
                    0
                  ],
                  "childNode": {
                    "computed": {
                      "expected-value-maximization": {
                        "childrenPayoff": [
                          "1 1/2"
                        ],
                        "payoff": [
                          "11 1/2"
                        ],
                        "optimal": true
                      },
                      "expected-value-minimization": {
                        "childrenPayoff": [
                          "0"
                        ],
                        "payoff": [
                          "10"
                        ]
                      },
                      "maxi-min": {
                        "childrenPayoff": [
                          "0"
                        ],
                        "payoff": [
                          "10"
                        ]
                      },
                      "maxi-max": {
                        "childrenPayoff": [
                          "15"
                        ],
                        "payoff": [
                          "25"
                        ],
                        "optimal": true
                      },
                      "mini-min": {
                        "childrenPayoff": [
                          "-12"
                        ],
                        "payoff": [
                          "-2"
                        ]
                      },
                      "mini-max": {
                        "childrenPayoff": [
                          "0"
                        ],
                        "payoff": [
                          "10"
                        ]
                      },
                      "min-max": {
                        "childrenPayoff": [
                          "0",
                          "0"
                        ],
                        "combinedPayoff": "-10",
                        "payoff": [
                          "10",
                          "0"
                        ]
                      },
                      "max-min": {
                        "childrenPayoff": [
                          "1 1/2",
                          "0"
                        ],
                        "combinedPayoff": "11 1/2",
                        "payoff": [
                          "11 1/2",
                          "0"
                        ],
                        "optimal": true
                      },
                      "min-min": {
                        "childrenPayoff": [
                          "0",
                          "0"
                        ],
                        "combinedPayoff": "-10",
                        "payoff": [
                          "10",
                          "0"
                        ]
                      },
                      "max-max": {
                        "childrenPayoff": [
                          "1 1/2",
                          "0"
                        ],
                        "combinedPayoff": "11 1/2",
                        "payoff": [
                          "11 1/2",
                          "0"
                        ],
                        "optimal": true
                      }
                    },
                    "childEdges": [
                      {
                        "computed": {
                          "payoff": [
                            "0",
                            "0"
                          ],
                          "expected-value-maximization": {
                            "probability": 1,
                            "optimal": true
                          },
                          "expected-value-minimization": {
                            "probability": 0
                          },
                          "maxi-min": {
                            "probability": 0
                          },
                          "maxi-max": {
                            "probability": 1,
                            "optimal": true
                          },
                          "mini-min": {
                            "probability": 1
                          },
                          "mini-max": {
                            "probability": 0
                          },
                          "min-max": {
                            "probability": 0
                          },
                          "max-min": {
                            "probability": 1,
                            "optimal": true
                          },
                          "min-min": {
                            "probability": 0
                          },
                          "max-max": {
                            "probability": 1,
                            "optimal": true
                          }
                        },
                        "name": "Continue",
                        "payoff": [
                          0,
                          0
                        ],
                        "childNode": {
                          "computed": {
                            "expected-value-maximization": {
                              "childrenPayoff": [
                                "1 1/2"
                              ],
                              "payoff": [
                                "1 1/2"
                              ],
                              "optimal": true
                            },
                            "expected-value-minimization": {
                              "childrenPayoff": [
                                "1 1/2"
                              ],
                              "payoff": [
                                "1 1/2"
                              ]
                            },
                            "maxi-min": {
                              "childrenPayoff": [
                                "-12"
                              ],
                              "payoff": [
                                "-12"
                              ]
                            },
                            "maxi-max": {
                              "childrenPayoff": [
                                "15"
                              ],
                              "payoff": [
                                "15"
                              ],
                              "optimal": true
                            },
                            "mini-min": {
                              "childrenPayoff": [
                                "-12"
                              ],
                              "payoff": [
                                "-12"
                              ]
                            },
                            "mini-max": {
                              "childrenPayoff": [
                                "15"
                              ],
                              "payoff": [
                                "15"
                              ]
                            },
                            "min-max": {
                              "childrenPayoff": [
                                "1 1/2",
                                "0"
                              ],
                              "combinedPayoff": "-1 1/2",
                              "payoff": [
                                "1 1/2",
                                "0"
                              ]
                            },
                            "max-min": {
                              "childrenPayoff": [
                                "1 1/2",
                                "0"
                              ],
                              "combinedPayoff": "1 1/2",
                              "payoff": [
                                "1 1/2",
                                "0"
                              ],
                              "optimal": true
                            },
                            "min-min": {
                              "childrenPayoff": [
                                "1 1/2",
                                "0"
                              ],
                              "combinedPayoff": "-1 1/2",
                              "payoff": [
                                "1 1/2",
                                "0"
                              ]
                            },
                            "max-max": {
                              "childrenPayoff": [
                                "1 1/2",
                                "0"
                              ],
                              "combinedPayoff": "1 1/2",
                              "payoff": [
                                "1 1/2",
                                "0"
                              ],
                              "optimal": true
                            }
                          },
                          "childEdges": [
                            {
                              "computed": {
                                "payoff": [
                                  "15",
                                  "0"
                                ],
                                "probability": "1/2",
                                "expected-value-maximization": {
                                  "probability": "1/2",
                                  "optimal": true
                                },
                                "expected-value-minimization": {
                                  "probability": "1/2"
                                },
                                "maxi-min": {
                                  "probability": 0
                                },
                                "maxi-max": {
                                  "probability": 1,
                                  "optimal": true
                                },
                                "mini-min": {
                                  "probability": 0
                                },
                                "mini-max": {
                                  "probability": 1
                                },
                                "min-max": {
                                  "probability": "1/2"
                                },
                                "max-min": {
                                  "probability": "1/2",
                                  "optimal": true
                                },
                                "min-min": {
                                  "probability": "1/2"
                                },
                                "max-max": {
                                  "probability": "1/2",
                                  "optimal": true
                                }
                              },
                              "name": "Positive\noutcome",
                              "probability": "#",
                              "payoff": [
                                "BPos",
                                0
                              ],
                              "childNode": {
                                "computed": {
                                  "expected-value-maximization": {
                                    "aggregatedPayoff": [
                                      "25"
                                    ],
                                    "probabilityToEnter": "1/4",
                                    "payoff": [
                                      "15"
                                    ],
                                    "optimal": true
                                  },
                                  "expected-value-minimization": {
                                    "aggregatedPayoff": [
                                      "25"
                                    ],
                                    "probabilityToEnter": 0,
                                    "payoff": [
                                      "15"
                                    ]
                                  },
                                  "maxi-min": {
                                    "aggregatedPayoff": [
                                      "25"
                                    ],
                                    "probabilityToEnter": 0,
                                    "payoff": [
                                      "15"
                                    ]
                                  },
                                  "maxi-max": {
                                    "aggregatedPayoff": [
                                      "25"
                                    ],
                                    "probabilityToEnter": "1",
                                    "payoff": [
                                      "15"
                                    ],
                                    "optimal": true
                                  },
                                  "mini-min": {
                                    "aggregatedPayoff": [
                                      "25"
                                    ],
                                    "probabilityToEnter": 0,
                                    "payoff": [
                                      "15"
                                    ]
                                  },
                                  "mini-max": {
                                    "aggregatedPayoff": [
                                      "25"
                                    ],
                                    "probabilityToEnter": 0,
                                    "payoff": [
                                      "15"
                                    ]
                                  },
                                  "min-max": {
                                    "aggregatedPayoff": [
                                      "25",
                                      "0"
                                    ],
                                    "probabilityToEnter": 0,
                                    "combinedPayoff": "-15",
                                    "payoff": [
                                      "15",
                                      "0"
                                    ]
                                  },
                                  "max-min": {
                                    "aggregatedPayoff": [
                                      "25",
                                      "0"
                                    ],
                                    "probabilityToEnter": "1/4",
                                    "combinedPayoff": "15",
                                    "payoff": [
                                      "15",
                                      "0"
                                    ],
                                    "optimal": true
                                  },
                                  "min-min": {
                                    "aggregatedPayoff": [
                                      "25",
                                      "0"
                                    ],
                                    "probabilityToEnter": 0,
                                    "combinedPayoff": "-15",
                                    "payoff": [
                                      "15",
                                      "0"
                                    ]
                                  },
                                  "max-max": {
                                    "aggregatedPayoff": [
                                      "25",
                                      "0"
                                    ],
                                    "probabilityToEnter": "1/4",
                                    "combinedPayoff": "15",
                                    "payoff": [
                                      "15",
                                      "0"
                                    ],
                                    "optimal": true
                                  }
                                },
                                "childEdges": [],
                                "name": "",
                                "code": "",
                                "expressionScope": {
                                  "APos": 10,
                                  "BPos": 15,
                                  "ANeg": -5,
                                  "BNeg": -12
                                },
                                "folded": false,
                                "location": {
                                  "x": 751,
                                  "y": 11
                                },
                                "type": "terminal",
                                "expectedValue": 25,
                                "p10": 18,
                                "p90": 32,
                                "charts": [
                                  {
                                    "nodeId": "0a13c00a-5683-4c60-5cf1-2574fe40ade9",
                                    "x": 46.99999237060547,
                                    "y": -39.00000208616257,
                                    "type": "distribution",
                                    "active": true
                                  },
                                  {
                                    "nodeId": "0a13c00a-5683-4c60-5cf1-2574fe40ade9",
                                    "x": 60,
                                    "y": -90,
                                    "type": "waterfall",
                                    "active": false
                                  },
                                  {
                                    "nodeId": "0a13c00a-5683-4c60-5cf1-2574fe40ade9",
                                    "x": 63,
                                    "y": 89,
                                    "type": "tornado",
                                    "active": false
                                  }
                                ]
                              },
                              "expectedValue": 15,
                              "p10": 10,
                              "p90": 21,
                              "optimal": true
                            },
                            {
                              "computed": {
                                "payoff": [
                                  "-12",
                                  "0"
                                ],
                                "probability": "1/2",
                                "expected-value-maximization": {
                                  "probability": "1/2",
                                  "optimal": true
                                },
                                "expected-value-minimization": {
                                  "probability": "1/2"
                                },
                                "maxi-min": {
                                  "probability": 1
                                },
                                "maxi-max": {
                                  "probability": 0,
                                  "optimal": false
                                },
                                "mini-min": {
                                  "probability": 1
                                },
                                "mini-max": {
                                  "probability": 0
                                },
                                "min-max": {
                                  "probability": "1/2"
                                },
                                "max-min": {
                                  "probability": "1/2",
                                  "optimal": true
                                },
                                "min-min": {
                                  "probability": "1/2"
                                },
                                "max-max": {
                                  "probability": "1/2",
                                  "optimal": true
                                }
                              },
                              "name": "Negative\noutcome",
                              "probability": "#",
                              "payoff": [
                                "BNeg",
                                0
                              ],
                              "childNode": {
                                "computed": {
                                  "expected-value-maximization": {
                                    "aggregatedPayoff": [
                                      "-2"
                                    ],
                                    "probabilityToEnter": "1/4",
                                    "payoff": [
                                      "-12"
                                    ],
                                    "optimal": true
                                  },
                                  "expected-value-minimization": {
                                    "aggregatedPayoff": [
                                      "-2"
                                    ],
                                    "probabilityToEnter": 0,
                                    "payoff": [
                                      "-12"
                                    ]
                                  },
                                  "maxi-min": {
                                    "aggregatedPayoff": [
                                      "-2"
                                    ],
                                    "probabilityToEnter": 0,
                                    "payoff": [
                                      "-12"
                                    ]
                                  },
                                  "maxi-max": {
                                    "aggregatedPayoff": [
                                      "-2"
                                    ],
                                    "probabilityToEnter": 0,
                                    "payoff": [
                                      "-12"
                                    ]
                                  },
                                  "mini-min": {
                                    "aggregatedPayoff": [
                                      "-2"
                                    ],
                                    "probabilityToEnter": 0,
                                    "payoff": [
                                      "-12"
                                    ]
                                  },
                                  "mini-max": {
                                    "aggregatedPayoff": [
                                      "-2"
                                    ],
                                    "probabilityToEnter": 0,
                                    "payoff": [
                                      "-12"
                                    ]
                                  },
                                  "min-max": {
                                    "aggregatedPayoff": [
                                      "-2",
                                      "0"
                                    ],
                                    "probabilityToEnter": 0,
                                    "combinedPayoff": "12",
                                    "payoff": [
                                      "-12",
                                      "0"
                                    ]
                                  },
                                  "max-min": {
                                    "aggregatedPayoff": [
                                      "-2",
                                      "0"
                                    ],
                                    "probabilityToEnter": "1/4",
                                    "combinedPayoff": "-12",
                                    "payoff": [
                                      "-12",
                                      "0"
                                    ],
                                    "optimal": true
                                  },
                                  "min-min": {
                                    "aggregatedPayoff": [
                                      "-2",
                                      "0"
                                    ],
                                    "probabilityToEnter": 0,
                                    "combinedPayoff": "12",
                                    "payoff": [
                                      "-12",
                                      "0"
                                    ]
                                  },
                                  "max-max": {
                                    "aggregatedPayoff": [
                                      "-2",
                                      "0"
                                    ],
                                    "probabilityToEnter": "1/4",
                                    "combinedPayoff": "-12",
                                    "payoff": [
                                      "-12",
                                      "0"
                                    ],
                                    "optimal": true
                                  }
                                },
                                "childEdges": [],
                                "name": "",
                                "code": "",
                                "expressionScope": {
                                  "APos": 10,
                                  "BPos": 15,
                                  "ANeg": -5,
                                  "BNeg": -12
                                },
                                "folded": false,
                                "location": {
                                  "x": 751,
                                  "y": 95.99999999999997
                                },
                                "type": "terminal",
                                "expectedValue": -2,
                                "p10": -8,
                                "p90": 4,
                                "charts": [
                                  {
                                    "nodeId": "231f5236-d56d-65b0-f305-9f6ecda3650f",
                                    "x": 47,
                                    "y": -41,
                                    "type": "distribution",
                                    "active": true
                                  }
                                ]
                              },
                              "expectedValue": -12,
                              "p10": -15,
                              "p90": -9,
                              "optimal": true
                            }
                          ],
                          "name": "Risk B",
                          "code": "",
                          "expressionScope": {
                            "APos": 10,
                            "BPos": 15,
                            "ANeg": -5,
                            "BNeg": -12
                          },
                          "folded": false,
                          "location": {
                            "x": 566,
                            "y": 53.49999999999997
                          },
                          "type": "chance",
                          "expectedValue": 1.5,
                          "p10": -14,
                          "p90": 19
                        },
                        "expectedValue": 0,
                        "p10": 0,
                        "p90": 0,
                        "optimal": true
                      },
                      {
                        "computed": {
                          "payoff": [
                            "0",
                            "0"
                          ],
                          "expected-value-maximization": {
                            "probability": 0,
                            "optimal": false
                          },
                          "expected-value-minimization": {
                            "probability": 1
                          },
                          "maxi-min": {
                            "probability": 1
                          },
                          "maxi-max": {
                            "probability": 0,
                            "optimal": false
                          },
                          "mini-min": {
                            "probability": 0
                          },
                          "mini-max": {
                            "probability": 1
                          },
                          "min-max": {
                            "probability": 1
                          },
                          "max-min": {
                            "probability": 0,
                            "optimal": false
                          },
                          "min-min": {
                            "probability": 1
                          },
                          "max-max": {
                            "probability": 0,
                            "optimal": false
                          }
                        },
                        "name": "Stop",
                        "payoff": [
                          0,
                          0
                        ],
                        "childNode": {
                          "computed": {
                            "expected-value-maximization": {
                              "aggregatedPayoff": [
                                "10"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "0"
                              ]
                            },
                            "expected-value-minimization": {
                              "aggregatedPayoff": [
                                "10"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "0"
                              ]
                            },
                            "maxi-min": {
                              "aggregatedPayoff": [
                                "10"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "0"
                              ]
                            },
                            "maxi-max": {
                              "aggregatedPayoff": [
                                "10"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "0"
                              ]
                            },
                            "mini-min": {
                              "aggregatedPayoff": [
                                "10"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "0"
                              ]
                            },
                            "mini-max": {
                              "aggregatedPayoff": [
                                "10"
                              ],
                              "probabilityToEnter": 0,
                              "payoff": [
                                "0"
                              ]
                            },
                            "min-max": {
                              "aggregatedPayoff": [
                                "10",
                                "0"
                              ],
                              "probabilityToEnter": 0,
                              "combinedPayoff": "0",
                              "payoff": [
                                "0",
                                "0"
                              ]
                            },
                            "max-min": {
                              "aggregatedPayoff": [
                                "10",
                                "0"
                              ],
                              "probabilityToEnter": 0,
                              "combinedPayoff": "0",
                              "payoff": [
                                "0",
                                "0"
                              ]
                            },
                            "min-min": {
                              "aggregatedPayoff": [
                                "10",
                                "0"
                              ],
                              "probabilityToEnter": 0,
                              "combinedPayoff": "0",
                              "payoff": [
                                "0",
                                "0"
                              ]
                            },
                            "max-max": {
                              "aggregatedPayoff": [
                                "10",
                                "0"
                              ],
                              "probabilityToEnter": 0,
                              "combinedPayoff": "0",
                              "payoff": [
                                "0",
                                "0"
                              ]
                            }
                          },
                          "childEdges": [],
                          "name": "",
                          "code": "",
                          "expressionScope": {
                            "APos": 10,
                            "BPos": 15,
                            "ANeg": -5,
                            "BNeg": -12
                          },
                          "folded": false,
                          "location": {
                            "x": 751,
                            "y": 198
                          },
                          "type": "terminal",
                          "expectedValue": 10,
                          "p10": 5,
                          "p90": 16,
                          "charts": [
                            {
                              "nodeId": "b149e3e7-c01c-08e7-e85d-5920b287ddae",
                              "x": 46,
                              "y": -39,
                              "type": "distribution",
                              "active": true
                            }
                          ]
                        },
                        "expectedValue": 0,
                        "p10": 0,
                        "p90": 0,
                        "optimal": false
                      }
                    ],
                    "name": "Decision",
                    "code": "",
                    "expressionScope": {
                      "APos": 10,
                      "BPos": 15,
                      "ANeg": -5,
                      "BNeg": -12
                    },
                    "folded": false,
                    "location": {
                      "x": 381,
                      "y": 125.74999999999997
                    },
                    "type": "decision",
                    "expectedValue": 1.5,
                    "p10": -14,
                    "p90": 19
                  },
                  "expectedValue": 10,
                  "p10": 5,
                  "p90": 16,
                  "optimal": true
                },
                {
                  "computed": {
                    "payoff": [
                      "-5",
                      "0"
                    ],
                    "probability": "1/2",
                    "expected-value-maximization": {
                      "probability": "1/2",
                      "optimal": true
                    },
                    "expected-value-minimization": {
                      "probability": "1/2"
                    },
                    "maxi-min": {
                      "probability": 1
                    },
                    "maxi-max": {
                      "probability": 0,
                      "optimal": false
                    },
                    "mini-min": {
                      "probability": 1,
                      "optimal": true
                    },
                    "mini-max": {
                      "probability": 0
                    },
                    "min-max": {
                      "probability": "1/2"
                    },
                    "max-min": {
                      "probability": "1/2",
                      "optimal": true
                    },
                    "min-min": {
                      "probability": "1/2"
                    },
                    "max-max": {
                      "probability": "1/2",
                      "optimal": true
                    }
                  },
                  "name": "Negative\noutcome",
                  "probability": "#",
                  "payoff": [
                    "ANeg",
                    0
                  ],
                  "childNode": {
                    "computed": {
                      "expected-value-maximization": {
                        "aggregatedPayoff": [
                          "-5"
                        ],
                        "probabilityToEnter": "1/2",
                        "payoff": [
                          "-5"
                        ],
                        "optimal": true
                      },
                      "expected-value-minimization": {
                        "aggregatedPayoff": [
                          "-5"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "-5"
                        ]
                      },
                      "maxi-min": {
                        "aggregatedPayoff": [
                          "-5"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "-5"
                        ]
                      },
                      "maxi-max": {
                        "aggregatedPayoff": [
                          "-5"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "-5"
                        ]
                      },
                      "mini-min": {
                        "aggregatedPayoff": [
                          "-5"
                        ],
                        "probabilityToEnter": "1",
                        "payoff": [
                          "-5"
                        ],
                        "optimal": true
                      },
                      "mini-max": {
                        "aggregatedPayoff": [
                          "-5"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "-5"
                        ]
                      },
                      "min-max": {
                        "aggregatedPayoff": [
                          "-5",
                          "0"
                        ],
                        "probabilityToEnter": 0,
                        "combinedPayoff": "5",
                        "payoff": [
                          "-5",
                          "0"
                        ]
                      },
                      "max-min": {
                        "aggregatedPayoff": [
                          "-5",
                          "0"
                        ],
                        "probabilityToEnter": "1/2",
                        "combinedPayoff": "-5",
                        "payoff": [
                          "-5",
                          "0"
                        ],
                        "optimal": true
                      },
                      "min-min": {
                        "aggregatedPayoff": [
                          "-5",
                          "0"
                        ],
                        "probabilityToEnter": 0,
                        "combinedPayoff": "5",
                        "payoff": [
                          "-5",
                          "0"
                        ]
                      },
                      "max-max": {
                        "aggregatedPayoff": [
                          "-5",
                          "0"
                        ],
                        "probabilityToEnter": "1/2",
                        "combinedPayoff": "-5",
                        "payoff": [
                          "-5",
                          "0"
                        ],
                        "optimal": true
                      }
                    },
                    "childEdges": [],
                    "name": "",
                    "code": "",
                    "expressionScope": {
                      "APos": 10,
                      "BPos": 15,
                      "ANeg": -5,
                      "BNeg": -12
                    },
                    "folded": false,
                    "location": {
                      "x": 751,
                      "y": 300
                    },
                    "type": "terminal",
                    "expectedValue": -5,
                    "p10": -7,
                    "p90": -3,
                    "charts": [
                      {
                        "nodeId": "dc02d232-e4e2-8653-d68c-ae89e9a5800e",
                        "x": 47,
                        "y": -39.000003814697266,
                        "type": "distribution",
                        "active": true
                      }
                    ]
                  },
                  "expectedValue": -5,
                  "p10": -7,
                  "p90": -3,
                  "optimal": true
                }
              ],
              "name": "Risk A",
              "code": "",
              "expressionScope": {
                "APos": 10,
                "BPos": 15,
                "ANeg": -5,
                "BNeg": -12
              },
              "folded": false,
              "location": {
                "x": 196,
                "y": 212.87499999999997
              },
              "type": "chance",
              "expectedValue": 3.25,
              "p10": -7,
              "p90": 27,
              "charts": [
                {
                  "nodeId": "9b38201a-97eb-38c8-f814-4d337040ed85",
                  "x": 50,
                  "y": -100,
                  "type": "distribution",
                  "active": false
                }
              ]
            },
            "expectedValue": 0,
            "p10": 0,
            "p90": 0,
            "optimal": true
          },
          {
            "computed": {
              "payoff": [
                "0",
                "0"
              ],
              "expected-value-maximization": {
                "probability": 0,
                "optimal": false
              },
              "expected-value-minimization": {
                "probability": 1,
                "optimal": true
              },
              "maxi-min": {
                "probability": 1,
                "optimal": true
              },
              "maxi-max": {
                "probability": 0,
                "optimal": false
              },
              "mini-min": {
                "probability": 0,
                "optimal": false
              },
              "mini-max": {
                "probability": 1,
                "optimal": true
              },
              "min-max": {
                "probability": 1,
                "optimal": true
              },
              "max-min": {
                "probability": 0,
                "optimal": false
              },
              "min-min": {
                "probability": 1,
                "optimal": true
              },
              "max-max": {
                "probability": 0,
                "optimal": false
              }
            },
            "name": "Stop",
            "payoff": [
              0,
              0
            ],
            "childNode": {
              "computed": {
                "expected-value-maximization": {
                  "aggregatedPayoff": [
                    "0"
                  ],
                  "probabilityToEnter": 0,
                  "payoff": [
                    "0"
                  ]
                },
                "expected-value-minimization": {
                  "aggregatedPayoff": [
                    "0"
                  ],
                  "probabilityToEnter": "1",
                  "payoff": [
                    "0"
                  ],
                  "optimal": true
                },
                "maxi-min": {
                  "aggregatedPayoff": [
                    "0"
                  ],
                  "probabilityToEnter": "1",
                  "payoff": [
                    "0"
                  ],
                  "optimal": true
                },
                "maxi-max": {
                  "aggregatedPayoff": [
                    "0"
                  ],
                  "probabilityToEnter": 0,
                  "payoff": [
                    "0"
                  ]
                },
                "mini-min": {
                  "aggregatedPayoff": [
                    "0"
                  ],
                  "probabilityToEnter": 0,
                  "payoff": [
                    "0"
                  ]
                },
                "mini-max": {
                  "aggregatedPayoff": [
                    "0"
                  ],
                  "probabilityToEnter": "1",
                  "payoff": [
                    "0"
                  ],
                  "optimal": true
                },
                "min-max": {
                  "aggregatedPayoff": [
                    "0",
                    "0"
                  ],
                  "probabilityToEnter": "1",
                  "combinedPayoff": "0",
                  "payoff": [
                    "0",
                    "0"
                  ],
                  "optimal": true
                },
                "max-min": {
                  "aggregatedPayoff": [
                    "0",
                    "0"
                  ],
                  "probabilityToEnter": 0,
                  "combinedPayoff": "0",
                  "payoff": [
                    "0",
                    "0"
                  ]
                },
                "min-min": {
                  "aggregatedPayoff": [
                    "0",
                    "0"
                  ],
                  "probabilityToEnter": "1",
                  "combinedPayoff": "0",
                  "payoff": [
                    "0",
                    "0"
                  ],
                  "optimal": true
                },
                "max-max": {
                  "aggregatedPayoff": [
                    "0",
                    "0"
                  ],
                  "probabilityToEnter": 0,
                  "combinedPayoff": "0",
                  "payoff": [
                    "0",
                    "0"
                  ]
                }
              },
              "childEdges": [],
              "name": "",
              "code": "",
              "expressionScope": {
                "APos": 10,
                "BPos": 15,
                "ANeg": -5,
                "BNeg": -12
              },
              "folded": false,
              "location": {
                "x": 751,
                "y": 402
              },
              "type": "terminal",
              "expectedValue": 0,
              "p10": 0,
              "p90": 0,
              "charts": [
                {
                  "nodeId": "17867b56-5eff-2523-81b2-f749789e8234",
                  "x": 47,
                  "y": -38.000003814697266,
                  "type": "distribution",
                  "active": true
                }
              ]
            },
            "expectedValue": 0,
            "p10": 0,
            "p90": 0,
            "optimal": false
          }
        ],
        "name": "Decision",
        "code": "",
        "expressionScope": {
          "APos": 10,
          "BPos": 15,
          "ANeg": -5,
          "BNeg": -12
        },
        "folded": false,
        "location": {
          "x": 11,
          "y": 307.4375
        },
        "type": "decision",
        "expectedValue": 3.25,
        "p10": -7,
        "p90": 27,
        "charts": [
          {
            "nodeId": "c94bfb16-a9ea-1f48-f46b-44fe9c76d89c",
            "x": -75.00000524520874,
            "y": -330.9999656677246,
            "type": "distribution",
            "active": true
          }
        ]
      }
    ],
    "texts": [],
    "payoffNames": [],
    "defaultCriterion1Weight": 1,
    "weightLowerBound": 0,
    "weightUpperBound": "Infinity"
  },
  "sensitivityAnalysis": {},
  "definitionsDialog": {
    "valueList": [
      {
        "name": "APos",
        "value": "10",
        "low": 5,
        "high": 15,
        "distribution": "triangular",
        "error": ""
      },
      {
        "name": "BPos",
        "value": "15",
        "low": 10,
        "high": 20,
        "distribution": "triangular",
        "error": ""
      },
      {
        "name": "ANeg",
        "value": "-5",
        "low": -7,
        "high": -3,
        "distribution": "triangular",
        "error": ""
      },
      {
        "name": "BNeg",
        "value": "-12",
        "low": -15,
        "high": -9,
        "distribution": "triangular",
        "error": ""
      },
      {
        "name": null,
        "value": "",
        "low": null,
        "high": null,
        "distribution": "constant",
        "error": ""
      }
    ]
  }
}