module.exports={
  "SilverDecisions": "1.1.0",
  "buildTimestamp": 1717147542185,
  "savetime": "2024-06-05T12:31:51.662Z",
  "lng": "en",
  "viewMode": "criterion1",
  "rule": "expected-value-maximization",
  "title": "Step10",
  "description": "",
  "format": {
    "locales": "en",
    "payoff1": {
      "style": "currency",
      "currency": "USD",
      "currencyDisplay": "symbol",
      "minimumFractionDigits": 0,
      "maximumFractionDigits": 0,
      "useGrouping": true
    },
    "payoff2": {
      "style": "percent",
      "currency": "USD",
      "currencyDisplay": "symbol",
      "minimumFractionDigits": 0,
      "maximumFractionDigits": 0,
      "useGrouping": true
    },
    "probability": {
      "style": "percent",
      "minimumFractionDigits": 0,
      "maximumFractionDigits": 0,
      "useGrouping": true
    }
  },
  "treeDesigner": {
    "margin": {
      "left": 520,
      "right": 500,
      "top": 250,
      "bottom": 100
    },
    "scale": 1,
    "lng": "en",
    "layout": {
      "type": "cluster",
      "nodeSize": 22,
      "limitNodePositioning": true,
      "limitTextPositioning": true,
      "gridHeight": 105,
      "gridWidth": 160,
      "edgeSlantWidthMax": 0
    },
    "fontFamily": "sans-serif",
    "fontSize": "12px",
    "fontWeight": "normal",
    "fontStyle": "normal",
    "node": {
      "strokeWidth": "2px",
      "optimal": {
        "stroke": "#006f00",
        "strokeWidth": "2px"
      },
      "label": {
        "fontSize": "1em",
        "color": "black"
      },
      "payoff": {
        "fontSize": "1em",
        "color": "black",
        "negativeColor": "#b60000"
      },
      "decision": {
        "fill": "#ff7777",
        "stroke": "#660000",
        "selected": {
          "fill": "#aa3333"
        }
      },
      "chance": {
        "fill": "#ffff44",
        "stroke": "#666600",
        "selected": {
          "fill": "#aaaa00"
        }
      },
      "terminal": {
        "fill": "#44ff44",
        "stroke": "black",
        "selected": {
          "fill": "#00aa00"
        },
        "payoff": {
          "fontSize": "1em",
          "color": "black",
          "negativeColor": "#b60000"
        }
      }
    },
    "edge": {
      "stroke": "#424242",
      "strokeWidth": "1.5",
      "optimal": {
        "stroke": "#006f00",
        "strokeWidth": "2.4"
      },
      "selected": {
        "stroke": "#045ad1",
        "strokeWidth": "3.5"
      },
      "label": {
        "fontSize": "1em",
        "color": "back"
      },
      "payoff": {
        "fontSize": "1em",
        "color": "black",
        "negativeColor": "#b60000"
      }
    },
    "probability": {
      "fontSize": "1em",
      "color": "#0000d7"
    },
    "title": {
      "fontSize": "16px",
      "fontWeight": "bold",
      "fontStyle": "normal",
      "color": "#000000",
      "margin": {
        "top": 15,
        "bottom": 10
      }
    },
    "description": {
      "show": true,
      "fontSize": "12px",
      "fontWeight": "bold",
      "fontStyle": "normal",
      "color": "#000000",
      "margin": {
        "top": 5,
        "bottom": 10
      }
    },
    "readOnly": false,
    "disableAnimations": false,
    "forceFullEdgeRedraw": false,
    "hideLabels": false,
    "hidePayoffs": true,
    "hideExpectedValues": false,
    "hideProbabilities": true,
    "hideCharts": false,
    "hideTextboxes": false,
    "hideMilestones": false,
    "raw": false,
    "payoffNames": [
      null,
      null
    ],
    "maxPayoffsToDisplay": 1,
    "milestoneNames": [
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    ]
  },
  "data": {
    "code": "MainClaimA=43\nClaimB=20\nClaimC=14\n",
    "expressionScope": {
      "MainClaimA": 43,
      "ClaimB": 20,
      "ClaimC": 14
    },
    "trees": [
      {
        "computed": {
          "expected-value-maximization": {
            "childrenPayoff": [
              "58 49577598069649/140737488355328"
            ],
            "payoff": [
              "57 51441066624935/52776558133248"
            ],
            "optimal": true
          },
          "expected-value-minimization": {
            "childrenPayoff": [
              "57 51441066624935/52776558133248"
            ],
            "payoff": [
              "57 51441066624935/52776558133248"
            ],
            "optimal": true
          },
          "maxi-min": {
            "childrenPayoff": [
              "43 19874253694977/140737488355328"
            ],
            "payoff": [
              "43 19874253694977/140737488355328"
            ],
            "optimal": true
          },
          "maxi-max": {
            "childrenPayoff": [
              "80 13772412824231/17592186044416"
            ],
            "payoff": [
              "80 13772412824231/17592186044416"
            ],
            "optimal": true
          },
          "mini-min": {
            "childrenPayoff": [
              "43 19874253694977/140737488355328"
            ],
            "payoff": [
              "43 19874253694977/140737488355328"
            ],
            "optimal": true
          },
          "mini-max": {
            "childrenPayoff": [
              "80 13772412824231/17592186044416"
            ],
            "payoff": [
              "80 13772412824231/17592186044416"
            ],
            "optimal": true
          },
          "min-max": {
            "childrenPayoff": [
              "57 51441066624935/52776558133248",
              "0"
            ],
            "combinedPayoff": "-57 51441066624935/52776558133248",
            "payoff": [
              "57 51441066624935/52776558133248",
              "0"
            ],
            "optimal": true
          },
          "max-min": {
            "childrenPayoff": [
              "57 51441066624935/52776558133248",
              "0"
            ],
            "combinedPayoff": "57 51441066624935/52776558133248",
            "payoff": [
              "57 51441066624935/52776558133248",
              "0"
            ],
            "optimal": true
          },
          "min-min": {
            "childrenPayoff": [
              "57 51441066624935/52776558133248",
              "0"
            ],
            "combinedPayoff": "-57 51441066624935/52776558133248",
            "payoff": [
              "57 51441066624935/52776558133248",
              "0"
            ],
            "optimal": true
          },
          "max-max": {
            "childrenPayoff": [
              "57 51441066624935/52776558133248",
              "0"
            ],
            "combinedPayoff": "57 51441066624935/52776558133248",
            "payoff": [
              "57 51441066624935/52776558133248",
              "0"
            ],
            "optimal": true
          }
        },
        "childEdges": [
          {
            "computed": {
              "payoff": [
                "30",
                "0"
              ],
              "expected-value-maximization": {
                "probability": 1,
                "optimal": true
              },
              "expected-value-minimization": {
                "probability": 1,
                "optimal": true
              },
              "maxi-min": {
                "probability": 1,
                "optimal": true
              },
              "maxi-max": {
                "probability": 1,
                "optimal": true
              },
              "mini-min": {
                "probability": 1,
                "optimal": true
              },
              "mini-max": {
                "probability": 1,
                "optimal": true
              },
              "min-max": {
                "probability": 1,
                "optimal": true
              },
              "max-min": {
                "probability": 1,
                "optimal": true
              },
              "min-min": {
                "probability": 1,
                "optimal": true
              },
              "max-max": {
                "probability": 1,
                "optimal": true
              }
            },
            "name": "",
            "payoff": [
              "30",
              0
            ],
            "childNode": {
              "computed": {
                "expected-value-maximization": {
                  "childrenPayoff": [
                    "28 49577598069645/140737488355328"
                  ],
                  "payoff": [
                    "57 51441066624935/52776558133248"
                  ],
                  "optimal": true
                },
                "expected-value-minimization": {
                  "childrenPayoff": [
                    "27 51441066624935/52776558133248"
                  ],
                  "payoff": [
                    "57 51441066624935/52776558133248"
                  ],
                  "optimal": true
                },
                "maxi-min": {
                  "childrenPayoff": [
                    "13 79497014779907/562949953421312"
                  ],
                  "payoff": [
                    "43 19874253694977/140737488355328"
                  ],
                  "optimal": true
                },
                "maxi-max": {
                  "childrenPayoff": [
                    "50 13772412824231/17592186044416"
                  ],
                  "payoff": [
                    "80 13772412824231/17592186044416"
                  ],
                  "optimal": true
                },
                "mini-min": {
                  "childrenPayoff": [
                    "13 79497014779907/562949953421312"
                  ],
                  "payoff": [
                    "43 19874253694977/140737488355328"
                  ],
                  "optimal": true
                },
                "mini-max": {
                  "childrenPayoff": [
                    "50 13772412824231/17592186044416"
                  ],
                  "payoff": [
                    "80 13772412824231/17592186044416"
                  ],
                  "optimal": true
                },
                "min-max": {
                  "childrenPayoff": [
                    "27 51441066624935/52776558133248",
                    "0"
                  ],
                  "combinedPayoff": "-57 51441066624935/52776558133248",
                  "payoff": [
                    "57 51441066624935/52776558133248",
                    "0"
                  ],
                  "optimal": true
                },
                "max-min": {
                  "childrenPayoff": [
                    "27 51441066624935/52776558133248",
                    "0"
                  ],
                  "combinedPayoff": "57 51441066624935/52776558133248",
                  "payoff": [
                    "57 51441066624935/52776558133248",
                    "0"
                  ],
                  "optimal": true
                },
                "min-min": {
                  "childrenPayoff": [
                    "27 51441066624935/52776558133248",
                    "0"
                  ],
                  "combinedPayoff": "-57 51441066624935/52776558133248",
                  "payoff": [
                    "57 51441066624935/52776558133248",
                    "0"
                  ],
                  "optimal": true
                },
                "max-max": {
                  "childrenPayoff": [
                    "27 51441066624935/52776558133248",
                    "0"
                  ],
                  "combinedPayoff": "57 51441066624935/52776558133248",
                  "payoff": [
                    "57 51441066624935/52776558133248",
                    "0"
                  ],
                  "optimal": true
                }
              },
              "childEdges": [
                {
                  "computed": {
                    "payoff": [
                      "50 13772412824231/17592186044416",
                      "0"
                    ],
                    "probability": "1/3",
                    "expected-value-maximization": {
                      "probability": "1/3",
                      "optimal": true
                    },
                    "expected-value-minimization": {
                      "probability": "1/3",
                      "optimal": true
                    },
                    "maxi-min": {
                      "probability": 0,
                      "optimal": false
                    },
                    "maxi-max": {
                      "probability": 1,
                      "optimal": true
                    },
                    "mini-min": {
                      "probability": 0,
                      "optimal": false
                    },
                    "mini-max": {
                      "probability": 1,
                      "optimal": true
                    },
                    "min-max": {
                      "probability": "1/3",
                      "optimal": true
                    },
                    "max-min": {
                      "probability": "1/3",
                      "optimal": true
                    },
                    "min-min": {
                      "probability": "1/3",
                      "optimal": true
                    },
                    "max-max": {
                      "probability": "1/3",
                      "optimal": true
                    }
                  },
                  "name": "",
                  "probability": "#",
                  "payoff": [
                    "MainClaimA",
                    0
                  ],
                  "childNode": {
                    "computed": {
                      "expected-value-maximization": {
                        "aggregatedPayoff": [
                          "80 55089651296923/70368744177664"
                        ],
                        "probabilityToEnter": "1/3",
                        "payoff": [
                          "50 13772412824231/17592186044416"
                        ],
                        "optimal": true
                      },
                      "expected-value-minimization": {
                        "aggregatedPayoff": [
                          "80 13772412824231/17592186044416"
                        ],
                        "probabilityToEnter": "1/3",
                        "payoff": [
                          "50 13772412824231/17592186044416"
                        ],
                        "optimal": true
                      },
                      "maxi-min": {
                        "aggregatedPayoff": [
                          "80 13772412824231/17592186044416"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "50 13772412824231/17592186044416"
                        ]
                      },
                      "maxi-max": {
                        "aggregatedPayoff": [
                          "80 13772412824231/17592186044416"
                        ],
                        "probabilityToEnter": "1",
                        "payoff": [
                          "50 13772412824231/17592186044416"
                        ],
                        "optimal": true
                      },
                      "mini-min": {
                        "aggregatedPayoff": [
                          "80 13772412824231/17592186044416"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "50 13772412824231/17592186044416"
                        ]
                      },
                      "mini-max": {
                        "aggregatedPayoff": [
                          "80 13772412824231/17592186044416"
                        ],
                        "probabilityToEnter": "1",
                        "payoff": [
                          "50 13772412824231/17592186044416"
                        ],
                        "optimal": true
                      },
                      "min-max": {
                        "aggregatedPayoff": [
                          "80 13772412824231/17592186044416",
                          "0"
                        ],
                        "probabilityToEnter": "1/3",
                        "combinedPayoff": "-50 13772412824231/17592186044416",
                        "payoff": [
                          "50 13772412824231/17592186044416",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-min": {
                        "aggregatedPayoff": [
                          "80 13772412824231/17592186044416",
                          "0"
                        ],
                        "probabilityToEnter": "1/3",
                        "combinedPayoff": "50 13772412824231/17592186044416",
                        "payoff": [
                          "50 13772412824231/17592186044416",
                          "0"
                        ],
                        "optimal": true
                      },
                      "min-min": {
                        "aggregatedPayoff": [
                          "80 13772412824231/17592186044416",
                          "0"
                        ],
                        "probabilityToEnter": "1/3",
                        "combinedPayoff": "-50 13772412824231/17592186044416",
                        "payoff": [
                          "50 13772412824231/17592186044416",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-max": {
                        "aggregatedPayoff": [
                          "80 13772412824231/17592186044416",
                          "0"
                        ],
                        "probabilityToEnter": "1/3",
                        "combinedPayoff": "50 13772412824231/17592186044416",
                        "payoff": [
                          "50 13772412824231/17592186044416",
                          "0"
                        ],
                        "optimal": true
                      }
                    },
                    "childEdges": [],
                    "name": "",
                    "code": "",
                    "expressionScope": {
                      "MainClaimA": 43,
                      "ClaimB": 20,
                      "ClaimC": 14
                    },
                    "folded": false,
                    "location": {
                      "x": 331,
                      "y": 11
                    },
                    "type": "terminal",
                    "expectedValue": 80.78287103089171,
                    "p10": 66.23664962765201,
                    "p90": 98.23664962765201,
                    "charts": [
                      {
                        "nodeId": "da92370a-702b-f453-ade2-19b9f8e62487",
                        "x": 57,
                        "y": -42,
                        "type": "bar",
                        "active": true
                      },
                      {
                        "nodeId": "da92370a-702b-f453-ade2-19b9f8e62487",
                        "x": 368.99999237060547,
                        "y": -41.99999713897705,
                        "type": "distribution",
                        "active": true
                      },
                      {
                        "nodeId": "da92370a-702b-f453-ade2-19b9f8e62487",
                        "x": 380.99998474121094,
                        "y": -54,
                        "type": "tornado",
                        "active": false
                      },
                      {
                        "nodeId": "da92370a-702b-f453-ade2-19b9f8e62487",
                        "x": 212.99998474121094,
                        "y": -42,
                        "type": "waterfall",
                        "active": true
                      }
                    ]
                  },
                  "expectedValue": 50.78287103089173,
                  "p10": 36.236649627652014,
                  "p90": 68.23664962765201,
                  "optimal": true
                },
                {
                  "computed": {
                    "payoff": [
                      "20",
                      "0"
                    ],
                    "probability": "1/3",
                    "expected-value-maximization": {
                      "probability": "1/3",
                      "optimal": true
                    },
                    "expected-value-minimization": {
                      "probability": "1/3",
                      "optimal": true
                    },
                    "maxi-min": {
                      "probability": 0,
                      "optimal": false
                    },
                    "maxi-max": {
                      "probability": 0,
                      "optimal": false
                    },
                    "mini-min": {
                      "probability": 0,
                      "optimal": false
                    },
                    "mini-max": {
                      "probability": 0,
                      "optimal": false
                    },
                    "min-max": {
                      "probability": "1/3",
                      "optimal": true
                    },
                    "max-min": {
                      "probability": "1/3",
                      "optimal": true
                    },
                    "min-min": {
                      "probability": "1/3",
                      "optimal": true
                    },
                    "max-max": {
                      "probability": "1/3",
                      "optimal": true
                    }
                  },
                  "name": "",
                  "probability": "#",
                  "payoff": [
                    "ClaimB",
                    0
                  ],
                  "childNode": {
                    "computed": {
                      "expected-value-maximization": {
                        "aggregatedPayoff": [
                          "50"
                        ],
                        "probabilityToEnter": "1/3",
                        "payoff": [
                          "20"
                        ],
                        "optimal": true
                      },
                      "expected-value-minimization": {
                        "aggregatedPayoff": [
                          "50"
                        ],
                        "probabilityToEnter": "1/3",
                        "payoff": [
                          "20"
                        ],
                        "optimal": true
                      },
                      "maxi-min": {
                        "aggregatedPayoff": [
                          "50"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "20"
                        ]
                      },
                      "maxi-max": {
                        "aggregatedPayoff": [
                          "50"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "20"
                        ]
                      },
                      "mini-min": {
                        "aggregatedPayoff": [
                          "50"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "20"
                        ]
                      },
                      "mini-max": {
                        "aggregatedPayoff": [
                          "50"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "20"
                        ]
                      },
                      "min-max": {
                        "aggregatedPayoff": [
                          "50",
                          "0"
                        ],
                        "probabilityToEnter": "1/3",
                        "combinedPayoff": "-20",
                        "payoff": [
                          "20",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-min": {
                        "aggregatedPayoff": [
                          "50",
                          "0"
                        ],
                        "probabilityToEnter": "1/3",
                        "combinedPayoff": "20",
                        "payoff": [
                          "20",
                          "0"
                        ],
                        "optimal": true
                      },
                      "min-min": {
                        "aggregatedPayoff": [
                          "50",
                          "0"
                        ],
                        "probabilityToEnter": "1/3",
                        "combinedPayoff": "-20",
                        "payoff": [
                          "20",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-max": {
                        "aggregatedPayoff": [
                          "50",
                          "0"
                        ],
                        "probabilityToEnter": "1/3",
                        "combinedPayoff": "20",
                        "payoff": [
                          "20",
                          "0"
                        ],
                        "optimal": true
                      }
                    },
                    "childEdges": [],
                    "name": "",
                    "code": "",
                    "expressionScope": {
                      "MainClaimA": 43,
                      "ClaimB": 20,
                      "ClaimC": 14
                    },
                    "folded": false,
                    "location": {
                      "x": 331,
                      "y": 116
                    },
                    "type": "terminal",
                    "expectedValue": 50,
                    "p10": 46,
                    "p90": 54,
                    "charts": [
                      {
                        "nodeId": "9e792b1d-9be8-baef-0c4e-49ebdc061610",
                        "x": 57,
                        "y": -41.000003814697266,
                        "type": "bar",
                        "active": true
                      },
                      {
                        "nodeId": "9e792b1d-9be8-baef-0c4e-49ebdc061610",
                        "x": 369.99998474121094,
                        "y": -41.000003814697266,
                        "type": "distribution",
                        "active": true
                      },
                      {
                        "nodeId": "9e792b1d-9be8-baef-0c4e-49ebdc061610",
                        "x": 65,
                        "y": -90,
                        "type": "tornado",
                        "active": false
                      },
                      {
                        "nodeId": "9e792b1d-9be8-baef-0c4e-49ebdc061610",
                        "x": 213.99998474121094,
                        "y": -41.000003814697266,
                        "type": "waterfall",
                        "active": true
                      }
                    ]
                  },
                  "expectedValue": 20,
                  "p10": 16,
                  "p90": 24,
                  "optimal": true
                },
                {
                  "computed": {
                    "payoff": [
                      "13 79497014779907/562949953421312",
                      "0"
                    ],
                    "probability": "1/3",
                    "expected-value-maximization": {
                      "probability": "1/3",
                      "optimal": true
                    },
                    "expected-value-minimization": {
                      "probability": "1/3",
                      "optimal": true
                    },
                    "maxi-min": {
                      "probability": 1,
                      "optimal": true
                    },
                    "maxi-max": {
                      "probability": 0,
                      "optimal": false
                    },
                    "mini-min": {
                      "probability": 1,
                      "optimal": true
                    },
                    "mini-max": {
                      "probability": 0,
                      "optimal": false
                    },
                    "min-max": {
                      "probability": "1/3",
                      "optimal": true
                    },
                    "max-min": {
                      "probability": "1/3",
                      "optimal": true
                    },
                    "min-min": {
                      "probability": "1/3",
                      "optimal": true
                    },
                    "max-max": {
                      "probability": "1/3",
                      "optimal": true
                    }
                  },
                  "name": "",
                  "probability": "#",
                  "payoff": [
                    "ClaimC",
                    0
                  ],
                  "childNode": {
                    "computed": {
                      "expected-value-maximization": {
                        "aggregatedPayoff": [
                          "43 19874253694977/140737488355328"
                        ],
                        "probabilityToEnter": "1/3",
                        "payoff": [
                          "13 79497014779907/562949953421312"
                        ],
                        "optimal": true
                      },
                      "expected-value-minimization": {
                        "aggregatedPayoff": [
                          "43 19874253694977/140737488355328"
                        ],
                        "probabilityToEnter": "1/3",
                        "payoff": [
                          "13 79497014779907/562949953421312"
                        ],
                        "optimal": true
                      },
                      "maxi-min": {
                        "aggregatedPayoff": [
                          "43 19874253694977/140737488355328"
                        ],
                        "probabilityToEnter": "1",
                        "payoff": [
                          "13 79497014779907/562949953421312"
                        ],
                        "optimal": true
                      },
                      "maxi-max": {
                        "aggregatedPayoff": [
                          "43 19874253694977/140737488355328"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "13 79497014779907/562949953421312"
                        ]
                      },
                      "mini-min": {
                        "aggregatedPayoff": [
                          "43 19874253694977/140737488355328"
                        ],
                        "probabilityToEnter": "1",
                        "payoff": [
                          "13 79497014779907/562949953421312"
                        ],
                        "optimal": true
                      },
                      "mini-max": {
                        "aggregatedPayoff": [
                          "43 19874253694977/140737488355328"
                        ],
                        "probabilityToEnter": 0,
                        "payoff": [
                          "13 79497014779907/562949953421312"
                        ]
                      },
                      "min-max": {
                        "aggregatedPayoff": [
                          "43 19874253694977/140737488355328",
                          "0"
                        ],
                        "probabilityToEnter": "1/3",
                        "combinedPayoff": "-13 79497014779907/562949953421312",
                        "payoff": [
                          "13 79497014779907/562949953421312",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-min": {
                        "aggregatedPayoff": [
                          "43 19874253694977/140737488355328",
                          "0"
                        ],
                        "probabilityToEnter": "1/3",
                        "combinedPayoff": "13 79497014779907/562949953421312",
                        "payoff": [
                          "13 79497014779907/562949953421312",
                          "0"
                        ],
                        "optimal": true
                      },
                      "min-min": {
                        "aggregatedPayoff": [
                          "43 19874253694977/140737488355328",
                          "0"
                        ],
                        "probabilityToEnter": "1/3",
                        "combinedPayoff": "-13 79497014779907/562949953421312",
                        "payoff": [
                          "13 79497014779907/562949953421312",
                          "0"
                        ],
                        "optimal": true
                      },
                      "max-max": {
                        "aggregatedPayoff": [
                          "43 19874253694977/140737488355328",
                          "0"
                        ],
                        "probabilityToEnter": "1/3",
                        "combinedPayoff": "13 79497014779907/562949953421312",
                        "payoff": [
                          "13 79497014779907/562949953421312",
                          "0"
                        ],
                        "optimal": true
                      }
                    },
                    "childEdges": [],
                    "name": "",
                    "code": "",
                    "expressionScope": {
                      "MainClaimA": 43,
                      "ClaimB": 20,
                      "ClaimC": 14
                    },
                    "folded": false,
                    "location": {
                      "x": 331,
                      "y": 221
                    },
                    "type": "terminal",
                    "expectedValue": 43.14121506591619,
                    "p10": 37.68468679789435,
                    "p90": 47.68468679789435,
                    "charts": [
                      {
                        "nodeId": "a111e74e-09e6-f21e-fdf0-6e20498695c6",
                        "x": 56,
                        "y": -42.99999809265137,
                        "type": "bar",
                        "active": true
                      },
                      {
                        "nodeId": "a111e74e-09e6-f21e-fdf0-6e20498695c6",
                        "x": 369.99998474121094,
                        "y": -43,
                        "type": "distribution",
                        "active": true
                      },
                      {
                        "nodeId": "a111e74e-09e6-f21e-fdf0-6e20498695c6",
                        "x": 213.99998474121094,
                        "y": -42.99999928474426,
                        "type": "waterfall",
                        "active": true
                      }
                    ]
                  },
                  "expectedValue": 13.14121506591619,
                  "p10": 7.684686797894351,
                  "p90": 17.68468679789435,
                  "optimal": true
                }
              ],
              "name": "",
              "code": "",
              "expressionScope": {
                "MainClaimA": 43,
                "ClaimB": 20,
                "ClaimC": 14
              },
              "folded": false,
              "location": {
                "x": 171,
                "y": 116
              },
              "type": "chance",
              "expectedValue": 28.352270021648202,
              "p10": 10.68468679789435,
              "p90": 57.68468679789435,
              "charts": [
                {
                  "nodeId": "c2399cc8-d763-c6f4-f208-cc26100779fd",
                  "x": -73.99999904632568,
                  "y": -106,
                  "type": "distribution",
                  "active": false
                }
              ]
            },
            "expectedValue": 30,
            "p10": 30,
            "p90": 30,
            "optimal": true
          }
        ],
        "name": "",
        "code": "",
        "expressionScope": {
          "MainClaimA": 43,
          "ClaimB": 20,
          "ClaimC": 14
        },
        "folded": false,
        "location": {
          "x": 11,
          "y": 116
        },
        "type": "decision",
        "expectedValue": 58.35227002164823,
        "p10": 40.68468679789435,
        "p90": 87.68468679789436,
        "charts": [
          {
            "nodeId": "b09007a7-a9d3-ba27-22b7-4bd7fbc05ffb",
            "x": -75.9999942779541,
            "y": -124,
            "type": "tornado",
            "active": true
          },
          {
            "nodeId": "b09007a7-a9d3-ba27-22b7-4bd7fbc05ffb",
            "x": -73.99999618530273,
            "y": -106,
            "type": "distribution",
            "active": false
          }
        ]
      }
    ],
    "texts": [
      {
        "value": "1. Bar chart",
        "location": {
          "x": 391,
          "y": -46
        }
      },
      {
        "value": "2. Waterfall",
        "location": {
          "x": 546,
          "y": -46.33161544799805
        }
      },
      {
        "value": "3. Distribution",
        "location": {
          "x": 700,
          "y": -46.33162307739258
        }
      },
      {
        "value": "4. Tornado",
        "location": {
          "x": -64,
          "y": -20.331621170043945
        }
      }
    ],
    "payoffNames": [],
    "defaultCriterion1Weight": 1,
    "weightLowerBound": 0,
    "weightUpperBound": "Infinity"
  },
  "sensitivityAnalysis": {},
  "definitionsDialog": {
    "valueList": [
      {
        "name": "MainClaimA",
        "value": "43",
        "low": 36,
        "high": 68,
        "distribution": "triangular",
        "error": ""
      },
      {
        "name": "ClaimB",
        "value": "20",
        "low": 16,
        "high": 24,
        "distribution": "triangular",
        "error": ""
      },
      {
        "name": "ClaimC",
        "value": "14",
        "low": 8,
        "high": 18,
        "distribution": "triangular",
        "error": ""
      },
      {
        "name": null,
        "value": "",
        "low": null,
        "high": null,
        "distribution": "constant",
        "error": ""
      },
      {
        "name": null,
        "value": "",
        "low": null,
        "high": null,
        "distribution": "constant",
        "error": ""
      }
    ]
  }
}